import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Menu } from 'antd'; // Import Menu from Ant Design
import { PlusOutlined } from '@ant-design/icons';

const DropDown = ({ name, options, allTags, setterFunc, fetchOptions, onChange, setInputVisibleType }) => {
  const [visible, setVisible] = useState(false); // State to manage dropdown visibility

  const onDropdownVisibleChange = (open) => {
    if (open && typeof fetchOptions === 'function') {
      fetchOptions();
    }
    setVisible(open); // Update visibility state
  };

  const onTagsMenuClick = (option) => {
    const tag = option.value;
    const updatedTags = allTags.includes(tag) ? allTags.filter((elem) => elem !== tag) : [...allTags, tag];
    setterFunc(updatedTags);
    if (typeof onChange === 'function') {
      // <-- BE VERY CAREFULL WITH THIS OPERATION
      onChange(updatedTags);
    }
  };

  useEffect(() => {
    if (typeof fetchOptions === 'function') {
      fetchOptions();
    }
  }, []);

  return (
    <Dropdown
      className="preferences-container__space--addTag"
      name={name}
      trigger={['click']}
      visible={visible} // Set the visible prop to control dropdown visibility manually
      onVisibleChange={onDropdownVisibleChange}
      overlayClassName="preferences-container__space--addTagOverlay"
      overlay={
        <Menu>
          {options.map((option) => (
            <Menu.Item
              key={option.key}
              onClick={() => {
                if (option?.value?.toLowerCase()?.trim() === 'others') {
                  setVisible(false);
                  setInputVisibleType?.({ status: true, type: name });
                } else {
                  onTagsMenuClick(option);
                }
              }}
              className={allTags.includes(option.value) ? 'ant-dropdown-menu-item-selected' : ''}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {option.label}
              </div>
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <Button>
        <PlusOutlined /> Add Tag
      </Button>
    </Dropdown>
  );
};

export default DropDown;
