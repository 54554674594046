// imports
import { CheckSquareOutlined } from '@ant-design/icons';
import { nanoid } from '@reduxjs/toolkit';
import { Switch } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { reset_data, setSearchContext, setpropertyToggle } from '../../../../leadgen/features/searchSlice';
import { commericalPropertyRadios, residentialPropertyRadios } from './topNav.utils';
import './topNav.scss';
import './PropertyTypeToggle.scss';
const PropertyTypeToggle = ({ setSearchParams, setSearchQuery }) => {
  // hooks
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigateTo = useNavigate();

  // States
  const { propertyToggle, searchContext } = useSelector((store) => store.search);

  return (
    <div className="d-flex a-center  g-10 property-type-toggle">
      <Switch
        // style={{ width: 130 }}
        className="topNavSwitch"
        checkedChildren="Commercial"
        unCheckedChildren="Residential"
        defaultChecked
        value={propertyToggle}
        onChange={(e) => {
          dispatch(setpropertyToggle(!propertyToggle));
          dispatch(setSearchContext(!e ? 'residentialProperty' : 'commercialProperty'));
          dispatch(reset_data());
          setSearchQuery('');
          setSearchParams('');
          if (!pathname?.includes('/leadgen/search')) navigateTo('/leadgen/search');
        }}
      />
      <div>
        {(propertyToggle ? commericalPropertyRadios : residentialPropertyRadios)?.map((element) => (
          <span
            onClick={() => {
              if (searchContext == element?.value) {
                return;
              }
              dispatch(reset_data());
              setSearchQuery('');
              setSearchParams('');
              if (!pathname?.includes('/leadgen/search')) navigateTo('/leadgen/search');
              if (element?.disabled) return;
              dispatch(setSearchContext(element?.value));
            }}
            className={`${element?.disabled ? 'disabled' : ''} ${
              searchContext == element?.value ? 'radioBtnSelected' : ''
            } radioBtnCustom`}
            key={nanoid()}
          >
            {searchContext === element?.value && <CheckSquareOutlined />} {element?.label}
          </span>
        ))}
      </div>
      {/* </div> */}
    </div>
  );
};

export default PropertyTypeToggle;
