import { UserOutlined } from '@ant-design/icons';
import DeleteIconTrash from '../../../../../assets/images/DeleteIconTrash';

import { Avatar, Modal } from 'antd';
import React from 'react';

import Text from 'antd/es/typography/Text';
import { truncate } from 'lodash';
import { useNavigate } from 'react-router';
import CommentComponent from '../../../socialApp/posts/postFooter/CommentComponent';
import './postTypes.scss';
import RepostIcon from '../../../../../assets/SocialApp/RepostIcon';

const AdPost = ({
  data,
  postData,
  handleDeletPost,
  handleSeeMore,
  handleCancel,
  isVisiable,
  user,
  sanitizer,
  companyData,
  isCompany,
  setAllPosts,
  setPostData,
  handleDeleteRepost,
}) => {
  const navigate = useNavigate();
  return (
    <div className="profile-container">
      {data?.isReposted && (
        <div className="d-flex g-5 a-center">
          <div className="repost-icon">
            <RepostIcon className="repost-icon" />
          </div>
          <div>You Reposted</div>
        </div>
      )}
      <div className="profile-layout" gutter={16}>
        <div className="imgcontainer position-relative">
          <img
            src={
              data?.imageUrls?.length > 0
                ? data?.imageUrls?.[0]?.url
                : 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai'
            }
            alt="Profile"
            className="profile-image"
          />
        </div>
        <div className="content-col" style={{ width: '100%' }}>
          <div className="d-flex jc-between g-10 " style={{ width: '100%' }}>
            <span className="d-flex g-15 a-center ">
              <Avatar src={data?.ownerDetails?.personalDetails?.imageUrl || ''} icon={<UserOutlined />} />
              {isCompany ? (
                <div className="d-flex d-column">
                  <span className="font14 fontExtraLight ">{data?.ownerDetails?.name}</span>
                  {data?.ownerDetails?.address && (
                    <span className="font14 fontExtraLight ">
                      {data?.ownerDetails?.address?.city},{data?.ownerDetails?.address?.state}
                    </span>
                  )}
                </div>
              ) : (
                <div className="d-flex d-column">
                  <Text style={{ fontWeight: '600', color: '#535353' }}>{data?.ownerDetails?.name}</Text>
                  <div className="d-flex">
                    {data?.ownerDetails?.professionalDetails?.designation && (
                      <Text
                        style={{ fontWeight: '600', fontSize: '12px', color: '#A2A2A2' }}
                      >{`${data?.ownerDetails?.professionalDetails?.designation}`}</Text>
                    )}
                    {data?.ownerDetails?.professionalDetails?.companyName && (
                      <Text style={{ fontWeight: '600', fontSize: '12px', color: '#A2A2A2' }}>
                        @{data?.ownerDetails?.professionalDetails?.companyName}
                      </Text>
                    )}
                  </div>
                </div>
              )}
            </span>
            <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '16 px' }} className="deleteIconPost">
              <DeleteIconTrash
                strokeColor={'#BFBFBF'}
                onClick={data?.isReposted ? handleDeleteRepost : handleDeletPost}
              />
            </div>
          </div>
          <Text id="post-title" style={{ fontWeight: '600', color: '#535353' }}>
            {truncate(data?.title, { length: 40 })}
          </Text>

          <div
            id="post-body"
            style={{ fontWeight: '600', color: '#535353' }}
            dangerouslySetInnerHTML={{ __html: sanitizer(truncate(data?.postDetails?.body, { length: 142 })) }}
          ></div>

          <span className="font14 postBody fontExtraLight">
            <span className="fontt600 fontLight"> Designation :</span> {data?.postDetails?.designation}
          </span>
          <span className="font14 postBody fontExtraLight">
            <span className="fontt600 fontLight"> City :</span> {data?.postDetails?.city}
          </span>

          <Text
            className="cursor-pointer"
            style={{ fontWeight: '600', color: '#0081FC' }}
            onClick={() => navigate(`/user/socialApp/postDetails/${data?._id}/${data?.ownerType}`)}
          >
            See more
          </Text>
          <div className="d-flex font12 fontExtraLight jc-between w-100">
            <div className="d-flex">
              <span>{data.likes || 0} Likes</span>
            </div>
            <div className="d-flex g-10">
              <span>{data.comments || 0} Comments</span> &#9679;
              <span>{data.reposts || 0} Repost &#9679;</span>
              <span>{data.saves || 0} Saves</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdPost;
