import { Card, Space, Tag } from 'antd';
import ProspectImage from '../../components/prospectImage/ProspectImage';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import Reviews from '../../components/reviews/Reviews';
import { addBreadcrumb, clearBreadcrumbs, getDetailedConnectInfo, truncateBreadcrumbs,resetDetailData } from '../../features/searchSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { isEmpty } from 'lodash';
import { FormatConnect } from '../../../utils/formatSearchData/Connect';
import search_connect from '../../../assets/images/person.svg';
import './ConnectDetailsCard.scss';
import { getConnectReviews } from '../../features/contactBookSlice';

const ConnectDetailsCard = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { detailedConnectData, breadcrumbs } = useSelector((store) => store.search);
  const { connectReviews } = useSelector((store) => store.contactBook);
  const [connectDetails, setConnectDetails] = useState({});
  const [reviwesDetails, setReviewsDetails] = useState([]);

  const calculateAverageRating = (reviews) => {
    if (!reviews || reviews.length === 0) return 0;

    const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
    return (totalRating / reviews.length).toFixed(1);
  };

  useEffect(() => {
    dispatch(getDetailedConnectInfo(id));
    dispatch(getConnectReviews(id))
  }, [dispatch, id]);

  useEffect(() => {
    if (isEmpty(detailedConnectData)) return;
    const data = FormatConnect(detailedConnectData?.[0]);
    // dispatch(clearBreadcrumbs());
    dispatch(addBreadcrumb({ name: `${data?.name}`, path: `/leadGen/connectDetails/${id}` }));
    setConnectDetails(data);
  }, [detailedConnectData]);

  useEffect(() => {
    if (isEmpty(connectReviews)) return;
    setReviewsDetails(connectReviews);
  }, [connectReviews]);

  useEffect(() => {
    return () =>  { 
      dispatch(resetDetailData());
       dispatch(truncateBreadcrumbs(breadcrumbs?.[breadcrumbs.length - 1]?.name));
      }
  },[])
  


  const { selectedNav } = useSelector((store) => store.leadGenSideNav);

  return (
    <section className="personalDtlsSection">
      <Card className="personalDtlsSection-cardone">
        <Space direction="vertical">
          <Text
            onClick={() => {
              if (selectedNav !== 'ContactBook') {
                dispatch(truncateBreadcrumbs(breadcrumbs?.[breadcrumbs.length - 2]?.name));
                navigate(breadcrumbs?.[breadcrumbs.length - 2]?.path);
                return;
              }
              navigate('/leadgen/contactbook');
            }}
            strong
            className="personalDtlsSection-cardone__details"
          >
            <span className="details-user">{'< '}</span>
            <span className="details-username">{connectDetails?.name}</span>
          </Text>
          <div className="prospectImage">
            <ProspectImage imageUrl={connectDetails?.featuredImage || search_connect} />
          </div>
          <Title level={5} className="personalDtlsSection-cardone__heading">
            Personal Details
          </Title>
          <div className="personalDtlsSection-cardone__personaldtls">
            <Text className="personaldtls-text">Name : {connectDetails?.name}</Text>
            <Text className="personaldtls-text">E-Mail : {connectDetails?.email}</Text>
            <Text className="personaldtls-text">Mobile No. : {connectDetails?.phone}</Text>
          </div>
          <Reviews averageRating={calculateAverageRating(reviwesDetails)} reviewsList={reviwesDetails} />
        </Space>
      </Card>
      <Card className="personalDtlsSection-cardone">
        <Space direction="vertical">
          <Title level={5}>Professional Details</Title>
          <div className="personalDtlsSection-cardone__personaldtls">
            <Text className="personaldtls-text">Current Company: {connectDetails?.currentCompany}</Text>
            <Text className="personaldtls-text">Designation: {connectDetails?.designation}</Text>
            <Text className="personaldtls-text">Years of Experience: {connectDetails?.experience}</Text>
            <Text className="personaldtls-text">Current Industry: {connectDetails?.currentIndustry}</Text>
            <Text className="personaldtls-text">Last Company Industry: {connectDetails?.lastIndustry}</Text>
          </div>
          <Title level={5}>Key Skills</Title>
          <div className="personalDtlsSection-cardone__tagbox">
            {connectDetails?.keySkills?.map((el) => {
              return (
                <Tag key={el} className="tagbox-tag">
                  {el}
                </Tag>
              );
            })}
          </div>
        </Space>
      </Card>
      <Card className="personalDtlsSection-cardthree">
        <Title level={5}>Personal details</Title>
        <div className="personalDtlsSection-cardthree__personaldtls">
          <Text className="personaldtls-text">Type of Users: {connectDetails?.userType}</Text>
          <Text className="personaldtls-text">Experties: {connectDetails?.experties}</Text>
          <Text className="personaldtls-text">Preferred Cities: {connectDetails?.perferredCities}</Text>
        </div>
        <Title level={5} className="personalDtlsSection-cardthree__services">
          Services they sell
        </Title>
        <div className="personalDtlsSection-cardthree__tagbox">
          {connectDetails?.serviceSell?.map((el) => {
            return (
              <Tag key={el} className="box-tag">
                {el}
              </Tag>
            );
          })}
        </div>
        <Title level={5} className="personalDtlsSection-cardthree__services">
          Target Group
        </Title>
        <div className="personalDtlsSection-cardthree__tagbox">
          {connectDetails?.targetGroup?.map((el) => {
            return (
              <Tag key={el} className="box-tag">
                {el}
              </Tag>
            );
          })}
        </div>
        <Title level={5} className="personalDtlsSection-cardthree__services">
          Services they buy
        </Title>
        <div className="personalDtlsSection-cardthree__tagbox">
          {connectDetails?.servicesBuy?.map((el) => {
            return (
              <Tag key={el} className="box-tag">
                {el}
              </Tag>
            );
          })}
        </div>
      </Card>
    </section>
  );
};

export default ConnectDetailsCard;
