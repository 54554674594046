import { Form, Input, Space, Select, Tag, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import './personalDetails.scss';
import { keySkills } from '../../../../utils/referenceData/personalDetailsOfferings';
import { current_industry_options } from '../../../../utils/current_industry_options';
import yrsOfExperienceOption from '../../../../assets/default-data/yrsOfExperienceOptions.json';

import verifiedIcon from '../../../../assets/images/verified.png';
import { CloseCircleFilled } from '@ant-design/icons';

const CompanyDetails = ({ isFormEditable, user, handleOpenVerifyOtpModal }) => {
  const { Option } = Select;
  const form = Form.useFormInstance();
  const options = keySkills.map((el) => {
    return {
      label: el,
      value: el,
    };
  });

  const [yrsOfExpOption, setYrsOfExpOption] = useState([]);

  useEffect(() => {
    setYrsOfExpOption(yrsOfExperienceOption);
  }, []);

  const removeSkill = (index) => {
    let keySkillsList = [...form.getFieldValue('keySkills')];
    const updatedSkills = keySkillsList.filter((val, i) => i !== index);
    console.log('updatedSkills', updatedSkills);
    form.setFieldsValue({ keySkills: updatedSkills }); // Use form.setFieldsValue here
  };

  return (
    <>
      <Space direction="vertical" className="personalDtlsSection__companyDetails">
        <Title level={4} className="companyDetails-heading">
          Professional Details
        </Title>
        <Form.Item style={{ fontSize: '14px' }} label="Current Company" className="companyDetails-label">
          {isFormEditable ? (
            <Form.Item disabled name="companyName">
              <Input placeholder="Enter Current Company" className="form-placeholder" disabled={true} />
            </Form.Item>
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <Text strong className="userPersonalDtls__formValues">
                  {getFieldValue('companyName') || '-'}
                </Text>
              )}
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item
          label="Current work mail Id "
          name="companyEmailId"
          className="companyDetails-label"
          rules={[
            {
              required: true,
              message: 'Please input your work Email!',
              // pattern: /^[a-z0-9._]+@[a-z0-9.]+\.[a-z]{2,4}$/,
            },
          ]}
          style={{
            marginTop: '-12px',
          }}
        >
          {isFormEditable ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.companyEmailId !== currentValues.companyEmailId}
              >
                {({ getFieldValue }) => {
                  const value = getFieldValue('companyEmailId') || ''; // Fetch the value of the field

                  return (
                    <Input
                      name="companyEmailId"
                      value={value} // Set the value of the input field
                      placeholder="Enter Work Email Id"
                      className="company-placeholder"
                      disabled={true}
                      style={{ height: '45px' }}
                      suffix={
                        user?.verificationStatus?.email && (
                          <img src={verifiedIcon} alt="verified" width="17px" height="17px" className="form-suffix" />
                        )
                      }
                    />
                  );
                }}
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.companyEmailId !== currentValues.companyEmailId}
              >
                {({ getFieldError, getFieldValue }) => {
                  const value = getFieldValue('companyEmailId');
                  const hasError = getFieldError('companyEmailId').length > 0;
                  const isEmpty = !value || value.trim() === '';
                  const isDisabled = hasError || isEmpty;

                  return (
                    !user?.verificationStatus?.email && (
                      <Button
                        className="anchor-button-myaccount-company"
                        onClick={() => handleOpenVerifyOtpModal(user?.email, 'email')}
                        // style={{ color: isDisabled ? '#bfbfbf' : '#0080FC' }}
                      >
                        Verify
                      </Button>
                    )
                  );
                }}
              </Form.Item>
            </div>
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <div className="userPersonalDtls__formValues" style={{ display: 'flex', alignItems: 'center' }}>
                  <Text strong>
                    {getFieldValue('companyEmailId')}
                    {user?.verificationStatus?.email ? (
                      <img
                        src={verifiedIcon}
                        alt="verified"
                        width="17px"
                        height="17px"
                        style={{ position: 'absolute', right: '11px' }}
                      />
                    ) : null}
                  </Text>
                </div>
              )}
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item label="Current Designation" name="designation" className="companyDetails-label">
          {isFormEditable ? (
            <Input placeholder="Enter Designation" className="form-placeholder" />
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <Text strong className="userPersonalDtls__formValues">
                  {getFieldValue('designation') || '-'}
                </Text>
              )}
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item label="Years of Experience" name="yearsOfExperience" className="companyDetails-label">
          {isFormEditable ? (
            <Select
              placeholder={'Select Years of Experience'}
              style={{ width: '100%', height: '45px' }}
              className="custom-select-yrs-experience-myaccount"
            >
              {yrsOfExpOption.map((item) => (
                <Option key={item.value} value={item.value} className="form-placeholder-years-of-experience-account">
                  {item.label}
                </Option>
              ))}
            </Select>
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <Text strong className="userPersonalDtls__formValues">
                  {getFieldValue('yearsOfExperience') || '-'}
                </Text>
              )}
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item
          label="Current Company Industry"
          name="industry"
          className="companyDetails-label industry-label"
          rules={[
            {
              required: false,
              message: 'Please input valid Industry.',
              // pattern: /^[a-zA-Z\s-]*$/,
            },
          ]}
        >
          {isFormEditable ? (
            <Form.Item
              className="companydetails-container__row-formitem"
              name="industry"
              rules={[
                {
                  required: true,
                  message: 'Please select an appropraite Industry!',
                },
              ]}
            >
              <Select
                className="custom-select-yrs-experience-myaccount"
                options={current_industry_options}
                disabled={true}
              />
            </Form.Item>
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <Text strong className="userPersonalDtls__formValues">
                  {getFieldValue('industry') || '-'}
                </Text>
              )}
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item
          label="Last Company Name"
          name="lastCompanyName"
          className="companyDetails-label"
          style={{
            marginTop: '-12px',
          }}
        >
          {isFormEditable ? (
            <Input placeholder="Enter Last Company Name" className="form-placeholder" />
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <Text strong className="userPersonalDtls__formValues">
                  {getFieldValue('lastCompanyName') || '-'}
                </Text>
              )}
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item label="Last Company Designation" name="lastCompanyDesignation" className="companyDetails-label">
          {isFormEditable ? (
            <Input placeholder="Enter Last Company Designation" className="form-placeholder" />
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <Text strong className="userPersonalDtls__formValues">
                  {getFieldValue('lastCompanyDesignation') || '-'}
                </Text>
              )}
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item
          label="Last Company Industry"
          name="lastCompanyIndustry"
          className="companyDetails-label"
          rules={[
            {
              required: false,
              message: 'Please input valid Industry.',
              // pattern: /^[a-zA-Z\s-]*$/,
            },
          ]}
        >
          {isFormEditable ? (
            <Input placeholder="Enter Last Company Industry" className="form-placeholder" />
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <Text strong className="userPersonalDtls__formValues">
                  {getFieldValue('lastCompanyIndustry') || '-'}
                </Text>
              )}
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item label="Key Skills" className="companyDetails-label">
          {isFormEditable ? (
            // <Input placeholder="Eg: Innovator, Design Thinking, Strategic Thinker" className='form-placeholder' />
            <>
              <Form.Item name="keySkills">
                <Select
                  className="keySkills"
                  mode="multiple"
                  style={{
                    width: '100%',
                    height: '46px',
                    marginTop: '0px',
                  }}
                  placeholder="Select Skills"
                  options={options}
                />
              </Form.Item>
              <Form.Item shouldUpdate className="userPersonalDtls__formValues">
                {({ getFieldValue }) => (
                  <Text strong className="userPersonalDtls__formValues" style={{ maxWidth: '400px' }}>
                    <div style={{ maxHeight: '80px', overflow: 'scroll' }}>
                      {getFieldValue('keySkills')?.length > 0
                        ? getFieldValue('keySkills')?.map((el, index) => (
                            <Tag
                              key={index}
                              style={{
                                backgroundColor: '#3F52A3',
                                fontWeight: '100px',
                                border: 'none',
                                color: '#fff',
                                borderRadius: '100px',
                                paddingLeft: '20px',
                                paddingRight: '20px',
                              }}
                            >
                              <Text style={{ color: 'white', fontSize: '12px' }}>{el}</Text>
                              <CloseCircleFilled style={{ marginLeft: 10 }} onClick={() => removeSkill(index)} />
                            </Tag>
                          ))
                        : '-'}
                    </div>
                  </Text>
                )}
              </Form.Item>
            </>
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <Text strong className="userPersonalDtls__formValues" style={{ maxWidth: '400px' }}>
                  <div style={{ maxHeight: '80px', overflow: 'scroll' }}>
                    {getFieldValue('keySkills')?.map((el, index) => (
                      <Tag
                        key={index}
                        color="#3F52A3"
                        style={{
                          backgroundColor: '#3F52A3',
                          fontWeight: '100px',
                          border: 'none',
                          color: '#fff',
                          borderRadius: '100px',
                          paddingLeft: '20px',
                          paddingRight: '20px',
                        }}
                      >
                        {el}
                      </Tag>
                    ))}
                  </div>
                </Text>
              )}
            </Form.Item>
          )}
        </Form.Item>
      </Space>
    </>
  );
};

export default CompanyDetails;
