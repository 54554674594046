// imports
import { Form, Input, Space, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PreselectedTags from '../../../components/userOnboarding/Offerings/UserTypeOfferingsLayout/common/PreselectedTags';
import DropDown from '../../../components/userOnboarding/preferences/DropDown';
import {
  userOnboardingAddPreferences,
  userOnboardingGetPreferences,
  userOnboardingGetPreferencesList,
} from '../../../features/userOnboardingSlice';
import './preferences.scss';
import { PlusOutlined } from '@ant-design/icons';

const tagInputStyle = {
  width: 300,
  height: 30,
  marginLeft: 30,
};

const tagPlusStyle = {
  border: '1px solid #4096ff' /* Matching blue border */,
  color: '#4096ff' /* Blue text */,
  backgroundColor: 'transparent' /* Transparent background */,
  borderRadius: '20px' /* Rounded corners */,
  padding: '4px 12px' /* Adjust padding */,
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  width: '110px',
  cursor: 'pointer',
};

const Preference = ({ offerings = '' }) => {
  // hooks
  const dispatch = useDispatch();
  const [formDetails] = Form.useForm();
  // states
  const { user, userV2 } = useSelector((store) => store.user);
  // States
  const [userSellsTags, setUserSellsTags] = useState([]);
  const [userSellOptions, setUserSellOptions] = useState([]);
  const [userBuyOptions, setUserBuyOptions] = useState([]);
  const [userAudienceOptions, setUserAudienceOptions] = useState([]);
  const [userDreamClientsOptions, setUserDreamClientsOptions] = useState([]);
  const [userDreamClientsTags, setUserDreamClientsTags] = useState([]);
  const [userInterestsOptions, setUserInterestsOptions] = useState([]);
  const [userInterestsTags, setUserInterestsTags] = useState([]);
  const [userTargetAudienceTags, setUserTargetAudienceTags] = useState([]);
  const [userWouldBuyTag, setUserWouldBuyTag] = useState([]);
  const [inputVisibleType, setInputVisibleType] = useState({
    status: false,
    type: '',
  });
  const [inputValue, setInputValue] = useState('');

  const inputRef = useRef(null);
  const inputRefUserTargetAudience = useRef(null);
  const inputRefUserWouldBy = useRef(null);
  const inputRefUserSells = useRef(null);
  function handleChangePayload(name, value) {
    const payload = {
      userSells: userSellsTags,
      userTargetAudience: userTargetAudienceTags,
      userWouldBuy: userWouldBuyTag,
      dreamClients: userDreamClientsTags,
      interest: userInterestsTags,
    };
    if (name) {
      payload[name] = value || [];
    }
    return payload;
  }

  const showInput = () => {
    setInputVisibleType({
      status: true,
      type: 'preference_userSells_offerings',
    });
  };

  useEffect(() => {
    if (inputVisibleType.status && inputVisibleType.type === 'preference_userSells_offerings') {
      inputRef.current?.focus();
    }
    if (inputVisibleType.status && inputVisibleType.type === 'preference_userTargetAudience') {
      inputRefUserTargetAudience.current?.focus();
    }
    if (inputVisibleType.status && inputVisibleType.type === 'preference_userWouldBuy') {
      inputRefUserWouldBy.current?.focus();
    }
    if (inputVisibleType.status && inputVisibleType.type === 'preference_userSells') {
      inputRefUserSells.current?.focus();
    }
  }, [inputVisibleType]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputUserWouldByConfirm = () => {
    if (inputValue && !userWouldBuyTag.includes(inputValue)) {
      setUserWouldBuyTag([...userWouldBuyTag, inputValue]);
      formDetails.setFieldsValue({
        preference_userWouldBuy: [...userWouldBuyTag, inputValue] || [],
      });
      dispatch(userOnboardingAddPreferences(handleChangePayload('userWouldBuy', [...userWouldBuyTag, inputValue])));
    }
    setInputVisibleType({ status: false, type: '' });
    setInputValue('');
  };
  const handleInputUserSellsConfirm = () => {
    if (inputValue && !userSellsTags.includes(inputValue)) {
      setUserSellsTags([...userSellsTags, inputValue]);
      formDetails.setFieldsValue({
        preference_userSells: [...userSellsTags, inputValue] || [],
      });
      dispatch(userOnboardingAddPreferences(handleChangePayload('userSells', [...userSellsTags, inputValue])));
    }
    setInputVisibleType({ status: false, type: '' });
    setInputValue('');
  };
  const handleInputUserTargetAudienceConfirm = () => {
    if (inputValue && !userTargetAudienceTags.includes(inputValue)) {
      setUserTargetAudienceTags([...userTargetAudienceTags, inputValue]);
      formDetails.setFieldsValue({
        preference_userTargetAudience: [...userTargetAudienceTags, inputValue] || [],
      });
      dispatch(
        userOnboardingAddPreferences(
          handleChangePayload('userTargetAudience', [...userTargetAudienceTags, inputValue]),
        ),
      );
    }
    setInputVisibleType({ status: false, type: '' });
    setInputValue('');
  };

  // Fetching prefernces and setting
  useEffect(() => {
    dispatch(userOnboardingGetPreferences())?.then((res) => {
      const userPreferenceData = res?.payload?.data?.response?.data?.[0];
      if (userPreferenceData) {
        setUserSellsTags(userPreferenceData.userSells || []);
        setUserTargetAudienceTags(userPreferenceData.userTargetAudience || []);
        setUserWouldBuyTag(userPreferenceData.userWouldBuy || []);
        setUserDreamClientsTags(userPreferenceData.dreamClients || []);
        setUserInterestsTags(userPreferenceData.interest || []);

        // ------Setting fetched data to states-------
        formDetails.setFieldsValue({
          preference_dreamClients: userPreferenceData.dreamClients || [],
          preference_interests: userPreferenceData.interest || [],
          preference_userWouldBuy: userPreferenceData.userWouldBuy || [],
          preference_userTargetAudience: userPreferenceData.userTargetAudience || [],
          preference_userSells: userPreferenceData.userSells || [],
        });
      }
    });
  }, [dispatch, formDetails]);

  // Fetch options from API and set state
  const fetchOptions = (type, offerings, setterFunc) => {
    const payloadBody = {
      type,
      offerings,
    };
    dispatch(userOnboardingGetPreferencesList(payloadBody)).then((res) => {
      const options = res?.payload?.data?.response?.data;
      if (options) {
        setterFunc(
          options.map((elem) => ({
            label: elem?.name,
            key: elem?.name,
            value: elem?.name,
          })),
        );
      }
    });
  };

  // calling api to set data on change
  const handleFormChange = (_, e) => {
    if (inputVisibleType.status) return;
    const payload = {
      userSells: e?.preference_userSells,
      userTargetAudience: e?.preference_userTargetAudience,
      userWouldBuy: e?.preference_userWouldBuy,
      dreamClients: e?.preference_dreamClients,
      interest: e?.preference_interests,
    };
    dispatch(userOnboardingAddPreferences(payload));
  };

  // jsx
  return (
    <Form layout="vertical" style={{ marginTop: '30px' }} form={formDetails} onValuesChange={handleFormChange}>
      <div className="scrollbar-preference">
        <section className="preferences" style={{ flexDirection: 'row' }}>
          <>
            <div className="insideContainers">
              <heading>My Preferences</heading>
              {/* ------------User sells----------- */}
              <div className="preferences-container">
                <Space direction="vertical" className="preferences-container__space">
                  <Form.Item
                    label={`Services that ${user?.name.split(' ')[0]} sells`}
                    name="preference_userSells"
                    className="preferences-container__space--item"
                  >
                    <Form.Item
                      name="preference_userSells"
                      style={{ margin: 0, display: 'inline-flex' }}
                      rules={[
                        {
                          required: true,
                          message:
                            userSellsTags && userSellsTags.length > 0
                              ? ''
                              : 'Please select what you would like to sell',
                        },
                      ]}
                    >
                      {offerings !== '' ? (
                        <div style={{ display: 'inline-flex' }}>
                          <DropDown
                            name="preference_userSells"
                            options={userSellOptions}
                            allTags={userSellsTags}
                            setterFunc={setUserSellsTags}
                            setInputVisibleType={setInputVisibleType}
                            fetchOptions={() =>
                              fetchOptions(
                                'userSells',
                                userV2?.profileClassification?.profileClassification,
                                setUserSellOptions,
                              )
                            }
                          />
                          {inputVisibleType.status && inputVisibleType.type === 'preference_userSells' && (
                            <Input
                              ref={inputRefUserSells}
                              type="text"
                              size="small"
                              style={tagInputStyle}
                              value={inputValue}
                              onChange={(e) => {
                                const value = e.target.value;
                                // Update the input value but don't allow only spaces
                                if (value.trim() !== '' || value === '') {
                                  handleInputChange(e); // Call your existing handler if input is valid
                                }
                              }}
                              onBlur={() => {
                                if (inputValue.trim() !== '') {
                                  handleInputUserSellsConfirm(); // Call confirm only if input is valid
                                }
                              }}
                              onPressEnter={() => {
                                if (inputValue.trim() !== '') {
                                  handleInputUserSellsConfirm(); // Call confirm only if input is valid
                                }
                              }}
                              placeholder={`Enter Services`}
                            />
                          )}
                        </div>
                      ) : (
                        <div style={{ display: 'inline-flex' }}>
                          <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
                            Add Tag
                          </Tag>
                          {inputVisibleType.status && inputVisibleType.type === 'preference_userSells_offerings' && (
                            <Input
                              ref={inputRef}
                              type="text"
                              size="small"
                              style={tagInputStyle}
                              value={inputValue}
                              onChange={(e) => {
                                const value = e.target.value;
                                // Update the input value but don't allow only spaces
                                if (value.trim() !== '' || value === '') {
                                  handleInputChange(e); // Call your existing handler if input is valid
                                }
                              }}
                              onBlur={() => {
                                if (inputValue.trim() !== '') {
                                  handleInputUserSellsConfirm(); // Call confirm only if input is valid
                                }
                              }}
                              onPressEnter={() => {
                                if (inputValue.trim() !== '') {
                                  handleInputUserSellsConfirm(); // Call confirm only if input is valid
                                }
                              }}
                              placeholder={`Enter Services`}
                            />
                          )}
                        </div>
                      )}
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    name="preference_userSells"
                    style={{ margin: 0 }}
                    className={` ${
                      userSellsTags && userSellsTags?.length > 0
                        ? 'preferences-container__space--item'
                        : 'preferences-container__space--noData'
                    } `}
                  >
                    {userSellsTags && userSellsTags?.length > 0 && (
                      <PreselectedTags setTagData={setUserSellsTags} tagsData={userSellsTags} />
                    )}
                  </Form.Item>
                  <div className="preferences-container__space--note">Note: min 1 is compulsory</div>
                </Space>
              </div>
              {/* ------------User sells----------- */}

              {/* ------------User Target Audience----------- */}

              <div className="preferences-container">
                <Space direction="vertical" className="preferences-container__space">
                  <Form.Item
                    // label={`${user?.name.split(" ")[0]}/${userV2?.professionalDetails?.companyName || 'BI'} target Audience`}
                    label={`${user?.name.split(' ')[0]}'s target Audience`}
                    className="preferences-container__space--item"
                  >
                    <Form.Item
                      name="preference_userTargetAudience"
                      style={{ margin: 0, display: 'inline-flex' }}
                      rules={[
                        {
                          required: true,
                          message:
                            userTargetAudienceTags && userTargetAudienceTags.length > 0
                              ? ''
                              : 'Please select audience what you would like to target',
                        },
                      ]}
                    >
                      <DropDown
                        name="preference_userTargetAudience"
                        options={userAudienceOptions}
                        allTags={userTargetAudienceTags}
                        setterFunc={setUserTargetAudienceTags}
                        setInputVisibleType={setInputVisibleType}
                        fetchOptions={() => fetchOptions('userTargetAudience', null, setUserAudienceOptions)}
                      />
                      {inputVisibleType.status && inputVisibleType.type === 'preference_userTargetAudience' && (
                        <Input
                          ref={inputRefUserTargetAudience}
                          type="text"
                          size="small"
                          style={tagInputStyle}
                          value={inputValue}
                          // onChange={handleInputChange}
                          // onBlur={handleInputUserTargetAudienceConfirm}
                          // onPressEnter={handleInputUserTargetAudienceConfirm}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Update the input value but don't allow only spaces
                            if (value.trim() !== '' || value === '') {
                              handleInputChange(e); // Call your existing handler if input is valid
                            }
                          }}
                          onBlur={() => {
                            if (inputValue.trim() !== '') {
                              handleInputUserTargetAudienceConfirm(); // Call confirm only if input is valid
                            }
                          }}
                          onPressEnter={() => {
                            if (inputValue.trim() !== '') {
                              handleInputUserTargetAudienceConfirm(); // Call confirm only if input is valid
                            }
                          }}
                          placeholder={`Enter Services`}
                        />
                      )}
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    name="preference_userTargetAudience"
                    style={{ margin: 0 }}
                    className={` ${
                      userTargetAudienceTags && userTargetAudienceTags?.length > 0
                        ? 'preferences-container__space--item'
                        : 'preferences-container__space--noData'
                    } `}
                  >
                    {userTargetAudienceTags && userTargetAudienceTags?.length > 0 && (
                      <PreselectedTags n setTagData={setUserTargetAudienceTags} tagsData={userTargetAudienceTags} />
                    )}
                  </Form.Item>
                  <div className="preferences-container__space--note">Note: min 1 is compulsory</div>
                </Space>
              </div>

              {/* ------------User Target Audience----------- */}

              {/* ------------User would like to buy----------- */}
              <div className="preferences-container">
                <Space direction="vertical" className="preferences-container__space">
                  <Form.Item
                    // label={`Services that ${user?.name.split(" ")[0]}/${
                    //   userV2?.professionalDetails?.companyName || 'BI'
                    // } would like to buy`}
                    label={`Services that ${user?.name.split(' ')[0]} would like to buy`}
                    className="preferences-container__space--item"
                    name="preference_userWouldBuy"
                  >
                    <Form.Item
                      name="preference_userWouldBuy"
                      style={{ margin: 0, display: 'inline-flex' }}
                      rules={[
                        {
                          required: true,
                          message:
                            userWouldBuyTag && userWouldBuyTag.length > 0 ? '' : 'Please select what you would  to buy',
                        },
                      ]}
                    >
                      <DropDown
                        name="preference_userWouldBuy"
                        options={userBuyOptions}
                        allTags={userWouldBuyTag}
                        setterFunc={setUserWouldBuyTag}
                        setInputVisibleType={setInputVisibleType}
                        fetchOptions={() => fetchOptions('userWouldBuy', null, setUserBuyOptions)}
                      />
                      {inputVisibleType.status && inputVisibleType.type === 'preference_userWouldBuy' && (
                        <Input
                          ref={inputRefUserWouldBy}
                          type="text"
                          size="small"
                          style={tagInputStyle}
                          value={inputValue}
                          // onChange={handleInputChange}
                          // onBlur={handleInputUserWouldByConfirm}
                          // onPressEnter={handleInputUserWouldByConfirm}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Update the input value but don't allow only spaces
                            if (value.trim() !== '' || value === '') {
                              handleInputChange(e); // Call your existing handler if input is valid
                            }
                          }}
                          onBlur={() => {
                            if (inputValue.trim() !== '') {
                              handleInputUserWouldByConfirm(); // Call confirm only if input is valid
                            }
                          }}
                          onPressEnter={() => {
                            if (inputValue.trim() !== '') {
                              handleInputUserWouldByConfirm(); // Call confirm only if input is valid
                            }
                          }}
                          placeholder={`Enter Services`}
                        />
                      )}
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    name="preference_userWouldBuy"
                    style={{ margin: 0 }}
                    className={` ${
                      userWouldBuyTag && userWouldBuyTag?.length > 0
                        ? 'preferences-container__space--item'
                        : 'preferences-container__space--noData'
                    } `}
                  >
                    {userWouldBuyTag && userWouldBuyTag?.length > 0 && (
                      <PreselectedTags setTagData={setUserWouldBuyTag} tagsData={userWouldBuyTag} />
                    )}
                  </Form.Item>
                  <div className="preferences-container__space--note">Note: min 1 is compulsory</div>
                </Space>
              </div>
            </div>
            {/* ------------User would like to buy----------- */}
            {/* ------------User Dream Client----------- */}
            <div className="insideContainers">
              <div className="preferences-container">
                <Space direction="vertical" className="preferences-container__space">
                  <Form.Item
                    label={`${user?.name.split(' ')[0]}'s Dream Clients`}
                    className="preferences-container__space--item"
                    name="preference_dreamClients"
                  >
                    <Form.Item
                      name="preference_dreamClients"
                      style={{ margin: 0 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please select dream clients',
                        },
                      ]}
                    >
                      <DropDown
                        name="preference_dreamClients"
                        options={userDreamClientsOptions}
                        allTags={userDreamClientsTags}
                        setterFunc={setUserDreamClientsTags}
                        fetchOptions={() => fetchOptions('dreamClients', null, setUserDreamClientsOptions)}
                      />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    name="preference_dreamClients"
                    style={{ margin: 0 }}
                    className={` ${
                      userDreamClientsTags && userDreamClientsTags?.length > 0
                        ? 'preferences-container__space--item'
                        : 'preferences-container__space--noData'
                    } `}
                  >
                    {userDreamClientsTags && userDreamClientsTags?.length > 0 && (
                      <PreselectedTags setTagData={setUserDreamClientsTags} tagsData={userDreamClientsTags} />
                    )}
                  </Form.Item>
                  {/* <div className="preferences-container__space--note">Note: min 1 is compulsory</div> */}
                </Space>
              </div>
              {/* -----------User Dream Client----------- */}
              {/* ------------User Interests----------- */}

              <div className="preferences-container">
                <Space direction="vertical" className="preferences-container__space">
                  <Form.Item
                    label={`${user?.name.split(' ')[0]}'s Interest`}
                    className="preferences-container__space--item"
                    name="preference_interests"
                  >
                    <Form.Item
                      name="preference_interests"
                      style={{ margin: 0 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please select interests',
                        },
                      ]}
                    >
                      <DropDown
                        name="preference_interests"
                        options={userInterestsOptions}
                        allTags={userInterestsTags}
                        setterFunc={setUserInterestsTags}
                        fetchOptions={() => fetchOptions('interests', null, setUserInterestsOptions)}
                      />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    name="preference_interests"
                    style={{ margin: 0 }}
                    className={` ${
                      userInterestsTags && userInterestsTags?.length > 0
                        ? 'preferences-container__space--item'
                        : 'preferences-container__space--noData'
                    } `}
                  >
                    {userInterestsTags && userInterestsTags?.length > 0 && (
                      <PreselectedTags setTagData={setUserInterestsTags} tagsData={userInterestsTags} />
                    )}
                  </Form.Item>
                  {/* <div className="preferences-container__space--note">Note: min 1 is compulsory</div> */}
                </Space>
              </div>
            </div>
            {/* -----------User Interests----------- */}
          </>
        </section>
      </div>
    </Form>
  );
};

export default Preference;
