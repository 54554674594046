import React from 'react';

const FollowIcon = ({className ,strokeColor}) => {
  return (
    <svg style={{ marginRight: '2px' }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke={strokeColor} d="M2.82849 22.042C2.68569 22.042 2.55489 21.9928 2.47089 21.9064C2.39169 21.8248 2.35209 21.7084 2.35569 21.5584C2.35569 17.7256 4.65849 14.3284 8.22369 12.8992L9.04329 12.5704L8.32209 12.0616C6.84009 11.014 5.95449 9.31836 5.95449 7.52556C5.95449 4.45596 8.45049 1.95996 11.5189 1.95996C12.9985 1.95996 14.3929 2.54076 15.4453 3.59556C16.4977 4.65036 17.0773 6.04596 17.0773 7.52676C17.0773 9.27876 16.2253 10.9516 14.7973 12.0016L14.0821 12.5272L14.9137 12.838C15.5809 13.0876 16.1053 13.336 16.5661 13.6204C16.8229 13.7776 16.8277 13.984 16.7869 14.128C16.7329 14.32 16.5613 14.5144 16.3393 14.5144C16.2541 14.5144 16.1653 14.4856 16.0717 14.4292C14.7481 13.6108 13.2277 13.1776 11.6737 13.1776C7.05609 13.1776 3.29889 16.9336 3.29889 21.5512C3.30249 21.7084 3.26409 21.8284 3.18369 21.9088C3.09969 21.9952 2.96889 22.0444 2.82609 22.0444L2.82849 22.042ZM11.5189 2.90556C8.97129 2.90556 6.89889 4.97796 6.89889 7.52556C6.89889 10.0732 8.97129 12.1384 11.5189 12.1384C14.0665 12.1384 16.1329 10.0696 16.1329 7.52556C16.1329 4.98156 14.0629 2.90556 11.5189 2.90556Z" fill="#2258A6"/>
    <path stroke={strokeColor} d="M17.5199 22.0199C17.5199 22.2515 17.3315 22.4399 17.0999 22.4399H4.9511C4.7267 22.4399 4.5107 22.3451 4.3583 22.1795C4.2071 22.0139 4.1327 21.7919 4.1519 21.5699V21.5567C4.3379 19.4987 5.2031 17.6255 6.5915 16.2839C8.0399 14.8823 9.9815 14.1551 12.0563 14.2367C12.2303 14.2367 14.0951 14.2727 15.7907 15.2315C15.9983 15.3491 16.0619 15.6191 15.9347 15.8207C15.8555 15.9467 15.7199 16.0151 15.5795 16.0151C15.5075 16.0151 15.4331 15.9971 15.3659 15.9587C13.8215 15.0935 12.0695 15.0767 12.0467 15.0767H12.0299C8.3147 14.9267 5.3591 17.6675 4.9931 21.5999H17.0999C17.3315 21.5999 17.5199 21.7883 17.5199 22.0199Z" fill="#2258A6"/>
    <path stroke={strokeColor} d="M17.1073 21.0659C16.9177 21.0659 16.6933 20.9579 16.6861 20.6543V19.0511H15.0697H15.0385C14.7409 19.0511 14.6317 18.8327 14.6269 18.6455C14.6221 18.4451 14.7313 18.2255 15.0481 18.2075H16.6861V16.5983C16.6849 16.4843 16.7281 16.3751 16.8073 16.2947C16.8853 16.2143 16.9909 16.1699 17.1025 16.1699C17.2189 16.1699 17.3269 16.2143 17.4073 16.2947C17.4877 16.3751 17.5309 16.4819 17.5285 16.5959V18.2075H19.1389C19.4497 18.2075 19.5601 18.4343 19.5601 18.6287C19.5601 18.8231 19.4497 19.0499 19.1389 19.0499H17.5285V20.6591C17.5213 20.9591 17.2969 21.0647 17.1073 21.0647V21.0659Z" fill="#2258A6"/>
    </svg>
  );
};

export default FollowIcon
