import React, { useRef, useEffect, useState } from 'react';
import { Avatar, Button, Card, Spin, Switch, Table, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Title from 'antd/es/typography/Title';
import './propertySubscriptions.scss';
import { BellOutlined, HomeOutlined, RightOutlined } from '@ant-design/icons';
import { fetchSubscriptionsDetailsProperty } from '../../../../universal/features/myAccountSlice';
import { useSubscriptionHandlers } from '../../../../leadgen/services/subscribtionsHandler';
import NotificationConfig from './NotificationConfig';
import { useNavigate } from 'react-router';
import { setSelectedNav } from '../../../../leadgen/features/universalSidenavSlice';
import { useLocation } from 'react-router';
import { addBreadcrumb,clearBreadcrumbs } from '../../../../leadgen/features/searchSlice';

const PropertySubscriptions = () => {
  const [whatsappEnabled, setWhatsappEnabled] = useState(true);
  const [emailEnabled, setEmailEnabled] = useState(true);
  const { handleUnSubscribedClick } = useSubscriptionHandlers();
  const location = useLocation()
  const dispatch = useDispatch();
  const { subscriptionDetailsPropertyLoader } = useSelector((store) => store.myAccount);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [propertyLoading, setPropertyLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();
  const propertyTableColumns = [
    {
      title: 'Property Name',
      dataIndex: 'propertyName',
      key: 'propertyName',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar src={record?.propertyIcon} style={{ flexShrink: 0 }} />
          <Tooltip title={record.propertyName}>
            <span
              style={{
                marginLeft: '8px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '150px',
                cursor: 'pointer',
              }}
            >
              {record.propertyName}
            </span>
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Locality',
      dataIndex: 'locality',
      key: 'locality',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: '',
      dataIndex: 'subscribe',
      key: 'subscribe',
      render: (text, record) => (
        <Button
          type="primary"
          icon={<BellOutlined />}
          style={{
            backgroundColor: '#F2F9FF',
            color: '#1890ff',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => handleUnsubscribe(record, 'property')}
        >
          Unsubscribe
        </Button>
      ),
    },
    {
      title: '',
      key: 'arrow',
      render: (record) => (
        <Button
          type="link"
          icon={<RightOutlined />}
          style={{ color: '#1890ff', alignItems: 'center', justifyContent: 'center' }}
          onClick={(e) => {
            navigateToPropertyDetail(e, record?.propertId, 'occupants');
            dispatch(setSelectedNav('LeadGen'));
          }}
          // onClick={() => navigate(`/leadGen/connectedDetails/${record.propertId}`)}
        />
      ),
    },
  ];
  const navigateToPropertyDetail = (e, id, page) => {
    e.stopPropagation();
    navigate(`/leadGen/propertyDetails/${id}?list=${page}`, { replace: true });
  };
  const fetchSubscriptions = (page, shouldCheckScroll = false) => {
    setPropertyLoading(true);
    const limit = 8;
    const offset = (page - 1) * limit;

    dispatch(fetchSubscriptionsDetailsProperty({ propertyType: 'property', limit, offset }))
      .then((response) => {
        const newData = response?.payload?.data?.response?.data?.subscriptions || [];
        const totalDataCount = response?.payload?.data?.response?.data?.totalCount || 0;

        setTotalCount(totalDataCount);
        setData((prevData) => {
          const uniqueData = newData.filter((newItem) => !prevData.some((prevItem) => prevItem._id === newItem._id));
          return [...prevData, ...uniqueData];
        });

        setPropertyLoading(false);

        if (shouldCheckScroll) {
          setTimeout(() => {
            const tableBody = document.querySelectorAll('.ant-table-body')[0];
            if (tableBody) {
              const { scrollHeight, clientHeight } = tableBody;
              if (scrollHeight <= clientHeight && newData.length > 0 && data.length < totalDataCount) {
                setCurrentPage((prevPage) => prevPage + 1);
              }
            }
          }, 100);
        }
      })
      .catch((error) => {
        console.error('Error fetching subscriptions:', error);
        setPropertyLoading(false);
      });
  };

  useEffect(() => {
    dispatch(clearBreadcrumbs());
    dispatch(addBreadcrumb({ name: 'Subscriptions', path: (location?.pathname + location?.search) }));
    dispatch(setSelectedNav('Subscriptions'));

  }, [dispatch, location]);

  useEffect(() => {
    fetchSubscriptions(currentPage, true);
  }, [currentPage]);

  useEffect(() => {
    const tableBody = document.querySelectorAll('.ant-table-body')[0];

    if (tableBody) {
      tableBody.addEventListener('scroll', handlePropertyScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener('scroll', handlePropertyScroll);
      }
    };
  }, [data, propertyLoading]);

  const handlePropertyScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if ((scrollTop + clientHeight) / scrollHeight >= 0.8 && !propertyLoading && data.length < totalCount) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleUnsubscribe = async (record) => {
    await handleUnSubscribedClick(record, 'property_type_from_list');
    setData([]);
    setCurrentPage(1);
    fetchSubscriptions(1);
  };

  const DropdownContent = (
    <div className="ps-dropdown-content">
      <div>
        <h3>Notification</h3>
      </div>
      <div className="ps-dropdown-content__item">
        <span>WhatsApp</span>
        <Switch checked={whatsappEnabled} onChange={setWhatsappEnabled} />
      </div>
      <div className="ps-dropdown-content__item">
        <span>Email</span>
        <Switch checked={emailEnabled} onChange={setEmailEnabled} />
      </div>
    </div>
  );

  return (
    <div>
      <Card className="right-card">
        <div className="right-card__position">
          <div className="flex-container">
            <Title level={5} className="points-desc">
              Property
            </Title>
            <NotificationConfig type="property" />
          </div>

          <Table
            scroll={{ y: 'calc(72vh - 50px)' }}
            columns={propertyTableColumns}
            dataSource={data.map((item) => ({
              key: item?.id || item?._id,
              propertyName: item?.name || 'Unknown Name',
              propertyIcon: item?.featuredImages[0] || <Avatar icon={<HomeOutlined />} />,
              locality: item?.locality || 'Unknown Locality',
              city: item?.city || 'Unknown City',
              propertId: item?.resourceId,
            }))}
            pagination={false}
            loading={subscriptionDetailsPropertyLoader}
          />
          {propertyLoading && (
            <div style={{ textAlign: 'center', padding: '10px' }}>
              <Spin />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default PropertySubscriptions;
