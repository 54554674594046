import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React from 'react';

import Text from 'antd/es/typography/Text';
import { useNavigate } from 'react-router';
import PollComponent from '../../../socialApp/posts/allPosts/pollCardPost/PollComponent';
import PollProgress from '../../../socialApp/posts/allPosts/pollCardPost/PollProgress';
import './postTypes.scss';
import DeleteIconTrash from '../../../../../assets/images/DeleteIconTrash';

const PollCard = ({
  data,
  postData,
  handleDeletPost,
  handleSeeMore,
  handleCancel,
  isVisiable,
  user,
  sanitizer,
  companyData,
  isCompany,
  setAllPosts,
  setPostData,
}) => {
  const navigate = useNavigate();

  return (
    <div className="profile-container">
      <div className="profile-layout" gutter={16}>
        <div className="content-col" style={{ width: '100%' }}>
          <div className="d-flex jc-between g-10 " style={{ width: '100%' }}>
            <span className="d-flex g-15 a-center ">
              <Avatar src={data?.ownerDetails?.personalDetails?.imageUrl || ''} icon={<UserOutlined />} />
              {isCompany ? (
                <div className="d-flex d-column">
                  <span className="font14 fontExtraLight ">{data?.ownerDetails?.name}</span>
                  {data?.ownerDetails?.address && (
                    <span className="font14 fontExtraLight ">
                      {data?.ownerDetails?.address?.city},{data?.ownerDetails?.address?.state}
                    </span>
                  )}
                </div>
              ) : (
                <div className="d-flex d-column">
                  <Text style={{ fontWeight: '600', color: '#535353' }}>{data?.ownerDetails?.name}</Text>
                  <div className="d-flex">
                    {data?.ownerDetails?.professionalDetails?.designation && (
                      <Text
                        style={{ fontWeight: '600', fontSize: '12px', color: '#A2A2A2' }}
                      >{`${data?.ownerDetails?.professionalDetails?.designation}`}</Text>
                    )}
                    {data?.ownerDetails?.professionalDetails?.companyName && (
                      <Text style={{ fontWeight: '600', fontSize: '12px', color: '#A2A2A2' }}>
                        @{data?.ownerDetails?.professionalDetails?.companyName}
                      </Text>
                    )}
                  </div>
                </div>
              )}
            </span>
            <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '16 px' }} className="deleteIconPost">
              <DeleteIconTrash strokeColor={'#BFBFBF'} onClick={handleDeletPost} />
            </div>
          </div>

          <div className="d-flex g-5 d-column pt-20 w-100 flex-2 p-5">
            <Text className="font14 fontDark font700">{data?.title}</Text>
            {!data?.postDetails?.isVoted ? (
              <PollComponent options={data?.postDetails?.options} />
            ) : (
              <PollProgress viewPost={true} responses={data?.postDetails?.options} />
            )}
            <Text
              className="cursor-pointer"
              style={{ fontWeight: '600', color: '#0081FC' }}
              onClick={() => navigate(`/user/socialApp/postDetails/${data?._id}/${data?.ownerType}`)}
            >
              See more
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PollCard;
