import { asyncThunkCreator, buildCreateSlice } from '@reduxjs/toolkit';
import { ExternalError, AuthenticationError } from '../../utils/error/error';
import { get } from 'lodash';
import {
  deleteResourcesAPI,
  getConnectsFromOrganizationAPI,
  getDetailedConnectInfoAPI,
  getDetailedOrganizationInfoAPI,
  getDetailedPropertyInfoAPI,
  getOrganizationFromConnectsAPI,
  getOrganizationFromPropertyAPI,
  getOccupantsFromPropertyAPI,
  getPropertiesFromOrganizationAPI,
  getSearchResultsAPI,
  getWishlistContentAPI,
  getWishlistContentWithPointsAPI,
  saveResourcesAPI,
  unlockFieldsAPI,
  getOccupantDetailsFullInfoAPI,
  getResidentialDetailedPropertyInfoAPI,
  getResidentialUnitsFromPropertyAPI,
  getVacantUnitsFromPropertyAPI,
  getVacantUnitsFromResidentialPropertyAPI,
  getVacantUnitFullInfoAPI,
  addBrokerHelpAPI,
  getBrokerHelpAPI,
  getPieAnalyticsDataFromPropertyAPI,
  getBarAnalyticsDataFromPropertyAPI,
  getBuildingStatsFromPropertyAPI,
  getBuildingOccupiedStatsFromPropertyAPI,
} from '../services/searchAPI';
import { fetchUserPoints } from '../../universal/features/userOnboardingSlice';
import { set_toast } from '../../universal/features/toastSlice';
import { FormatProperty } from '../../utils/formatSearchData/Property';
import {
  doSubscribed as _doSubscribed,
  doUnSubscribed as _doUnSubscribed,
} from '../../universal/services/myAccountAPI';
import { BuildingStats } from '../../utils/formatSearchData/BuildingStats';

const createSliceWithThunks = buildCreateSlice({
  creators: {
    asyncThunk: asyncThunkCreator,
  },
});

const initialState = {
  loading: false,
  organizationsData: [],
  organizationsSearchData: [],
  propertyData: [],
  connectsData: [],
  unitsData: [],
  vacantUnitsData: [],
  residentialVacantUnitsData: [],
  residentialPropertyData: [],
  searchVisible: false,
  searchClasifier: '',
  column2View: '',
  column3View: '',
  wishlist: {},
  detailedConnectData: {},
  detailedPropertyData: {},
  detailedResidentialPropertyData: {},
  detailedOrganizationData: {},
  navigateFrom: '',
  occupantDetails: [],
  searchContext: 'commercialProperty',
  propertyToggle: true,
  pagination: {},
  searchQuery: {},
  hasMoreData: true,
  hasMoreSimilarCompaniesData: true,
  selectedPropertyId: '',
  selectedConnectId: '',
  selectedOrganizationId: '',
  organizationPagination: {},
  isSavedAllProperty: false,
  isSavedAllOrganization: false,
  isSavedAllConnects: false,
  isSavedAllResidentialProperty: false,
  propertyDetailsLoader: false,
  companyDetailsLoader: false,
  connectLoader: false,
  searchResultLoader: false,
  breadcrumbs: [],
  vacantUnitDetails: [],
  subscriptionDetailsProperty: {},
  subscriptionDetailsPropertyLoader: false,
  subscriptionDetailsCompany: {},
  subscriptionDetailsCompanyLoader: false,
  pieAnalyticsData: {},
  barAnalyticsData: {},
  buildingStatsData: {},
  // isSubscribedAllProperty: false,
  // isSubscribedAllOrganization: false,
  // isSubscribedAllConnects: false,
  // isSubscribedAllResidentialProperty: false,
};

const handleSelectDeselectState = (state, action, isSaved) => {
  state.loading = false;
  const payloadArray = Array.isArray(action.payload) ? action.payload : [action.payload];
  const updateState = (data, payload, isSaved) => {
    return data?.map((el) => {
      const match = payload?.some((res) => res?.resourceId === el?._id);
      return match ? { ...el, isSaved } : el;
    });
  };

  const resourceSubType =
    payloadArray?.[0]?.resourceType === 'residential' && payloadArray?.[0]?.resourceSubType !== 'unit'
      ? 'residentialProperty'
      : payloadArray?.[0]?.resourceSubType;

  const setIsSavedAllFlag = (data) => {
    return data.every((item) => item.isSaved === true);
  };

  switch (resourceSubType) {
    case 'property':
      state.propertyData = updateState(state.propertyData, payloadArray, isSaved);

      state.detailedPropertyData = updateState(
        Array.isArray(state.detailedPropertyData) ? state.detailedPropertyData : [state.detailedPropertyData],
        payloadArray,
        isSaved,
      );
      state.isSavedAllProperty = setIsSavedAllFlag(state.propertyData);
      break;
    case 'organization':
      state.organizationsSearchData = updateState(state.organizationsSearchData, payloadArray, isSaved);
      state.organizationsData = updateState(state.organizationsData, payloadArray, isSaved);
      state.isSavedAllOrganization = setIsSavedAllFlag(state.organizationsSearchData);
      break;
    case 'connect':
      state.connectsData = updateState(state.connectsData, payloadArray, isSaved);
      state.isSavedAllConnects = setIsSavedAllFlag(state.connectsData);
      break;
    case 'unit':
      state.unitsData = updateState(state.unitsData, payloadArray, isSaved);
      break;
    case 'residentialProperty':
      state.residentialPropertyData = updateState(state.residentialPropertyData, payloadArray, isSaved);
      state.detailedResidentialPropertyData = updateState(
        Array.isArray(state.detailedResidentialPropertyData)
          ? state.detailedResidentialPropertyData
          : [state.detailedResidentialPropertyData],
        payloadArray,
        isSaved,
      );
      state.isSavedAllResidentialProperty = setIsSavedAllFlag(state.residentialPropertyData);
      break;
    default:
      console.warn('resourceSubType not found');
  }
};
const handleFollowUnfollowState = (state, action) => {
  const updateState = (data, userId) => {
    return data?.map((el) => {
      const match = el?._id === userId;

      return match ? { ...el, isFollowed: action?.payload?.isFollow } : el;
    });
  };

  state.connectsData = updateState(state.connectsData, action?.payload?.userId);
};

//Subscribed and Unsubscribed Handler
const handleSelectDeselectSubscribedStateForProperty = (state, action, isSubscribedId) => {
  state.loading = false;
  const payloadArray = Array.isArray(action.payload) ? action.payload : [action.payload];

  const updateState = (data, payload, isSubscribedId) => {
    // Update only the matched objects, keeping the original structure intact

    return data?.map((el) => {
      // Convert both resourceId and _id to strings before comparing
      const match = payload?.some((res) => String(res?.data?.response?.data?.resourceId) === String(el?._id));

      // If match, update `isSubscribed`, otherwise return the original object
      if (match) {
        return { ...el, isSubscribed: isSubscribedId };
      }

      // Keep existing element if no match
      return el;
    });
  };

  const resourceSubType = payloadArray[0]?.data?.response?.data?.type === 'property' && 'property';

  const setIsSavedAllFlag = (data) => {
    return data.every((item) => item.isSubscribed === true);
  };
  switch (resourceSubType) {
    case 'property':
      state.propertyData = updateState(state.propertyData, payloadArray, isSubscribedId);

      state.detailedPropertyData = updateState(
        Array.isArray(state.detailedPropertyData) ? state.detailedPropertyData : [state.detailedPropertyData],
        payloadArray,
        isSubscribedId,
      );
      state.detailedResidentialPropertyData = updateState(
        Array.isArray(state.detailedResidentialPropertyData)
          ? state.detailedResidentialPropertyData
          : [state.detailedResidentialPropertyData],
        payloadArray,
        isSubscribedId,
      );
      state.residentialPropertyData = updateState(
        Array.isArray(state.residentialPropertyData) ? state.residentialPropertyData : [state.residentialPropertyData],
        payloadArray,
        isSubscribedId,
      );
      // state.isSubscribedAllProperty = setIsSavedAllFlag(state.propertyData);
      break;

    default:
      console.warn('resourceSubType not found');
  }
};
const handleSelectDeselectSubscribedStateForCompany = (state, action, isSubscribedId) => {
  state.loading = false;
  const payloadArray = Array.isArray(action.payload) ? action.payload : [action.payload];

  const updateState = (data, payload, isSubscribedId) => {
    // Update only the matched objects, keeping the original structure intact

    return data?.map((el) => {
      // Convert both resourceId and _id to strings before comparing

      const match = payload?.some((res) => String(res?.data?.response?.data?.resourceId) === String(el?._id));

      // If match, update `isSubscribed`, otherwise return the original object
      if (match) {
        return { ...el, isSubscribed: isSubscribedId };
      }

      // Keep existing element if no match
      return el;
    });
  };

  const resourceSubType = payloadArray[0]?.data?.response?.data?.type === 'company' && 'Commercial';

  const setIsSavedAllFlag = (data) => {
    return data.every((item) => item.isSubscribed === true);
  };
  switch (resourceSubType) {
    case 'Commercial':
      state.organizationsData = updateState(state.organizationsData, payloadArray, isSubscribedId);
      state.organizationsSearchData = updateState(state.organizationsSearchData, payloadArray, isSubscribedId);

      // state.isSubscribedAllOrganization = setIsSavedAllFlag(state.organizationsData);
      break;

    default:
      console.warn('resourceSubType not found');
  }
};
const handleSelectDeselectSubscribedStateResedential = (state, action, isSubscribedId) => {
  state.loading = false;
  const payloadArray = Array.isArray(action.payload) ? action.payload : [action.payload];

  const updateState = (data, payload, isSubscribedId) => {
    // Update only the matched objects, keeping the original structure intact

    return data?.map((el) => {
      // Convert both resourceId and _id to strings before comparing
      const match = payload?.some((res) => String(res?.data?.data?.response?.data?.resourceId) === String(el?._id));

      // If match, update `isSubscribed`, otherwise return the original object
      if (match) {
        return { ...el, isSubscribed: isSubscribedId };
      }

      // Keep existing element if no match
      return el;
    });
  };

  const resourceSubType =
    payloadArray[0]?.data?.data?.response?.data?.type === 'ResidentialProperty' && 'ResidentialProperty';

  const setIsSavedAllFlag = (data) => {
    return data.every((item) => item.isSubscribed === true);
  };
  switch (resourceSubType) {
    case 'ResidentialProperty':
      state.residentialPropertyData = updateState(state.residentialPropertyData, payloadArray, isSubscribedId);
      state.detailedResidentialPropertyData = updateState(
        Array.isArray(state.detailedResidentialPropertyData)
          ? state.detailedResidentialPropertyData
          : [state.detailedResidentialPropertyData],
        payloadArray,
        isSubscribedId,
      );
      state.propertyData = updateState(
        Array.isArray(state.propertyData) ? state.propertyData : [state.propertyData],
        payloadArray,
        isSubscribedId,
      );
      state.detailedPropertyData = updateState(
        Array.isArray(state.detailedPropertyData) ? state.detailedPropertyData : [state.detailedPropertyData],
        payloadArray,
        isSubscribedId,
      );
      // state.isSubscribedAllProperty = setIsSavedAllFlag(state.propertyData);
      break;

    default:
      console.warn('resourceSubType not found');
  }
};
const handleSelectDeselectUNSubscribedState = (state, action, isSubscribedId) => {
  state.loading = false;
  const payloadArray = Array.isArray(action.payload) ? action.payload : [action.payload];

  const updateState = (data, payload, isSubscribedId) => {
    // Update only the matched objects, keeping the original structure intact

    return data?.map((el) => {
      // Convert both resourceId and _id to strings before comparing

      const match = payload?.some((res) => String(res?.propertyId) === String(el?._id));

      // If match, update `isSubscribed`, otherwise return the original object
      if (match) {
        return { ...el, isSubscribed: isSubscribedId };
      }

      // Keep existing element if no match
      return el;
    });
  };
  let resourceSubType = ' ';
  if (payloadArray[0]?.propertyType === 'property_type_from_list') {
    resourceSubType = 'property';
  } else if (payloadArray[0]?.propertyType === 'commercialProperty') {
    resourceSubType = 'property';
  } else if (payloadArray[0]?.propertyType === 'Company') {
    resourceSubType = 'Commercial';
  } else if (payloadArray[0]?.propertyType === 'company_type_from_list') {
    resourceSubType = 'Commercial';
  } else if (payloadArray[0]?.propertyType === 'CompanyDetails') {
    resourceSubType = 'Commercial';
  }

  const setIsSavedAllFlag = (data) => {
    return data.every((item) => item.isSubscribed === true);
  };
  switch (resourceSubType) {
    case 'property':
      state.propertyData = updateState(state.propertyData, payloadArray, isSubscribedId);
      state.residentialPropertyData = updateState(
        Array.isArray(state.residentialPropertyData) ? state.residentialPropertyData : [state.residentialPropertyData],
        payloadArray,
        isSubscribedId,
      );
      state.detailedPropertyData = updateState(
        Array.isArray(state.detailedPropertyData) ? state.detailedPropertyData : [state.detailedPropertyData],
        payloadArray,
        isSubscribedId,
      );
      state.detailedResidentialPropertyData = updateState(
        Array.isArray(state.detailedResidentialPropertyData)
          ? state.detailedResidentialPropertyData
          : [state.detailedResidentialPropertyData],
        payloadArray,
        isSubscribedId,
      );
      // state.isSubscribedAllProperty = setIsSavedAllFlag(state.propertyData);
      break;
    case 'Commercial':
      state.organizationsData = updateState(state.organizationsData, payloadArray, isSubscribedId);
      state.organizationsSearchData = updateState(state.organizationsSearchData, payloadArray, isSubscribedId);

      // state.isSubscribedAllOrganization = setIsSavedAllFlag(state.organizationsData);
      break;

    default:
      console.warn('resourceSubType not found');
  }
};
const handleSelectDeselectUNSubscribedStateResedential = (state, action, isSubscribedId) => {
  state.loading = false;
  const payloadArray = Array.isArray(action.payload) ? action.payload : [action.payload];

  const updateState = (data, payload, isSubscribedId) => {
    return data?.map((el) => {
      // Convert both resourceId and _id to strings before comparing
      const match = payload?.some((res) => String(res?.propertyId) === String(el?._id));

      // If match, update `isSubscribed`, otherwise return the original object
      if (match) {
        return { ...el, isSubscribed: isSubscribedId };
      }

      // Keep existing element if no match
      return el;
    });
  };
  let resourceSubType = '';
  if (payloadArray[0]?.propertyType === 'ResidentialProperty') {
    resourceSubType = 'ResidentialProperty';
  } else if (payloadArray[0]?.propertyType === 'ResidentialPropertyDetails') {
    resourceSubType = 'ResidentialProperty';
  } else if (payloadArray[0]?.propertyType === 'CompanyDetails') {
    resourceSubType = 'commercial';
  }

  const setIsSavedAllFlag = (data) => {
    return data.every((item) => item.isSubscribed === true);
  };
  switch (resourceSubType) {
    case 'ResidentialProperty':
      state.residentialPropertyData = updateState(state.residentialPropertyData, payloadArray, isSubscribedId);
      state.propertyData = updateState(state.propertyData, payloadArray, isSubscribedId);
      state.detailedResidentialPropertyData = updateState(
        Array.isArray(state.detailedResidentialPropertyData)
          ? state.detailedResidentialPropertyData
          : [state.detailedResidentialPropertyData],
        payloadArray,
        isSubscribedId,
      );
      state.detailedPropertyData = updateState(
        Array.isArray(state.detailedPropertyData) ? state.detailedPropertyData : [state.detailedPropertyData],
        payloadArray,
        isSubscribedId,
      );

      // state.isSubscribedAllProperty = setIsSavedAllFlag(state.propertyData);
      break;
    case 'commercial':
      state.organizationsData = updateState(state.organizationsData, payloadArray, isSubscribedId);
      state.organizationsSearchData = updateState(state.organizationsSearchData, payloadArray, isSubscribedId);

      // state.isSubscribedAllProperty = setIsSavedAllFlag(state.propertyData);
      break;
    default:
      console.warn('resourceSubType not found');
  }
};
const searchSlice = createSliceWithThunks({
  name: 'search',
  initialState,
  reducers: (create) => ({
    showSearchBar: (state, action) => {
      state.searchVisible = action.payload;
    },
    resetDetailData :(state,action) => {
      state.detailedConnectData = initialState.detailedConnectData;
    },
    resetDetailPropertyData :(state,action) => {
      state.detailedPropertyData = initialState.detailedPropertyData;
    },
    setSavedAllProperty: (state, action) => {
      state.isSavedAllProperty = action.payload;
    },
    setSavedAllOrganization: (state, action) => {
      state.isSavedAllOrganization = action.payload;
    },
    setSavedAllConnects: (state, action) => {
      state.isSavedAllConnects = action.payload;
    },
    setSavedAllResidentialProperty: (state, action) => {
      state.isSavedAllResidentialProperty = action.payload;
    },
    setpropertyToggle: (state, action) => {
      state.propertyToggle = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setOrganizationPagination: (state, action) => {
      state.organizationPagination = action.payload;
    },
    setHasMoreData: (state, action) => {
      state.hasMoreData = action.payload;
    },
    setHasMoreSimilarCompaniesData: (state, action) => {
      state.hasMoreSimilarCompaniesData = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setDetailedPropertyData: (state, action) => {
      state.detailedPropertyData = FormatProperty(action?.payload);
      state.occupantDetails = [];
    },
    setDetailedResidentialPropertyData: (state, action) => {
      state.detailedResidentialPropertyData = FormatProperty(action?.payload);
    },
    setDetailedOrganizationData: (state, action) => {
      state.detailedOrganizationData = action.payload;
    },
    setSearchContext: (state, action) => {
      state.searchContext = action?.payload;
    },
    setPropertyData: (state, action) => {
      state.propertyData = action?.payload;
    },
    setSelectedOrganizationId: (state, action) => {
      state.selectedOrganizationId = action?.payload;
    },
    setDetailedBuildingStatsPropertyData: (state, action) => {
      state.buildingStatsData = BuildingStats(action?.payload);
    },
    setSelectedConnectId: (state, action) => {
      state.loading = true;
      state.detailedPropertyData = {};
      state.detailedOrganizationData = {};
      state.selectedConnectId = action?.payload;
      state.propertyData = [];
      state.organizationsData = [];
    },
    reset_data: (state, action) => {
      state.organizationsData = [];
      state.organizationsSearchData = [];
      state.propertyData = [];
      state.connectsData = [];
      state.residentialPropertyData = [];
      state.unitsData = [];
      state.vacantUnitsData = [];
      state.hasMoreData = true;
      state.hasMoreSimilarCompaniesData = true;
    },
    viewMoreProperty: (state, action) => {
      state.loading = true;
      state.organizationsData = [];
      state.connectsData = [];
      state.selectedPropertyId = action?.payload;
      state.organizationPagination = { offset: 0 };
    },
    viewMoreResidentialProperty: (state, action) => {
      state.loading = true;
      state.unitsData = [];
    },
    viewLessProperty: (state, action) => {
      state.organizationsData = [];
      state.connectsData = [];
      state.selectedPropertyId = '';
      state.propertyData = state?.propertyData?.map((el) => {
        if (el?._id === action.payload) {
          return {
            ...el,
            isExpanded: false,
          };
        } else {
          return {
            ...el,
          };
        }
      });
    },
    viewLessResidentialProperty: (state, action) => {
      state.unitsData = [];
      state.residentialPropertyData = state?.residentialPropertyData?.map((el) => {
        if (el?._id === action.payload) {
          return {
            ...el,
            isExpanded: false,
          };
        } else {
          return {
            ...el,
          };
        }
      });
    },
    viewLessPropertyOrganizationFlow: (state, action) => {
      // state.organizationsData = [];
      // state.connectsData = [];
      state.propertyData = state?.propertyData?.map((el) => {
        if (el?._id === action.payload) {
          return {
            ...el,
            isExpanded: false,
          };
        } else {
          return {
            ...el,
          };
        }
      });
    },
    viewMorePropertyOrganizationFlow: (state, action) => {
      // state.organizationsData = [];
      // state.connectsData = [];
      state.propertyData = state?.propertyData?.map((el) => {
        if (el?._id === action.payload) {
          return {
            ...el,
            isExpanded: true,
          };
        } else {
          return {
            ...el,
          };
        }
      });
    },
    viewMoreOrganization: (state, action) => {
      state.loading = true;
    },
    viewLessOrganization: (state, action) => {
      state.connectsData = [];
      state.organizationsData = state?.organizationsData?.map((el) => {
        if (el?._id === action.payload) {
          return {
            ...el,
            isExpanded: false,
          };
        } else {
          return {
            ...el,
          };
        }
      });
    },
    viewMoreConnects: (state, action) => {
      state.loading = true;
      state.connectsData = state?.connectsData?.map((el) => {
        if (el?._id === action.payload) {
          return {
            ...el,
            isExpanded: true,
          };
        } else {
          return {
            ...el,
            isExpanded: false,
          };
        }
      });
    },
    viewMoreOrganizationConnectsFlow: (state, action) => {
      state.loading = true;
    },
    viewLessOrganizationConnectsFlow: (state, action) => {
      state.propertyData = [];
      state.organizationsData = state?.organizationsData?.map((el) => {
        if (el?._id === action.payload) {
          return {
            ...el,
            isExpanded: false,
          };
        } else {
          return {
            ...el,
          };
        }
      });
    },
    getSearchResults: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          param.body.tag = thunkAPI.getState().search.propertyToggle
            ? `${thunkAPI.getState().search.searchContext}`
            : 'residentialProperty';
          let data = await getSearchResultsAPI(param, userId);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }
          thunkAPI.dispatch(setSearchQuery(param));
          // if (data?.data?.response?.data?.tag === 'organization') {
          //   thunkAPI.dispatch(getConnectsFromOrganization(data?.data?.response?.data?.data?.[0]?._id));
          // }
          if (data.data.response.data?.data?.length > 0) {
            thunkAPI.dispatch(
              setPagination({
                offset: thunkAPI.getState().search.pagination?.offset + 10,
                limit: 10,
              }),
            );
            thunkAPI.dispatch(setSavedAllProperty(false));
            thunkAPI.dispatch(setSavedAllResidentialProperty(false));
          } else {
            thunkAPI.dispatch(setHasMoreData(false));
          }
          return data.data.response.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.searchResultLoader = true;
          state.detailedResidentialPropertyData = {};
          state.unitsData = [];
        },
        rejected: (state, action) => {
          state.searchResultLoader = false;
        },
        fulfilled: (state, action) => {
          state.searchResultLoader = false;
          state.searchClasifier = action.payload.tag;
          switch (action.payload.tag) {
            case 'commercialProperty': {
              const newCommercialData = action?.payload?.data?.filter(
                (item) => !state.propertyData.some((existingItem) => existingItem._id === item._id), // Assuming each item has a unique 'id'
              );
              state.propertyData.push(...newCommercialData);
              break;
            }
            case 'residentialProperty': {
              const newResidentialData = action?.payload?.data?.filter(
                (item) => !state.residentialPropertyData.some((existingItem) => existingItem._id === item._id), // Assuming each item has a unique 'id'
              );

              state.residentialPropertyData.push(...newResidentialData);

              state.detailedResidentialPropertyData = {};
              state.unitsData = [];
              break;
            }
            case 'connect': {
              const newConnectsData = action?.payload?.data?.filter(
                (item) => !state.connectsData.some((existingItem) => existingItem?._id === item?._id), // Assuming each item has a unique 'id'
              );
              state.connectsData.push(...newConnectsData);
              break;
            }
            case 'organization': {
              const newOrganizationData = action?.payload?.data?.filter(
                (item) => !state.organizationsData.some((existingItem) => existingItem._id === item._id), // Assuming each item has a unique 'id'
              );
              state.organizationsData.push(...newOrganizationData);
              state.organizationsSearchData.push(...newOrganizationData);
              break;
            }
            default:
              return;
          }
        },
      },
    ),
    getDetailedPropertyInfo: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getDetailedPropertyInfoAPI(param, userId);
          if (data?.data?.response?.code !== 200) {
            throw new ExternalError(data?.data?.errors?.[0]?.message, {
              statusCode: data?.data?.response?.code,
            });
          }
          thunkAPI.dispatch(setDetailedPropertyData(data?.data?.response?.data?.[0]));
          const editedPayload = thunkAPI.getState().search?.propertyData?.map((ele) => {
            if (ele?._id === data?.data?.response?.data?.[0]?._id) {
              return {
                ...ele,
                ...data?.data?.response?.data?.[0],
                isExpanded: true,
              };
            } else {
              return { ...ele, isExpanded: false };
            }
          });
          return editedPayload;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.propertyDetailsLoader = true;
        },
        rejected: (state, action) => {
          state.propertyDetailsLoader = false;
        },
        fulfilled: (state, action) => {
          state.propertyData = action.payload;
          state.propertyDetailsLoader = false;
        },
      },
    ),

    getDetailedResidentialPropertyInfo: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getResidentialDetailedPropertyInfoAPI(param, userId);
          if (data?.data?.response?.code !== 200) {
            throw new ExternalError(data?.data?.errors?.[0]?.message, {
              statusCode: data?.data?.response?.code,
            });
          }
          thunkAPI.dispatch(setDetailedResidentialPropertyData(data?.data?.response?.data));
          const editedPayload = thunkAPI.getState().search?.residentialPropertyData?.map((ele) => {
            if (ele?._id === data?.data?.response?.data?._id) {
              return {
                ...ele,
                ...data?.data?.response?.data,
                isExpanded: true,
              };
            } else {
              return { ...ele, isExpanded: false };
            }
          });
          return editedPayload;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {},
        rejected: (state, action) => {},
        fulfilled: (state, action) => {
          state.residentialPropertyData = action.payload;
        },
      },
    ),
    getOrganizationFromConnects: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getOrganizationFromConnectsAPI(param, userId);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }

          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.organizationsData = [];
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.organizationsData = action.payload;
          state.loading = false;
        },
      },
    ),
    unlockConnectsFields: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await unlockFieldsAPI(param, userId);

          if (data?.response?.status === 402) {
            throw new ExternalError(data?.response?.data?.errors?.message, {
              statusCode: data?.status,
            });
          }

          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }

          thunkAPI.dispatch(fetchUserPoints());
          return data?.data?.response?.data?.[0];
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          thunkAPI.dispatch(set_toast({ show: true, type: 'warning', content: message }));
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.connectsData = state.connectsData?.map((el) => {
            if (el?._id === action?.payload?._id) {
              return { ...action.payload, isExpanded: true };
            } else {
              return el;
            }
          });
        },
      },
    ),
    unlockOrganizationFields: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await unlockFieldsAPI(param, userId);

          if (data?.response?.status === 402) {
            throw new ExternalError(data?.response?.data?.errors?.message, {
              statusCode: data?.status,
            });
          }

          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }

          thunkAPI.dispatch(fetchUserPoints());

          return data?.data?.response?.data?.[0];
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          thunkAPI.dispatch(set_toast({ show: true, type: 'warning', content: message }));
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.organizationsData = state.organizationsData?.map((el) => {
            if (el?._id === action?.payload?._id) {
              return { ...action.payload, isExpanded: true };
            } else {
              return el;
            }
          });
        },
      },
    ),
    unlockPropertyFields: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await unlockFieldsAPI(param, userId);
          if (data?.response?.status === 402) {
            throw new ExternalError(data?.response?.data?.errors?.message, {
              statusCode: data?.status,
            });
          }
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }

          thunkAPI.dispatch(fetchUserPoints());
          return data?.data?.response?.data?.[0];
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          thunkAPI.dispatch(set_toast({ show: true, type: 'warning', content: message }));
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.propertyData = state.propertyData?.map((el) => {
            if (el?._id === action?.payload?._id) {
              return { ...action.payload, isExpanded: true };
            } else {
              return el;
            }
          });
        },
      },
    ),
    unlockVacantUnitFields: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await unlockFieldsAPI(param, userId);
          if (data?.response?.status === 402) {
            throw new ExternalError(data?.response?.data?.errors?.message, {
              statusCode: data?.status,
            });
          }
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }

          thunkAPI.dispatch(fetchUserPoints());
          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          thunkAPI.dispatch(set_toast({ show: true, type: 'warning', content: message }));
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.vacantUnitDetails = action?.payload;
        },
      },
    ),
    getConnectsFromOrganization: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getConnectsFromOrganizationAPI(
            thunkAPI.getState().search.selectedOrganizationId || param,
            userId,
          );
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }

          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.connectLoader = true;
          state.detailedConnectData = {};
        },
        rejected: (state, action) => {
          state.connectLoader = false;
        },
        fulfilled: (state, action) => {
          state.connectsData = action?.payload;
          state.connectLoader = false;
        },
      },
    ),
    getDetailedOrganizationInfo: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getDetailedOrganizationInfoAPI(param, userId);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }
          thunkAPI.dispatch(setDetailedOrganizationData(data?.data?.response?.data?.[0]));
          const editedPayload = thunkAPI.getState().search?.organizationsData?.map((ele) => {
            if (ele?._id === data?.data?.response?.data?.[0]?._id) {
              return {
                ...ele,
                ...data.data.response.data[0],
                isExpanded: true,
              };
            } else {
              return { ...ele, isExpanded: false };
            }
          });

          return editedPayload;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.organizationsData = action?.payload;
          state.loading = false;
        },
      },
    ),
    getDetailedConnectInfo: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getDetailedConnectInfoAPI(param, userId);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }

          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.detailedConnectData = action.payload?.data?.response?.data;
          state.loading = false;
        },
      },
    ),
    deleteResources: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await deleteResourcesAPI(param, userId);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }

          await thunkAPI.dispatch(fetchUserPoints());
          await thunkAPI.dispatch(getWishlistContentWithPoints());
          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => handleSelectDeselectState(state, action, false),
      },
    ),
    saveResources: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await saveResourcesAPI(param, userId);
          if (data?.status !== 201) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }
          await thunkAPI.dispatch(fetchUserPoints());
          await thunkAPI.dispatch(getWishlistContentWithPoints());
          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => handleSelectDeselectState(state, action, true),
      },
    ),
    getWishlistContent: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getWishlistContentAPI(userId);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }

          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.wishlist = action.payload;
        },
      },
    ),
    getWishlistContentWithPoints: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getWishlistContentWithPointsAPI(userId);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }

          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.wishlist = action.payload;
        },
      },
    ),
    getPropertiesFromOrganization: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          // thunkAPI.dispatch(setPropertyData([]));
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getPropertiesFromOrganizationAPI(param, userId);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }
          await thunkAPI.dispatch(setSelectedOrganizationId(param));
          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {},
        rejected: (state, action) => {},
        fulfilled: (state, action) => {
          const prev = state.propertyData;
          state.propertyData = [...(prev || []), ...(action?.payload || [])];

          state.loading = false;
        },
      },
    ),
    getSelectedPropertyFromOrganization: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getPropertiesFromOrganizationAPI(param, userId);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }
          await thunkAPI.dispatch(setSelectedOrganizationId(param));
          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
          state.detailedPropertyData = {};
          state.propertyData = [];
        },
        fulfilled: (state, action) => {
          state.detailedPropertyData = action?.payload?.[0] || {};
          state.propertyData = action?.payload?.slice(1) || [];
          state.loading = false;
        },
      },
    ),
    getOrganizationFromProperty: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          const offset = thunkAPI.getState().search.organizationPagination?.offset;
          let data = await getOrganizationFromPropertyAPI(param, userId, offset);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }
          if (data?.data?.response?.data?.length > 0) {
            thunkAPI.dispatch(
              setOrganizationPagination({
                offset: thunkAPI.getState().search.organizationPagination?.offset + 20,
                limit: 20,
              }),
            );
            thunkAPI.dispatch(setSavedAllOrganization(false));
          } else {
            thunkAPI.dispatch(setHasMoreSimilarCompaniesData(false));
          }
          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.companyDetailsLoader = true;
        },
        rejected: (state, action) => {
          state.companyDetailsLoader = false;
        },
        fulfilled: (state, action) => {
          // state.organizationsData = action.payload;
          state.organizationsData.push(...action?.payload);
          state.companyDetailsLoader = false;
        },
      },
    ),
    getVacantUnitsFromProperty: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getVacantUnitsFromPropertyAPI(param, userId);
          if (!data || data.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }

          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.vacantUnitsData = action?.payload;
          state.loading = false;
        },
      },
    ),
    getVacantUnitsFromResidentialProperty: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getVacantUnitsFromResidentialPropertyAPI(param, userId);
          if (!data || data.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }

          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.residentialVacantUnitsData = action?.payload;
          state.loading = false;
        },
      },
    ),
    getResidentialUnitsFromProperty: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getResidentialUnitsFromPropertyAPI(param, userId);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }

          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.unitsData.push(...action?.payload);
          state.loading = false;
        },
      },
    ),
    getOccupantsFromProperty: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getOccupantsFromPropertyAPI(param, userId);
          if (data?.data?.response?.code !== 200) {
            throw new ExternalError(data?.data?.errors?.[0]?.message, {
              statusCode: data?.data?.response?.code,
            });
          }

          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {},
        rejected: (state, action) => {},
        fulfilled: (state, action) => {
          state.occupantsData = action?.payload;
          state.loading = false;
        },
      },
    ),
    setNavigateFrom: (state, action) => {
      state.navigateFrom = action?.payload;
    },
    getOccupantDetailsFullInfo: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          const propertyId = thunkAPI.getState().search?.propertyToggle
            ? thunkAPI.getState().search?.detailedPropertyData?._id
            : thunkAPI.getState().search?.detailedResidentialPropertyData?._id;
          let data = await getOccupantDetailsFullInfoAPI(param, userId, propertyId);
          if (data?.data?.response?.code !== 200) {
            throw new ExternalError(data?.data?.response?.errors?.[0]?.message, {
              statusCode: data?.data?.response?.code,
            });
          }
          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.occupantDetails = action?.payload?.[0];
          state.loading = false;
        },
      },
    ),
    addBreadcrumb: (state, action) => {
      const { name, path } = action.payload;
      const existingIndex = state.breadcrumbs.findIndex(crumb => crumb.path === path);
      
      if (existingIndex !== -1) {
        state.breadcrumbs = state.breadcrumbs.slice(0, existingIndex + 1);
        state.breadcrumbs[existingIndex].name = name;
      } else {
        state.breadcrumbs.push({ name, path });
      }
    },
    
    truncateBreadcrumbs: (state, action) => {
      const index = state.breadcrumbs.findIndex((crumb) => crumb.name === action.payload);
      if (index !== -1) {
        state.breadcrumbs = state.breadcrumbs.slice(0, index + 1);
      }
    },
    
    clearBreadcrumbs: (state) => {
      state.breadcrumbs = [];
    },
    getVacantUnitFullInfo: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getVacantUnitFullInfoAPI(
            userId,
            param,
            thunkAPI.getState().search?.propertyToggle ? 'commercial' : 'residential',
          );

          if (data?.data?.response?.code !== 200) {
            throw new ExternalError(data?.data?.response?.errors?.[0]?.message, {
              statusCode: data?.data?.response?.code,
            });
          }
          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.vacantUnitDetails = action?.payload?.[0];
          state.loading = false;
        },
      },
    ),
    addBrokerHelp: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await addBrokerHelpAPI(param, userId);

          if (data?.status !== 201) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }
          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
        },
      },
    ),
    getBrokerHelp: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getBrokerHelpAPI(param, userId);

          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }
          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
        },
      },
    ),
    //------Subscription Actions------
    doSubscribed: create.asyncThunk(
      async (payload, thunkApi) => {
        try {
          let data = await _doSubscribed(payload);
          if (data?.status !== 201) {
            throw new AuthenticationError(data?.response?.data?.errors?.message, {
              statusCode: data.status,
            });
          }
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkApi.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.subscriptionDetailsPropertyLoader = true;
        },
        rejected: (state, action) => {
          state.error = action.error;
          state.subscriptionDetailsPropertyLoader = false;
        },
        fulfilled: (state, action) => {
          state.subscriptionDetailsPropertyLoader = false;
          state.subscriptionDetails = action?.payload?.data?.response?.data;
          if (action?.payload?.data?.response?.data?.type === 'company') {
            handleSelectDeselectSubscribedStateForCompany(state, action, action?.payload?.data?.response?.data?.id);
          } else {
            handleSelectDeselectSubscribedStateForProperty(state, action, action?.payload?.data?.response?.data?.id);
          }
        },
      },
    ),
    doSubscribedResedential: create.asyncThunk(
      async (payload, thunkApi) => {
        try {
          // const userId = thunkApi.getState().user?.user?.id;
          let data = await _doSubscribed(payload);
          if (data?.status !== 201) {
            throw new AuthenticationError(data?.response?.data?.errors?.message, {
              statusCode: data.status,
            });
          }
          // data?.data?.response?.data?.type = 'ResedentialProperty';
          data.data.response.data.type = 'ResidentialProperty';
          return { data };
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkApi.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.subscriptionDetailsPropertyLoader = true;
        },
        rejected: (state, action) => {
          state.error = action.error;
          state.subscriptionDetailsPropertyLoader = false;
        },
        fulfilled: (state, action) => {
          state.subscriptionDetailsPropertyLoader = false;
          state.subscriptionDetails = action?.payload?.data?.response?.data;

          handleSelectDeselectSubscribedStateResedential(
            state,
            action,
            action?.payload?.data?.data?.response?.data?.id,
          );
        },
      },
    ),

    doUnSubscribed: create.asyncThunk(
      async (property, thunkApi) => {
        try {
          const { unSubscribedId, propertyId, propertyType } = property;

          let data = await _doUnSubscribed(unSubscribedId);

          if (data?.status !== 200) {
            throw new AuthenticationError(data?.response?.data?.errors?.message, {
              statusCode: data.status,
            });
          }

          return { data, propertyId, propertyType };
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkApi.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.subscriptionDetailsLoader = true;
        },
        rejected: (state, action) => {
          state.error = action.error;
          state.subscriptionDetailsLoader = false;
        },
        fulfilled: (state, action, data) => {
          state.subscriptionDetailsLoader = false;

          if (data?.data?.response?.message !== 'Not Found Exception') {
            handleSelectDeselectUNSubscribedState(state, action, null);
          }
        },
      },
    ),
    doUnSubscribedResedential: create.asyncThunk(
      async (property, thunkApi) => {
        try {
          const { unSubscribedId, propertyId, propertyType } = property;

          let data = await _doUnSubscribed(unSubscribedId);

          if (data?.status !== 200) {
            throw new AuthenticationError(data?.response?.data?.errors?.message, {
              statusCode: data.status,
            });
          }

          return { data, propertyId, propertyType };
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkApi.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.subscriptionDetailsLoader = true;
        },
        rejected: (state, action) => {
          state.error = action.error;
          state.subscriptionDetailsLoader = false;
        },
        fulfilled: (state, action, data) => {
          state.subscriptionDetailsLoader = false;
          // state.subscriptionDetails = action?.payload?.data?.response?.data;
          if (data?.data?.response?.message !== 'Not Found Exception') {
            handleSelectDeselectUNSubscribedStateResedential(state, action, null);
          }
        },
      },
    ),
    getPieAnalyticsDataFromProperty: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getPieAnalyticsDataFromPropertyAPI(param, userId);
          if (!data || data.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }

          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.pieAnalyticsData = action?.payload;
          state.loading = false;
        },
      },
    ),
    getBarAnalyticsDataFromProperty: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getBarAnalyticsDataFromPropertyAPI(param, userId);
          if (!data || data.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }
          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.barAnalyticsData = action?.payload;
          state.loading = false;
        },
      },
    ),
    getBuildingStatsFromProperty: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getBuildingStatsFromPropertyAPI(param, userId);
          if (data?.data?.response?.code !== 200) {
            throw new ExternalError(data?.data?.errors?.[0]?.message, {
              statusCode: data?.data?.response?.code,
            });
          }

          return thunkAPI.dispatch(setDetailedBuildingStatsPropertyData(data?.data?.response?.data));
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.buildingStatsData = action?.payload;
          state.loading = false;
        },
      },
    ),
    getBuildingUnitStatsFromProperty: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          const { id, unitNumber, stats, resource } = param;
          let data = await getBuildingOccupiedStatsFromPropertyAPI(id, userId, resource, stats);
          if (!data || data.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
              statusCode: data?.status,
            });
          }
          return data?.data?.response?.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.buildingStatsData = action?.payload;
          state.loading = false;
        },
      },
    ),
    doFollowUnfollowe: create.asyncThunk(
      async (payload, thunkApi) => {
        try {
          // const userId = thunkApi.getState().user?.user?.id;
          const { userId, isFollow } = payload;

          return { userId, isFollow };
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
        }
      },
      {
        pending: (state) => {
          // state.subscriptionDetailsPropertyLoader = true;
        },
        rejected: (state, action) => {},
        fulfilled: (state, action) => {
          handleFollowUnfollowState(state, action);
        },
      },
    ),
  }),
});

export const {
  getSearchResults,
  showSearchBar,
  getDetailedPropertyInfo,
  reset_data,
  viewMoreProperty,
  viewLessProperty,
  setSelectedConnectId,
  getConnectsFromOrganization,
  getOrganizationFromProperty,
  viewMoreOrganization,
  viewLessOrganization,
  getDetailedOrganizationInfo,
  deleteResources,
  saveResources,
  getWishlistContent,
  getWishlistContentWithPoints,
  viewMoreConnects,
  getOrganizationFromConnects,
  getPropertiesFromOrganization,
  getSelectedPropertyFromOrganization,
  viewMoreOrganizationConnectsFlow,
  viewLessOrganizationConnectsFlow,
  getDetailedConnectInfo,
  unlockPropertyFields,
  unlockOrganizationFields,
  unlockConnectsFields,
  unlockVacantUnitFields,
  setDetailedPropertyData,
  setDetailedResidentialPropertyData,
  setDetailedOrganizationData,
  setNavigateFrom,
  getOccupantDetailsFullInfo,
  getOccupantsFromProperty,
  setSearchContext,
  viewMorePropertyOrganizationFlow,
  viewLessPropertyOrganizationFlow,
  setpropertyToggle,
  viewMoreResidentialProperty,
  getDetailedResidentialPropertyInfo,
  getResidentialUnitsFromProperty,
  viewLessResidentialProperty,
  setPagination,
  setSearchQuery,
  setHasMoreData,
  setOrganizationPagination,
  setSavedAllProperty,
  setSavedAllOrganization,
  setSavedAllConnects,
  setSavedAllResidentialProperty,
  setPropertyData,
  setHasMoreSimilarCompaniesData,
  setSelectedOrganizationId,
  setDetailedBuildingStatsPropertyData,
  getVacantUnitsFromProperty,
  getVacantUnitsFromResidentialProperty,
  addBreadcrumb,
  clearBreadcrumbs,
  truncateBreadcrumbs,
  resetDetailData,
  resetDetailPropertyData,
  getVacantUnitFullInfo,
  addBrokerHelp,
  getBrokerHelp,
  doSubscribed,
  doUnSubscribed,
  doSubscribedResedential,
  doUnSubscribedResedential,
  getPieAnalyticsDataFromProperty,
  getBarAnalyticsDataFromProperty,
  getBuildingStatsFromProperty,
  getBuildingUnitStatsFromProperty,
  doFollowUnfollowe,
} = searchSlice.actions;
export default searchSlice.reducer;
