import { Typography, Flex, Radio, Popover, Button } from 'antd';
import { DownOutlined, FilterOutlined, UpOutlined } from '@ant-design/icons';
import { useState } from 'react';
import ListingFilters from './filters/ListingsFilter';
import "./leads.scss"
import RequirementFilters from './filters/RequirementsFilter';
import Requirements from "./Requirements"
import Listings from "./listing"

const MyLeads = () => {
    const { Title } = Typography;
    const [propertyToggle, setPropertyToggle] = useState("buyOrRentIn");
    const [open, setOpen] = useState(false);
    const tabs = [
        { label: "Buy/Rent In", value: "buyOrRentIn" },
        { label: "Sale/Rent Out", value: "saleOrRentOut" }
    ]

    return (
        <div>
            <div className='p-10'>
                <div className="d-inline-flex">
                    <div className='boxBackground'>
                        <Flex gap="middle" align='center'>
                            <Title level={3} className="ml-10 mt-10">Leads</Title>
                            <Radio.Group block defaultValue="buyOrRentIn" options={tabs} buttonStyle='solid' optionType='button' onChange={(e) => setPropertyToggle(e.target.value)} />
                            <Popover
                                open={open}
                                onOpenChange={(open) => {
                                    setOpen(open);
                                }}
                                placement="bottomLeft"
                                content={propertyToggle === "buyOrRentIn" ? <RequirementFilters setOpen={setOpen} /> : <ListingFilters setOpen={setOpen} />}
                                trigger={'click'}
                            >
                                <Button icon={<FilterOutlined />} className="ml-10 mr-10" >
                                    {open ? (<UpOutlined />) : (<DownOutlined />)}
                                </Button>
                            </Popover>
                        </Flex>
                    </div>
                </div>
            </div>
            {propertyToggle === "buyOrRentIn" ? <Requirements /> : <Listings />}
        </div >
    );
};

export default MyLeads;
