import { CloseCircleFilled, FileOutlined } from '@ant-design/icons';
import React from 'react';

const UploadedImage = ({ imgNames, onChange, setImgNames, imageUrls, setImageUrls }) => {
  const closeImage = (index) => {
    const newImageUrls = imageUrls.filter((elem, i) => index !== i);
    setImageUrls(newImageUrls);
    setImgNames((urls) => urls.filter((elem, i) => index !== i));
    onChange(newImageUrls);
  };
  return (
    <div>
      {imgNames?.length > 0 && (
        <div className="d-flex d-column g-10" style={{ maxHeight: '125px', overflow: 'scroll' }}>
          {imgNames?.map((elem, index) => (
            <div onClick={() => closeImage(index)} className="upoadedFileContainer" key={index}>
              <FileOutlined /> {elem} <CloseCircleFilled />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UploadedImage;
