import { Typography, Button, Radio, Divider, Input, Form, Select, Card, Flex, Upload, Row, Col, DatePicker } from "antd";
import { useState } from "react";
import { PlusCircleFilled, CloudUploadOutlined, LeftOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import "./ListingForm.scss";


const ListingForm = ({ currentState, setMode }) => {
    const { Dragger } = Upload;
    const { Title } = Typography;
    const { TextArea } = Input;
    const [current, setCurrent] = useState(currentState ?? 0);
    const [form] = Form.useForm();
    const [meetingRoom, setMeetingRoom] = useState([]);
    const [selectedAmenities, setSelectedAmenities] = useState([]);

    const handleAddAmenity = (value) => {
        if (value && !selectedAmenities.includes(value)) {
            setSelectedAmenities([...selectedAmenities, value]);
        }
    };

    const handleRemoveAmenity = (amenity) => {
        setSelectedAmenities(selectedAmenities.filter(item => item !== amenity));
    };
    const handleNext = () => {
        // form.validateFields().then(() => {
        if (current < 4) {
            setCurrent(current + 1);
        }
        // }).catch((errorInfo) => {
        //     console.log('Validation Failed:', errorInfo);
        // });
    };

    const handlePrevious = () => {
        if (current > 0) {
            setCurrent(current - 1);
        }
    };

    const handleSubmit = () => {
        form.validateFields().then(() => {
            console.log(form.getFieldsValue());
        }).catch((errorInfo) => {
            console.log('Validation Failed:', errorInfo);
        });
        setMode(false);
    }

    const handleMeetingRoomChange = (index, field, value) => {
        const updatedMeetingRooms = [...meetingRoom];
        updatedMeetingRooms[index][field] = value;
        setMeetingRoom(updatedMeetingRooms);
    }

    return (
        <div className="p-20 border-light-2 border-radius-8 mt-20 bgWhite">
            <Form form={form} layout="vertical">
                {current === 0 && (
                    <>
                        <Title level={2} >
                            Listing
                        </Title>

                        <Flex vertical  >
                            <Form.Item label={<label className="font18">Listing the property as:</label>} name="listingType" rules={[{ required: true, message: "Please select a listing type" }]}>
                                <Radio.Group block optionType="button" buttonStyle="solid" size="large"
                                    options={[{
                                        label: "Owner",
                                        value: "owner"
                                    }, {
                                        label: "Broker",
                                        value: "broker"
                                    }, {
                                        label: "Builder",
                                        value: "builder"
                                    },
                                    {
                                        label: "Coworking",
                                        value: "coworking"
                                    }

                                    ]} />
                            </Form.Item>
                        </Flex>
                        <Divider style={{ margin: '18px 0' }} />
                        <Flex vertical gap={10} >
                            <Form.Item name={"propertyFor"} label={<label className="font18">Property For</label>} rules={[{ required: true, message: "Please select the property for" }]}>
                                <Radio.Group name={"propertyFor"} block size="large"
                                    options={[{
                                        label: "Sale",
                                        value: "sale"
                                    }, {
                                        label: "Rent",
                                        value: "rent"
                                    }]} />
                            </Form.Item>
                        </Flex>
                        <Flex vertical gap={10}>
                            <Form.Item name="propertyType" label={<label className="font18" >Property Type</label>} rules={[{ required: true, message: "Please select the property type" }]}>
                                <Radio.Group name="propertyType" block size="large"
                                    options={[{
                                        label: "Residential",
                                        value: "residential"
                                    }, {
                                        label: "Commercial",
                                        value: "commercial"
                                    }]} />
                            </Form.Item>
                        </Flex>
                        <Divider style={{ margin: '18px 0' }} />
                    </>
                )}
                {current === 1 && (
                    <>
                        <LeftOutlined onClick={handlePrevious} />
                        <Title level={2} >
                            Property Details
                        </Title>
                        <Row>
                            <Col span={6}>
                                <Form.Item label={<label className="font18">Building Name</label>} name="buildingName" rules={[{ required: true, message: 'Please enter the name' }]}>
                                    <Select placeholder="Building Name" dropdownRender={(menu) => (
                                        <div>
                                            {menu}
                                            <div
                                                className="createCompanyButton"
                                                onMouseDown={(e) => e.preventDefault()}
                                            >
                                                <PlusCircleFilled type="plus" /> Add More
                                            </div>
                                        </div>
                                    )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16} className="inputBox">
                            <Col span={6} >
                                <Form.Item label={<label className="font18">Locality</label>} name="buildingLocality" rules={[{ required: true, message: 'Please enter a locality' }]}>
                                    <Input placeholder="Type here" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={<label className="font18">City</label>} name="buildingCity" rules={[{ required: true, message: 'Please enter the city' }]}>
                                    <Input placeholder="Type here" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={<label className="font18">State</label>} name="buildingState" rules={[{ required: true, message: 'Please enter the State' }]}>
                                    <Input placeholder="Type here" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>

                                <Form.Item label={<label className="font18">Pincode</label>} name="buildingPincode" rules={[{ required: true, message: 'Please enter the pincode' }]}>
                                    <Input placeholder="Type here" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16} className="inputBox">
                            <Col span={6}>

                                <Form.Item label={<label className="font18">Latitude</label>} name="buildingLatitude" rules={[{ required: true, message: 'Please enter the latitude' }]}>
                                    <Input placeholder="Type here" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={<label className="font18">Longitude</label>} name="buildingLongitude" rules={[{ required: true, message: 'Please enter the longitude' }]}>
                                    <Input placeholder="Type here" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16} className="inputBox">
                            <Col span={6}>

                                <Form.Item label={<label className="font18">Unit Type</label>} name="unitType" rules={[{ required: true, message: 'Please select a Unit Type' }]}>
                                    <Select mode="multiple" placeholder="Select Unit" options={[
                                        { label: "1RK", value: "1rk" },
                                        { label: "1BHK", value: "1bhk" },
                                        { label: "2BHK", value: "2bhk" },
                                        { label: "Others", value: "others" }
                                    ]} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={<label className="font18">Unit Condition</label>} name="unitCondition" rules={[{ required: true, message: 'Please select the unit condition' }]}>
                                    <Select mode="multiple" placeholder="Select Unit" options={[
                                        { label: "Warmshell", value: "warmshell" },
                                        { label: "Bareshell", value: "bareshell" },
                                        { label: "Furnished", value: "furnished" },
                                        { label: "Other", value: "other" }
                                    ]} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={<label className="font18">Floor No.</label>} name="unitFloorNo" rules={[{ required: true, message: 'Please select the unit condition' }]}>
                                    <Input placeholder="Type here" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={<label className="font18">Unit No.</label>} name="unitCondition" rules={[{ required: true, message: 'Please enter the unit no.' }]}>
                                    <Input placeholder="Unit No." />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16} className="inputBox">
                            <Col span={6} >
                                <Form.Item label={<label className="font18">Area Type</label>} name="areaType" rules={[{ required: true, message: 'Please select the area type' }]}>
                                    <Select placeholder="Select Area Type" options={[
                                        { label: "Chargeable", value: "chargeable" },
                                        { label: "Carpet", value: "carpet" },
                                        { label: "Other", value: "other" },
                                    ]} />
                                </Form.Item>
                            </Col>
                            <Col span={6} >
                                <Form.Item label={<label className="font18">Area in Sq.ft</label>} name="areaInSqFt" rules={[{ required: true, message: 'Please enter the area in sq.ft' }]}>
                                    <Input type="number" placeholder="Type here" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />
                        <Title level={4}>Additional Information</Title>
                        <Row gutter={16} className="inputBox">
                            <Col span={12}>
                                {meetingRoom.map((room, index) => (
                                    <Card key={index} className="mb-10">
                                        <Row gutter={16}>
                                            <Col span={10}>
                                                <label className="font18">Capacity</label>
                                                <Form.Item name={"unitCapacity" + index} rules={[{ required: true, message: 'Please enter a Unit Capacity' }]}>
                                                    <Input placeholder="Type here" value={room.unitCapacity} onChange={(value) => handleMeetingRoomChange(index, 'unitCapacity', value)} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={10}>
                                                <label className="font18"><span className="font14 fontError">*</span>No. of Rooms</label>
                                                <Form.Item name={"unitNoRooms" + index} rules={[{ required: true, message: 'Please select a Unit Type' }]}>
                                                    <Input placeholder="Type here" value={room.unitNoRooms} onChange={(value) => handleMeetingRoomChange(index, 'unitNoRooms', value)} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={4}>
                                                <Flex justify="end">
                                                    <DeleteOutlined className="font24 deleteIcon cursor-pointer" onClick={() => setMeetingRoom(meetingRoom.filter((_, i) => i !== index))} />
                                                </Flex>
                                            </Col>
                                        </Row>
                                    </Card>
                                ))}
                            </Col>
                        </Row>

                        <div className="ml-10 font16 mt-10 mb-10 cursor-pointer fontBlue font400" onClick={() => setMeetingRoom([...meetingRoom, { unitType: '', unitCondition: '' }])}><PlusOutlined className="font14" type="plus" /> Add More</div>
                        <div>
                            <Row gutter={16} align={"bottom"} className="inputBox">
                                <Col span={6}>
                                    <Form.Item label={<label className="word-break-word font18">Expected Amount/ Compensation</label>} name="expectedAmount" rules={[{ required: false, message: 'Please enter the expected amount' }]}>
                                        <Input placeholder="Type here" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label={<label className="font18">Rate</label>} name="rate" rules={[{ required: false, message: 'Please select the rate' }]}>
                                        <Input placeholder="Type here" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Divider style={{ margin: '18px 0' }} />
                    </>
                )}
                {current === 2 && (
                    <div>
                        <LeftOutlined className="mb-20" onClick={handlePrevious} />
                        <Row>
                            <Col span={12}>
                                <Form.Item label={<Title level={4}>Open To Breaker</Title>} name="openToBreaker" rules={[{ required: true, message: 'Please select an option' }]}>
                                    <Radio.Group options={[{
                                        label: "Yes",
                                        value: "yes"
                                    }, {
                                        label: "No",
                                        value: "no"
                                    }]} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider style={{ margin: '18px 0' }} />
                        <Row gutter={16} align={"start"} className="mt-10 inputBox">
                            <Col span={8} justify="end">
                                <Form.Item label={<label className="font18">Amenities</label>} name="amenities">
                                    <Select mode="multiple" options={[]} placeholder="Select Amenities" />
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item label={<label className="font18">Other Amenties</label>} name="otherAmenities">
                                    <Input placeholder="Type here" onPressEnter={(e) => { handleAddAmenity(e.target.value); e.target.value = ""; }} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={<label className="font18">Proposed Availability Date</label>} name="timePeriodUnit" rules={[{ required: true, message: 'Please select the time period unit' }]}>
                                    <DatePicker placeholder="DD-MM-YYYY" format="DD/MM/YYYY" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />
                        <div>
                            <Row>
                                <Col span={8}>
                                    <Form.Item label={<Title level={4}>Any Additional Listing</Title>} name="description" rules={[{ message: 'Please enter the description' }]}>
                                        <TextArea type="textarea" placeholder="Description" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Divider />
                        <Row>
                            <Col span={8}>
                                <Form.Item label={<Title level={4}>Upload images, links & plans </Title>} name="upload">
                                    <Dragger >
                                        <p className="ant-upload-drag-icon">
                                            <CloudUploadOutlined />
                                        </p>
                                        <p className="ant-upload-hint">
                                            Please upload all your files here
                                        </p>
                                    </Dragger>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider style={{ margin: '18px 0' }} />
                    </div>
                )}
            </Form>
            <Flex justify="end" className="mt-20" >
                {current !== 2 && <Button size="large" type="primary" onClick={handleNext}>Next</Button>}
                {current === 2 && <Button size="large" type="primary" onClick={handleSubmit}>Submit</Button>}
            </Flex>
        </div >
    )
}

export default ListingForm;