// Function to map floorNo to an integer
function parseFloorNo(floorNo) {
  if (floorNo === 'NA') return 0; // Ground floor
  if (floorNo.startsWith('B')) {
    // Basement floors
    const number = parseInt(floorNo.slice(1), 10);
    return -number;
  }
  return parseInt(floorNo, 10) || null;
}

// Function to parse unit numbers (handles ranges and multiple units)
function parseUnitNos(unitNo, floorNumber) {
  const units = [];

  // Handle cases like 'GF-1 & GF-2' or 'BF-1 & BF-2'
  if (unitNo.includes('&')) {
    const unitParts = unitNo.split('&').map((part) => part.trim());
    unitParts.forEach((part) => {
      units.push(parseUnitNo(part, floorNumber));
    });
  } else {
    units.push(parseUnitNo(unitNo, floorNumber));
  }

  return units;
}

// Helper function to parse a single unit number
function parseUnitNo(unitNo, floorNumber) {
  // Remove any non-numeric characters
  const numericPart = unitNo.replace(/\D/g, '');
  if (numericPart) {
    const unitNumber = floorNumber * 100 + parseInt(numericPart, 10);
    return unitNumber;
  }
  return null;
}


// Example of overriding the getUnitStatus function
let getUnitStatus = function (unit) {
  // Custom logic to determine unit status
  if (unit.unitStatus === 'Occupied') return 'Occupied';
  if (unit.unitStatus === 'Vacant') return 'Vacant';
  if (unit.unitStatus === null || unit.unitStatus === undefined) {
    return 'To be confirmed';
  }
  // Add more conditions if needed
  return unit.unitStatus;
};

// Main function to transform the data
function transformData(data) {
  if (!Array.isArray(data) || data.length === 0) {
    return false;
  }

  const property = data[0]; // Assuming only one property


  // Destructure with default values
  const {
    maxFloorNo = 0,
    maxUnitNo = 0,
    statsList = [],
  } = property || {};

  // Create a map of units based on statsList
  const unitsMap = {};

  statsList.forEach((stat) => {
    const floorNumber = parseFloorNo(stat.floorNo);
    if (floorNumber !== null) {
      const unitNumbers = parseUnitNos(stat.unitNo, floorNumber);
      unitNumbers.forEach((unitNumber) => {
        if (unitNumber !== null) {
          unitsMap[unitNumber] = {
            number: unitNumber,
            status: getUnitStatus(stat),
            data: stat,
            callBack: () => { },
          };
        }
      });
    }
  });

  // Generate floors and units
  const floors = [];
  const maxFloor = maxFloorNo < 100 ? maxFloorNo : 100;
  for (let floor = 1; floor <= maxFloor; floor++) {
    const rooms = [];
    for (let unit = 1; unit <= maxUnitNo; unit++) {
      const unitNumber = floor * 100 + unit; // e.g., 101, 102, etc.

      let unitData;
      if (unitsMap[unitNumber]) {
        // If unit exists in statsList, use its data
        unitData = unitsMap[unitNumber];
      } else {
        // Unit not in statsList, default status and data
        unitData = {
          number: unitNumber,
          status: 'To be confirmed',
          data: null,
          callBack: () => { },
        };
      }

      rooms.push(unitData);
    }
    floors.push({ number: floor, rooms });
  }

  return floors;
}

// Export the BuildingStats function
export const BuildingStats = (data) => {

  if (!data || !Array.isArray(data)) return;
  const floorsData = transformData(data);
  return floorsData;
};
