import { useState } from "react";
import {
    Radio, Divider, Select, Input,
    Button,
    Typography, Flex
} from "antd";
import { CloseOutlined } from "@ant-design/icons";

const RequirementFilters = ({ setOpen }) => {
    const { Title } = Typography;
    const [wantTo, setWantTo] = useState('buy');
    const [interestedIn, setInterestedIn] = useState('residential');
    const [locality, setLocality] = useState(null);
    const [carpetArea, setCarpetArea] = useState(null);
    const [budgetMin, setBudgetMin] = useState('');
    const [budgetMax, setBudgetMax] = useState('');

    const wantToOptions = [
        { label: "Buy", value: "buy" },
        { label: "Rent In", value: "rentIn" },
    ];

    const interestedOtions = [
        { label: "Residential", value: "residential" },
        { label: "Commercial", value: "commercial" },
    ];

    const handleClearFilter = () => {
        setWantTo('buy');
        setInterestedIn('residential');
        setLocality(null);
        setCarpetArea(null);
        setBudgetMin('');
        setBudgetMax('');
    };

    const handleApplyFilter = () => {
        console.log('Filters applied');
    };

    return (
        <div style={{ width: 300 }} className="leadCardPopover d-flex d-column g-10 fontLight font16">
            <Flex justify="space-between" align="baseline">
                <Title level={4}>Filter</Title>
                <CloseOutlined className="font14" onClick={() => setOpen(false)} />
            </Flex>
            <span>Interested in</span>
            <Radio.Group
                className="d-flex g-4 font16"
                value={interestedIn}
                onChange={(e) => setInterestedIn(e.target.value)}
            >
                {interestedOtions.map((option, index) => (
                    <Radio key={index} style={{ width: `${100 / interestedOtions.length}`, textAlign: "center" }} label={option.label} value={option.value}>{option.label}</Radio>
                ))}
            </Radio.Group>
            <Divider />
            <span>Want To</span>
            <div className="mb-10 border-none">
                <Radio.Group className="w-100" style={{ boxShadow: "none" }} optionType="button" buttonStyle='solid' defaultValue="buy" >
                    {wantToOptions.map((option, index) => (
                        <Radio.Button
                            key={index}
                            value={option.value}
                            style={{
                                borderRadius: "100px",
                                width: `${100 / wantToOptions.length}%`,
                                textAlign: 'center',
                            }}
                        >
                            {option.label}
                        </Radio.Button>
                    ))}
                </Radio.Group>
            </div>
            <Divider />
            <span>Locality</span>
            <Select
                placeholder="Any Area"
                value={locality}
                onChange={setLocality}
                options={[]}
            />
            <span>Carpet Area</span>
            <Select
                placeholder="Select Property"
                value={carpetArea}
                onChange={setCarpetArea}
                options={[]}
            />
            <span>Budget Range</span>
            <div className="d-flex g-10">
                <Input
                    type="number"
                    placeholder="Min"
                    value={budgetMin}
                    onChange={(e) => setBudgetMin(e.target.value)}
                    style={{ width: '50%' }}
                />
                <Input
                    type="number"
                    placeholder="Max"
                    value={budgetMax}
                    onChange={(e) => setBudgetMax(e.target.value)}
                    style={{ width: '50%' }}
                />
            </div>
            <div className="d-flex g-5 mt-10 jc-end">
                <Button
                    onClick={handleClearFilter}
                    className="fontBlue cursor-pointer font16"
                >
                    Reset
                </Button>
                <Button
                    type="primary"
                    onClick={handleApplyFilter}
                    className="cursor-pointer font16"
                >
                    Apply
                </Button>
            </div>
        </div>
    );
};

export default RequirementFilters;