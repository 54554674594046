import { Card, Form } from 'antd';
import './index.scss';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCompanyRERADetails,
  userOnboardingGetAllIndusteriesDetails,
  userOnboardingGetCompany,
  userOnboardingGetCompanyDirectors,
  userOnboardingGetCompanyFollowStats,
  fetchCompanyReraDetails,
} from '../../../features/userOnboardingSlice';
import PostDetails from '../PostDetails/PostDetails';
import AboutCompany from './AboutCompany';
import Organization from './Organization';
import { setIsCompanyFormEditable, updateCompanyProfileData } from '../../../features/myAccountSlice';
import { getOrganizationReviews } from '../../../../leadgen/features/contactBookSlice';
import { isObjectId } from '../../../../utils/helpers';
const CompanyDetailsTab = ({ activeTab, userV2, activeAccountTab, loading, isFormEditable }) => {
  const { organizationReviews } = useSelector((store) => store.contactBook);
  const { companyDirectors, companyReraDetails } = useSelector((store) => store.userOnboarding);
  // States
  const [companyData, setCompanyData] = useState(null);
  const [industry, setIndustry] = useState(null);
  const [stats, setStats] = useState(null);
  const [companyImg, setCompanyImg] = useState(null);
  const [companyPhotoGallery, setCompanyPhotoGallery] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [reraCertificateFile, setReraCertificateFile] = useState(null);
  const [CertificateFile, setCertificateFile] = useState(null);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [logoPreviewUrl, setLogoPreviewUrl] = useState(companyData?.companyLogo || null);
  const [allIndustriesList, setAllIndustriesList] = useState([]);
  const [organization] = Form.useForm();
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  // hooks
  const dispatch = useDispatch();
  useEffect(() => {
    if (isEmpty(companyData)) return;
    organization.setFieldsValue({
      // reraIdFile: companyData?.registrationNumber || '',
      headOffice: companyData?.headOfficeLocation || '',
      industryType: companyData?.industryType || '',
      websiteLink: companyData?.websiteLink || '',
      companyStrength: companyData?.companyStrength || '',
      about: companyData?.description || '',
      keySkills: companyData?.keyOfferings?.split(',') || '',
      reraId: companyData?.reraRegistrationCertificationId,
    });
    setPreviewUrls(companyData.companyImages);
    setLogoPreviewUrl(companyData?.companyLogo);

    dispatch(userOnboardingGetAllIndusteriesDetails())?.then((res) => {
      const items = res?.payload?.data?.response?.data?.map((elem) => ({
        value: elem?.id,
        label: elem?.name,
      }));
      if (res?.payload?.data?.response?.data) {
        setAllIndustriesList(items);
      }
    });

    if (companyData?.reraCertificates?.reraCertificate) {
      setIsUploaded(true);
    }
  }, [companyData]);

  // Fetch Data
  useEffect(() => {
    dispatch(userOnboardingGetCompany())?.then((res) => {
      const data = res?.payload.data?.response?.data;
      if (data) {
        let industry = null;
        if (isObjectId(data?.[0]?.companyDetails?.industryType)) {
          industry = allIndustriesList?.filter((elem) => elem?.value === data?.[0]?.companyDetails?.industryType)?.[0]
            ?.label;
        } else {
          industry = data?.[0]?.companyDetails?.industryType;
        }

        let companyDetails = {
          ...data?.[0]?.companyDetails,
          industryType: industry,
        };
        // setIndustry(data?.industryDetails?.name);
        setCompanyData(companyDetails);
        dispatch(getOrganizationReviews(userV2?.companyDetails?.companyId));
        // setIsCompanyFormEditable(false);
        // dispatch(userOnboardingGetIndustryDetails(data?.id))?.then((res) => {
        //   const data = res?.payload.data?.response?.data;
        //   setIndustry(data);
        // });
      }
    });
    dispatch(userOnboardingGetCompanyFollowStats())?.then((res) => {
      const data = res?.payload.data?.response?.data;
      if (data) {
        setStats(data);
      }
    });
    dispatch(userOnboardingGetCompanyDirectors())?.then((res) => {});
    dispatch(fetchCompanyReraDetails());
  }, [activeTab, dispatch]);

  const getPayloadTemplate = (e) => {
    const formData = new FormData();
    formData.append('images', companyImg);
    formData.append('headOfficeLocation', e?.headOffice);
    formData.append('industryType', e?.industryType);
    formData.append('websiteLink', e?.websiteLink);
    formData.append('companyStrength', e?.companyStrength);
    formData.append('description', e?.about);
    formData.append('keyOfferings', e?.keySkills?.join(','));
    formData.append('companyLogo', companyImg?.name);
    formData.append('deletedFiles', JSON.stringify(deletedFiles));
    formData.append('images', reraCertificateFile);
    formData.append('reraRegistrationCertificationId', e?.reraId);
    formData.append('reraCertificateFileName', reraCertificateFile?.name);
    companyPhotoGallery.forEach((file) => {
      formData.append('images', file);
    });

    return formData;
  };

  const onFinish = (e) => {
    if (isFormEditable) {
      dispatch(updateCompanyProfileData(getPayloadTemplate(e)));
      return;
    }
    dispatch(setIsCompanyFormEditable(true));
    setIsUploaded(false);
  };
  const handleUpload = async (file, type) => {
    if (type === 'reraCertificate') {
      setReraCertificateFile(file);

      const fileUrl = URL.createObjectURL(file);

      setCertificateFile(fileUrl);

      setIsSuccessModalVisible(true);
      setIsUploaded(true);
      return;
    }
    const fileUrl = URL.createObjectURL(file);

    setLogoPreviewUrl(fileUrl);
    setCompanyImg(file);
  };
  const handleUploadPhotoGallery = async (fileList) => {
    const newFiles = Array.from(fileList);
    setCompanyPhotoGallery((prevGallery) => [...prevGallery, ...newFiles]);
    const newPreviewUrls = newFiles.map((file) => URL.createObjectURL(file));
    setPreviewUrls((prevUrls) => [...prevUrls, ...newPreviewUrls]);
  };

  useEffect(() => {
    setIsUploaded(false);
  }, []);
  return (
    <>
      <section className="personalDtlsSection">
        <Form
          className="personalDtlsSection-form"
          name="organization"
          initialValues={{ remember: true }}
          layout="vertical"
          onFinish={onFinish}
          form={organization}
        >
          <Card className="personalDtlsSection-form__card">
            <Organization
              industry={industry}
              stats={stats}
              companyData={companyData}
              isFormEditable={isFormEditable}
              loading={loading}
              handleUpload={handleUpload}
              handleUploadPhotoGallery={handleUploadPhotoGallery}
              previewUrls={previewUrls}
              organizationReviews={organizationReviews}
              setPreviewUrls={setPreviewUrls}
              logoPreviewUrl={logoPreviewUrl}
              setDeletedFiles={setDeletedFiles}
              setIsSuccessModalVisible={setIsSuccessModalVisible}
              isSuccessModalVisible={isSuccessModalVisible}
              isUploaded={isUploaded}
              setIsUploaded={setIsUploaded}
              reraCertificateFile={reraCertificateFile}
              setReraCertificateFile={setReraCertificateFile}
              setCertificateFile={setCertificateFile}
              CertificateFile={CertificateFile}
            />
          </Card>

          <Card className="personalDtlsSection-form__card">
            <AboutCompany
              isFormEditable={isFormEditable}
              companyData={companyData}
              companyDirectors={companyDirectors}
            />
          </Card>
        </Form>
        <Card className="personalDtlsSection-form__cardtwo">
          <PostDetails companyData={companyData} activeAccountTab={activeAccountTab} />
        </Card>
        {/* </Form> */}
      </section>
    </>
  );
};

export default CompanyDetailsTab;
