import React, { useEffect, useState, useRef } from 'react';
import { Form, Space, Input, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import { userOnboardingGetPreferencesList } from '../../../features/userOnboardingSlice';
import PreselectedTags from '../Offerings/UserTypeOfferingsLayout/common/PreselectedTags';
import DropDown from './DropDown';
import './Preference.scss';

const Preference = ({ _userPreferenceData, offerings, setPreferenceUser }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);
  // State for user preferences
  const [userSellsTags, setUserSellsTags] = useState([]);
  const [userSellOptions, setUserSellOptions] = useState([]);
  const [userAudienceOptions, setUserAudienceOptions] = useState([]);
  const [userBuyOptions, setUserBuyOptions] = useState([]);
  const [userTargetAudienceTags, setUserTargetAudienceTags] = useState([]);
  const [inputVisibleType, setInputVisibleType] = useState({
    status: false,
    type: '',
  });
  const [userWouldBuyTag, setUserWouldBuyTag] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [inputVisible, setInputVisible] = useState(false);

  const inputRef = useRef(null);
  const inputRefUserTargetAudience = useRef(null);
  const inputRefUserWouldBy = useRef(null);
  const inputRefUserSells = useRef(null);

  const tagPlusStyle = {
    border: '1px solid #4096ff' /* Matching blue border */,
    color: '#4096ff' /* Blue text */,
    backgroundColor: 'transparent' /* Transparent background */,
    borderRadius: '20px' /* Rounded corners */,
    padding: '4px 12px' /* Adjust padding */,
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    width: '110px',
    cursor: 'pointer',
  };

  const tagInputStyle = {
    width: 300,
    height: 30,
    marginLeft: 30,
  };

  const showInput = () => {
    setInputVisible(true);
  };

  // Fetch options from API and set state
  const fetchOptions = (type, offerings, setterFunc) => {
    const payloadBody = {
      type,
      offerings,
    };
    dispatch(userOnboardingGetPreferencesList(payloadBody)).then((res) => {
      const options = res?.payload?.data?.response?.data;
      if (options) {
        setterFunc(
          options.map((elem) => ({
            label: elem?.name,
            key: elem?.name,
            value: elem?.name,
          })),
        );
      }
    });
  };

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
    if (inputVisibleType.status && inputVisibleType.type === 'preference_userTargetAudience') {
      inputRefUserTargetAudience.current?.focus();
    }
    if (inputVisibleType.status && inputVisibleType.type === 'preference_userWouldBuy') {
      inputRefUserWouldBy.current?.focus();
    }
    if (inputVisibleType.status && inputVisibleType.type === 'preference_userSells') {
      inputRefUserSells.current?.focus();
    }
  }, [inputVisible, inputVisibleType]);

  // Set initial values when _userPreferenceData changes
  useEffect(() => {
    if (!isEmpty(_userPreferenceData)) {
      setUserSellsTags(_userPreferenceData.userSells ? _userPreferenceData.userSells : []);
      setUserTargetAudienceTags(_userPreferenceData.userTargetAudience ? _userPreferenceData.userTargetAudience : []);
      setUserWouldBuyTag(_userPreferenceData.userWouldBuy ? _userPreferenceData.userWouldBuy : []);
    }
  }, [_userPreferenceData]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && !userSellsTags.includes(inputValue)) {
      setUserSellsTags([...userSellsTags, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  };
  const handleInputUserWouldBuyConfirm = () => {
    if (inputValue && !userWouldBuyTag.includes(inputValue)) {
      setUserWouldBuyTag([...userWouldBuyTag, inputValue]);
    }
    setInputVisibleType({ status: false, type: '' });
    setInputValue('');
  };
  const handleInputUserSellsConfirm = () => {
    if (inputValue && !userSellsTags.includes(inputValue)) {
      setUserSellsTags([...userSellsTags, inputValue]);
    }
    setInputVisibleType({ status: false, type: '' });
    setInputValue('');
  };
  const handleInputUserTargetAudienceConfirm = () => {
    if (inputValue && !userTargetAudienceTags.includes(inputValue)) {
      setUserTargetAudienceTags([...userTargetAudienceTags, inputValue]);
    }
    setInputVisibleType({ status: false, type: '' });
    setInputValue('');
  };

  useEffect(() => {
    setPreferenceUser('preference_userWouldBuy', userWouldBuyTag || []);
    setPreferenceUser('preference_userSells', userSellsTags || []);
    setPreferenceUser('preference_userTargetAudience', userTargetAudienceTags || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSellsTags, userTargetAudienceTags, userWouldBuyTag]);

  return (
    <section className="preferences">
      {/* User Sells */}
      <div className="preferences-container">
        <Space direction="vertical" className="preferences-container__space">
          <Form.Item
            label={`Services that ${user?.name.split(" ")[0]} sells`}
            name="preference_userSells"
            className="preferences-container__space--item"
            rules={[
              {
                required: true,
                message: userSellsTags.length === 0 ? 'Please select what you would like to sell' : '',
              },
            ]}
          >
            {offerings !== '' ? (
              <div style={{ display: 'inline-flex' }}>
                <DropDown
                  name="preference_userSells"
                  options={userSellOptions}
                  allTags={userSellsTags}
                  setterFunc={setUserSellsTags}
                  setInputVisibleType={setInputVisibleType}
                  fetchOptions={() => fetchOptions('userSells', offerings, setUserSellOptions)}
                />
                {inputVisibleType.status && inputVisibleType.type === 'preference_userSells' && (
                  <Input
                    ref={inputRefUserSells}
                    type="text"
                    size="small"
                    style={tagInputStyle}
                    value={inputValue}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Update the input value but don't allow only spaces
                      if (value.trim() !== '' || value === '') {
                        handleInputChange(e); // Call your existing handler if input is valid
                      }
                    }}
                    onBlur={() => {
                      if (inputValue.trim() !== '') {
                        handleInputUserSellsConfirm(); // Call confirm only if input is valid
                      }
                    }}
                    onPressEnter={() => {
                      if (inputValue.trim() !== '') {
                        handleInputUserSellsConfirm(); // Call confirm only if input is valid
                      }
                    }}
                    placeholder={`Enter Services`}
                  />
                )}
              </div>
            ) : (
              <div style={{ display: 'inline-flex' }}>
                <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
                  Add Tag
                </Tag>
                {inputVisible && (
                  <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    style={tagInputStyle}
                    value={inputValue}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Update the input value but don't allow only spaces
                      if (value.trim() !== '' || value === '') {
                        handleInputChange(e); // Call your existing handler if input is valid
                      }
                    }}
                    onBlur={() => {
                      if (inputValue.trim() !== '') {
                        handleInputConfirm(); // Call confirm only if input is valid
                      }
                    }}
                    onPressEnter={() => {
                      if (inputValue.trim() !== '') {
                        handleInputConfirm(); // Call confirm only if input is valid
                      }
                    }}
                    placeholder={`Enter Services that ${user?.name.split(" ")[0]} sells`}
                  />
                )}
              </div>
            )}
          </Form.Item>
          <Form.Item
            name="preference_userSells"
            style={{ margin: 0 }}
            className={` ${
              userSellsTags && userSellsTags?.length > 0
                ? 'preferences-container__space--item'
                : 'preferences-container__space--noData'
            } `}
          >
            {userSellsTags && userSellsTags?.length > 0 && (
              <PreselectedTags setTagData={setUserSellsTags} tagsData={userSellsTags} />
            )}
          </Form.Item>

          <div className="preferences-container__space--note">Note: min 1 is compulsory</div>
        </Space>
      </div>

      {/* User Target Audience */}
      <div className="preferences-container">
        <Space direction="vertical" className="preferences-container__space">
          <Form.Item
            label={`${user?.name.split(" ")[0]}/${_userPreferenceData?.companyName || 'BI'} target Audience`}
            name="preference_userTargetAudience"
            className="preferences-container__space--item"
            rules={[
              {
                required: true,
                message:
                  userTargetAudienceTags.length === 0 ? 'Please select audience what you would like to target' : '',
              },
            ]}
          >
            <div style={{ display: 'inline-flex' }}>
              <DropDown
                name="preference_userTargetAudience"
                options={userAudienceOptions}
                allTags={userTargetAudienceTags}
                setterFunc={setUserTargetAudienceTags}
                setInputVisibleType={setInputVisibleType}
                fetchOptions={() => fetchOptions('userTargetAudience', null, setUserAudienceOptions)}
              />
              {inputVisibleType.status && inputVisibleType.type === 'preference_userTargetAudience' && (
                <Input
                  ref={inputRefUserTargetAudience}
                  type="text"
                  size="small"
                  style={tagInputStyle}
                  value={inputValue}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Update the input value but don't allow only spaces
                    if (value.trim() !== '' || value === '') {
                      handleInputChange(e); // Call your existing handler if input is valid
                    }
                  }}
                  onBlur={() => {
                    if (inputValue.trim() !== '') {
                      handleInputUserTargetAudienceConfirm(); // Call confirm only if input is valid
                    }
                  }}
                  onPressEnter={() => {
                    if (inputValue.trim() !== '') {
                      handleInputUserTargetAudienceConfirm(); // Call confirm only if input is valid
                    }
                  }}
                  placeholder={`Enter Services`}
                />
              )}
            </div>
          </Form.Item>
          <Form.Item
            name="preference_userTargetAudience"
            style={{ margin: 0 }}
            className={` ${
              userTargetAudienceTags && userTargetAudienceTags?.length > 0
                ? 'preferences-container__space--item'
                : 'preferences-container__space--noData'
            } `}
          >
            {userTargetAudienceTags && userTargetAudienceTags?.length > 0 && (
              <PreselectedTags n setTagData={setUserTargetAudienceTags} tagsData={userTargetAudienceTags} />
            )}
          </Form.Item>
          <div className="preferences-container__space--note">Note: min 1 is compulsory</div>
        </Space>
      </div>

      {/* User Would Like to Buy */}
      <div className="preferences-container">
        <Space direction="vertical" className="preferences-container__space">
          <Form.Item
            label={`Services that ${user?.name.split(" ")[0]}/${_userPreferenceData?.companyName || 'BI'} would like to buy`}
            name="preference_userWouldBuy"
            className="preferences-container__space--item"
            rules={[
              {
                required: true,
                message: userWouldBuyTag.length === 0 ? 'Please select what you would like to buy' : '',
              },
            ]}
          >
            <div style={{ display: 'inline-flex' }}>
              <DropDown
                name="preference_userWouldBuy"
                options={userBuyOptions}
                allTags={userWouldBuyTag}
                setInputVisibleType={setInputVisibleType}
                setterFunc={setUserWouldBuyTag}
                fetchOptions={() => fetchOptions('userWouldBuy', null, setUserBuyOptions)}
              />
              {inputVisibleType.status && inputVisibleType.type === 'preference_userWouldBuy' && (
                <Input
                  ref={inputRefUserWouldBy}
                  type="text"
                  size="small"
                  style={tagInputStyle}
                  value={inputValue}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Update the input value but don't allow only spaces
                    if (value.trim() !== '' || value === '') {
                      handleInputChange(e); // Call your existing handler if input is valid
                    }
                  }}
                  onBlur={() => {
                    if (inputValue.trim() !== '') {
                      handleInputUserWouldBuyConfirm(); // Call confirm only if input is valid
                    }
                  }}
                  onPressEnter={() => {
                    if (inputValue.trim() !== '') {
                      handleInputUserWouldBuyConfirm(); // Call confirm only if input is valid
                    }
                  }}
                  placeholder={`Enter Services`}
                />
              )}
            </div>
          </Form.Item>
          <Form.Item
            name="preference_userWouldBuy"
            style={{ margin: 0 }}
            className={` ${
              userWouldBuyTag && userWouldBuyTag?.length > 0
                ? 'preferences-container__space--item'
                : 'preferences-container__space--noData'
            } `}
          >
            {userWouldBuyTag && userWouldBuyTag?.length > 0 && (
              <PreselectedTags setTagData={setUserWouldBuyTag} tagsData={userWouldBuyTag} />
            )}
          </Form.Item>

          <div className="preferences-container__space--note">Note: min 1 is compulsory</div>
        </Space>
      </div>
    </section>
  );
};

export default Preference;
