import { Switch, Pagination, Flex, Table, Tag } from 'antd';
import { useState } from 'react';
import { RightOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import './listing.scss';

const RequirementTable = () => {
  const [propertyToggle, setPropertyToggle] = useState(false);
  const navigateTo = useNavigate();
  const dataSource = [
    {
      key: '1',
      wantTo: 'ResidentialTable',
      residential: 'residential',
      specs: 'specs',
      locality: 'locality',
      carpetArea: 'carpetArea',
      budgetRange: [10, 20],
      isLock: false,
    },
    {
      key: '2',
      wantTo: 'wantTo',
      residential: 'residential',
      specs: 'specs',
      locality: 'locality',
      carpetArea: 'carpetArea',
      budgetRange: [10, 20],
      isLock: true,
    },
    {
      key: '3',
      wantTo: 'wantTo',
      residential: 'residential',
      specs: 'specs',
      locality: 'locality',
      carpetArea: 'carpetArea',
      budgetRange: [10, 20],
      isLock: true,
    },
    {
      key: '4',
      wantTo: 'wantTo',
      residential: 'residential',
      specs: 'specs',
      locality: 'locality',
      carpetArea: 'carpetArea',
      budgetRange: [10, 20],
      isLock: true,
    },
  ];

  const columns = [
    {
      title: 'Want To',
      dataIndex: 'wantTo',
      key: 'wantTo',
    },
    {
      title: (
        <Flex>
          <Switch
            size="default"
            checkedChildren={<span>Commercial</span>}
            defaultChecked
            unCheckedChildren={<span>Residential</span>}
          />
        </Flex>
      ),
      dataIndex: propertyToggle ? 'commercial' : 'residential',
      key: propertyToggle ? 'commercial' : 'residential',
    },
    {
      title: 'Specs',
      dataIndex: 'specs',
      key: 'specs',
    },
    {
      title: 'Locality',
      dataIndex: 'locality',
      key: 'locality',
    },
    {
      title: 'Carpet Area',
      dataIndex: 'carpetArea',
      key: 'carpetArea',
    },
    {
      title: 'Budget Range',
      dataIndex: 'budgetRange',
      key: 'budgetRange',
      render: (val) => (
        <Flex align="center" style={{ whiteSpace: 'nowrap' }}>
          <Tag className="boxPadding">Rs. {val[0]} /-</Tag>
          <span style={{ margin: '0 8px' }}>-</span>
          <Tag className="boxPadding">Rs. {val[1]} /-</Tag>
        </Flex>
      ),
    },
    {
      key: 'actions',
      dataIndex: 'key',
      render: (val) => (
        <Flex gap="middle" justify="end">
          <DeleteOutlined className="font24 redHighlight" style={{ cursor: 'pointer' }} />
          <RightOutlined className="primaryHighlight" onClick={() => navigateTo(`/user/requirementDetails/${val}`)} />
        </Flex>
      ),
    },
  ];

  return (
    <div>
      <div className="p-10">
        <div className="boxBackground">
          <div>
            <div className="listingTable">
              <Table
                columns={columns}
                dataSource={dataSource}
                rowKey={(record) => record?.key}
                loading={false}
                pagination={false}
                className="custom-data-grid"
              />
              <Flex justify="center" className="p-20">
                <Pagination
                  showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} results`}
                  current={1}
                  pageSize={10}
                  total={100}
                  style={{ cursor: 'pointer' }}
                />
              </Flex>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequirementTable;
