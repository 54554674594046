import { Button, Divider, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import search_organization from '../../../../../assets/images/org2.svg';
import wall from '../../../../../assets/images/WALL.svg';
import person from '../../../../../assets/images/person.svg';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router';
import { setSelectedNav } from '../../../../features/universalSidenavSlice';
import bookmark from '../../../../../assets/images/bookmark.svg';
import { useWindowSize } from '../../../../../utils/useWindowSize/useWindowSize';
import './wishlist.scss';
import { LoadingIcon } from '@cometchat/chat-uikit-vue';

const Wishlist = ({ wishlist }) => {
  const { Text } = Typography;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAbove1320, isAbove1920, isAbove1020 } = useWindowSize();

  const { userPoints } = useSelector((store) => store.userOnboarding);
  const [amount, setAmount] = useState(0);
  useEffect(() => {
    if (isEmpty(wishlist)) return;
    setAmount(wishlist.totalPoints);
  }, [wishlist]);

  // const getPoints = () => {
  //   return parseInt(userPoints) - parseInt(amount) < 0
  //     ? Math.abs(parseInt(userPoints) - parseInt(amount))
  //     : parseInt(userPoints) - parseInt(amount);
  // };
  return (
    <div
      style={{
        borderRadius: '15px',
        overflow: 'hidden',
        border: '1px solid #7070703E',
        background: '#FFFFFF',
        boxShadow: '0px 3px 3px #00000029',
        height: '95%',
        overflow: 'auto',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: isAbove1920 ? '101px' : '20px',
          justifyContent: 'left',
          backgroundColor: '#fffff',
          padding: '20px 20px 0px 20px',
          overflowY: 'scroll',
          overflowX: 'hidden'
          // height: '100%',
        }}
      >
        {/* <img src={wall} style={{ width: '20px' }} alt="" /> */}
        <Text
          style={{
            fontSize: isAbove1020 ? '15px' : '18px',
            width: '100%',
            fontWeight: 'bolder',
            marginTop: isAbove1020 && '-5px',
          }}
        >
          My Cart
        </Text>
        <div>
          <img src={bookmark} alt="bookmark" style={{ width: isAbove1020 ? '15px' : '20px' }} />
        </div>
      </div>
      <div className="wishlist-scroll">
        {/* <Text disabled style={{ fontSize: '13px' }}>
          This space is your Wishlist!
        </Text>
        <Divider style={{ margin: '15px 0px 15px 0px' }} /> */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text style={{ fontSize: isAbove1020 ? '10px' : '16px', margin: '0px', fontWeight: '600' }}>
            Total Saved Leads
          </Text>
          <Text
            className="wishlist-disabled-text"
            style={{ fontSize: isAbove1020 ? '9px' : '13px', margin: '0px' }}
            disabled
          >
            As of today
          </Text>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '10px',
            marginTop: isAbove1020 ? '5px' : '12px',
          }}
        >
          <Space>
            <img src={person} alt="connects" style={{ width: isAbove1020 ? '20px' : '29px' }} />
            <Text style={{ fontSize: isAbove1020 && '10px' }}>Connects</Text>
          </Space>
          <Text style={{ fontSize: isAbove1020 ? '20px' : '28px', color: '#0081FC', fontWeight: 700 }}>
            {wishlist?.connects || 0}
          </Text>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: isAbove1020 ? '-4px' : '12px',
          }}
        >
          <Space>
            <img style={{ width: isAbove1020 ? '20px' : '26px' }} src={search_organization} alt="organizations" />
            <Text style={{ fontSize: isAbove1020 && '10px' }}>Organizations</Text>
          </Space>
          <Text style={{ fontSize: isAbove1020 ? '20px' : '28px', color: '#F2C877', fontWeight: 700 }}>
            {wishlist?.organization || 0}
          </Text>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: isAbove1020 ? '-4px' : '12px',
          }}
        >
          <Space>
            <img src={wall} style={{ width: isAbove1020 && '15px' }} alt="properties" />
            <Text style={{ fontSize: isAbove1020 && '10px' }}>Properties</Text>
          </Space>
          <Text style={{ fontSize: isAbove1020 ? '20px' : '28px', color: '#7D66FC', fontWeight: 700 }}>
            {wishlist?.properties || 0}
          </Text>
        </div>
        <Divider style={{ margin: isAbove1020 ? '1px 0px 1px' : '10px 0px 15px 0px' }} />
        <Text className="wishlist-disabled-text" disabled style={{ fontSize: isAbove1020 ? '9px' : '13px' }}>
          The selected leads are worth of total &nbsp;
          <Text style={{ fontSize: isAbove1020 ? '13px' : '18px', fontWeight: 700 }}>{amount} points</Text>
        </Text>
        <Button
          type="primary"
          block
          style={{
            height: isAbove1020 ? '30px' : '40px',
            fontSize: isAbove1020 ? '11px' : '18px',
            marginTop: isAbove1020 ? '5px' : '10px',
          }}
          onClick={() => {
            navigate('/leadgen/contactbook');
            dispatch(setSelectedNav('ContactBook'));
          }}
        >
          View All Leads
        </Button>
        <Divider style={{ margin: isAbove1020 ? '9px 0px' : '20px 0px 20px 0px' }} />
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Text
            className="wishlist-disabled-text"
            style={{ marginTop: isAbove1020 ? '-6px' : '-12px', fontSize: isAbove1020 ? '9px' : '13px' }}
            disabled
          >
            You need additional
          </Text>
          <Text style={{ fontSize: isAbove1020 ? '20px' : '30px', color: '#0081FC', fontWeight: 700 }}>
            {parseInt(userPoints) - parseInt(amount) < 0 ? Math.abs(parseInt(userPoints) - parseInt(amount)) : 0}
          </Text>
          <Text
            style={{ fontSize: isAbove1020 ? '15px' : '20px', color: '#0081FC', fontWeight: '600', marginTop: '-8px' }}
          >
            Points
          </Text>
          <Text
            className="wishlist-disabled-text"
            disabled
            style={{ marginTop: isAbove1020 ? '0px' : '10px', fontSize: isAbove1020 ? '9px' : '13px' }}
          >
            Buy them now at only Rs. XXX/-
          </Text>
          <Button
            type="primary"
            block
            style={{
              height: isAbove1020 ? '30px' : '40px',
              fontSize: isAbove1020 ? '11px' : '18px',
              marginTop: isAbove1020 ? '5px' : '10px',
            }}
          >
            Buy Points NOW!
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Wishlist;
