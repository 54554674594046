import { Switch, Pagination, Flex, Table, Tag } from 'antd';
import { useState } from 'react';
import LockedIcon from '../../../layout/home/images/LockedIcon';
import SaveForLaterIcon from '../../../layout/home/images/SaveForLaterIcon';
import UnlockedIcon from '../../../layout/home/images/UnlockedIcon';
import "./leads.scss"

const Requirements = () => {
    const [propertyToggle, setPropertyToggle] = useState(false);
    const dataSource = [
        {
            key: '1',
            wantTo: 'wantTo',
            residential: 'residential',
            specs: 'specs',
            locality: 'locality',
            carpetArea: 'carpetArea',
            budgetRange: [10, 20],
            isLock: false,
        },
        {
            key: '2',
            wantTo: 'wantTo',
            residential: 'residential',
            specs: 'specs',
            locality: 'locality',
            carpetArea: 'carpetArea',
            budgetRange: [10, 20],
            isLock: true,
        },
        {
            key: '3',
            wantTo: 'wantTo',
            residential: 'residential',
            specs: 'specs',
            locality: 'locality',
            carpetArea: 'carpetArea',
            budgetRange: [10, 20],
            isLock: true,
        },
        {
            key: '4',
            wantTo: 'wantTo',
            residential: 'residential',
            specs: 'specs',
            locality: 'locality',
            carpetArea: 'carpetArea',
            budgetRange: [10, 20],
            isLock: true,
        },
    ];

    const columns = [
        {
            title: 'Want To',
            dataIndex: 'wantTo',
            key: 'wantTo',
        },
        {
            title: (
                <Flex>
                    <Switch
                        size="default"
                        checkedChildren={<span>Commercial</span>}
                        defaultChecked
                        unCheckedChildren={<span>Residential</span>}
                    />
                </Flex>
            ),
            dataIndex: propertyToggle ? 'commercial' : 'residential',
            key: propertyToggle ? 'commercial' : 'residential',
        },
        {
            title: 'Specs',
            dataIndex: 'specs',
            key: 'specs',
        },
        {
            title: 'Locality',
            dataIndex: 'locality',
            key: 'locality',
        },
        {
            title: 'Carpet Area',
            dataIndex: 'carpetArea',
            key: 'carpetArea',
        },
        {
            title: 'Budget Range',
            dataIndex: 'budgetRange',
            key: 'budgetRange',
            render: (val) => (
                <Flex align="center" style={{ whiteSpace: 'nowrap' }}>
                    <Tag className='boxPadding'>Rs. {val[0]} /-</Tag>
                    <span style={{ margin: '0 8px' }}>-</span>
                    <Tag className='boxPadding'>Rs. {val[1]} /-</Tag>
                </Flex>
            ),
        },
        {
            title: "Unlock",
            dataIndex: 'isLock',
            key: 'isLock',
            render: (val) => {
                return (<Flex className='cursor-pointer' justify='space-evenly'>{val ? <LockedIcon /> : <UnlockedIcon />}
                    {val ? <SaveForLaterIcon fill={"grey"} /> : <SaveForLaterIcon fill={"orange"} />}
                </Flex>);
            },
        },
    ];

    return (
        <div>
            <div className="p-10" >
                <div className="boxBackground">
                    <div className='listingTable'>
                        <Table
                            columns={columns}
                            dataSource={dataSource}
                            rowKey={(record) => record?.key}
                            loading={false}
                            pagination={false}
                            className="custom-data-grid"
                        />
                        <Flex justify='center' className="p-20" >
                            <Pagination showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} results`} current={1} pageSize={10} total={100} />
                        </Flex>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Requirements;
