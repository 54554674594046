import { RightOutlined } from '@ant-design/icons';
import { nanoid } from '@reduxjs/toolkit';
import { Avatar } from 'antd';
import React, { useState } from 'react';

import SimilarCompaniesSkeleton from '../CompanyDetails/skeletons/SimilarCompaniesSkeleton';
import './propertyDetails.scss';
import CustomRating from './CustomRating';
import animationData from '../../../../../assets/images/SelectedLottie.json';
import LottieAnimation from '../../../../../universal/components/lottieAnimation/LottieAnimation';
import { useWindowSize } from '../../../../../utils/useWindowSize/useWindowSize';
import animationDataEmp from '../../../../../assets/images/PropertyAndComapnyLottieImage.json';

const SimilarProperties = ({
  propertyData = [],
  searchQuery,
  organizationsName,
  isReverseFlow = false,
  loader = false,
  onCardClick,
}) => {
  const { isAbove1020, isAbove1280, isAbove1440 } = useWindowSize();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const propertiesOf = isReverseFlow ? organizationsName : searchQuery?.body?.query;
  const onPropertyClick = (id) => {
    onCardClick(id);
    // dispatch(viewMoreProperty(id));
  };
  return (
    <div style={{ overflow: 'scroll', height: '51vh' }} className="similarPropertyContainer d-flex  g-10 d-column">
      {searchQuery?.body?.tag === 'commercialProperty' ||
        searchQuery?.body?.tag === 'residentialProperty' ||
        isReverseFlow ? (
        <div className="font14 font900 fontLight-3">Similar Properties</div>
      ) : (
        <div className="font14 font900 fontLight-3">Other Properties of {propertiesOf}</div>
      )}
      <div className="similarPropertyScrollbar jc-center pr-10" style={{ maxHeight: '550px', overflow: 'scroll' }}>
        {!loader && propertyData.length === 0 ? (
          <div className="property-imgdiv">
            <LottieAnimation height={150} width={150} animationData={animationData} loop={true} />
            <span className="bold property-imgdiv__text">
              We are finding right<br></br> <span className="bold text-middleproperty">PROPERTY</span>
              <br></br>
              For you, Be with us !
            </span>
          </div>
        ) : loader && isReverseFlow ? (
          [...new Array(6)]?.map(() => <SimilarCompaniesSkeleton key={nanoid()} />)
        ) : (
          propertyData?.map((ele, index) =>
            loader ? (
              <SimilarCompaniesSkeleton />
            ) : (
              <div
                onClick={() => {
                  onPropertyClick(ele?._id);
                }}
                key={nanoid()}
                className={`d-flex a-center jc-between mt-15 p-10 similarPropertiesHover`}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <span className="d-flex g-10 jc-between">
                  {ele?.assetsInfo?.[0]?.featuredImage ? (
                    <Avatar className="companyAvatar" src={ele?.assetsInfo?.[0]?.featuredImage} />
                  ) : (
                    <div
                      style={{
                        border: '1px solid black',
                        width: isAbove1020 ? '35px' : isAbove1280 ? '40px' : isAbove1440 ? '50px' : '60px',
                        height: isAbove1020 ? '35px' : isAbove1280 ? '40px' : isAbove1440 ? '50px' : '60px',
                        borderRadius: '50%',
                      }}
                    >
                      <LottieAnimation height={'100%'} width={'100%'} animationData={animationDataEmp} loop={true} />
                    </div>
                  )}
                  <span className="d-flex d-column jc-between">
                    <span className="font12 font600">{ele?.buildingName}</span>
                    <span className="font10 locality-hover">
                      {ele?.addressInfo?.[0]?.locality ?? ""}, {ele?.addressInfo?.[0]?.city ?? ele?.addressInfo?.[0]?.state ?? ""}
                    </span>
                    <CustomRating
                      rating={!isNaN(Number(ele?.averageRating)) ? Number(ele?.averageRating) : 0}
                      hovered={hoveredIndex === index}
                    />
                  </span>
                </span>
                <RightOutlined className="fontLight-2" />
              </div>
            ),
          )
        )}
      </div>
    </div>
  );
};

export default SimilarProperties;
