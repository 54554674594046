export default function LeadGenIcon({ strokeColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 22">
      <g id="LMT" transform="translate(-58.19 -49.22)">
        <path
          id="Path_18547"
          data-name="Path 18547"
          d="M66.3,86.131a.321.321,0,0,1-.323-.323V80.793L60.062,72a.765.765,0,0,1,.638-1.19h2.27l-.115-.176a1.819,1.819,0,1,1,3.045,0l-.117.176H68.62l.017-.094a2.389,2.389,0,0,1,4.7,0l.017.094h2.593A.765.765,0,0,1,76.587,72l-5.914,8.8v3.253a.323.323,0,0,1-.2.3L66.439,86.1A.336.336,0,0,1,66.3,86.131Zm.325-.818,3.4-1.488v-3.13a.319.319,0,0,1,.055-.181l6.082-9.046H60.481l.982,1.46H71a.325.325,0,1,1,0,.648H61.9l4.575,6.8h1.853a.325.325,0,1,1,0,.65h-1.7ZM71,69.392a1.745,1.745,0,0,0-1.675,1.275l-.043.145H72.7l-.04-.145A1.747,1.747,0,0,0,71,69.392Zm-6.622-.922a1.169,1.169,0,1,0,1.171,1.169A1.169,1.169,0,0,0,64.376,68.47Z"
          transform="translate(-1.373 -14.645)"
          fill="none"
          stroke={strokeColor}
         
        />
        <path
          id="Path_18548"
          data-name="Path 18548"
          d="M99.705,52.858a1.819,1.819,0,1,1,1.813-1.817A1.819,1.819,0,0,1,99.705,52.858Zm0-2.986a1.169,1.169,0,1,0,1.169,1.169A1.169,1.169,0,0,0,99.705,49.872Z"
          transform="translate(-31.256)"
          fill="none"
          stroke={strokeColor}
         
        />
        <path
          id="Path_18549"
          data-name="Path 18549"
          d="M127.331,62.361a.327.327,0,0,1-.332-.319v-2.5a.325.325,0,1,1,.65,0v2.5A.327.327,0,0,1,127.331,62.361Z"
          transform="translate(-54.188 -7.875)"
          fill="none"
          stroke={strokeColor}
         
        />
        <path
          id="Path_18550"
          data-name="Path 18550"
          d="M127.331,53.12a.325.325,0,0,1-.23-.1.331.331,0,0,1-.1-.221.323.323,0,0,1,.325-.323.329.329,0,0,1,.23.094.325.325,0,0,1-.23.555Z"
          transform="translate(-54.188 -2.567)"
          fill="none"
          stroke={strokeColor}
         
        />
        <path
          id="Path_18551"
          data-name="Path 18551"
          d="M86.669,54.223a.325.325,0,0,1-.323-.325V52.1a.325.325,0,1,1,.648,0v1.8a.327.327,0,0,1-.325.325Z"
          transform="translate(-22.172 -1.99)"
          fill="none"
          stroke={strokeColor}
         
        />
        <path
          id="Path_18552"
          data-name="Path 18552"
          d="M58.515,52.743a.327.327,0,0,1-.325-.325V49.555a.325.325,0,0,1,.65,0v2.862A.327.327,0,0,1,58.515,52.743Z"
          transform="translate(0 -0.008)"
          fill="none"
          stroke={strokeColor}
         
        />
        <path
          id="Path_18553"
          data-name="Path 18553"
          d="M58.515,71.125a.325.325,0,0,1-.23-.1.323.323,0,1,1,.459,0A.321.321,0,0,1,58.515,71.125Z"
          transform="translate(0 -16.744)"
          fill="none"
          stroke={strokeColor}
         
        />
        <path
          id="Path_18554"
          data-name="Path 18554"
          d="M119.945,92.468a.324.324,0,1,1,.325-.325A.327.327,0,0,1,119.945,92.468Z"
          transform="translate(-48.376 -33.547)"
          fill="none"
          stroke={strokeColor}
         
        />
      </g>
    </svg>
  );
}
