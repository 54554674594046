import React, { useState } from 'react';
import './login.scss';
import { Button, Divider, Form, Input, Typography } from 'antd';
import { Link } from 'react-router-dom';
import logo from '../../../assets/logo.svg';
import LinkedinIcon from '../../../assets/images/linkedin.svg';
// import { isEmpty } from 'lodash';
import { setCurrentStep } from '../../features/loginSlice';
import { useDispatch } from 'react-redux';

const LoginForm = ({ loading, value, setValue, handleSubmit, onCode, onClose, OauthPopup, url }) => {
  const { Text } = Typography;
  const dispatch = useDispatch();
  const [isValid, setIsValid] = useState(false);

  return (
    <Form onFinish={() => handleSubmit(value)}>
      <div className="login-box">
        <div className="login-box__img">
          <img src={logo} alt="logo" width="150px" />
        </div>
        <div className="login-box__button">
          <OauthPopup url={url} onCode={onCode} onClose={onClose}>
            <Button type="primary" className="button-items">
              <img src={LinkedinIcon} alt="linkedIn" className="button-img" />
              Login via Linkedin
            </Button>
          </OauthPopup>
        </div>
        <div className="d-flex d-column">
          <Divider className="login-box__divider" plain>
            OR
          </Divider>
          <Text>
            <span className="mandatory-star">*</span>Work E-mail/&nbsp;Mobile No.
          </Text>
          <Form.Item
            name="mobile_email"
            rules={[
              {
                validator(_, value) {
                  const patterns = [
                    /^[0-9]{10}$/, // Only letters
                    /^[a-z0-9._]+@[a-z0-9.]+\.[a-z]{2,4}$/, // Letters and numbers
                  ];

                  // Validate against each pattern
                  for (const pattern of patterns) {
                    if (pattern.test(value)) {
                      setIsValid(true);
                      return Promise.resolve(); // Valid if any pattern matches
                    }else{
                      setIsValid(false);
                    }
                  }

                  return Promise.reject(new Error('Please enter valid Email or Mobile Number'));
                },
              },
            ]}
          >
            <Input
              name="mobile_email"
              style={{ marginBottom: 0 }}
              placeholder="Enter E-mail/Mobile No."
              className="login-box__placeholder"
              onChange={(e) => setValue(e?.target?.value)}
              value={value}
            />
          </Form.Item>
          <div className="d-flex g-5 mt-20">
            <Button
              type="primary"
              block
              ghost
              disabled={!isValid}
              onClick={() => {
                dispatch(setCurrentStep('ENTER_PASSWORD'));
              }}
              className="login-verify__button"
            >
              Via Password
            </Button>
            <Button
              type="primary"
              block
              ghost
              disabled={!isValid}
              // onClick={() => {
              //   handleSubmit(value);
              // }}
              htmlType="submit"
              loading={loading.loginLoading}
              className="login-verify__button"
            >
              Via OTP
            </Button>
          </div>
          <Text className="login-msg">
            Don't have an account?
            <Link to="/register" className="login-to__register">
              &nbsp;&nbsp;Register
            </Link>
          </Text>
        </div>
      </div>
    </Form>
  );
};

export default LoginForm;
