import { Button, Card, Checkbox, Col, Row, Space, Typography } from 'antd';
import { useGeolocation } from '@uidotdev/usehooks';
import { useNavigate } from 'react-router';
import locationIcon from '../../../assets/images/location.svg';
import ComingSoon from '../../../assets/comingSoonBadge.png';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './userOfferings.scss';
import { fetchLocationViaLongLat, saveUserLocationDB } from '../../features/userDashboardSlice';
import SimpleMap from './SimpleMap';
import Debouncer from './Debouncer';
import man from '../../../assets/man.png';
import logo from '../../../assets/logo.svg';
import OKRLogo from '../../../assets/images/OKR.svg';
import LeadGenLogo from '../../../assets/images/LMT.svg';
import CRMLogo from '../../../assets/images/CRM.svg';
import FMSLogo from '../../../assets/images/FMS.svg';

const UserOfferings = () => {
  const navigateTo = useNavigate();

  const { Text } = Typography;

  const { user, profileCompletion } = useSelector((store) => store.user);
  const locationState = useGeolocation();

  //   const { currentLocation } = useSelector((store) => store.userDashboard);

  const [locationName, setLocationName] = useState('');

  const dispatch = useDispatch();
  const [coordinates, setCoordinates] = useState();
  const [allowChangeLocation, setAllowChangeLocation] = useState(false);
  const [locationChecked, setLocationChecked] = useState(false);
  const [selectedOffering, setSelectedOffering] = useState(1);
  const offerings = [
    {
      id: 1,
      label: 'Lead Gen',
      img: LeadGenLogo,
      title: (
        <span className="font20 fontLight-2 ">
          Supercharge your <span className="font20 font700 fontDark">Lead Generation</span>
        </span>
      ),
      content:
        'Harness the power of predictive Al to identify and engage with high-potential leads. Simplify your outreach and maximize conversions with data-driven insights.',
    },
    {
      id: 2,
      label: 'CRM',
      img: CRMLogo,
      comingSoon: true,
      title: (
        <span className="font20 fontLight-2 ">
          Optimize Every <span className="font20 font700 fontDark">Customer Interaction</span>
        </span>
      ),
      content:
        'Streamline every interaction with a CRM designed for real estate professionals. Enhance productivity with intelligent workflows and real-time insights.',
    },
    {
      id: 3,
      label: 'OKR',
      comingSoon: true,

      img: OKRLogo,
      title: <span className="font20">Align Goals, Drive Success</span>,
      content:
        "Track and achieve your objectives with OKR tools that align your team's efforts. Monitor progress and stay focused on what truly matters.",
    },
    {
      id: 4,
      label: 'FMS',
      comingSoon: true,

      img: FMSLogo,
      title: <span className="font20">Transform Facility Operations with Precision</span>,
      content:
        'Manage your facilities with precision using advanced tools. Ensure operational efficiency and proactive maintenance with real-time data.',
    },
  ];

  useEffect(() => {
    if (locationState.error) setLocationName('Enable permissions to access your location data');
    if (locationState.loading) setLocationName('Loading... (you may need to enable permissions)');
    if (locationState.latitude && locationState.longitude) {
      dispatch(
        fetchLocationViaLongLat({
          userId: user.id,
          body: {
            latitude: `${locationState.latitude}`,
            longitude: `${locationState.longitude}`,
          },
        }),
      ).then((data) => {
        const name =
          data?.payload?.data?.response?.data?.locationDetails?.name ||
          data?.payload?.data?.response?.data?.locationDetails?.additionalResults?.[0];
        setLocationName(name);
      });
    }
  }, [dispatch, locationState, user.id]);

  useEffect(() => {
    if (!profileCompletion?.overallCompletion) return;
    if (profileCompletion?.overallCompletion >= 75) {
      navigateTo('/user/socialApp');
    }
  }, [navigateTo, profileCompletion?.overallCompletion]);
  return (
    <div className="useroffering-container">
      <div className="userJourney">
        <div className="userJourney__title">
          <Text className="userjourney__welcome">
            Welcome, <span className="welcome-user"> {user?.name}</span>
          </Text>
        </div>
        <Space direction="vertical">
          <Text strong className="user-item">
            Trythat is happy to have you here!
          </Text>
          <p className="user-desc">
            To give you the best leads and recommendations we have created a short survey that we would like you to fill
            up. Also note that on filling the entire form you will earn points with which you can unlock leads and make
            the most of this platform.
          </p>

          <p className="user-journey">Let's start your journey</p>

          <Checkbox
            className="user-left__checkbox"
            checked={locationChecked}
            onChange={() => {
              setLocationChecked(!locationChecked);
            }}
          >
            <div className="check-location">
              <img src={locationIcon} className="location-img" alt="locationIcon" />
              Use this as my current location
            </div>
          </Checkbox>
          <p className="location-name">{locationName}</p>
          <Text
            className="location-change"
            onClick={() => {
              setAllowChangeLocation(true);
            }}
          >
            Change Location
          </Text>
          <div>
            <Row>
              <Col span={16}>
                {allowChangeLocation && (
                  <Debouncer
                    coordinates={coordinates}
                    setCoordinates={setCoordinates}
                    setLocationName={setLocationName}
                  />
                )}
              </Col>
              <Col span={8}>
                <Button
                  onClick={() => {
                    dispatch(
                      saveUserLocationDB({
                        latitude: locationState.latitude || coordinates.lat,
                        longitude: locationState.longitude || coordinates.lng,
                        location: `${locationName}`,
                      }),
                    );
                    navigateTo('/user/onboarding');
                  }}
                  type="primary"
                  className="useroffering-btn"
                  disabled={
                    !(
                      ![
                        '',
                        'Enable permissions to access your location data',
                        'Loading... (you may need to enable permissions)',
                      ].includes(locationName) && locationChecked
                    )
                  }
                >
                  Let's Start
                </Button>
              </Col>
            </Row>

            {coordinates && <SimpleMap coordinates={coordinates} setCoordinates={setCoordinates} />}
          </div>
        </Space>
      </div>
      <div className="offerings flex-1">
        <div className="offerings__title">
          <Text className="title-size">
            Our <span className="title-offering">Offerings</span>
          </Text>
        </div>
        <div className="offering-desc">
          {offerings?.map((el) => {
            return (
              <div className="offeringCard">
                <Card
                  key={el.id}
                  onClick={() => setSelectedOffering(el?.id)}
                  style={{
                    width: 150,
                    height: 150,
                    borderColor: el?.id == selectedOffering ? '#0081fc87' : '#EAEAEA',
                  }}
                >
                  <img alt="" src={el?.img} />
                  <div className="font12 fontLight">{el.label}</div>
                  {el?.comingSoon && <img className="mt-10 w-100 mb-10" src={ComingSoon} />}
                </Card>
              </div>
            );
          })}
        </div>
        <Space className="useroffering-left">
          <Space direction="vertical">
            {offerings?.find((el) => el?.id == selectedOffering)?.title}
            <Text>{offerings?.find((el) => el?.id == selectedOffering)?.content}</Text>
          </Space>
          <div className="left-desc">
            <img alt="" src={logo} width={100} className="left-img" />
            <img alt="" src={man} height={140} width={200} />
          </div>
        </Space>
      </div>
    </div>
  );
};

export default UserOfferings;
