import { Card, Col, Space, Tag, Modal, Button, Avatar, Tooltip } from 'antd';
import ProspectImage from '../../components/prospectImage/ProspectImage';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import PhotoGallery from '../../components/photoGallery/PhotoGallery';
import Reviews from '../../components/reviews/Reviews';
import DataGrid from '../../components/dataGrid/DataGrid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDetailedPropertyInfo,
  getOccupantsFromProperty,
  setNavigateFrom,
  getVacantUnitsFromProperty,
  unlockVacantUnitFields,
  addBreadcrumb,
  truncateBreadcrumbs,
  getVacantUnitsFromResidentialProperty,
  clearBreadcrumbs,
} from '../../features/searchSlice';
import { isEmpty } from 'lodash';
import AmenitiesIconEnum from '../../../utils/referenceData/search/amenitiesIconEnum';
import { FormatVacantUnitsData } from '../../../utils/formatSearchData/VacantUnit';
import { UserOutlined } from '@ant-design/icons';
import { getPropertyReviews } from '../../features/contactBookSlice';
import properties from '../../../assets/images/WALL.svg';
import search_organization from '../../../assets/images/search_organization.svg';
import LockedIcon from '../../../layout/home/images/LockedIcon';
import UnlockedIcon from '../../../layout/home/images/UnlockedIcon';

import Address from '../../components/address/Address';
import { transactProspects } from '../../features/savedSlice';
import { setInsufficientPoints, setTransactionModal } from '../../features/savedSlice';
import './ResidentialPropertyDetailsCard.scss';
import { useSearchParams } from 'react-router-dom';

const ResidentialPropertyDetailsCard = ({ id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { detailedResidentialPropertyData, residentialVacantUnitsData, breadcrumbs } = useSelector(
    (store) => store.search,
  );
  const { propertyReviews } = useSelector((store) => store.contactBook);

  const [propertyDetails, setPropertyDetails] = useState({});
  const [reviwesDetails, setReviewsDetails] = useState([]);
  const [vacantUnitDetails, setVacantUnitDetails] = useState([]);

  const { selectedNav } = useSelector((store) => store.leadGenSideNav);
  const { userPoints } = useSelector((store) => store.userOnboarding);
  const { transactionLoading } = useSelector((store) => store.saved);
  const { crmData } = useSelector((store) => store.userDashboard);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [params, setSearchParams] = useSearchParams();
  const searchQueries = params?.get('shareable');
  const vacantUnitListColumns = [
    {
      title: '',
      dataIndex: 'vacantProfileImage',
      key: 'vacantProfileImage',
      render: (text, record) => {
        return (
          <div style={{ display: 'flex' }}>
            {record?.vacantProfileImage ? (
              <Avatar src={record?.vacantProfileImage} />
            ) : (
              <img
                src={search_organization}
                alt="location"
                className="organizationListContainer__div__card__row2__col1__row__col__img"
              />
            )}
          </div>
        );
      },
    },
    {
      title: 'Chargable Area',
      dataIndex: 'chargableArea',
      key: 'chargableArea',
      render: (text, record) => {
        return <span>{`${parseFloat(record?.chargableArea).toFixed(2)} Sq.Ft.`}</span>;
      },
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: (text, record) => {
        return <span>{`${parseFloat(record?.rate).toFixed(2)} Sq.Ft.`}</span>;
      },
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      render: (text, record) => {
        return (
          <span className="unit-property-doc-type">
            {record?.transactionType === 'Rent'
              ? 'For Rent'
              : record?.transactionType === 'Sell'
              ? 'For Sell'
              : 'Awaited'}
          </span>
        );
      },
    },
    {
      title: '',
      dataIndex: 'isResourceLocked',
      key: 'isResourceLocked',
      render: (text, record) => {
        return <div style={{ display: 'flex' }}>{record?.isResourceLocked ? <LockedIcon /> : <UnlockedIcon />}</div>;
      },
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <span
          style={{ cursor: 'pointer', fontSize: '15px' }}
          onClick={() => {
            dispatch(setNavigateFrom('PropertyDetails'));
            navigate(`/leadGen/occupantDetails/${text._id}`);
          }}
        >
          {'>'}
        </span>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getDetailedPropertyInfo(id));
    // dispatch(getOccupantsFromProperty(id));
    dispatch(getPropertyReviews(id));
    dispatch(getVacantUnitsFromResidentialProperty(id));
    const crumb = breadcrumbs?.findIndex((el) => el?.path?.includes('occupantDetails'));
    dispatch(truncateBreadcrumbs(breadcrumbs?.[crumb]?.name));
    if (detailedResidentialPropertyData?.buildingName && !searchQueries) {
      dispatch(
        addBreadcrumb({
          name: `${detailedResidentialPropertyData?.buildingName}`,
          path: `/leadGen/propertyDetails/${id}`,
        }),
      );
    }
  }, [id]);

  useEffect(() => {
    if (searchQueries) {
      dispatch(clearBreadcrumbs());
    }
  }, [searchQueries]);

  useEffect(() => {
    if (isEmpty(detailedResidentialPropertyData)) return;
    setPropertyDetails(detailedResidentialPropertyData);
  }, [detailedResidentialPropertyData]);

  useEffect(() => {
    if (isEmpty(propertyReviews)) return;
    setReviewsDetails(propertyReviews);
  }, [propertyReviews]);

  useEffect(() => {
    if (isEmpty(residentialVacantUnitsData)) return;
    const data = FormatVacantUnitsData(residentialVacantUnitsData);
    setVacantUnitDetails(data);
  }, []);

  const onRowClick = (id) => {
    const vacantUnitData = vacantUnitDetails?.filter((item) => item?.id === id);
    if (vacantUnitData?.[0]?.isResourceLocked == false) {
      navigate(`/leadGen/unitDetails/${id}`);
      return;
    }
    const transactPayload = {
      body: {
        payload: [
          {
            resourceType: 'residential',
            resourceSubType: 'vacantUnit',
            resourceId: id,
            unlockedFields: ['address', 'poc'],
          },
        ],
      },
    };
    dispatch(unlockVacantUnitFields(transactPayload))
      .then((res) => {
        if (res?.error?.message === 'Rejected') {
          dispatch(setTransactionModal(true));
          dispatch(setInsufficientPoints(100));
          return;
        }
        navigate(`/leadGen/unitDetails/${id}`);
      })
      .catch((err) => {
        console.log(err, 'err');
      });
    dispatch(setNavigateFrom('PropertyDetails'));
  };

  const handleTransacBody = (resourceSubType, selectedRecords) => {
    switch (resourceSubType) {
      case 'organization':
        return {
          Email: selectedRecords?.officemailid,
          FirstName: selectedRecords?.name,
          Mobile: selectedRecords?.headOfficeNumber,
          Website: selectedRecords?.websiteLink,
          Description: selectedRecords?.description,
          Micromarket: selectedRecords?.location?.mapLocation,
        };
      case 'connect':
        return {
          Email: selectedRecords?.personalDetails?.email,
          FirstName: selectedRecords?.personalDetails?.name,
          Mobile: selectedRecords?.personalDetails?.phone,
        };
      case 'property':
        return {
          FirstName: selectedRecords?.name,
          Micromarket: selectedRecords?.location?.mapLocation,
        };

      default:
        return;
    }
  };

  const handleUnlockProspects = (value) => {
    const selectedRecords = value;
    const body = {
      payload: selectedRecords
        ?.filter((data) => data?.resourceType === 'commercial')
        ?.map((el) => ({
          resourceType: el?.resourceType,
          resourceSubType: el?.resourceSubType,
          resourceId: el?.resourceId,
          unlockedFields: el.unlockedFields,
          crmPayload: {
            ...handleTransacBody(selectedRecords?.[0]?.resourceSubType, selectedRecords?.[0]),
          },
        })),
      crmDetails: {
        id: crmData?.id,
        accessToken: crmData?.accessToken,
      },
    };
    dispatch(transactProspects(body));
  };

  const showPopupModal = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const PopupModal = () => (
    <Modal
      centered
      open={isModalVisible}
      onOk={() => handleModalClose()}
      onCancel={() => handleModalClose()}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={
        <Button key="ok" type="primary" loading={transactionLoading} onClick={() => navigate('/user/store')}>
          Make Payment
        </Button>
      }
      className="savedModal"
    >
      <Space direction="vertical" align="center">
        <Card
          style={{
            width: 250,
            marginTop: '20px',
            background: 'transparent linear-gradient(123deg, #0081FC 0%, #66B3FD 100%) 0% 0% no-repeat padding-box',
            boxShadow: '0px 0px 20px #00000029',
            textAlign: 'center',
          }}
        >
          <Space direction="vertical">
            <Title style={{ color: '#FFFFFF' }} level={4}>
              Oops insufficient points!
            </Title>
            <Text style={{ color: '#FFFFFF' }}>You need additional {100 - userPoints} points to unlock</Text>
          </Space>
        </Card>
      </Space>
    </Modal>
  );

  return (
    <section className="personalDtlsSection">
      <Card className="personalDtlsSection-card">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Title level={4} strong className="card__title">
            <span
              onClick={() => {
                if (selectedNav !== 'ContactBook') {
                  dispatch(truncateBreadcrumbs(breadcrumbs?.[breadcrumbs.length - 2]?.name));
                  navigate(breadcrumbs?.[breadcrumbs.length - 2]?.path);
                  return;
                }
                navigate('/leadgen/contactbook');
              }}
              className="card__title-pointer"
            >
              {'< '}
              <span style={{ fontSize: '16px', verticalAlign: 'middle', fontWeight: '600' }}>
                {propertyDetails?.buildingName}
              </span>
            </span>
          </Title>
          <div className="prospectImage">
            <ProspectImage imageUrl={propertyDetails?.featuredImage || properties} />
            <Text className="font10" style={{ color: '#313131' }}>
              {propertyDetails?.addressInfo?.locality}, {propertyDetails?.addressInfo?.city}
            </Text>
          </div>
          <Text style={{ fontWeight: '900' }}>Building Mgmt. Contact Details</Text>
          <Text style={{ fontSize: '12px' }}>Developer Name : {propertyDetails?.developer}</Text>
          <Text style={{ fontSize: '12px' }}>Developer Contact : {propertyDetails?.developerContact}</Text>
          <Text style={{ fontSize: '12px' }}>Developer Email : {propertyDetails?.developerEmail}</Text>
          <Text style={{ fontSize: '12px' }}>Society Formation : {propertyDetails?.societyInfo?.societyFormation}</Text>
          <Text style={{ fontSize: '12px' }}>Society Contact : {propertyDetails?.societyInfo?.societyContact}</Text>
          <Text style={{ fontSize: '12px' }}>Society Email : {propertyDetails?.societyInfo?.societyEmail}</Text>

          <br />
          <Address addressInfo={propertyDetails?.addressInfo} className="font900 font13" />
          <br />
          <Text style={{ fontWeight: '900' }}>About</Text>
          <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
            <Text style={{ fontSize: '12px' }}>{propertyDetails?.about || 'Awaited'}</Text>
          </div>
        </Space>
      </Card>

      <Card style={{ flex: 1.2 }} className="personalDtlsSection-card">
        <Text className="font18 font600">Key Information</Text>
        <Space direction="vertical" className="w-100">
          <Space
            direction="vertical"
            className="w-100 p-10 mt-10"
            style={{ border: '2px solid #ffa31d', borderRadius: '10px' }}
          >
            <div className='residential-lockicon'
            >
              <div className='lockicon'>
                {propertyDetails?.isResourceLocked ? <LockedIcon /> : <UnlockedIcon />}
              </div>
            </div>
            <Text className="font12 bold">Building Type : {propertyDetails?.buildingType}</Text>
            <Text className="font12 bold">Building Status : {propertyDetails?.buildingStatus}</Text>
            <Text className="font12 bold">
              Average sale rate:{' '}
              {propertyDetails?.moreBuildingInfo?.[0]?.averageSaleRate
                ? `${Number(propertyDetails?.moreBuildingInfo?.[0]?.averageSaleRate).toFixed(2)} Rs/sqft`
                : 'Awaited'}
            </Text>
            <Text className="font12 bold">
              Average rent rate:{' '}
              {propertyDetails?.moreBuildingInfo?.[0]?.averageRentRate
                ? `${Number(propertyDetails?.moreBuildingInfo?.[0]?.averageRentRate)?.toFixed(2)} Rs/sqft`
                : 'Awaited'}
            </Text>
            <Text className="font12 bold">
              Average CAM chargeable :{' '}
              {propertyDetails?.moreBuildingInfo?.[0]?.averageCamChargeable
                ? `${Number(propertyDetails?.moreBuildingInfo?.[0]?.averageCamChargeable)?.toFixed(2)} Rs/sqft/month`
                : 'Awaited'}
            </Text>
          </Space>
          <Text className="font12 p-10">
            <span className="bold">Total Chargeable Area : </span>
            {propertyDetails?.totalChargeableArea}
          </Text>
          <Text className="font12 p-10">
            <span className="bold"> Average Floor Plate :</span>{' '}
            {propertyDetails?.moreBuildingInfo?.[0]?.averageFloorPlateCarpet
              ? `${propertyDetails?.moreBuildingInfo?.[0]?.averageFloorPlateCarpet} sqft`
              : 'Awaited'}
          </Text>
          <Text className="font12 p-10">
            {' '}
            <span className="bold">Efficiency : </span>
            {propertyDetails.efficiency}
          </Text>
          <Text className="font12 p-10">
            {' '}
            <span className="bold">Grade of building :</span> {propertyDetails?.gradeOfBuilding || 'Awaited'}
          </Text>
          <Text className="font12 p-10">
            {' '}
            <span className="bold">Building Classification:</span> {propertyDetails?.buildingClassification}
          </Text>
          <Text className="font12 p-10">
            {' '}
            <span className="bold">Building Structure:</span> {propertyDetails?.buildingStructure}
          </Text>
          <Text className="font18 font600">Amenities</Text>
          {propertyDetails?.amenities && (
            <div className="amenitiesDtlsSection__div">
              {propertyDetails?.amenities?.map((item) => {
                return (
                  <Col key={item} span={6} className="amenitiesDtlsSection__div__col">
                    {isEmpty(AmenitiesIconEnum?.[item?.toLowerCase()]) ? (
                      <Tooltip title={'Default'}>
                        <img src={AmenitiesIconEnum?.['DEFAULT']} className="amenitiesDtlsSection__div__col__img" />
                      </Tooltip>
                    ) : (
                      <Tooltip title={item}>
                        <img
                          src={AmenitiesIconEnum?.[item?.toLowerCase()]}
                          className="amenitiesDtlsSection__div__col__img"
                        />
                      </Tooltip>
                    )}
                  </Col>
                );
              })}
            </div>
          )}
          {propertyDetails?.areaFacilities && propertyDetails?.areaFacilities?.length > 0 && (
            <div className="propertyListContainer__div__card__row1__col1__div1">
              {propertyDetails?.areaFacilities?.map((item) => {
                if (item?.facility && item?.metadata?.location)
                  return (
                    <span key={item.facility} className="propertyListContainer__div__card__row1__col1__div1__span">
                      <p className="propertyListContainer__div__card__row1__col1__div1__span__para">{item?.facility}</p>
                      :&nbsp;{' '}
                      <p className="propertyListContainer__div__card__row1__col1__div1__span__para">
                        {item?.metadata?.location}
                      </p>
                    </span>
                  );
                return '';
              })}
            </div>
          )}
          <PhotoGallery photos={propertyDetails?.propertyImages} />
          <Reviews averageRating={propertyDetails?.averageRating} reviewsList={reviwesDetails} />
        </Space>
      </Card>
      <Card style={{ flex: '2' }} className="companiesVacantList">
        <div className="tab-headings">
          <Text className="listHeading" style={{ color: '#313131' }}>
            List of Vacant Unit
          </Text>
        </div>
        <div className="data-grid-container">
          <DataGrid columns={vacantUnitListColumns} data={vacantUnitDetails} onRowClick={(id) => onRowClick(id)} />
        </div>
      </Card>
      <PopupModal />
    </section>
  );
};

export default ResidentialPropertyDetailsCard;
