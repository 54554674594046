import { RightOutlined } from '@ant-design/icons';
import { nanoid } from '@reduxjs/toolkit';
import { Avatar, Divider, List } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import InfiniteScroll from 'react-infinite-scroll-component';
import SimilarCompaniesSkeleton from './skeletons/SimilarCompaniesSkeleton';
import { isEmpty } from 'lodash';
import {
  getConnectsFromOrganization,
  getDetailedOrganizationInfo,
  getDetailedPropertyInfo,
  getOrganizationFromProperty,
  getPropertiesFromOrganization,
} from '../../../../features/searchSlice';
import './companyDetails.scss';
import animationData from '../../../../../assets/images/SelectedLottie.json';
import LottieAnimation from '../../../../../universal/components/lottieAnimation/LottieAnimation';
import CustomRating from '../ProperyDetails/CustomRating';
import { getOrganizationReviews } from '../../../../features/contactBookSlice';
import { setSelectedNav } from '../../../../features/leadGenSideNavSlice';
import { useWindowSize } from '../../../../../utils/useWindowSize/useWindowSize';
import animationDataEmp from '../../../../../assets/images/PropertyAndComapnyLottieImage.json';

const SimilarCompanies = ({ companyData = [], isReverseFlow = false, loader }) => {
  const { isAbove1020, isAbove1280, isAbove1440 } = useWindowSize();
  const { hasMoreSimilarCompaniesData, detailedPropertyData } = useSelector((store) => store.search);
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const { propertyId } = useParams();
  const loadMoreData = () => {
    dispatch(getOrganizationFromProperty(propertyId));
  };
  return (
    <div style={{ overflow: 'scroll' }} className="similarPropertyContainer d-flex  g-10 d-column">
      {isReverseFlow ? (
        <div className="font14 font900 fontLight-3"> Tailored Suggestions for You</div>
      ) : (
        <div className="font14 font900 fontLight-3"> Companies in {detailedPropertyData?.buildingName}</div>
      )}
      <div className="similarCompanyScrollbar jc-center pr-10" style={{ maxHeight: 550, overflowY: 'scroll' }}>
        {loader ? (
          [...new Array(5)]?.map(() => <SimilarCompaniesSkeleton key={nanoid()} />)
        ) : isEmpty(companyData) ? (
          <div className="lottie-div">
            <LottieAnimation height={120} width={120} animationData={animationData} loop={true} />
            <span className="bold lottie-div__text">
              We are finding right<br></br> <span className="bold text-middle">COMPANIES</span>
              <br></br>
              For you, Be with us !
            </span>
          </div>
        ) : (
          <InfiniteScroll
            dataLength={companyData?.length}
            next={() => {
              loadMoreData();
            }}
            hasMore={hasMoreSimilarCompaniesData}
            endMessage={<Divider plain>It is all, nothing more </Divider>}
            scrollableTarget="columnOneDiv"
          >
            <List
              dataSource={companyData}
              renderItem={(ele, index) => (
                <List.Item
                  key={nanoid()}
                  onClick={() => {
                    dispatch(getPropertiesFromOrganization(ele?._id)).then((res) => {
                      if (!isReverseFlow) {
                        dispatch(getDetailedPropertyInfo(res?.payload?.[0]?._id));
                        dispatch(getDetailedOrganizationInfo(ele?._id));
                        dispatch(getConnectsFromOrganization(ele?._id));
                        dispatch(getOrganizationReviews(ele?._id));
                      } else {
                        navigateTo(`/leadGen/connectedDetails/${res?.payload?.[0]?._id}`);
                        dispatch(setSelectedNav('LeadGen'));
                      }
                    });
                  }}
                  style={{
                    borderBlockEnd: 'none',
                    display: 'block',
                    width: '100%',
                    padding: '0px',
                  }}
                >
                  <div
                    key={nanoid()}
                    className={`d-flex a-center jc-between mt-15 p-10 similarPropertiesHover`}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <span className="d-flex g-10 jc-between">
                      {ele?.otherCompanyInfo?.assetsInfo?.[0]?.featuredImage ? (
                        <Avatar className="companyAvatar" src={ele?.otherCompanyInfo?.assetsInfo?.[0]?.featuredImage} />
                      ) : (
                        <div
                          style={{
                            border: '1px solid black',
                            width: isAbove1020 ? '35px' : isAbove1280 ? '40px' : isAbove1440 ? '50px' : '60px',
                            height: isAbove1020 ? '35px' : isAbove1280 ? '40px' : isAbove1440 ? '50px' : '60px',
                            borderRadius: '50%',
                          }}
                        >
                          <LottieAnimation
                            height={'100%'}
                            width={'100%'}
                            animationData={animationDataEmp}
                            loop={true}
                          />
                        </div>
                      )}
                      <span className="d-flex d-column jc-between">
                        <span className="font12 font600">{ele?.companyName}</span>
                        <span className="font10 font400 subTitleDiv">
                          {`Floor No.: ${
                            ele?.propertyEngagement?.floorNo ? ele?.propertyEngagement?.floorNo : 'Awaited'
                          } | Unit No.: ${
                            ele?.propertyEngagement?.unitNo ? ele?.propertyEngagement?.unitNo : 'Awaited'
                          }`}
                        </span>
                        <CustomRating
                          rating={
                            ele?.averageRating && !isNaN(Number(ele?.averageRating)) ? Number(ele?.averageRating) : 4
                          }
                          hovered={hoveredIndex === index}
                        />
                      </span>
                    </span>
                    <RightOutlined className="fontLight-2" />
                  </div>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

export default SimilarCompanies;
