import React, { useEffect, useState } from 'react';
import SelectedPropertyDetail from './SelectedPropertyDetails';
import SelectedPropertyDetailsSkeleton from './SelectedPropertyDetailsSkeleton';
import SimilarProperties from './SimilarProperties';
import './propertyDetails.scss';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const PropertyDetails = ({
  loader,
  propertyData,
  selectedPropertyData,
  searchQuery,
  onSaveCard,
  onDeleteCard,
  organizationsName,
  isReverseFlow,
  companyData,
}) => {
  const { raiseModal } = useSelector((store) => store.myAccount);
  const navigateTo = useNavigate();
  const [zIndexer, setZindexer] = useState(false);
  useEffect(() => {
    setZindexer(raiseModal);
  }, [raiseModal]);

  return (
    <>
      {loader ? (
        <div className="property_container">
          <div className="font14 font900 fontLight-3 mb-10">
            {isReverseFlow ? `Property ${companyData?.companyName ? `of ${companyData?.companyName}`: ''}` : 'Property Selected'}
          </div>
          <SelectedPropertyDetailsSkeleton />
          <SimilarProperties
            loader={loader}
            isReverseFlow={isReverseFlow}
            propertyData={propertyData.filter((item) => item?._id !== selectedPropertyData?._id)}
          />
        </div>
      ) : (
        <div className="property_container" style={{ zIndex: zIndexer ? '0' : '1' }}>
          <div className="font14 font900 fontLight-3 mb-10">
            {isReverseFlow ? `Property of ${companyData?.companyName || ''}` : 'Property Selected'}
          </div>
          <SelectedPropertyDetail
            propertyItem={Array.isArray(selectedPropertyData) ? selectedPropertyData?.[0] : selectedPropertyData}
            onSaveCard={onSaveCard}
            onDeleteCard={onDeleteCard}
          />
          <SimilarProperties
            searchQuery={searchQuery}
            loader={loader}
            onCardClick={(id) => {
              navigateTo(`/leadGen/connectedDetails/${id}`);
            }}
            propertyData={propertyData.filter((item) => {
              if (Array.isArray(selectedPropertyData)) {
                return item?._id !== selectedPropertyData?.[0]?._id;
              }
              return item?._id !== selectedPropertyData?._id;
            })}
            organizationsName={organizationsName}
            isReverseFlow={isReverseFlow}
          />
        </div>
      )}
    </>
  );
};

export default PropertyDetails;
