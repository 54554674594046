import React, { useState, Fragment, Suspense } from 'react';
import { Card, Switch, Typography, Button, Spin } from 'antd';
import DataGrid from '../../components/dataGrid/DataGrid';
// import BuildingStatsComponent from '../buildingStatsComponent/BuildingStatsComponent'; // Import the BuildingStatsComponent

import './OccupantsCard.scss';
import { useSearchParams } from 'react-router-dom';
import BuildingSkeletonLoader from './BuildingSkeletonLoader';


const { Text } = Typography;
const BuildingStatsComponent = React.lazy(() => import('../buildingStatsComponent/BuildingStatsComponent'));

const OccupantsCard = ({
  occupantsDetails,
  vacantUnitDetails,
  occupantsListColumns,
  vacantUnitListColumns,
  onRowClick,
  isDetailsVisible,
  floorsData,
  propertyDetails,
}) => {
  const [searchParams] = useSearchParams();
  const list = searchParams.get('list');
  const [activeTab, setActiveTab] = useState(list);
  const [view, setView] = useState('occupants'); // Manage the switch state

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleViewChange = (checked) => {
    setView(checked ? 'buildingStats' : 'occupants');
  };


  return (
    <Card
      style={{
        flex: 2,
        // height: isDetailsVisible ? '255px' : '100%',
        border: 'none',
      }}
      className="occupants-card-view"
    >
      <div className="card-header" style={{ width: 'max-content', marginLeft: '500px' }}>
        <Switch
          checkedChildren="Building Stats"
          unCheckedChildren="Occupants"
          checked={view === 'buildingStats'}
          onChange={handleViewChange}
          className="large-switch"
        />
      </div>
      {view === 'occupants' ? (
        <Fragment>
          <div className="tab-headings">
            <Text
              className="listHeading"
              style={{
                color: activeTab === 'occupants' ? '#313131' : '#b7b7b7',
              }}
              onClick={() => handleTabChange('occupants')}
            >
              List of Occupants
            </Text>
            <Text
              className="listHeading"
              style={{
                color: activeTab === 'vacantUnits' ? '#313131' : '#b7b7b7',
              }}
              onClick={() => handleTabChange('vacantUnits')}
            >
              List of Vacant Unit
            </Text>
          </div>
          <div className="data-grid-container">
            {activeTab === 'occupants' ? (
              <DataGrid
                columns={occupantsListColumns}
                data={occupantsDetails}
                onRowClick={(id) => onRowClick(id, 'occupants')}
              />
            ) : (
              <DataGrid
                columns={vacantUnitListColumns}
                data={vacantUnitDetails}
                onRowClick={(id) => {
                  onRowClick(id, 'vacantUnits', propertyDetails?.buildingName)
                }
                }
              />
            )}
          </div>
        </Fragment>
      ) : (
        // Use Suspense to load BuildingStatsComponent asynchronously with a fallback spinner
        <Suspense fallback={<BuildingSkeletonLoader floor={40} />}>
          <BuildingStatsComponent floors={floorsData} />
        </Suspense>
      )}
    </Card>
  );
};

export default OccupantsCard;
