import { Divider, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import CommentIcon from '../../../../../assets/SocialApp/CommentIcon';
import LikeIcon from '../../../../../assets/SocialApp/LikeIcon';
import SaveIcon from '../../../../../assets/SocialApp/SaveIcon';
import ShareIcon from '../../../../../assets/SocialApp/ShareIcon';
import { ShareModal } from '../../../../../utils/ShareModal/ShareModal';
import { updateActivity } from '../../../../features/socialAppSlice';
import './postFooter.scss';
import RepostComponent from './RepostComponent';

const PostFooter = ({
  isViewPost,
  setPostDetails = () => {},
  postDetails,
  setPostData,
  isUserPost,
  setAllPosts,
  allPostsPage = false,
  isPollCard = false,
  setLoginPopup = () => {},
}) => {
  const userId = useSelector((state) => state.user?.user?.id);
  const [sharePost, setSharePost] = useState();
  const [params, setSearchParams] = useSearchParams();
  const sharedPost = params?.get('sharedPost');
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleLike = () => {
    if (sharedPost) {
      setLoginPopup(true);
      return;
    }
    dispatch(updateActivity({ userId: userId, postId: postDetails?._id, action: 'likes' }))?.then((res) => {
      if (res?.payload) {
        if (isUserPost) {
          setPostData((prev) => ({
            ...prev,
            isLiked: true,
            likes: (prev?.likes || 0) + 1,
          }));
          setPostDetails((prev) => ({
            ...prev,
            isLiked: true,
            likes: (prev?.likes || 0) + 1,
          }));
        }
        setAllPosts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?._id === postDetails?._id) {
              elem = { ...elem, isLiked: true, likes: (elem?.likes || 0) + 1 };
            }
            return elem;
          }),
        }));
      }
    });
  };

  const handleUnLike = () => {
    if (sharedPost) {
      setLoginPopup(true);
      return;
    }
    dispatch(updateActivity({ userId: userId, postId: postDetails?._id, action: 'unlikes' }))?.then((res) => {
      if (res?.payload) {
        if (isUserPost) {
          setPostData((prev) => ({
            ...prev,
            isLiked: false,
            likes: (prev?.likes || 0) - 1,
          }));
          setPostDetails((prev) => ({
            ...prev,
            isLiked: false,
            likes: (prev?.likes || 0) - 1,
          }));
        }
        setAllPosts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?._id === postDetails?._id) {
              elem = { ...elem, isLiked: false, likes: elem?.likes - 1 };
            }
            return elem;
          }),
        }));
      }
    });

    // dispatch(getComments({ userId: userId, postId: postDetails?._id }));
  };

  const handleSave = () => {
    if (sharedPost) {
      setLoginPopup(true);
      return;
    }
    dispatch(updateActivity({ userId: userId, postId: postDetails?._id, action: 'saves' }))?.then((res) => {
      if (res?.payload) {
        if (isUserPost) {
          setPostData((prev) => ({
            ...prev,
            isSaved: true,
            saves: (prev?.saves || 0) + 1,
          }));
          setPostDetails((prev) => ({
            ...prev,
            isSaved: true,
            saves: (prev?.saves || 0) + 1,
          }));
        }
        setAllPosts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?._id == postDetails?._id) {
              elem = { ...elem, isSaved: true, saves: (elem?.saves || 0) + 1 };
            }
            return elem;
          }),
        }));
      }
    });
  };

  const handleUnSave = () => {
    if (sharedPost) {
      setLoginPopup(true);
      return;
    }
    dispatch(updateActivity({ userId: userId, postId: postDetails?._id, action: 'unsaves' }))?.then((res) => {
      if (res?.payload) {
        if (isUserPost) {
          setPostData((prev) => ({
            ...prev,
            isSaved: false,
            saves: (prev?.saves || 0) - 1,
          }));
          setPostDetails((prev) => ({
            ...prev,
            isSaved: false,
            saves: (prev?.saves || 0) - 1,
          }));
        }
        setAllPosts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?._id == postDetails?._id) {
              elem = { ...elem, isSaved: false, saves: elem?.saves - 1 };
            }
            return elem;
          }),
        }));
      }
    });
  };

  const setRepostCount = (add) => {
    if (isUserPost) {
      setPostData((prev) => ({
        ...prev,
        reposts: add ? (prev?.reposts || 0) + 1 : (prev?.reposts || 0) - 1,
      }));
      setPostDetails((prev) => ({
        ...prev,
        reposts: add ? (prev?.reposts || 0) + 1 : (prev?.reposts || 0) - 1,
      }));
    }
    setAllPosts((prev) => ({
      ...prev,
      posts: prev?.posts?.map((elem) => {
        if (elem?._id == postDetails?._id) {
          elem = { ...elem, reposts: add ? (elem?.reposts || 0) + 1 : (elem?.reposts || 0) - 1 };
        }
        return elem;
      }),
    }));
  };
  const onShareClick = (postId, postType) => {
    if (sharedPost) {
      setLoginPopup(true);
      return;
    }
    setSharePost(`${window.location.protocol}${window.location.host}/socialApp/postDetails/${postId}/${postType}`);
  };
  return (
    <div className="d-flex d-column mt-10">
      <div className="d-flex jc-between p-3">
        <span className="d-flex jc-between g-20 footerIcons">
          {!isPollCard && (
            <>
              <Tooltip title={'Like'}>
                <span
                  className="d-flex a-center cursor-pointer"
                  onClick={postDetails?.isLiked ? handleUnLike : handleLike}
                >
                  <LikeIcon color={postDetails?.isLiked ? '#0081FC' : '#aaa9a9'} />
                </span>
              </Tooltip>
              <Tooltip title="Comment">
                <span
                  className="d-flex a-center cursor-pointer"
                  onClick={() => {
                    if (allPostsPage && !isViewPost) {
                      setPostDetails(postDetails);
                      navigate(`/user/socialApp/postDetails/${postDetails?._id}/${postDetails?.ownerType}`);
                    }
                  }}
                  style={allPostsPage ? { cursor: 'pointer' } : { cursor: 'auto' }}
                >
                  <CommentIcon />
                </span>
              </Tooltip>
            </>
          )}
          <Tooltip title="Share">
            <span
              onClick={() => onShareClick(postDetails?._id, postDetails?.ownerType)}
              className="d-flex a-center cursor-pointer"
              style={allPostsPage ? { cursor: 'pointer' } : { cursor: 'auto' }}
            >
              <ShareIcon />
            </span>
          </Tooltip>
        </span>
        {!isPollCard && (
          <span className="d-flex a-center cursor-pointer g-20">
            <RepostComponent
              setRepostCount={setRepostCount}
              reposted={postDetails?.isReposted}
              postId={postDetails?._id}
              sharedPost={sharedPost}
              setLoginPopup={setLoginPopup}
            />
            <Tooltip title={postDetails?.isSaved ? 'Unsave' : 'Save'}>
              <span className="d-flex a-center" onClick={postDetails?.isSaved ? handleUnSave : handleSave}>
                <SaveIcon color={postDetails?.isSaved ? '#0081FC' : '#aaa9a9'} />
              </span>
            </Tooltip>
          </span>
        )}
      </div>
      <Divider className="footerDivider" />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '12px',
          color: '#cbcbcb',
          marginTop: '5px',
        }}
      >
        {isPollCard ? (
          <></>
        ) : (
          // <span>{postDetails.shares || 0} Shares</span>
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
              }}
            >
              <span>{postDetails.likes || 0} Likes</span>
              <span>{/* <li>{postDetails.shares || 0} Shares</li> */}</span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
              }}
            >
              <span>{postDetails.comments || 0} Comments</span>
              <span>
                <li>{postDetails.reposts || 0} Repost</li>
              </span>
              <span>
                <li>{postDetails.saves || 0} Saves</li>
              </span>
            </div>
          </>
        )}
      </div>
      {sharePost && <ShareModal showShareModal={sharePost} shareUrl={sharePost} setShowShareModal={setSharePost} />}
    </div>
  );
};

export default PostFooter;
