import GardenIcon from '../../../assets/images/garden.svg';
import PlayAreaIcon from '../../../assets/images/playArea.png';
import question from '../../../assets/images/question.svg';
import ParkingIcon from '../../../assets/Amenities/SVG/Parking.svg';
import ClubHouseIcon from '../../../assets/Amenities/SVG/Clubhouse.svg';
import GYMIcon from '../../../assets/Amenities/SVG/Gym.svg';
import BalconyIcon from '../../../assets/Amenities/SVG/Balcony.svg';
import BathtubsIcon from '../../../assets/Amenities/SVG/Bathtubs.svg';
import BilliardsTableIcon from '../../../assets/Amenities/SVG/Billiards Table.svg';
import BuildingCafeteriaIcon from '../../../assets/Amenities/SVG/Building Cafeteria.svg';
import CafeIcon from '../../../assets/Amenities/SVG/Cafe.svg';
import CCTVIcon from '../../../assets/Amenities/SVG/CCTV.svg';
import CommunityGardenIcon from '../../../assets/Amenities/SVG/Community Garden.svg';
import EVChargerIcon from '../../../assets/Amenities/SVG/EV Charger.svg';
import GamesRoomIcon from '../../../assets/Amenities/SVG/Games Room.svg';
import GreenBuildingSustainabilityIcon from '../../../assets/Amenities/SVG/Green Building Sustainability.svg';
import KidsPlayAreaIcon from '../../../assets/Amenities/SVG/Kids’ Play Area.svg';
import NaturalLightingIcon from '../../../assets/Amenities/SVG/Natural Lighting.svg';
import PetFriendlyIcon from '../../../assets/Amenities/SVG/Pet Friendly.svg';
import RetailIcon from '../../../assets/Amenities/SVG/Retail.svg';
import SecuritySystemsIntercomIcon from '../../../assets/Amenities/SVG/Security systems and intercom.svg';
import SmartHomeIcon from '../../../assets/Amenities/SVG/Smart home.svg';
import SnoozeRoomIcon from '../../../assets/Amenities/SVG/Snooze Room.svg';
import SocialAreaRooftopGarden from '../../../assets/Amenities/SVG/Social area or rooftop garden.svg';
import SpaIcon from '../../../assets/Amenities/SVG/Spa.svg';
import SwimmingPoolsIcon from '../../../assets/Amenities/SVG/Swimming pools.svg';
import ValetIcon from '../../../assets/Amenities/SVG/Valet.svg';
import OfficeAccess from '../../../assets/Amenities/SVG/24 7 Office accses.svg';
import AirConditioning from '../../../assets/Amenities/SVG/Air Conditioning.svg';
import Beverages from '../../../assets/Amenities/SVG/Beverages.svg';
import BikeParking from '../../../assets/Amenities/SVG/Bike Parking.svg';
import Cafeteria from '../../../assets/Amenities/SVG/Cafeteria.svg';
import CarParking from '../../../assets/Amenities/SVG/Car Parking.svg';
import CCTVSurveillance from '../../../assets/Amenities/SVG/CCTV Surveillance.svg';
import ConferenceRoom from '../../../assets/Amenities/SVG/Conference room.svg';
import Cubicle from '../../../assets/Amenities/SVG/Cubicle.svg';
import DrinkingWater from '../../../assets/Amenities/SVG/drinking Water.svg';
import ManagedReception from '../../../assets/Amenities/SVG/Managed Reception.svg';
import MeetingRoom from '../../../assets/Amenities/SVG/meeting Room.svg';
import PassengerLifts from '../../../assets/Amenities/SVG/Passenger lifts.svg';
import PrimeLocations from '../../../assets/Amenities/SVG/Prime Loctions.svg';
import PrintingFacility from '../../../assets/Amenities/SVG/Printing Facility.svg';
import ServerRoom from '../../../assets/Amenities/SVG/server Room.svg';
import SupportStaff from '../../../assets/Amenities/SVG/Support Staff.svg';
import Wifi from '../../../assets/Amenities/SVG/Wifi.svg';
import WindowCovering from '../../../assets/Amenities/SVG/window Covering.svg';

const AmenitiesIconEnum = Object.freeze({
  parking: ParkingIcon,
  clubhouse: ClubHouseIcon,
  gym: GYMIcon,
  garden: GardenIcon,
  playarea: PlayAreaIcon,
  DEFAULT: question,
  balcony: BalconyIcon,
  bathtubs: BathtubsIcon,
  'billiards table': BilliardsTableIcon,
  'building cafeteria': BuildingCafeteriaIcon,
  cafe: CafeIcon,
  cctv: CCTVIcon,
  'community garden': CommunityGardenIcon,
  'ev charger': EVChargerIcon,
  'games room': GamesRoomIcon,
  'green building/sustainability': GreenBuildingSustainabilityIcon,
  "kid's play area": KidsPlayAreaIcon,
  'natural lighting': NaturalLightingIcon,
  'pet friendly': PetFriendlyIcon,
  retail: RetailIcon,
  'security systems and intercom': SecuritySystemsIntercomIcon,
  'smart home': SmartHomeIcon,
  'snooze room': SnoozeRoomIcon,
  'social area or rooftop garden': SocialAreaRooftopGarden,
  spa: SpaIcon,
  'swimming pools': SwimmingPoolsIcon,
  valet: ValetIcon,
  '24/7 office access': OfficeAccess,
  'air conditioning': AirConditioning,
  beverages: Beverages,
  'bike parking': BikeParking,
  cafeteria: Cafeteria,
  'car parking': CarParking,
  'cctv surveillance': CCTVSurveillance,
  'conference room': ConferenceRoom,
  cubicle: Cubicle,
  'drinking water': DrinkingWater,
  'managed reception': ManagedReception,
  'meeting room': MeetingRoom,
  'passenger lifts': PassengerLifts,
  'prime locations': PrimeLocations,
  'printing facility': PrintingFacility,
  'server room': ServerRoom,
  'support staff': SupportStaff,
  wifi: Wifi,
  'window coverings': WindowCovering,
});

export default AmenitiesIconEnum;
