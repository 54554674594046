import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import './pieChart.scss';

const PieChart = ({ item }) => {
  console.log(item)
  const labelOccupied = item?.[0]?.percentage?.occupied_unit_percentage
    ? `${item?.[0]?.count?.occupied_unit_count} units (${Number(
      item?.[0]?.percentage?.occupied_unit_percentage,
    )?.toFixed(2)}%)`
    : `${item?.[0]?.count?.occupied_unit_count} units`;
  const labelVacant = item?.[0]?.percentage?.vacant_unit_percentage
    ? `${item?.[0]?.count?.vacant_unit_count} units (${Number(item?.[0]?.percentage?.vacant_unit_percentage)?.toFixed(
      2,
    )}%)`
    : `${item?.[0]?.count?.vacant_unit_count} units`;
  const labelConfirmed = item?.[0]?.percentage?.to_be_confirmed_unit_percenatge
    ? `${item?.[0]?.count?.te_be_confirmed_unit_count} units (${Number(
      item?.[0]?.percentage?.to_be_confirmed_unit_percenatge,
    )?.toFixed(2)}%)`
    : `${item?.[0]?.count?.te_be_confirmed_unit_count} units`;
  const chartData = {
    labels: [`Vacant . ${labelVacant}`, `Occupied . ${labelOccupied}`, `To be confirmed . ${labelConfirmed}`],
    datasets: [
      {
        data: [
          item?.[0]?.count?.vacant_unit_count,
          item?.[0]?.count?.occupied_unit_count,
          item?.[0]?.count?.te_be_confirmed_unit_count,
        ],
        hoverOffset: 0,
        cutout: 50,
        backgroundColor: ['rgb(0, 128, 252)', '#FFA31D', '#D3D3D3'],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true, // Ensure tooltips are enabled
        // backgroundColor: '#f2f9ff',
        titleColor: '#FFF',
        zIndex: 99999,
        font: {
          family: 'Poppins', // Use Poppins font
          size: 12,
        },
        padding: {
          top: 10,
          right: 5,
          bottom: 5,
          left: 5,
        },

        callbacks: {
          label: function (tooltipItem) {
            // Return only the label part without the value
            return '';
            // return tooltipItem.label || '';
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="chart-container">
      <div className='col'>
        <div className="pie-chart">
          <Doughnut data={chartData} options={options} width={210} height={210} />
        </div>

        <div className="pie-container pip-inner">
          <h2 className='pip-h2'>{item?.[0]?.totalSquareFeet}</h2>
          <h3 className='php-h3'>Total Sq.Ft</h3>
        </div>
      </div>
      <div className='col'>
        <div className="legend-container">
          <div style={{ display: 'flex' }}>
            <div className="legend-item legend-item--orange"></div>
            <h5>
              Occupied . {item?.[0]?.count?.occupied_unit_count} units{' '}
              {item?.[0]?.percentage?.occupied_unit_percentage
                ? `(${Number(item?.[0]?.percentage?.occupied_unit_percentage)?.toFixed(2)}%)`
                : ''}
            </h5>
          </div>
          <div style={{ display: 'flex' }}>
            <div className="legend-item"></div>
            <h5>
              To be confirmed . {item?.[0]?.count?.te_be_confirmed_unit_count} units{' '}
              {item?.[0]?.percentage?.to_be_confirmed_unit_percenatge
                ? `(${Number(item?.[0]?.percentage?.to_be_confirmed_unit_percenatge)?.toFixed(2)}%)`
                : ''}
            </h5>
          </div>
          <div style={{ display: 'flex' }}>
            <div className="legend-item legend-item--blue"></div>
            <h5>
              Vacant . {item?.[0]?.count?.vacant_unit_count} units{' '}
              {item?.[0]?.percentage?.vacant_unit_percentage
                ? `(${Number(item?.[0]?.percentage?.vacant_unit_percentage)?.toFixed(2)}%)`
                : ''}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieChart;
