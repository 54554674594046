import { Card, Space, Steps, Row, Col } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title as ChartTitle,
} from 'chart.js';
import dayjs from 'dayjs';
import {
  addBreadcrumb,
  clearBreadcrumbs,
  getOccupantDetailsFullInfo,
  truncateBreadcrumbs,
} from '../../features/searchSlice';

import { setSelectedNav } from '../../features/universalSidenavSlice';

import './OccupantDetailsCard.scss';

const customDot = (dot, { status, index }) => <></>;

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, ChartTitle);

const OccupantDetailsCard = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { detailedPropertyData, occupantDetails, navigateFrom, occupantsData, breadcrumbs } = useSelector(
    (store) => store.search,
  );
  const { selectedNav } = useSelector((store) => store.leadGenSideNav);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [unitTimelineDetails, setUnitTimelineDetails] = useState([]);
  const [params, setSearchParams] = useSearchParams();

  const [currentValue, setCurrentValue] = useState(0);

  const searchQueries = params?.get('shareable');

  useEffect(() => {
    if (searchQueries) {
      dispatch(clearBreadcrumbs());
    }
  }, [searchQueries]);

  const onChange = (value) => {
    let transactionId = unitTimelineDetails?.[value]?.id;
    dispatch(getOccupantDetailsFullInfo(transactionId));
    setCurrentValue(value);
  };

  useEffect(() => {
    dispatch(getOccupantDetailsFullInfo(id));
  }, [dispatch, id]);

  useEffect(() => {
    let occupantDetails = occupantsData?.filter((item) => item?._id === id);
    let filteredOccupantsData = occupantsData?.filter(
      (item) =>
        item?.address?.unitNo === occupantDetails?.[0]?.address?.unitNo &&
        item?.address?.floorNo === occupantDetails?.[0]?.address?.floorNo,
    );
    setUnitTimelineDetails(formatUnitTimelineObj(filteredOccupantsData));
    const crumb = breadcrumbs?.findIndex((el) => el?.path?.includes('occupantDetails'));

    dispatch(truncateBreadcrumbs(breadcrumbs?.[crumb]?.name));
    if (occupantDetails?.[0]?.address?.unitNo && !searchQueries) {
      dispatch(
        addBreadcrumb({ name: `${occupantDetails?.[0]?.address?.unitNo}`, path: `/leadGen/occupantDetails/${id}` }),
      );
    }
  }, [id, occupantsData]);

  useEffect(() => {
    if (isEmpty(detailedPropertyData)) return;
    setPropertyDetails(detailedPropertyData);
  }, [detailedPropertyData]);

  const formatUnitTimelineObj = (obj) => {
    let unitTimelines = obj?.map((item) => {
      return {
        id: item?._id,
        title: dayjs(item?.registrationDate).format('MMM YYYY'),
        status: 'wait',
        description: (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text style={{ fontWeight: '800' }}>
              {item?.isPerson ? item?.purchaserInfo?.[0]?.personName : item?.purchaserInfo?.[0]?.companyName}
            </Text>

            {item?.documentType != 'Sale' && item?.expiryDate && (
              <Text>Exp Date: {dayjs(item?.expiryDate).format('MMM YYYY')}</Text>
            )}
            <Text>Doc Name: {item?.documentType}</Text>
          </div>
        ),
      };
    });
    return unitTimelines;
  };
  const documentType = occupantDetails?.documentType ?? 'Awaited';

  return (
    <Fragment>
      <section
        className="occupantDtlsSection"
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: '#F8F8F8',
          gap: '10px',
        }}
      >
        <Card style={{ flex: '1.2' }}>
          <Space direction="vertical">
            <Title level={4} strong style={{ color: '#0081FC', fontSize: '24px', marginBottom: '0' }}>
              <span
                onClick={() => {
                  if (selectedNav !== 'ContactBook') {
                    dispatch(truncateBreadcrumbs(breadcrumbs?.[breadcrumbs.length - 2]?.name));
                    navigate(breadcrumbs?.[breadcrumbs.length - 2]?.path);
                    dispatch(setSelectedNav('LeadGen'));
                    return;
                  }
                  navigate('/leadGen/contactbook');
                }}
                style={{ cursor: 'pointer', verticalAlign: 'middle' }}
              >
                {'< '}
                <span style={{ fontSize: '16px', verticalAlign: 'middle', fontWeight: '600' }}>
                  {`${propertyDetails?.buildingName ?? 'Awaited'} - ${occupantDetails?.address?.unitNo ?? ''}`}
                </span>
              </span>
            </Title>
            <Text style={{ fontWeight: '900', fontSize: '15px' }}>Unit Details</Text>
            <Text style={{ fontSize: '13px', fontWeight: '600' }}>
              Building Name : {occupantDetails?.propertyInfo?.buildingName ?? 'Awaited'}
            </Text>
            <Text style={{ fontSize: '13px', fontWeight: '600', color: '#ffa300' }}>
              Floor No : {occupantDetails?.address?.floorNo ?? 'Awaited'}
            </Text>
            <Text style={{ fontSize: '13px', fontWeight: '600', color: '#ffa300' }}>
              Unit No : {occupantDetails?.address?.unitNo ?? 'Awaited'}
            </Text>
            <Text style={{ fontSize: '13px', fontWeight: '600' }}>
              Unit Status : {occupantDetails?.unitStatus ?? 'Awaited'}
            </Text>
            <Text style={{ fontSize: '13px', fontWeight: '600' }}>
              Unit Condition : {occupantDetails?.unitCondition ?? 'Awaited'}
            </Text>
            <Text style={{ fontSize: '13px', fontWeight: '600' }}>
              Locality : {occupantDetails?.propertyInfo?.addressDetail?.[0]?.locality ?? 'Awaited'}
            </Text>
            <Text style={{ fontSize: '13px', fontWeight: '600' }}>
              City : {occupantDetails?.propertyInfo?.addressDetail?.[0]?.city ?? 'Awaited'}
            </Text>
            <br />
            <Text style={{ fontWeight: '900', fontSize: '15px' }}>Transactional Details</Text>
            <Text className="text-size card--property-details" style={{ fontSize: '13px', fontWeight: '600' }}>
              Document Type : {documentType}
            </Text>
            {documentType === 'Rent' ? (
              <>
                <Text style={{ fontSize: '13px', fontWeight: '600' }}>
                  Registration Date :{' '}
                  {occupantDetails?.registrationDate
                    ? dayjs(occupantDetails?.registrationDate).format('DD/MM/YYYY')
                    : 'Awaited'}
                </Text>
                <Text style={{ fontSize: '13px', fontWeight: '600' }}>
                  Expiry Date :{' '}
                  {occupantDetails?.expiryDate ? dayjs(occupantDetails?.expiryDate).format('DD/MM/YYYY') : 'Awaited'}
                </Text>
                <Text style={{ fontSize: '13px', fontWeight: '600', color: '#ffa300' }}>
                  Chargeable Area :{' '}
                  {occupantDetails?.moreTransactionInfo?.[0]?.chargableArea
                    ? `${Number(occupantDetails?.moreTransactionInfo?.[0]?.chargableArea)?.toFixed(2)} Sqft`
                    : 'Awaited'}
                </Text>
                <Text style={{ fontSize: '13px', fontWeight: '600', color: '#ffa300' }}>
                  Start Rent :{' '}
                  {occupantDetails?.moreTransactionInfo?.[0]?.rentRate
                    ? `${Number(occupantDetails?.moreTransactionInfo?.[0]?.rentRate)?.toFixed(2)} Rs/sqft`
                    : 'Awaited'}
                </Text>
                <Text style={{ fontSize: '13px', fontWeight: '600', color: '#ffa300' }}>
                  Escalation :{' '}
                  {occupantDetails?.moreTransactionInfo?.[0]?.escalationInLicenseFees
                    ? `${occupantDetails?.moreTransactionInfo?.[0]?.escalationInLicenseFees} %`
                    : 'Awaited'}
                </Text>
                <Text style={{ fontSize: '13px', fontWeight: '600', color: '#ffa300' }}>
                  Current Rent Rate :{' '}
                  {occupantDetails?.moreTransactionInfo?.[0]?.currentRentRate
                    ? `${Number(occupantDetails?.moreTransactionInfo?.[0]?.currentRentRate)?.toFixed(2)} Rs/sqft`
                    : 'Awaited'}
                </Text>
              </>
            ) : documentType === 'Sale' ? (
              <>
                <Text style={{ fontSize: '13px', fontWeight: '600', color: '#ffa300' }}>
                  Chargeable Area : {occupantDetails?.moreTransactionInfo?.[0]?.chargableArea ?? 'Awaited'}
                </Text>
                <Text style={{ fontSize: '13px', fontWeight: '600', color: '#ffa300' }}>
                  Sale Rate :{' '}
                  {occupantDetails?.moreTransactionInfo?.[0]?.saleRate
                    ? Number(occupantDetails?.moreTransactionInfo?.[0]?.saleRate)?.toFixed(2)
                    : 'Awaited'}
                </Text>
              </>
            ) : null}
            <br />
            <Text style={{ fontWeight: '900', fontSize: '15px' }}>Parking</Text>
            <Text style={{ fontSize: '13px', fontWeight: '600' }}>
              Car Parking : {occupantDetails?.parkingInfoFourWheeler ?? 'Awaited'}
            </Text>
            <Text style={{ fontSize: '13px', fontWeight: '600' }}>
              2- wheeler Parking : {occupantDetails?.parkingInfoTwoWheeler ?? 'Awaited'}
            </Text>
          </Space>
        </Card>
        <Card style={{ flex: '2.2' }}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Text style={{ fontWeight: '900', fontSize: '15px' }}>Parties Involved</Text>
            <Card className="container-card">
              <div
                style={{
                  backgroundColor: '#F8F8F8',
                  padding: '0px !important',
                  border: 'none',
                }}
              >
                <Text style={{ fontWeight: '900', padding: '20px', fontSize: '15px' }}>
                  {occupantDetails?.documentType === 'Sale' ? 'Buyer' : 'Lessee'}
                </Text>
                {!occupantDetails?.buyerInfoIndividual?.length > 0 &&
                !occupantDetails?.sellerInfoCompany?.length > 0 &&
                occupantDetails?.buyerInfoCompany?.length > 0 ? (
                  <Fragment>
                    <Text
                      style={{
                        fontWeight: '900',
                        display: 'flex',
                        padding: '10px 20px 0px 20px',
                        fontSize: '14px',
                        marginTop: '10px',
                      }}
                    >
                      Name:
                    </Text>
                    {occupantDetails?.buyerInfoCompany?.map((el) => (
                      <Text style={{ fontWeight: '600', display: 'flex', padding: '2px 20px', fontSize: '13px' }}>
                        {el?.companyName}
                      </Text>
                    ))}
                    <Row
                      span={12}
                      style={{
                        gap: '20px',
                        padding: '20px 20px 0px 20px',
                      }}
                    >
                      <Col style={{ fontSize: '14px', fontWeight: '900' }} span={8}>
                        Representative Name:
                      </Col>
                      <Col style={{ fontSize: '14px', textAlign: 'left', fontWeight: '900' }} span={10}>
                        E-mail:
                      </Col>
                      <Col style={{ fontSize: '14px', fontWeight: '900' }} span={2}>
                        Contact:
                      </Col>
                    </Row>
                  </Fragment>
                ) : (
                  <Row
                    span={12}
                    style={{
                      gap: '20px',
                      padding: '10px 20px 0px 20px',
                      marginTop: '10px',
                    }}
                  >
                    <Col style={{ fontSize: '14px', fontWeight: '900' }} span={8}>
                      Name:
                    </Col>
                    <Col style={{ fontSize: '14px', textAlign: 'left', fontWeight: '900' }} span={10}>
                      E-mail:
                    </Col>
                    <Col style={{ fontSize: '14px', fontWeight: '900' }} span={2}>
                      Contact:
                    </Col>
                  </Row>
                )}
                {!occupantDetails?.buyerInfoIndividual?.length > 0 &&
                !occupantDetails?.sellerInfoCompany?.length > 0 &&
                occupantDetails?.buyerInfoCompany?.length > 0 &&
                occupantDetails?.buyerRepresentativeInfo?.length > 0
                  ? occupantDetails?.buyerRepresentativeInfo?.map((el, i) => (
                      <Row key={i} span={12} style={{ gap: '20px', padding: '2px 20px' }}>
                        <Col
                          style={{
                            fontSize: '13px',
                            inlineSize: '100%',
                            overflowWrap: 'break-word',
                            fontWeight: '600',
                          }}
                          span={8}
                        >
                          {el?.personName}
                        </Col>
                        <Col
                          style={{
                            fontSize: '13px',
                            inlineSize: '100%',
                            overflowWrap: 'break-word',
                            fontWeight: '600',
                          }}
                          span={10}
                        >
                          {el?.emailId}
                        </Col>
                        <Col style={{ fontSize: '13px', fontWeight: '600' }} span={2}>
                          {el?.contactNumber}
                        </Col>
                      </Row>
                    ))
                  : occupantDetails?.buyerInfoIndividual?.map((el, i) => (
                      <Row key={i} span={12} style={{ gap: '13px', padding: '2px 20px' }}>
                        <Col
                          style={{
                            fontSize: '13px',
                            inlineSize: '100%',
                            overflowWrap: 'break-word',
                            fontWeight: '600',
                          }}
                          span={8}
                        >
                          {el?.personName}
                        </Col>
                        <Col
                          style={{
                            fontSize: '13px',
                            inlineSize: '100%',
                            overflowWrap: 'break-word',
                            fontWeight: '600',
                          }}
                          span={10}
                        >
                          {el?.emailId}
                        </Col>
                        <Col style={{ fontSize: '13px', fontWeight: '600' }} span={2}>
                          {el?.contactNumber}
                        </Col>
                      </Row>
                    ))}
              </div>
              <div
                style={{
                  backgroundColor: '#0080fc',
                  borderBottomRightRadius: '10px',
                  borderBottomLeftRadius: '10px',
                  padding: '10px 0px',
                }}
              >
                <Text style={{ fontWeight: '900', padding: '20px', color: 'white', fontSize: '15px' }}>
                  {occupantDetails?.documentType === 'Sale' ? 'Seller' : 'Lessor'}
                </Text>
                {!occupantDetails?.sellerInfoIndividual?.length > 0 &&
                !occupantDetails?.buyerInfoCompany?.length > 0 &&
                occupantDetails?.sellerInfoCompany?.length > 0 ? (
                  <Fragment>
                    <Text
                      style={{ fontWeight: '900', display: 'flex', padding: '20px 20px 0px 20px', fontSize: '14px' }}
                    >
                      Name:
                    </Text>
                    {occupantDetails?.sellerInfoCompany?.map((el, i) => (
                      <Text
                        key={i}
                        style={{ fontWeight: '600', display: 'flex', padding: '2px 20px', fontSize: '13px' }}
                      >
                        {el?.companyName ?? 'Awaited'}
                      </Text>
                    ))}
                    <Row
                      span={12}
                      style={{
                        gap: '20px',
                        fontWeight: '600',
                        padding: '10px 20px 0px 20px',
                      }}
                    >
                      <Col style={{ fontSize: '14px', fontWeight: '900' }} span={8}>
                        Representative Name:
                      </Col>
                      <Col style={{ fontSize: '14px', textAlign: 'left', fontWeight: '900' }} span={10}>
                        E-mail:
                      </Col>
                      <Col style={{ fontSize: '14px', fontWeight: '900' }} span={2}>
                        Contact:
                      </Col>
                    </Row>
                  </Fragment>
                ) : (
                  <Row
                    span={12}
                    style={{ gap: '20px', fontWeight: '600', padding: '10px 20px 0px 20px', color: 'white' }}
                  >
                    <Col style={{ color: 'white', fontSize: '14px' }} span={8}>
                      Name:
                    </Col>
                    <Col style={{ color: 'white', fontSize: '14px' }} span={10}>
                      E-mail:
                    </Col>
                    <Col style={{ color: 'white', fontSize: '14px' }} span={2}>
                      Contact:
                    </Col>
                  </Row>
                )}
                {!occupantDetails?.sellerInfoIndividual?.length > 0 &&
                !occupantDetails?.buyerInfoCompany?.length > 0 &&
                occupantDetails?.sellerInfoCompany?.length > 0 &&
                occupantDetails?.sellerRepresentativeInfo?.length > 0
                  ? occupantDetails?.sellerInfoCompany?.map((el, i) => (
                      <Row key={i} span={12} style={{ gap: '20px', padding: '2px 20px', color: 'white' }}>
                        <Col
                          style={{ color: 'white', fontSize: '13px', inlineSize: '100%', overflowWrap: 'break-word' }}
                          span={8}
                        >
                          {el?.personName ?? 'Awaited'}
                        </Col>
                        <Col
                          style={{ color: 'white', fontSize: '13px', inlineSize: '100%', overflowWrap: 'break-word' }}
                          span={10}
                        >
                          {el?.emailId ?? 'Awaited'}
                        </Col>
                        <Col style={{ color: 'white', fontSize: '13px' }} span={2}>
                          {el?.contactNumber ?? 'Awaited'}
                        </Col>
                      </Row>
                    ))
                  : occupantDetails?.sellerInfoIndividual?.map((el, i) => (
                      <Row key={i} span={12} style={{ gap: '20px', padding: '2px 20px', color: 'white' }}>
                        <Col
                          style={{ color: 'white', fontSize: '13px', inlineSize: '100%', overflowWrap: 'break-word' }}
                          span={8}
                        >
                          {el?.personName ?? 'Awaited'}
                        </Col>
                        <Col
                          style={{ color: 'white', fontSize: '13px', inlineSize: '100%', overflowWrap: 'break-word' }}
                          span={10}
                        >
                          {el?.emailId ?? 'Awaited'}
                        </Col>
                        <Col style={{ color: 'white', fontSize: '13px' }} span={2}>
                          {el?.contactNumber ?? 'Awaited'}
                        </Col>
                      </Row>
                    ))}
              </div>
            </Card>
            {occupantDetails?.buyerInfoCompany?.length > 0 && occupantDetails?.sellerInfoCompany?.length > 0 && (
              <Card className="container-card">
                <div
                  style={{
                    backgroundColor: '#F8F8F8',
                    borderTopRightRadius: '10px',
                    borderTopLeftRadius: '10px',
                    padding: '10px 0px',
                  }}
                >
                  <Text style={{ fontWeight: '900', padding: '20px', fontSize: '15px', marginTop: '10px' }}>
                    {occupantDetails?.documentType === 'Sale' ? 'Buyer' : 'Lessee'}
                  </Text>
                  {occupantDetails?.buyerInfoCompany?.length > 0 && (
                    <Fragment>
                      <Text
                        style={{ fontWeight: '600', display: 'flex', padding: '10px 20px 0px 20px', fontSize: '14px' }}
                      >
                        Name:
                      </Text>
                      {occupantDetails?.buyerInfoCompany?.map((el, i) => (
                        <Text
                          key={i}
                          style={{ fontWeight: '600', display: 'flex', padding: '2px 20px', fontSize: '13px' }}
                        >
                          {el?.companyName ?? 'Awaited'}
                        </Text>
                      ))}
                      <Row
                        span={12}
                        style={{
                          gap: '20px',
                          fontWeight: '600',
                          padding: '20px',
                          paddingBottom: '0px',
                        }}
                      >
                        <Col style={{ fontSize: '14px' }} span={8}>
                          Representative Name:
                        </Col>
                        <Col style={{ fontSize: '14px', textAlign: 'left' }} span={10}>
                          E-mail:
                        </Col>
                        <Col style={{ fontSize: '14px' }} span={2}>
                          Contact:
                        </Col>
                      </Row>
                    </Fragment>
                  )}
                  {occupantDetails?.buyerRepresentativeInfo?.length > 0 &&
                    occupantDetails?.buyerRepresentativeInfo?.map((companyInfo, idx) => (
                      <Row key={idx} span={12} style={{ gap: '20px', padding: '2px 20px' }}>
                        <Col style={{ fontSize: '13px', inlineSize: '100%', overflowWrap: 'break-word' }} span={8}>
                          {companyInfo?.personName ?? 'Awaited'}
                        </Col>
                        <Col style={{ fontSize: '13px', inlineSize: '100%', overflowWrap: 'break-word' }} span={10}>
                          {companyInfo?.emailId ?? 'Awaited'}
                        </Col>
                        <Col style={{ fontSize: '13px' }} span={2}>
                          {companyInfo?.contactNumber ?? 'Awaited'}
                        </Col>
                      </Row>
                    ))}
                </div>
                <div
                  style={{
                    backgroundColor: '#0080fc',
                    borderBottomRightRadius: '10px',
                    borderBottomLeftRadius: '10px',
                    padding: '10px 0px',
                  }}
                >
                  <Text style={{ fontWeight: '900', padding: '20px', fontSize: '15px', color: 'white' }}>
                    {occupantDetails?.documentType === 'Sale' ? 'Seller' : 'Lessor'}
                  </Text>
                  {occupantDetails?.sellerInfoCompany?.length > 0 && (
                    <Fragment>
                      <Text
                        style={{
                          fontWeight: '600',
                          display: 'flex',
                          padding: '10px 20px 0px 20px',
                          fontSize: '14px',
                          color: 'white',
                        }}
                      >
                        Name:
                      </Text>
                      {occupantDetails?.sellerInfoCompany?.map((el, i) => (
                        <Text
                          key={i}
                          style={{
                            fontWeight: '600',
                            display: 'flex',
                            padding: '2px 20px',
                            fontSize: '13px',
                            color: 'white',
                          }}
                        >
                          {el?.companyName ?? 'Awaited'}
                        </Text>
                      ))}
                      <Row
                        span={12}
                        style={{
                          gap: '20px',
                          fontWeight: '600',
                          padding: '20px',
                        }}
                      >
                        <Col style={{ fontSize: '14px', fontWeight: '900', color: 'white' }} span={8}>
                          Representative Name:
                        </Col>
                        <Col
                          style={{ fontSize: '14px', textAlign: 'left', fontWeight: '900', color: 'white' }}
                          span={10}
                        >
                          E-mail:
                        </Col>
                        <Col style={{ fontSize: '14px', fontWeight: '900', color: 'white' }} span={2}>
                          Contact:
                        </Col>
                      </Row>
                    </Fragment>
                  )}
                  {occupantDetails?.sellerInfoCompany?.length > 0 &&
                    occupantDetails?.sellerRepresentativeInfo?.map((companyInfo, i) => (
                      <Row
                        key={i}
                        span={12}
                        style={{
                          gap: '20px',
                          padding: '2px 20px',
                          color: 'white',
                        }}
                      >
                        <Col style={{ fontSize: '13px', inlineSize: '100%', overflowWrap: 'break-word' }} span={8}>
                          {companyInfo?.personName ?? 'Awaited'}
                        </Col>
                        <Col style={{ fontSize: '13px', inlineSize: '100%', overflowWrap: 'break-word' }} span={10}>
                          {companyInfo?.emailId ?? 'Awaited'}
                        </Col>
                        <Col style={{ fontSize: '13px' }} span={2}>
                          {companyInfo?.contactNumber ?? 'Awaited'}
                        </Col>
                      </Row>
                    ))}
                </div>
              </Card>
            )}
          </Space>
        </Card>
        <Card className="companiesList">
          <Text style={{ fontWeight: '900', fontSize: '15px' }}>Unit Timeline</Text>
          <Steps
            type="navigation"
            current={currentValue}
            onChange={onChange}
            progressDot={customDot}
            direction="vertical"
            className="site-navigation-steps"
            items={unitTimelineDetails}
          />
        </Card>
      </section>
      {/* <div style={{ backgroundColor: '#fff', marginTop: '12px', display: 'flex', flexDirection: 'row' }}>
        <BarGraph data={data} />

        <PieChart item={item} />
      </div> */}
    </Fragment>
  );
};

export default OccupantDetailsCard;
