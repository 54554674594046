import React from 'react';

const GroupIcon = ({ color = '#003fab', width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32.555 34.625">
      <g id="Group_27715" data-name="Group 27715" transform="translate(-1197.4 -33.4)">
        <g id="social_media-14" data-name="social media-14" transform="translate(1192.88 30.66)">
          <path
            id="Path_18513"
            data-name="Path 18513"
            d="M21.5,54.386V51.931a6.311,6.311,0,0,0-6.311-6.311H11.431A6.311,6.311,0,0,0,5.12,51.931v2.455"
            transform="translate(0 -17.621)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.2"
          />
          <path
            id="Path_18514"
            data-name="Path 18514"
            d="M15.08,45.67s.548,2.275,2.38,2.275,2.187-2.275,2.187-2.275"
            transform="translate(-4.151 -17.642)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.2"
          />
          <circle
            id="Ellipse_1803"
            data-name="Ellipse 1803"
            cx="4.444"
            cy="4.444"
            r="4.444"
            transform="translate(8.765 17.402)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.2"
          />
          <path
            id="Path_18515"
            data-name="Path 18515"
            d="M30.98,46.222a6.322,6.322,0,0,1,6.2-5.132h3.762A6.311,6.311,0,0,1,47.252,47.4v2.455"
            transform="translate(-10.777 -15.733)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.2"
          />
          <path
            id="Path_18516"
            data-name="Path 18516"
            d="M40.76,41.13s.548,2.275,2.38,2.275,2.187-2.275,2.187-2.275"
            transform="translate(-14.853 -15.749)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.2"
          />
          <circle
            id="Ellipse_1804"
            data-name="Ellipse 1804"
            cx="4.444"
            cy="4.444"
            r="4.444"
            transform="translate(22.385 16.782) rotate(-22.39)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.2"
          />
          <path
            id="Path_18517"
            data-name="Path 18517"
            d="M22.862,12.343v2.117a2.533,2.533,0,0,1-2.531,2.531h-.776l.56,2.94-3.26-2.94H10.905A2.528,2.528,0,0,1,8.38,14.461V10.191A2.528,2.528,0,0,1,10.905,7.66H20"
            transform="translate(-1.359 -1.8)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.2"
          />
          <path
            id="Path_18518"
            data-name="Path 18518"
            d="M28.31,5.294v3.3a1.949,1.949,0,0,0,1.948,1.954h4.6l2.514,2.269-.432-2.269h.595a1.954,1.954,0,0,0,1.954-1.954v-3.3A1.954,1.954,0,0,0,37.531,3.34H30.258A1.956,1.956,0,0,0,28.31,5.294Z"
            transform="translate(-9.665)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.2"
          />
          <line
            id="Line_2105"
            data-name="Line 2105"
            x2="6.713"
            transform="translate(9.494 8.35)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.2"
          />
          <line
            id="Line_2106"
            data-name="Line 2106"
            x2="3.797"
            transform="translate(20.611 5.364)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.2"
          />
          <line
            id="Line_2107"
            data-name="Line 2107"
            x2="8.387"
            transform="translate(9.494 11.004)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.2"
          />
          <line
            id="Line_2108"
            data-name="Line 2108"
            x2="4.193"
            transform="translate(11.022 13.226)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.2"
          />
          <line
            id="Line_2109"
            data-name="Line 2109"
            x2="1.843"
            transform="translate(26.192 5.364)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.2"
          />
          <line
            id="Line_2110"
            data-name="Line 2110"
            x2="3.797"
            transform="translate(20.611 7.382)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.2"
          />
          <line
            id="Line_2111"
            data-name="Line 2111"
            x2="1.843"
            transform="translate(26.192 7.382)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.2"
          />
          <line
            id="Line_2112"
            data-name="Line 2112"
            y1="6.462"
            transform="translate(13.309 30.303)"
            fill="none"
            stroke="#3a3a3a"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="1.2"
          />
        </g>
      </g>
    </svg>
  );
};

export default GroupIcon;
