import { nanoid } from '@reduxjs/toolkit';
import { Card, Col, Divider, Empty, List, Rate, Row, Spin, Tooltip } from 'antd';
import { isEmpty, truncate } from 'lodash';
import { React, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import aminities from '../../../../../assets/images/Icon feather-home.svg';
import coreBadge from '../../../../../assets/images/Icon core-badge.svg';
import locationSVG from '../../../../../assets/images/address-location.svg';
import animationData from '../../../../../assets/images/PropertyAndComapnyLottieImage.json';
import bookmark from '../../../../../assets/images/bookmark.svg';
import bookmark_selected from '../../../../../assets/images/bookmark_selected.svg';
import LottieAnimation from '../../../../../universal/components/lottieAnimation/LottieAnimation';
import { populateAddressInfo } from '../../../../../utils/formatSearchData/Address';
import AmenitiesIconEnum from '../../../../../utils/referenceData/search/amenitiesIconEnum';
import { getSearchResults } from '../../../../features/searchSlice';
import SelectedPropertyDetailsSkeleton from '../../../propertyDetailsPage/components/ProperyDetails/SelectedPropertyDetailsSkeleton';
import KebabMenu from '../../../../components/kebabMenu/KebabMenu';
import Ready from '../../../../../assets/images/ready.svg';
import RaiseConcern from '../../../../../layout/universal/components/raiseConcern/RaiseConcern';
import UnderConstruction from '../../../../../assets/images/UnderConstruction.svg';
import '../../../searchPage/searchPage.scss';
import { useWindowSize } from '../../../../../utils/useWindowSize/useWindowSize';
import { setSelectedNav } from '../../../../features/universalSidenavSlice';
import { ShareModal } from '../../../../../utils/ShareModal/ShareModal';
// import { raiseConcern } from '../../../../universal/features/userSlice';
// import { doSubscribed,doUnSubscribed,} from '../../../../../universal/features/myAccountSlice';
import { useSubscriptionHandlers } from '../../../../../leadgen/services/subscribtionsHandler';
// import { useWindowSize } from '@uidotdev/usehooks';
import noData from '../../../../../assets/images/noData.svg';


const Property = ({ propertyData, onSaveCard, onDeleteCard }) => {
  const { searchQuery, pagination, hasMoreData, searchResultLoader } = useSelector((store) => store.search);
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showRaiseConcern, setShowRaiseConcern] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [showShareModal, setShowShareModal] = useState(null);
  const { handleSubscribedClick, handleUnSubscribedClick } = useSubscriptionHandlers();
  const { isAbove1020, isAbove1280, isAbove1320, isAbove1440 } = useWindowSize();
  // Function to show RaiseConcern
  const handleRaiseConcernClick = (propertyItem) => {
    setSelectedProperty(propertyItem);
    setShowRaiseConcern(true);
  };

  // Function to close RaiseConcern
  const handleCloseRaiseConcern = () => {
    setShowRaiseConcern(false);
    setSelectedProperty(null);
  };

  const loadMoreData = () => {
    dispatch(
      getSearchResults({
        body: {
          query: searchQuery?.body?.query,
          offset: pagination?.offset,
          limit: pagination?.limit,
        },
      }),
    );
  };

  const navigateToPropertyDetail = (e, id, page) => {
    e.stopPropagation();
    navigateTo(`/leadGen/propertyDetails/${id}?list=${page}`, { replace: true });
  };

  return (
    <div>
      {!isEmpty(propertyData) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            height: isAbove1020 ? '21px' : isAbove1320 ? '12px' : '17px',
            gap: '30px',
          }}
        >
          {/* <div style={{ fontWeight: '600', color: '#707070' }}>Search Results</div> */}
          <div
            style={{ color: '#9f9f9f', marginLeft: isAbove1020 ? '98px' : '126px', fontSize: isAbove1020 && '11px' }}
          >
            Your search result for Commercial property as{' '}
            <i style={{ color: '#48a4fc', fontWeight: '600' }}>{searchQuery?.body?.query}</i> and similar properties
          </div>
        </div>
      )}
      {/* ----------search card skeleton------- */}

      {isEmpty(propertyData) && searchResultLoader && (
        <div className="d-flex  flex-wrap g-10 jc-between">
          {[...new Array(20)]?.map(() => (
            <SelectedPropertyDetailsSkeleton className="searchCardSkeleton" key={nanoid()} />
          ))}
        </div>
      )}
      {/* ----------search card skeleton------- */}
      {!isEmpty(propertyData) ? (
        <div
          id="columnOneDiv"
          style={{ height: '80vh', overflow: 'auto', paddingBottom: '100px', marginTop: isAbove1440 ? '34px' : '14px' }}
          className={propertyData?.length < 3 ? 'columnOneDivNoWrap' : 'columnOneDiv'}
        >
          <InfiniteScroll
            dataLength={propertyData?.length}
            next={() => {
              loadMoreData();
            }}
            loader={
              <div className="d-flex jc-center">
                <Spin size={64} />
              </div>
            }
            // hasMore={hasMoreData}
            // endMessage={<Divider plain>It is all, nothing more </Divider>}
            scrollableTarget="columnOneDiv"
            hasMore={propertyData?.length >= 10 && hasMoreData}
            endMessage={
              propertyData?.length < 10 ? (
                <Divider plain>It is all, nothing more</Divider>
              ) : (
                <Divider plain>It is all, nothing more</Divider>
              )
            }
          >
            <List
              dataSource={propertyData}
              renderItem={(propertyItem) => (
                <List.Item key={nanoid()} style={{ borderBlockEnd: 'none', width: '100%', padding: 3 }}>
                  <div key={propertyItem?._id} className={'propertyListContainer__div  cursor-pointer'}>
                    <Card
                      onClick={() => {
                        navigateTo(`/leadGen/connectedDetails/${propertyItem?._id}`);
                        dispatch(setSelectedNav('LeadGen'));
                      }}
                      className="propertyListContainer__div__card"
                    >
                      <Row className="inside_row">
                        <div
                          style={{
                            position: 'absolute',
                            zIndex: 3,
                            marginLeft: isAbove1020 ? '8px' : isAbove1280 ? '7px' : '20px',
                            marginTop: isAbove1020 ? '-5px' : isAbove1280 ? '-5px' : '-8px',
                          }}
                        >
                          {propertyItem?.buildingStatus === 'Ready' && (
                            <img
                              src={Ready}
                              alt="Ready"
                              style={{
                                width: isAbove1020 ? '60%' : isAbove1280 ? '78%' : '100%',
                                height: isAbove1020 ? '50%' : isAbove1280 ? '78%' : '100%',
                              }}
                            />
                          )}
                          {propertyItem?.buildingStatus === 'Under Construction' && (
                            <img src={UnderConstruction} alt="Under Construction" />
                          )}
                        </div>
                        <Col
                          span={7}
                          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                          className="propertyListContainer__div__card__row__col__row__col"
                        >
                          {propertyItem?.assetsInfo?.[0]?.featuredImage ? (
                            <img
                              src={propertyItem.assetsInfo[0].featuredImage}
                              alt="Property Img"
                              className="propertyListContainer__div__card__row__col__row__col__img"
                            />
                          ) : (
                            <LottieAnimation height={70} width={100} animationData={animationData} loop={true} />
                          )}
                        </Col>
                        <Col span={16} className="d-flex d-column jc-between">
                          <div>
                            <div className="d-flex jc-between a-center mt-10 ">
                              <h5
                                // onClick={(e) => navigateToPropertyDetail(e, propertyItem?._id)}
                                style={{ color: '#0081FC' }}
                                className="text-underline propertyListContainer__div__card__row__col__row__col1__h5"
                              >
                                {propertyItem?.buildingName}
                              </h5>
                              <div className="d-flex">
                                <div>
                                  {!propertyItem?.isSaved && (
                                    <img
                                      src={bookmark}
                                      alt="bookmark"
                                      style={{
                                        visibility: !propertyItem?.isResourceLocked ? 'hidden' : 'visible',
                                        cursor: 'pointer',
                                        height: isAbove1020 ? '18px' : isAbove1280 ? '21px' : '25px',
                                        marginRight: '9px'
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onSaveCard(
                                          propertyItem?._id,
                                          propertyItem?.buildingName,
                                          'property',
                                          ['representativeInfo'],
                                          'commercial',
                                        );
                                      }}
                                    />
                                  )}
                                  {propertyItem?.isSaved && (
                                    <img
                                      src={bookmark_selected}
                                      alt="bookmark_selected"
                                      style={{
                                        visibility: !propertyItem?.isResourceLocked ? 'hidden' : 'visible',
                                        cursor: 'pointer',
                                        height: '25px',
                                        marginRight: '9px'
                                        // height: 21px;
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onDeleteCard(
                                          propertyItem?._id,
                                          propertyItem?.buildingName,
                                          'property',
                                          ['representativeInfo'],
                                          'commercial',
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                                <KebabMenu
                                  onShare={() => {
                                    const propertyId = propertyItem?._id;
                                    const url = new URL(
                                      `/leadGen/propertyDetails/${propertyId}`,
                                      window.location.origin,
                                    );
                                    url.searchParams.set('shareable', 'true');
                                    url.searchParams.set('list', 'occupants');
                                    setShowShareModal(url.toString());
                                  }}
                                  onRaiseConcern={() => handleRaiseConcernClick(propertyItem)}
                                  onSubscribed={() => handleSubscribedClick(propertyItem, 'commercialProperty')}
                                  onUnSubscribed={() => handleUnSubscribedClick(propertyItem, 'commercialProperty')}
                                  resourceId={propertyItem}
                                />
                              </div>
                            </div>
                            <div
                              className="propertyListContainer__div__card__row__col__row__col1__para"
                              style={{ color: '#313131' }}
                            >
                              <span>{propertyItem?.addressInfo?.[0]?.locality ?? ""}</span>,
                              {propertyItem?.addressInfo?.[0]?.city ?? propertyItem?.addressInfo?.[0]?.state ?? ""}
                            </div>
                            <Rate
                              allowHalf
                              disabled
                              defaultValue={
                                !isNaN(Number(propertyItem?.averageRating)) ? Number(propertyItem?.averageRating) : 0
                              }
                            />
                            <div className="d-flex  d-column g-5 mt-10">
                              <div className="d-flex g-5 a-center">
                                <img
                                  src={locationSVG}
                                  // style={{ marginTop: 3 }}
                                  alt="location"
                                  className="propertyListContainer__div__card__row1__col1__row__col__img"
                                />
                                <span className="propertyListContainer__div__card__row1__col1__row__col1__para">
                                  <Tooltip
                                    title={populateAddressInfo(propertyItem?.addressInfo?.[0])}
                                    overlayStyle={{
                                      zIndex: 1000000000,
                                      width: isAbove1020 ? 150 : isAbove1280 ? 180 : isAbove1440 ? 180 : undefined, // Adjust the width
                                      height: isAbove1020 ? 60 : isAbove1280 ? 46 : isAbove1440 ? 80 : undefined,
                                      fontSize: isAbove1020
                                        ? '8px'
                                        : isAbove1280
                                          ? '8px'
                                          : isAbove1440
                                            ? '11px'
                                            : undefined,
                                    }}
                                  >
                                    {truncate(populateAddressInfo(propertyItem?.addressInfo?.[0]), { length: 50 })}
                                  </Tooltip>
                                </span>
                              </div>
                              <div className="d-flex g-5 a-center">
                                <img
                                  src={coreBadge}
                                  alt="coreBadge"
                                  className="propertyListContainer__div__card__row1__col1__row__col__img"
                                  style={{ marginLeft: '-2px' }}
                                />
                                <p className="propertyListContainer__div__card__row1__col1__row__col1__para">
                                  <b>Grade of building :</b> {propertyItem?.gradeOfBuilding || 'Awaited'}
                                </p>
                              </div>
                              <div
                                className="d-flex g-5 a-center"
                                style={{ alignItems: 'flex-start', marginTop: '1px' }}
                              >
                                <img
                                  src={aminities}
                                  alt="aminities"
                                  className="propertyListContainer__div__card__row1__col1__row__col__img"
                                  style={{ marginLeft: '-2px' }}
                                />
                                <div
                                  className="propertyListContainer__div__card__row1__col1__row__col1__para"
                                  style={{ width: '100%' }}
                                >
                                  <p className="bold">Amenities: </p>
                                  <div
                                    style={{
                                      display: 'inline-flex',
                                      justifyContent: 'space-between',
                                      width: isAbove1020 ? '94%' : '100%',
                                      marginTop: isAbove1020 ? '2px' : '10px',
                                    }}
                                  >
                                    {propertyItem?.amenities && (
                                      <div
                                        className="propertyListContainer__div__card__row1__col1__div"
                                        style={{
                                          maxHeight: '200px',
                                          display: 'flex',
                                          width: '165px',
                                          // width:
                                          //   propertyItem?.amenities && propertyItem?.amenities?.length < 4
                                          //     ? 'auto'
                                          //     : '165',
                                        }}
                                      >
                                        {propertyItem?.amenities &&
                                          propertyItem?.amenities?.length > 0 &&
                                          propertyItem?.amenities?.slice(0, 4)?.map((item, index) => {
                                            return (
                                              <Col
                                                span={
                                                  propertyItem?.amenities && propertyItem?.amenities?.length < 4 ? 6 : 4
                                                }
                                                className="propertyListContainer__div__card__row1__col1__div__col"
                                                style={{ flex: index === 0 ? '1 1 auto' : '1 1 auto' }}
                                              >
                                                {isEmpty(AmenitiesIconEnum?.[item?.toLowerCase()]) && (
                                                  <Tooltip
                                                    title={'Default'}
                                                    overlayStyle={{
                                                      width: isAbove1020 && 'auto', // Adjust the width
                                                      // height: isAbove1020 && 20,
                                                      // fontSize: isAbove1020 && '8px',
                                                    }}
                                                  >
                                                    <img
                                                      src={AmenitiesIconEnum?.['DEFAULT']}
                                                      className="propertyListContainer__div__card__row1__col1__div__col__img"
                                                    />
                                                  </Tooltip>
                                                )}
                                                {!isEmpty(AmenitiesIconEnum?.[item?.toLowerCase()]) && (
                                                  <Tooltip
                                                    title={item}
                                                    overlayStyle={{
                                                      zIndex: 1000000000,
                                                      width: isAbove1020 || isAbove1280 || isAbove1440 || 100, // Adjust the width
                                                      height: isAbove1020
                                                        ? 20
                                                        : isAbove1280
                                                          ? 22
                                                          : isAbove1440
                                                            ? 29
                                                            : undefined,
                                                      fontSize: isAbove1020
                                                        ? '8px'
                                                        : isAbove1280
                                                          ? '9px'
                                                          : isAbove1440
                                                            ? '12px'
                                                            : undefined,
                                                    }}
                                                  >
                                                    <img
                                                      src={AmenitiesIconEnum?.[item?.toLowerCase()]}
                                                      className="propertyListContainer__div__card__row1__col1__div__col__img"
                                                    />
                                                  </Tooltip>
                                                )}
                                              </Col>
                                            );
                                          })}
                                      </div>
                                    )}
                                  
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="bottomRow">
                            <span
                              onClick={(e) => {
                                navigateToPropertyDetail(e, propertyItem?._id, 'vacantUnits');
                                dispatch(setSelectedNav('LeadGen'));
                              }}
                              className={`property-hover property-hover-border propertyListContainer__div__card__row1__col1__row__col1__para1 cursor-pointer`}
                            >
                              {`${propertyItem?.vacantUnitCount ?? 0} Unit Available`}
                            </span>
                            <h3
                              onClick={(e) => {
                                navigateToPropertyDetail(e, propertyItem?._id, 'occupants');
                                dispatch(setSelectedNav('LeadGen'));
                              }}
                              className="property-hover property-hover-border propertyListContainer__div__card__row1__col1__row__col1__para1"
                            >
                              View All Transaction
                            </h3>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div>
      ) : (
        <div
          className='d-flex jc-center ai-center h-90vh'
        >
          <Empty
            image={noData}
            className='empty-image'
            description={
              <span className="custom-description font18 font600">
                No Data
              </span>
            } />
        </div>
      )}

      {showShareModal && (
        <ShareModal shareUrl={showShareModal} showShareModal={showShareModal} setShowShareModal={setShowShareModal} />
      )}
      {showRaiseConcern && (
        <RaiseConcern
          resourceDetails={{
            resourceType: 'commercial',
            resourceSubType: 'Property',
            resourceName: selectedProperty?.buildingName,
            resourceId: selectedProperty?._id,
          }}
          onClose={handleCloseRaiseConcern}
        />
      )}
    </div>
  );
};

export default Property;
