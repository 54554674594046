import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./propertyDetailPage.scss";

import { useParams, useSearchParams } from "react-router-dom";
import {
  addBreadcrumb,
  clearBreadcrumbs,
  deleteResources,
  saveResources,
  setDetailedOrganizationData,
  setSelectedConnectId,
  showSearchBar,
} from "../../features/searchSlice";
import Wishlist from "../searchPage/components/wishlist/Wishlist";
import CompanyDetails from "./components/CompanyDetails/CompanyDetails";
import EmployeesDetails from "./components/EmployeesDetails/EmployeesDetails";
import PropertyDetails from "./components/ProperyDetails/PropertyDetails";
import Search_assets from "../../../assets/images/search-assets.svg";

const ReversePropertyDetailsPage = () => {
  // hooks
  const dispatch = useDispatch();

  // params
  const { connectId } = useParams();

  // states
  const {
    connectsData,
    propertyData,
    detailedPropertyData,
    organizationsData,
    wishlist,
    searchQuery,
    loading,
    selectedOrganizationId,
    selectedConnectId,
  } = useSelector((state) => state.search);

  const [params] = useSearchParams();
  const searchQueries = params?.get("shareable");

  useEffect(() => {
    if(loading) return
    dispatch(showSearchBar(true));
    dispatch(
      addBreadcrumb({
        name: `Connected Cards`,
        path: `/leadGen/reverse/connectedDetails/${connectId}`,
      })
    );
    if (connectId) {
      dispatch(setSelectedConnectId(connectId));
      dispatch(setDetailedOrganizationData([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchQueries) {
      dispatch(clearBreadcrumbs());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQueries]);

  const onDeleteCard = (
    resourceId,
    resourceName,
    cardType,
    unlockedFields,
    resourceType
  ) => {
    dispatch(
      deleteResources({
        body: {
          payload: [
            {
              name: resourceName,
              resourceId: resourceId,
              resourceType,
              resourceSubType: cardType,
              unlockedFields,
            },
          ],
        },
      })
    );
  };

  const onSaveCard = (
    resourceId,
    resourceName,
    cardType,
    unlockedFields,
    resourceType
  ) => {
    dispatch(
      saveResources({
        body: {
          payload: [
            {
              name: resourceName,
              resourceId: resourceId,
              resourceType,
              resourceSubType: cardType,
              unlockedFields,
            },
          ],
        },
      })
    );
  };

  return (
    <>
      <div className="container">
        <div className="propertydetail-container">
          <div
            style={{
              width: "100%",
              borderRadius: "8px",
              position: "relative",
              zIndex: 1,
            }}
          >
            <div className="d-flex w-100 g-20">
              <EmployeesDetails
                loader={loading}
                connectsData={connectsData}
                onSaveCard={onSaveCard}
                onDeleteCard={onDeleteCard}
                isConnectPage
              />
              <CompanyDetails
                loader={loading}
                organizationsData={organizationsData}
                onSaveCard={onSaveCard}
                onDeleteCard={onDeleteCard}
                connectData={
                  connectsData?.filter((elem, index) =>
                    selectedConnectId
                      ? elem?._id === selectedConnectId
                      : index === 0
                  )?.[0]
                }
                selectedOrganizationId={selectedOrganizationId}
                isReverseFlow
              />
              <PropertyDetails
                searchQuery={searchQuery}
                loader={loading}
                companyData={
                  organizationsData?.filter((elem, index) =>
                    selectedOrganizationId
                      ? elem?._id === selectedOrganizationId
                      : index === 0
                  )?.[0]
                }
                propertyData={propertyData}
                selectedPropertyData={detailedPropertyData}
                onSaveCard={onSaveCard}
                onDeleteCard={onDeleteCard}
                organizationsName={organizationsData?.[0]?.companyName || ""}
                isReverseFlow
              />
            </div>
          </div>
          <div style={{ width: "100%", display: "contents" }}>
            <img
              src={Search_assets}
              alt="Search"
              style={{
                width: "70%",
                position: "absolute",
                objectFit: "cover",
                objectPosition: "center",
                top: "63%",
                opacity: 0.8,
              }}
            />
          </div>
        </div>

        <div
          style={{
            background: "white",
            width: "16%",
            borderRadius: "8px",
            height: "87vh",
          }}
        >
          <Wishlist wishlist={wishlist} />
        </div>
      </div>
    </>
  );
};

export default ReversePropertyDetailsPage;
