import { Card, Col, Row, Rate, List, Divider, Button, Spin, Tooltip, Empty } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, truncate } from 'lodash';
import { nanoid } from '@reduxjs/toolkit';
import ProspectImage from '../../../../components/prospectImage/ProspectImage';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getSearchResults, getPropertiesFromOrganization } from '../../../../features/searchSlice';
import bookmark from '../../../../../assets/images/bookmark.svg';
import { populateAddressInfo } from '../../../../../utils/formatSearchData/Address';
import bookmark_selected from '../../../../../assets/images/bookmark_selected.svg';
import LottieAnimation from '../../../../../universal/components/lottieAnimation/LottieAnimation';
import animationData from '../../../../../assets/images/PropertyAndComapnyLottieImage.json';
import { useNavigate } from 'react-router';
import KebabMenu from '../../../../components/kebabMenu/KebabMenu';
import RaiseConcern from '../../../../../layout/universal/components/raiseConcern/RaiseConcern';
import { ShareModal } from '../../../../../utils/ShareModal/ShareModal';
// import { doSubscribed, doUnSubscribed } from '../../../../../universal/features/myAccountSlice';
import { useSubscriptionHandlers } from '../../../../../leadgen/services/subscribtionsHandler';
import { setSelectedNav } from '../../../../features/universalSidenavSlice';
import { useWindowSize } from '../../../../../utils/useWindowSize/useWindowSize';
import SelectedPropertyDetailsSkeleton from '../../../propertyDetailsPage/components/ProperyDetails/SelectedPropertyDetailsSkeleton';
// import './organization.scss';
import noData from '../../../../../assets/images/noData.svg';
const Organization = ({ organizationsData, onDeleteCard, onSaveCard }) => {

  const [openOverflowMenu, setOpenOverflowMenu] = useState(false);
  const { searchQuery, pagination, hasMoreData, searchResultLoader } = useSelector((store) => store.search);
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const [showShareModal, setShowShareModal] = useState(null);
  const [showRaiseConcern, setShowRaiseConcern] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const { handleSubscribedClick, handleUnSubscribedClick } = useSubscriptionHandlers();
  const { isAbove1020, isAbove1280, isAbove1320, isAbove1440 } = useWindowSize();

  // Function to show RaiseConcern
  const handleRaiseConcernClick = (selectedOrg) => {
    setSelectedOrganization(selectedOrg);
    setShowRaiseConcern(true);
  };

  // Function to close RaiseConcern
  const handleCloseRaiseConcern = () => {
    setShowRaiseConcern(false);
    setSelectedOrganization(null);
  };

  //function to subscribe and unsubscribe an organization

  const getLockedArray = (org) => {
    return ['directorInfo', 'propertyEngagement'];
  };
  const loadMoreData = () => {
    dispatch(
      getSearchResults({
        body: {
          query: searchQuery?.body?.query,
          offset: pagination?.offset,
          limit: pagination?.limit,
        },
      }),
    );
  };

  return (
    <div>
      {!isEmpty(organizationsData) && (
        <div style={{ display: 'flex', justifyContent: 'flex-start', height: '30px', gap: '30px' }}>
          {/* <div style={{ fontWeight: '600', color: '#707070' }}>Search Results</div> */}
          <div style={{ color: '#9f9f9f', marginLeft: '126px' }}>
            Your search result for company as{' '}
            <i style={{ color: '#48a4fc', fontWeight: '600' }}>{searchQuery.body.query}</i> and similar companies
          </div>
        </div>
      )}

      {/* ----------search card skeleton------- */}

      {isEmpty(organizationsData) && searchResultLoader && (
        <div className="d-flex  flex-wrap g-10 jc-between">
          {[...new Array(20)]?.map(() => (
            <SelectedPropertyDetailsSkeleton className="searchCardSkeleton" key={nanoid()} />
          ))}
        </div>
      )}
      {/* ----------search card skeleton------- */}
      {!isEmpty(organizationsData) ? (
        <div
          id="columnOneDiv"
          style={{ height: '80vh', overflow: 'auto', paddingBottom: '100px', marginTop: isAbove1440 ? '34px' : '14px' }}
          className={organizationsData?.length < 3 ? 'columnOneDivNoWrap' : 'columnOneDiv'}
        >
          <InfiniteScroll
            dataLength={organizationsData?.length}
            next={() => {
              loadMoreData();
            }}
            loader={
              <div className="d-flex jc-center">
                <Spin size={64} />
              </div>
            }
            // hasMore={hasMoreData}
            // endMessage={<Divider plain>It is all, nothing more</Divider>}
            scrollableTarget="columnOneDiv"
            hasMore={organizationsData?.length >= 10 && hasMoreData}
            endMessage={
              organizationsData?.length < 10 ? (
                <Divider plain>It is all, nothing more</Divider>
              ) : (
                <Divider plain>It is all, nothing more</Divider>
              )
            }
          >
            <List
              dataSource={organizationsData}
              renderItem={(org) => (
                <List.Item key={nanoid()} style={{ borderBlockEnd: 'none', width: '100%', padding: '0px 0px' }}>
                  <div key={org} className="organizationListContainer__div cursor-pointer">
                    <Card
                      className="organizationListContainer__div__card"
                      key={nanoid()}
                      onClick={() => {
                        dispatch(getPropertiesFromOrganization(org?._id)).then((res) => {
                          navigateTo(`/leadGen/connectedDetails/${res?.payload?.[0]?._id}`);
                          dispatch(setSelectedNav('LeadGen'));
                        });
                      }}
                    >
                      <Row className="organizationListContainer__div__card__row">
                        <Col span={24} className="organizationListContainer__div__card__row__col">
                          <Row className="organizationListContainer__div__card__row__col__row" style={{ gap: '12px' }}>
                            <Col span={6} className="organizationListContainer__div__card__row__col__row__col">
                              {org?.otherCompanyInfo?.assetsInfo?.[0]?.featuredImage ? (
                                <ProspectImage
                                  imageUrl={org?.otherCompanyInfo?.assetsInfo?.[0]?.featuredImage}
                                  size={isAbove1020 ? 35 : isAbove1280 ? 40 : isAbove1440 ? 45 : 60}
                                  imgClassName="organizationListContainer__div__card__row__col__row__col__img"
                                />
                              ) : (
                                <div
                                  style={{
                                    border: '1px solid black',
                                    width: isAbove1020 ? '35px' : isAbove1280 ? '40px' : isAbove1440 ? '45px' : '60px',
                                    borderRadius: '50%',
                                    marginBottom: '17px',
                                  }}
                                >
                                  <LottieAnimation
                                    height={isAbove1020 ? 35 : isAbove1280 ? 40 : isAbove1440 ? 45 : 60}
                                    width={isAbove1020 ? 35 : isAbove1280 ? 40 : isAbove1440 ? 45 : 60}
                                    animationData={animationData}
                                    loop={true}
                                  />
                                </div>
                              )}
                            </Col>
                            <Col span={16} className="organizationListContainer__div__card__row__col__row__col1">
                              <h5
                                className="organizationListContainer__div__card__row__col__row__col1__h5"
                              // onClick={(e) => {
                              //   e.stopPropagation();
                              //   navigateTo(`/leadgen/organizationDetails/${org?._id}`);
                              // }}
                              >
                                <u
                                  style={{
                                    color: '#0081FC',
                                    fontSize: isAbove1020 ? '9px' : isAbove1280 ? '9px' : isAbove1440 ? '10px' : '0.8rem',
                                  }}
                                >
                                  {org?.companyName}
                                </u>
                              </h5>
                              <p className="organizationListContainer__div__card__row__col__row__col1__para">
                                {org?.companyType || ''}
                              </p>

                              <Rate allowHalf disabled defaultValue={org?.average_rating || 4} />
                            </Col>

                            <Col span={2} className="organizationListContainer__div__card__row__col__row__col2">
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {!org?.isSaved ? (
                                  <img
                                    src={bookmark}
                                    alt="bookmark"
                                    style={{
                                      visibility: !org?.isResourceLocked ? 'hidden' : 'visible',
                                      cursor: 'pointer',
                                      marginRight: '9px'
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onSaveCard(
                                        org?._id,
                                        org?.companyName,
                                        'organization',
                                        getLockedArray(org),
                                        'commercial',
                                      );
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={bookmark_selected}
                                    alt="bookmark_selected"
                                    style={{
                                      visibility: !org?.isResourceLocked ? 'hidden' : 'visible',
                                      cursor: 'pointer',
                                      marginRight: '9px'
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onDeleteCard(
                                        org?._id,
                                        org?.companyName,
                                        'organization',
                                        getLockedArray(org),
                                        'commercial',
                                      );
                                    }}
                                  />
                                )}

                                <KebabMenu
                                  onShare={() => {
                                    const companyId = org?._id;
                                    const url = new URL(`/leadGen/organizationDetails/${companyId}`, window.location.origin);
                                    url.searchParams.set('shareable', 'true');
                                    setShowShareModal(url.toString());
                                  }}
                                  onRaiseConcern={() => handleRaiseConcernClick(org)}
                                  onSubscribed={() => handleSubscribedClick(org, 'Company')}
                                  onUnSubscribed={() => handleUnSubscribedClick(org, 'Company')}
                                  resourceId={org}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="organizationListContainer__div__card__row__col__row1__col__row">
                            <Col
                              span={14}
                              className="organizationListContainer__div__card__row__col__row1__col__row__col"
                            >
                              <Row
                                className="organizationListContainer__div__card__row__col__row1__col__row__col__row"
                                style={{ display: 'block' }}
                              >
                                <h5 className="organizationListContainer__div__card__row__col__row1__col__row__col__h5">
                                  Registered Address:
                                </h5>
                                <p className="organizationListContainer__div__card__row__col__row1__col__row__col1__para">
                                  {populateAddressInfo(org?.companyAddresses?.[0])}
                                </p>
                              </Row>
                              <div
                                style={{
                                  height: '55px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginLeft: isAbove1280 ? '33px' : isAbove1440 ? '30px' : '125px',
                                  marginTop:
                                    populateAddressInfo(org?.companyAddresses?.[0]) != 'Awaited'
                                      ? '-80px'
                                      : isAbove1440
                                        ? '-80px'
                                        : '-65px',
                                }}
                              >
                                <Divider type="vertical" style={{ height: '100%', top: '40%', left: ' 68%' }} />
                              </div>
                            </Col>

                            <Col
                              style={{ marginTop: '-10px' }}
                              span={10}
                              className="organizationListContainer__div__card__row__col__row1__col1"
                            >
                              <Row className="organizationListContainer__div__card__row__col__row1__col1__row">
                                <h5 className="organizationListContainer__div__card__row__col__row1__col1__row__h5">
                                  Website:
                                </h5>
                                <span className="organizationListContainer__div__card__row__col__row1__col1__row__para">
                                  {org?.otherCompanyInfo?.websiteLink ? (
                                    <Tooltip
                                      title={org?.otherCompanyInfo?.websiteLink}
                                      overlayStyle={{ zIndex: 1000000000 }}
                                    >
                                      {truncate(org?.otherCompanyInfo?.websiteLink, { length: 20 })}
                                    </Tooltip>
                                  ) : (
                                    'Awaited'
                                  )}
                                </span>
                              </Row>
                              <Row className="organizationListContainer__div__card__row__col__row1__col1__row">
                                <h5 className="organizationListContainer__div__card__row__col__row1__col1__row__h5">
                                  Industry:
                                </h5>
                                <span className="organizationListContainer__div__card__row__col__row1__col1__row__para">
                                  {org?.industryType ? (
                                    <Tooltip
                                      title={org?.industryType}
                                      overlayStyle={{ zIndex: 1000000000 }}
                                    >
                                      {truncate(org?.industryType, { length: 20 })}
                                    </Tooltip>
                                  ) : (
                                    'Awaited'
                                  )}

                                </span>
                              </Row>
                              <Row className="organizationListContainer__div__card__row__col__row1__col1__row">
                                <h5 className="organizationListContainer__div__card__row__col__row1__col1__row__h5">
                                  Strength:
                                </h5>
                                <span className="organizationListContainer__div__card__row__col__row1__col1__row__para">

                                  {org?.otherCompanyInfo?.maximumNumberOfMembers ? (
                                    <Tooltip
                                      title={org?.otherCompanyInfo?.maximumNumberOfMembers}
                                      overlayStyle={{ zIndex: 1000000000 }}
                                    >
                                      {truncate(org?.otherCompanyInfo?.maximumNumberOfMembers, { length: 20 })}
                                    </Tooltip>
                                  ) : (
                                    'Awaited'
                                  )}
                                </span>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Divider style={{ marginLeft: '0px' }} />
                        <div style={{ margin: 'auto' }}>
                          <h3
                            onClick={(e) => {
                              e.stopPropagation();
                              navigateTo(`/leadGen/organizationDetails/${org?._id}`);
                              dispatch(setSelectedNav('LeadGen'));
                            }}
                            className="organisation-hover"
                          >
                            View More Details
                          </h3>
                        </div>
                      </Row>
                    </Card>
                  </div>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div>
      ) : (
        <div
          className='d-flex jc-center ai-center h-90vh'
        >
          <Empty
            image={noData}
            className='empty-image'
            description={
              <span className="custom-description font18 font600">
                No Data
              </span>
            } />
        </div>
      )}

      {showRaiseConcern && (
        <RaiseConcern
          resourceDetails={{
            resourceType: 'commercial',
            resourceSubType: 'Organization',
            resourceName: selectedOrganization?.companyName,
            resourceId: selectedOrganization?._id,
          }}
          onClose={handleCloseRaiseConcern}
        />
      )}
      {showShareModal && (
        <ShareModal shareUrl={showShareModal} showShareModal={showShareModal} setShowShareModal={setShowShareModal} />
      )}
    </div>
  );
};

export default Organization;
