import { Avatar, Divider, Rate } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import bookmark from '../../../../../assets/images/bookmark.svg';
import bookmark_selected from '../../../../../assets/images/bookmark_selected.svg';
import '../CompanyDetails/companyDetails.scss';
import KebabMenu from '../../../../components/kebabMenu/KebabMenu';
import RaiseConcern from '../../../../../layout/universal/components/raiseConcern/RaiseConcern';
import { ShareModal } from '../../../../../utils/ShareModal/ShareModal';
import ProspectImage from '../../../../components/prospectImage/ProspectImage';
import LottieAnimation from '../../../../../universal/components/lottieAnimation/LottieAnimation';
import animationData from '../../../../../assets/images/PropertyAndComapnyLottieImage.json';
import './employeeDetails.scss';
import { setIsRaiseModal } from '../../../../../universal/features/myAccountSlice';
const ConnectDetails = ({ connectsData, onDeleteCard, onSaveCard }) => {
  // hooks
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const [showShareModal, setShowShareModal] = useState(null);
  const [showRaiseConcern, setShowRaiseConcern] = useState(false);

  // Function to show RaiseConcern
  const handleRaiseConcernClick = () => {
    setShowRaiseConcern(true);
    dispatch(setIsRaiseModal(true));
  };
  // Function to close RaiseConcern
  const handleCloseRaiseConcern = () => {
    setShowRaiseConcern(false);
    dispatch(setIsRaiseModal(false));
  };

  return (
    <>
      {' '}
      <div key={connectsData?._id} className="position-relative" style={{ zIndex: '0' }}>
        <div className="connectCard">
          <div className=" employeeitem g-5 mt-10 flex-1">
            <div className="employeeitem-card d-flex jc-between a-center mt-5 ">
              <div className=" d-flex a-center g-10">
                {connectsData?.otherCompanyInfo?.assetsInfo?.[0]?.featuredImage ? (
                  <ProspectImage
                    imageUrl={connectsData?.otherCompanyInfo?.assetsInfo?.[0]?.featuredImage}
                    size={60}
                    imgClassName="organizationListContainer__div__card__row__col__row__col__img"
                  />
                ) : (
                  <div className="employeeitem-card__lottie">
                    <LottieAnimation height={60} width={60} animationData={animationData} loop={true} />
                  </div>
                )}
                <div>
                  <h5 className="text-underline employeeitem-card__empname">
                    {connectsData?.personalInfo?.personName || 'Awaited'}
                  </h5>
                  <p className="employeeitem-card__empcompany">{connectsData?.addititionalConnectInfo?.designation}</p>

                  <Rate
                    allowHalf
                    disabled
                    defaultValue={!isNaN(Number(connectsData?.averageRating)) ? Number(connectsData?.averageRating || 4) : 4}
                  />
                </div>
              </div>
              <div className="employeeitem-card__bookmark d-flex">
                {!connectsData?.isSaved && (
                  <img
                    src={bookmark}
                    alt="bookmark"
                    style={{
                      visibility: !connectsData?.isResourceLocked ? 'hidden' : 'visible',
                      cursor: 'pointer',
                      height: '25px',
                    }}
                    onClick={() => {
                      onSaveCard(
                        connectsData?._id,
                        connectsData?.personalInfo?.personName,
                        'connect',
                        ['personalInfo'],
                        'commercial',
                      );
                    }}
                  />
                )}
                {connectsData?.isSaved && (
                  <img
                    src={bookmark_selected}
                    alt="bookmark_selected"
                    style={{
                      visibility: !connectsData?.isResourceLocked ? 'hidden' : 'visible',
                      cursor: 'pointer',
                      height: '25px',
                    }}
                    onClick={() => {
                      onDeleteCard(
                        connectsData?._id,
                        connectsData?.personalInfo?.personName,
                        'connect',
                        [],
                        'commercial',
                      );
                    }}
                  />
                )}
                <span>
                  <KebabMenu
                    onShare={() => {
                      const connectId = connectsData?._id;
                      const url = new URL(`/leadGen/connectDetails/${connectId}`, window.location.origin);
                      url.searchParams.set('shareable', 'true');
                      setShowShareModal(url.toString());
                    }}
                    
                    onRaiseConcern={handleRaiseConcernClick}
                  />

                  {showShareModal && (
                    <ShareModal
                      shareUrl={showShareModal}
                      showShareModal={showShareModal}
                      setShowShareModal={setShowShareModal}
                    />
                  )}
                </span>
              </div>
            </div>
            <div className="employeediv-contanier">
              <div className="employeeitem-card__info">
                <div className="info-div d-flex g-10 a-center ">
                  <h5 className="info-heading">Designation :</h5>
                  <p className="info-desc"> {connectsData?.desigantion || 'Awaited'}</p>
                </div>
                <div className="d-flex g-20 a-center">
                  <h5 className="info-heading">Company :</h5>
                  <p className="info-desc">{connectsData?.personalInfo?.companyName || 'Awaited'}</p>
                </div>
              </div>

              <div className="employeeitem-card__inforight">
                <div className="d-flex g-10 a-center">
                  <h5 className="inforight-heading mb-3">Yrs of Exp :</h5>
                  <p className="inforight-desc">{connectsData?.yearOfExp ?? 'Awaited'}</p>
                </div>
                <div className="d-flex  g-10 a-center">
                  <h5 style={{ marginLeft: '7px' }} className="inforight-heading mb-3">
                    Industry :
                  </h5>
                  <p className="inforight-desc">{connectsData?.industry ?? 'Awaited'}</p>
                </div>
                <div className="d-flex g-10 a-center">
                  <h5 style={{ marginLeft: '8px' }} className="inforight-heading ">
                    Key Skill :
                  </h5>
                  <p className="inforight-desc">{connectsData?.keySkills ?? 'Awaited'}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="view-button">
            <span
              className="property-hover font10 cursor-pointer bold view-button__h3"
              onClick={() => navigateTo(`/leadGen/connectDetails/${connectsData?._id}`)}
            >
              View Details
            </span>
          </div>
          <Divider style={{ marginLeft: '0px' }} />
        </div>
      </div>
      {showRaiseConcern && (
        <RaiseConcern
          resourceDetails={{
            resourceType: 'commercial',
            resourceSubType: 'connect',
            resourceName: connectsData?.personalInfo?.personName || '',
            resourceId: connectsData?._id,
          }}
          onClose={handleCloseRaiseConcern}
        />
      )}
      {showShareModal && (
        <ShareModal shareUrl={showShareModal} showShareModal={showShareModal} setShowShareModal={setShowShareModal} />
      )}
    </>
  );
};

export default ConnectDetails;
