import { useEffect, useState } from 'react';

export const useWindowSize = () => {
  const [width, setWidth] = useState(1);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isAbove1920 = width >= 1920;

  const isAbove1320 = width > 1320 && width < 1920;
  const isAbove1440 = width > 1439 && width < 1800;
  const isAbove1280 = width > 1279 && width < 1367;
  const isAbove1020 = width >= 1020 && width < 1279;
  // const isAbove1020 = width > 1020 && width < 1279;
  const isMobile = width <= 767;

  return { isMobile, isAbove1920, isAbove1320, isAbove1020, isAbove1280, isAbove1440 };
};
