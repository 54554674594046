import { Typography, Button, Radio, Divider, Input, Form, Select, Slider, DatePicker, Flex, Checkbox, Col, Row, Space } from "antd";
import { useState } from "react";
import { LeftOutlined } from '@ant-design/icons';
import './RequirementForm.scss';

const RequirementForm = ({ currentState, setMode }) => {
    const { Title } = Typography;
    const { TextArea } = Input;
    const [current, setCurrent] = useState(currentState ?? 0);
    const [interested, setInterested] = useState("");
    const [form] = Form.useForm();

    const handleNext = () => {
        // form.validateFields().then(() => {
        if (current < 4) {
            setCurrent(current + 1);
        }
        // }).catch((errorInfo) => {
        //     console.log('Validation Failed:', errorInfo);
        // });
    };

    const handlePrevious = () => {
        if (current > 0) {
            setCurrent(current - 1);
        }
    };

    const handleSubmit = () => {
        form.validateFields().then(() => {
            console.log(form.getFieldsValue());
        }).catch((errorInfo) => {
            console.log('Validation Failed:', errorInfo);
        });
        setMode(false);
    }

    return (
        <div className="p-20 border-light-2 border-radius-8 mt-20 bgWhite">
            <Form form={form} layout="vertical">
                {current === 0 && (
                    <>
                        <Title level={2} >
                            Requirement
                        </Title>

                        <Flex vertical gap="middle" >
                            <Form.Item label={<label className="font18">Listing the property as:</label>} name="listingType" rules={[{ required: true, message: "Select a requirement type for property." }]}>
                                <Radio.Group block optionType="button" buttonStyle="solid" size="large" style={{ width: "25%" }}
                                    options={[{
                                        label: "Buy",
                                        value: "buy"
                                    }, {
                                        label: "Lease",
                                        value: "lease"
                                    }]} />
                            </Form.Item>
                        </Flex>
                        <Divider style={{ margin: '24px 0' }} />
                        <Flex vertical style={{ marginBottom: '12px' }}>
                            <Form.Item label={<label className="font18">
                                Interested in:</label>} name={"propertyType"} rules={[{ required: true, message: "Select a interest you are looking for" }]}>
                                <Radio.Group block size="large"
                                    options={[{
                                        label: "Commercial",
                                        value: "commercial"
                                    }, {
                                        label: "Residential",
                                        value: "residential"
                                    }]} onChange={(e) => setInterested(e.target.value)} />


                                {interested === "commercial" && (
                                    <Flex vertical gap="middle" >
                                        <div className="mt-10">
                                            <Form.Item name={"interestedIn"}>
                                                <Checkbox.Group
                                                    style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}
                                                    options={[
                                                        { label: "Office Spaces", value: "office" },
                                                        { label: "Retail Shops", value: "retail" },
                                                        { label: "Showroom", value: "showroom" },
                                                        { label: "Others", value: "others" }
                                                    ]}
                                                >
                                                    {({ label, value }) => (
                                                        <Checkbox value={value} className="font16">
                                                            {label}
                                                        </Checkbox>
                                                    )}
                                                </Checkbox.Group>
                                            </Form.Item>
                                        </div>
                                    </Flex>
                                )}
                                {interested === "residential" && (
                                    <div className="mt-10">
                                        <Form.Item name={"interestedIn"}>
                                            <Checkbox.Group
                                                style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}
                                                options={[
                                                    { label: "1RK", value: "1rk" },
                                                    { label: "1BHK", value: "1bhk" },
                                                    { label: "2BHK", value: "2bhk" },
                                                    { label: "Others", value: "others" }
                                                ]}
                                            >
                                                {({ label, value }) => (
                                                    <Checkbox value={value} className="font16">
                                                        {label}
                                                    </Checkbox>
                                                )}
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </div>
                                )}
                            </Form.Item>
                        </Flex>
                        <Divider style={{ margin: '24px 0' }} />
                    </>
                )}
                {current === 1 && (
                    <>
                        <Flex vertical gap={"middle"}>
                            <LeftOutlined className="fontBlue" onClick={handlePrevious} />
                            <Title level={3} >
                                Contact Details
                            </Title>
                        </Flex>
                        <Flex vertical gap="middle" className="mb-10">
                            <Form.Item name={"lookingFor"} label={<label className="font18">Looking for:</label>} rules={[{ required: true, message: "Select Looking for" }]}>
                                <Radio.Group size="large" name={"lookingFor"}
                                    options={[{
                                        label: "Yourself",
                                        value: "yourself"
                                    }, {
                                        label: "Relatives",
                                        value: "relatives"
                                    }, {
                                        label: "Friends",
                                        value: "friends"
                                    }]} />
                            </Form.Item>
                        </Flex>
                        <Row gutter={16} className="inputBox">
                            <Col span={8}>
                                <Form.Item label={<label className="font18">Name</label>} name="buildingName" rules={[{ required: true, message: 'Please enter the name' }]}>
                                    <Input placeholder="type here" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={<label className="font18">Email</label>} name="buildingEmail" rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}>
                                    <Input placeholder="type here" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={<label className="font18">Phone No.</label>} name="buildingPhone" rules={[{ required: true, message: 'Please enter the phone number' }]}>
                                    <Input placeholder="type here" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />
                        <Title level={3}>Property Details</Title>
                        <Row gutter={16} className="inputBox">
                            <Col span={8} >
                                <Form.Item label={<label className="font18">Preferred Location</label>} name="unitPreferredLocation" rules={[{ required: true, message: 'Please enter a location' }]}>
                                    <Input placeholder="Type here" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={<label className="font18"><span className="font14 fontError">*</span>Required Carpet Area</label>} className="mb-0">
                                    <Flex align="center" gap="small">
                                        <Form.Item name="unitRequiredCarpetArea" rules={[{ required: true, message: 'Please select the carpet area' }]} className="mb-0 flex-1">
                                            <Select defaultValue={"approx"} options={[
                                                { label: "In Between", value: "inBetween" },
                                                { label: "Approx", value: "approx" },
                                                { label: "Other", value: "other" },
                                            ]} className="w-100" />
                                        </Form.Item>
                                        <Form.Item name="unitRequiredCarpetAreaInput" rules={[{ required: true, message: 'Please enter the carpet area' }]} className="mb-0 flex-2">
                                            <Input placeholder="Approx Carpet Area" className="w-100" />
                                        </Form.Item>
                                        <Form.Item
                                            name="unitCarpetAreaUnit"
                                            rules={[{ required: true, message: 'Please select the carpet area unit' }]}
                                            className="mb-0 flex-1"
                                        >
                                            <Select
                                                defaultValue={"sqft"}
                                                options={[
                                                    { label: "Sq.ft", value: "sqft" },
                                                    { label: "Nos", value: "nos" },
                                                    { label: "Sq.m", value: "sqm" },
                                                    { label: "Other", value: "other" },
                                                ]}
                                                className="w-100"
                                            />
                                        </Form.Item>
                                    </Flex>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={<label className="font18"><span className="font14 fontError">*</span>Want Possession Around</label>} className="mb-0">
                                    <Flex align="center" gap="small">
                                        <Form.Item name="possessionAround" rules={[{ required: true, message: 'Please select the possession time' }]}>
                                            <Select options={[{
                                                label: "Before",
                                                value: "before"
                                            }, {
                                                label: "After",
                                                value: "after"
                                            }, {
                                                label: "On",
                                                value: "on"
                                            }]} defaultValue={"before"}
                                                placeholder="Before" />
                                        </Form.Item>
                                        <Form.Item name="possessionDate" >
                                            <DatePicker placeholder="DD-MM-YYYY" format="DD/MM/YYYY" />
                                        </Form.Item>
                                    </Flex>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider style={{ margin: '18px 0' }} />
                    </>
                )}
                {current === 2 && (
                    <div>
                        <LeftOutlined className="fontBlue mb-20" onClick={handlePrevious} />
                        <Row gutter={16}>
                            <Col span={4}>
                                <Form.Item name="budgetRange" label={<Title level={3}>Budget Range</Title>} rules={[{ required: true, message: 'Please select the budget range' }]}>
                                    <Flex gap={"middle"} align="center">
                                        <Input placeholder="Rs. 500/-" />
                                        <span>-</span>
                                        <Input placeholder="Rs. 1000/-" />
                                    </Flex>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Flex gap={"middle"} align="center" className="inputBox mt-15">
                                    <Form.Item name="expectedROI" label={<label className="font18">Expected ROI</label>} rules={[{ required: true, message: 'Please enter the expected ROI' }]}>
                                        <Input type="number" placeholder="Type here" />
                                    </Form.Item>
                                </Flex>
                            </Col>
                        </Row>

                        <Divider />
                        <Form.Item name="openToBreaker" label={<Title level={4}>Open To Brokers</Title>} rules={[{ required: true, message: 'Please select an option' }]}>
                            <Radio.Group options={[{
                                label: "Yes",
                                value: "yes"
                            }, {
                                label: "No",
                                value: "no"
                            }]} />
                        </Form.Item>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item label={<label className="font18">Required Amenities</label>} name="requiredAmenities" rules={[{ required: true, message: 'Please select the amenities' }]}>
                                    <Select mode="multiple" placeholder="Select Amenities" options={[
                                        { label: "Parking", value: "parking" },
                                        { label: "Lift", value: "lift" },
                                        { label: "Security", value: "security" },
                                        { label: "Others", value: "others" },
                                    ]} />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item label={<label className="font18">Any Additional Description</label>} name="description" >
                                    <TextArea placeholder="Description" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider style={{ margin: '18px 0' }} />
                    </div>
                )
                }
            </Form >
            <Flex justify="end" className="mt-20" >
                {current !== 2 && <Button size="large" type="primary" onClick={handleNext}>Next</Button>}
                {current === 2 && <Button size="large" type="primary" onClick={handleSubmit}>Submit</Button>}
            </Flex>
        </div >
    )
}

export default RequirementForm;