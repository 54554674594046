import { RightOutlined } from "@ant-design/icons";
import { nanoid } from "@reduxjs/toolkit";
import { Avatar, Divider, List } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { isEmpty } from "lodash";
import { setSelectedConnectId } from "../../../../features/searchSlice";
import "./employeeDetails.scss";
import animationData from "../../../../../assets/images/SelectedLottie.json";
import animationDataEmp from "../../../../../assets/images/PropertyAndComapnyLottieImage.json";
import LottieAnimation from "../../../../../universal/components/lottieAnimation/LottieAnimation";
import CustomRating from "../ProperyDetails/CustomRating";
import SimilarEmployeesSkeleton from "./skeletons/SimilarEmployeesSkeleton";
import { useWindowSize } from "../../../../../utils/useWindowSize/useWindowSize";

const SimilarEmployees = ({ companyData = [], loader }) => {
  const { isAbove1020, isAbove1280, isAbove1440 } = useWindowSize();
  const { hasMoreSimilarCompaniesData } = useSelector((store) => store.search);
  const dispatch = useDispatch();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const loadMoreData = () => {};
  return (
    <div
      style={{ overflow: "scroll" }}
      className="similarEmployeeContainer d-flex  g-10 d-column"
    >
      <div className="font14 font900 fontLight-3"> Other Similar Profiles</div>
      <div
        className="similarEmployeeScrollbar jc-center pr-10"
        style={{ maxHeight: 550, overflowY: "scroll" }}
      >
        {loader ? (
          [...new Array(5)]?.map(() => (
            <SimilarEmployeesSkeleton key={nanoid()} />
          ))
        ) : isEmpty(companyData) ? (
          <div className="lottie-div">
            <LottieAnimation
              height={120}
              width={120}
              animationData={animationData}
              loop={true}
            />
            <span className="bold lottie-div__text">
              We are finding right<br></br>{" "}
              <span className="bold text-middle">CONNECTS</span>
              <br></br>
              For you, Be with us !
            </span>
          </div>
        ) : (
          <InfiniteScroll
            dataLength={companyData?.length}
            next={() => {
              loadMoreData();
            }}
            hasMore={hasMoreSimilarCompaniesData}
            endMessage={<Divider plain>It is all, nothing more </Divider>}
            scrollableTarget="columnOneDiv"
          >
            <List
              dataSource={companyData}
              renderItem={(ele, index) => (
                <List.Item
                  key={nanoid()}
                  onClick={() => {
                    dispatch(setSelectedConnectId(ele?._id));
                  }}
                  style={{
                    borderBlockEnd: "none",
                    display: "block",
                    width: "100%",
                    padding: "0px",
                  }}
                >
                  <div
                    key={nanoid()}
                    className="d-flex a-center jc-between mt-15 p-10 similarPropertiesHover"
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <span className="d-flex g-10 jc-between">
                      {ele?.otherCompanyInfo?.assetsInfo?.[0]?.featuredImage ? (
                        <Avatar
                          className="companyAvatar"
                          src={
                            ele?.otherCompanyInfo?.assetsInfo?.[0]
                              ?.featuredImage
                          }
                        />
                      ) : (
                        <div
                          style={{
                            border: "1px solid black",
                            width: isAbove1020
                              ? "35px"
                              : isAbove1280
                              ? "40px"
                              : isAbove1440
                              ? "50px"
                              : "60px",
                            height: isAbove1020
                              ? "35px"
                              : isAbove1280
                              ? "40px"
                              : isAbove1440
                              ? "50px"
                              : "60px",
                            borderRadius: "50%",
                          }}
                        >
                          <LottieAnimation
                            height={'100%'}
                            width={'100%'}
                            animationData={animationDataEmp}
                            loop={true}
                          />
                        </div>
                      )}
                      <span className="d-flex d-column jc-between">
                        <span className="font12 font600 subTitleDiv">
                          {ele?.personalInfo?.personName}
                        </span>
                        <span className="font10 font400 subTitleDiv">
                          {ele?.additionalConnectInfo?.designation}
                        </span>
                        <CustomRating
                          rating={
                            ele?.averageRating &&
                            !isNaN(Number(ele?.averageRating))
                              ? Number(ele?.averageRating)
                              : 0
                          }
                          hovered={hoveredIndex === index}
                        />
                      </span>
                    </span>
                    <RightOutlined className="fontLight-2" />
                  </div>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

export default SimilarEmployees;
