import './myAccount.scss';

import { Tabs, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import CompanyDetailsTab from './companyDetails';
import Subscriptions from './subscriptions/index.jsx';
import FormDetails from './formDetails/FormDetails';
import LeadDetails from './leadDetails/';
import Preference from './perferences/Preferences';
import PersonalDetailsTab from './personalDetails';
import TransactionalDetails from './transactionalDetails/TransactionalDetails';

const MyAccount = () => {
  // Search params
  const [params, setSearchParams] = useSearchParams();
  const activeTabParam = params?.get('activeTab') || 'personal_details';
  const { isFormEditable, loading, isCompanyFormEditable } = useSelector((store) => store.myAccount);
  const { userPoints, userOnboardingData } = useSelector((store) => store.userOnboarding);
  const [activeAccountTab, setActiveAccountTab] = useState(activeTabParam);
  const { user, profileCompletion, userImgUrl, userV2 } = useSelector((store) => store.user);

  // setting active account tab if activeTab is recieved
  useEffect(() => {
    setActiveAccountTab(activeTabParam);
  }, [activeTabParam]);

  const onAccountTabChange = (key) => {
    setActiveAccountTab(key);
    setSearchParams({ activeTab: key });
  };

  const items = [
    {
      label: 'Personal Details',
      key: 'personal_details',
      children: (
        <PersonalDetailsTab
          user={user}
          userV2={userV2}
          userPoints={userPoints}
          loading={loading}
          isFormEditable={isFormEditable}
          profileCompletion={profileCompletion}
          userImgUrl={userImgUrl}
          activeAccountTab={activeAccountTab}
        />
      ),
    },
    {
      label: 'Transactional Details',
      key: 'transactional_details',
      children: <TransactionalDetails />,
    },
    {
      label: 'Preferences',
      key: 'preferences',
      children: <Preference _userPreferenceData={user?.preferences} offerings={userOnboardingData?.profileClassification?.profileClassification || ''}/>,
    },
    {
      label: (
        <Tooltip title={'List/Requirements'} placement="top">
          <span style={{ cursor: 'pointer' }}>List../Req..</span>
        </Tooltip>
      ),

      // label: 'List../Req..',
      key: 'form_details',
      children: <LeadDetails user={userV2} />,
    },
    {
      label: 'Company Details',
      key: 'company_details',

      children: (
        <CompanyDetailsTab
          userV2={userV2}
          loading={loading}
          isFormEditable={isCompanyFormEditable}
          activeAccountTab={activeAccountTab}
        />
      ),
    },
    {
      label: 'Subscriptions',
      key: 'subscriptions',

      children: <Subscriptions activeAccountTab={activeAccountTab} />,
    },
  ];

  return (
    <>
      <Tabs
        type="card"
        activeKey={activeAccountTab}
        onChange={onAccountTabChange}
        items={items}
        className="myAccountTabs"
      />
    </>
  );
};

export default MyAccount;
