import { Flex, Radio, Popover, Button } from 'antd';
import { FilterOutlined, PlusOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import ListingFilters from './filters/ListingsFilter';
import "./listing.scss"
import RequirementFilters from './filters/RequirementsFilter';
import Requirement from "./Requirements"
import Listings from "./Listing"

const MyLeads = ({ tabs, setTabs, setCreateMode }) => {
    const [propertyToggle, setPropertyToggle] = useState(false);
    const [open, setOpen] = useState(false);
    const tabOptions = [
        { label: "Buy/Rent In", value: "buyOrRentIn" },
        { label: "Sale/Rent Out", value: "saleOrRentOut" }
    ];

    useEffect(() => {
        setPropertyToggle(tabs === 0 ? "buyOrRentIn" : "saleOrRentOut");
    }, [tabs]);

    const handleTabChange = (e) => {
        const newValue = e.target.value;
        setPropertyToggle(newValue);
        setTabs(newValue === "buyOrRentIn" ? 0 : 1);
    };

    const handleAddClick = () => {
        setCreateMode(true);
    };

    return (
        <div>
            <div className="p-10" >
                <Flex align='center' justify='space-between'>
                    <div className="d-inline-flex">
                        <div className='boxBackground'>
                            <div className="ml-20 mt-20 mb-20 mr-20">
                                <Flex justify="space-around" gap="middle" align='center'>
                                    <Radio.Group
                                        block
                                        value={propertyToggle}
                                        options={tabOptions}
                                        buttonStyle='solid'
                                        optionType='button'
                                        onChange={handleTabChange}
                                    />
                                    <Popover
                                        open={open}
                                        onOpenChange={(open) => {
                                            setOpen(open);
                                        }}
                                        placement="bottomLeft"
                                        content={propertyToggle === "buyOrRentIn" ? <RequirementFilters setOpen={setOpen} /> : <ListingFilters setOpen={setOpen} />}
                                        trigger={'click'}
                                    >
                                        <Button icon={<FilterOutlined className='font18' />}>
                                            {open ? (
                                                <UpOutlined className='font10' />
                                            ) : (
                                                <DownOutlined className='font10' />
                                            )}
                                        </Button>
                                    </Popover>
                                </Flex>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Button type="primary" icon={<PlusOutlined />} onClick={handleAddClick}>
                            {propertyToggle === "buyOrRentIn" ? "Add Requirements" : "Add Listing"}
                        </Button>
                    </div>
                </Flex>
            </div>
            {propertyToggle === "buyOrRentIn" ? <Requirement /> : <Listings />}
        </div >
    );
};

export default MyLeads;
