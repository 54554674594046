export default function DeleteIconTrash({ strokeColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25" viewBox="0 0 21.8 24" className="trashIcon">
      <g id="Icon_feather-trash-2" data-name="Icon feather-trash-2" transform="translate(1 1)">
        <path
          id="Icon_feather-trash-2-2"
          data-name="Icon feather-trash-2"
          d="M4.5,7.4H24.3m-2.2,0V22.8A2.2,2.2,0,0,1,19.9,25H8.9a2.2,2.2,0,0,1-2.2-2.2V7.4m3.3,0V5.2A2.2,2.2,0,0,1,12.2,3h4.4a2.2,2.2,0,0,1,2.2,2.2V7.4m-6.6,5.5v6.6m4.4-6.6v6.6"
          transform="translate(-4.5 -3)"
          fill="none"
          stroke={strokeColor}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
}
