import { LeftOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { nanoid } from '@reduxjs/toolkit';
import { Button, Form, Input, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { socialAppCreatePost } from '../../../../../features/socialAppSlice';
import CommonButton from '../../../common/CommonButton';

const PollCardForm = ({
  setShowForm = () => {},
  setAllposts,
  closePopup = () => {},
  setActiveTab = () => {},
  isCompany,
  postRel = 'user_post',
  isPostFromMyAccount = false,
  groupId,
}) => {
  const MAX_OPTIONS = 5;

  const dispatch = useDispatch();
  const { Text } = Typography;

  // form derails
  const [formDetails] = Form.useForm();

  useEffect(() => {
    formDetails.setFieldsValue({
      options: [
        {
          title: null,
        },
        {
          title: null,
        },
      ],
    });
  }, []);
  // states

  const [loader, setLoader] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const userId = useSelector((state) => state.user?.user?.id);
  const userv2 = useSelector((state) => state.user?.userV2);
  const companyId = useSelector((state) => state.user?.userV2?.companyDetails?.companyId);
  const personalDetails = useSelector((store) => store?.user?.personalDetails);
  const companyName = useSelector((state) => state.user?.user?.professionalDetails?.companyName);

  // handle submit
  const handleFormSubmit = (values) => {
    const options = values.options || [];
    const hasValidOption = options.some((option) => option.title);
    if (!hasValidOption) {
      message.error('Please add at least one valid option.');
      return;
    }

    const createPostPayload = {
      title: values?.post_title || '',
      type: 'poll_card',
      ownerType: postRel,
      ownerId: postRel === 'company_post' ? companyId : userId,
      postDetails: {
        options: values?.options?.map((elem) => ({ ...elem, id: nanoid() })) || [],
      },
      isGroupPost: groupId || undefined,
    };

    setLoader(true);
    dispatch(socialAppCreatePost(createPostPayload))?.then((res) => {
      setLoader(false);
      if (res?.payload?.data?.response) {
        closePopup();
        setActiveTab(isCompany ? 'COMPANY_POST' : 'MY_POST');
        setShowForm(false);
        message.success('Post created successfully...');
        if (!isPostFromMyAccount) {
          const addedPost = {
            _id: res?.payload?.data?.response?.data?._id,
            createdAt: new Date(),
            type: 'poll_card',
            title: values?.post_title || '',
            body: values?.post_caption || '',
            ownerId: userId,
            ownerType: postRel,
            likes: 0,
            comments: 0,
            // imageUrls: [{ url: defaultImage, type: 'image' }],
            isOwner: true,
            postDetails: createPostPayload.postDetails,
            ownerDetails: {
              _id: userId,
              name: postRel === 'company_post' ? companyName : userv2?.name,
              email: userv2?.email,
              professionalDetails: userv2?.professionalDetails,
              personalDetails,
            },
          };
          setAllposts((prev) => ({
            ...prev,
            posts: [addedPost, ...(prev?.posts || [])],
            totalRecords: prev?.totalRecords + 1,
          }));

          // Clear any existing timeout to avoid removing the wrong item
          if (timeoutId) {
            clearTimeout(timeoutId);
          }

          //clear post after 3 seconds from all posts
          // const id = setTimeout(() => {
          //   setAllposts((prevItems) => ({
          //     ...prevItems,
          //     posts: prevItems?.posts?.slice(1) || [],
          //     totalRecords: prevItems?.totalRecords - 1,
          //   }));
          // }, 5000);

          // setTimeoutId(id);
        }
      } else {
        message.error('Some error occurred...');
      }
    });
  };

  //reset form
  const resetForm = () => {
    formDetails.resetFields();
  };

  // Cleanup timeout if the component unmounts before timeout completes
  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  //JSX
  return (
    <div className="genericpostform">
      {true && (
        <div className="d-flex a-center jc-between g-10">
          <div className="d-flex a-center">
            <LeftOutlined
              style={{ fontSize: 20, marginRight: 10, cursor: 'pointer', color: '#0081FC' }}
              onClick={() => setShowForm(false)}
            />
            <Text className="font18 fontDark font600">Create a Poll</Text>
          </div>

          {!isPostFromMyAccount && (
            <div>
              <CommonButton ghost size="large" onClick={resetForm} style={{ marginRight: 10 }}>
                Reset
              </CommonButton>
              <CommonButton loader={loader} type="primary" htmlType="submit" onClick={() => formDetails.submit()}>
                Create Post
              </CommonButton>
            </div>
          )}
        </div>
      )}
      <Form
        defaultValue={{
          post_title: null,
          options: [
            {
              title: null,
            },
            {
              title: null,
            },
          ],
        }}
        layout="vertical"
        form={formDetails}
        onFinish={handleFormSubmit}
      >
        <div className="mt-10">
          <div className={`d-flex ${isPostFromMyAccount && 'd-column'} `}>
            <div className={`formContainer ${isPostFromMyAccount && 'w-100'}`}>
              <Form.Item
                name="post_title"
                required
                label="Question"
                className="dropdown-form-item"
                rules={[
                  {
                    required: true,
                    message: 'Add Question!',
                  },
                ]}
              >
                <Input placeholder="Type your Question" min={0} />
              </Form.Item>
              <span className="font12 pl-20 fontLight">Options</span>

              <Form.List name="options">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <>
                        <Form.Item
                          {...restField}
                          name={[name, 'title']}
                          rules={[{ required: true, message: 'Please input an option' }]}
                          className="dropdown-form-item"
                        >
                          <Input
                            placeholder="Add option"
                            min={0}
                            addonAfter={<MinusCircleOutlined onClick={() => remove(name)} />}
                          />
                        </Form.Item>
                      </>
                    ))}
                    <Form.Item>
                      <Button
                        className="add-more-button"
                        style={{ display: 'flex', justifyContent: 'start', width: 'max-content' }}
                        type="link"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                        disabled={fields.length >= MAX_OPTIONS}
                      >
                        Add More
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          </div>
        </div>
        {isPostFromMyAccount && (
          <div className="d-flex a-center jc-end g-10">
            <div>
              <CommonButton ghost size="large" onClick={resetForm} style={{ marginRight: 10 }}>
                Reset
              </CommonButton>
              <CommonButton loader={loader} type="primary" htmlType="submit" onClick={() => formDetails.submit()}>
                Create Post
              </CommonButton>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
  //   return <>Hi</>;
};

export default PollCardForm;
