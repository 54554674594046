import { Badge, Button, Card, Col, Divider, Flex, Image, Row, Tag, Typography } from "antd";
import { LeftOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router';
import "./ListingDetails.scss";

const ListingDetails = () => {
    const { Title, Text } = Typography;

    const navigate = useNavigate();

    const userDetails = {
        Name: "Sayali",
        Email: "sayali@altrr.com",
        Phone: 8988555221
    }

    const propertyDetails = {
        "Building Name": "VB Capital",
        "City": "Pune",
        "State": "Maharashtra",
        "Pincode": "410445",
        "Longitude": "q1221",
        "Latitude": "193913",
        "Phone": "93-1i4931i",
        "Unit Type": "Office Spaces",
        "Area Type": "Chargeable",
        "Proposed Availability Date": "Lorem ipsum",
        "Unit No.": 8,
        "Unit Condition": "Furnished",
        "Area In Sq.Ft": "10.0000 sq.ft"
    }

    const additionalInformation = {
        "No. of rooms": 25,
        "Capactity": "Lorem Ipsum",
        "Expected Amount/Compensation": "Lorem Ipsum",
        "Rate": "Lorem Ipsum"
    }

    const otherInformation = {
        "Open To Broker": "Yes",
        "Amenities": "Broker",
        "Any Addition Listing": "Lorem Ipsum Lorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem Ipsum"
    }
    return (
        <div className="boxBackgroundListing">
            <Flex justify="space-between" className="mb-20">
                <Flex gap={"middle"} justify="center">
                    <Button className="back-button" icon={<LeftOutlined onClick={() => navigate(-1)} />} />
                    <Title level={4} className="m-0">Listing Details</Title>
                </Flex>
                <Button className="delete-button" icon={<DeleteOutlined />} danger />
            </Flex>
            <Row>
                <Col span={16}>
                    <Badge.Ribbon
                        placement="start"
                        text="Coworking"
                        color="blue"
                        className="fontWhite font16 "
                    >
                        <Card className="bgOrange boxShadow">
                            <Flex align="center">
                                <Flex align="center">
                                    <Text className="fontWhite mr-10 font16 font900" >Property for</Text>
                                    <Tag color="blue" className="font16 p-8">Rent</Tag>
                                </Flex>
                                <Flex align="center">
                                    <Text className="fontWhite mr-10 font16 font900">Property Type</Text>
                                    <Tag color="green" className="font16 p-8">Commercial</Tag>
                                </Flex>
                            </Flex>
                        </Card>
                    </Badge.Ribbon>
                </Col>
            </Row>
            <Row gutter={10}>
                <Col span={16}>
                    <div className="boxBackgroundListing mt-10">
                        <Title level={5}>Contact Details</Title>
                        {Object.entries(userDetails).map(([key, value]) => (
                            <Row gutter={10}>
                                <Col span={6}>
                                    <Text className="font900 fontExtraLight">{key}:</Text>
                                </Col>
                                <Col span={6}>
                                    <Text>{value}</Text>
                                </Col>
                            </Row>
                        ))}
                        <Divider />
                        <Title level={5}>Property Details</Title>

                        {Object.entries(propertyDetails).reduce((rows, [key, value], index, array) => {
                            if (index % 2 === 0) {
                                rows.push(
                                    <Row key={index}>
                                        <Col span={6}>
                                            <Text className="font900 fontExtraLight">{key}:</Text>
                                        </Col>
                                        <Col span={6}>
                                            <Text>{value}</Text>
                                        </Col>
                                        {index + 1 < array.length && (
                                            <>
                                                <Col span={6}>
                                                    <Text className="font900 fontExtraLight">{array[index + 1][0]}:</Text>
                                                </Col>
                                                <Col span={6}>
                                                    <Text>{array[index + 1][1]}</Text>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                );
                            }
                            return rows;
                        }, [])}

                        <Divider />
                        <Title level={5}>Additional Information</Title>
                        {Object.entries(additionalInformation).reduce((rows, [key, value], index, array) => {
                            if (index % 2 === 0) {
                                rows.push(
                                    <Row key={index}>
                                        <Col span={6}>
                                            <Text className="font900 fontExtraLight">{key} :</Text>
                                        </Col>
                                        <Col span={6}>
                                            <Text>{value}</Text>
                                        </Col>
                                        {index + 1 < array.length && (
                                            <>
                                                <Col span={6}>
                                                    <Text className="font900 fontExtraLight">{array[index + 1][0]}:</Text>
                                                </Col>
                                                <Col span={6}>
                                                    <Text>{array[index + 1][1]}</Text>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                );
                            }
                            return rows;
                        }, [])}

                        <Divider />
                        <Title level={5}>Other Information</Title>
                        {Object.entries(otherInformation).reduce((rows, [key, value], index, array) => {
                            if (index % 2 === 0) {
                                rows.push(
                                    <Row key={index}>
                                        <Col span={6}>
                                            <Text className="font900 fontExtraLight">{key} :</Text>
                                        </Col>
                                        <Col span={6}>
                                            <Text>{value}</Text>
                                        </Col>
                                        {index + 1 < array.length && (
                                            <>
                                                <Col span={6}>
                                                    <Text className="font900 fontExtraLight">{array[index + 1][0]}:</Text>
                                                </Col>
                                                <Col span={6}>
                                                    <Text>{array[index + 1][1]}</Text>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                );
                            }
                            return rows;
                        }, [])}
                    </div>
                </Col>
                <Col span={8}>
                    <div className="boxBackgroundListing mt-10">
                        <Title level={5}>
                            Upload Images, Links & Plans
                        </Title>
                        <Row gutter={[8, 8]}>
                            {[...Array(9)].map((_, index) => (
                                <Col span={8} key={index}>
                                    <Image
                                        src="https://images.adsttc.com/media/images/6636/c29f/c734/945c/42c2/96ed/newsletter/architecture-as-a-product-what-makes-a-building-worth-repeating_1.jpg?1714864858"
                                        alt={`Random ${index + 1}`}
                                        className="w-100 border-radius-10 obj-cover"
                                    />
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Col>
            </Row >
        </ div>
    )

}

export default ListingDetails