import { nanoid } from '@reduxjs/toolkit';
import { Col, Form, Radio, Row, Tag, Typography, Space, Divider, Input } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import OpenToBrokerForm from './UserTypeOfferingsLayout/common/OpenToBrokerForm';
import './offerings.scss';

const Offerings = ({ _IAmA, selectedIndustry, handleSelectOffering }) => {
  const { Text } = Typography;
  const { TextArea } = Input;
  const [selectedIAmI, setSelectedIAmI] = useState('');

  // navigation items for  I am a Broker/ seller etc
  const navItems = [
    { label: 'Broker', value: 'broker' },
    { label: 'Seller/Landlord', value: 'seller' },
    { label: 'Buyer/Tenant', value: 'buyer' },
    { label: 'Co-Working Operator', value: 'coworking' },
  ];

  useEffect(() => {
    if (isEmpty(_IAmA)) return;
    setSelectedIAmI(_IAmA);
  }, [_IAmA]);

  return (
    <div className="offeringcontainer">
      <Row>
        {selectedIndustry === 'Real Estate' ? (
          <Col>
            <Form.Item
              name="iAmI"
              label="I am a"
              rules={[
                {
                  required: true,
                  message: 'Please pick an item!',
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => {
                  handleSelectOffering(e?.target?.value);
                  setSelectedIAmI(e?.target?.value);
                }}
              >
                {navItems?.map((el) => {
                  return (
                    <Tag key={nanoid()} className="offeringcontainer-item">
                      <Radio.Button
                        value={el?.value}
                        style={{
                          background: selectedIAmI === el?.value ? '#3F51A3' : '#DEF0FF',
                          borderStyle: 'none',
                          borderRadius: '100px',
                          fontSize: '12px',
                          padding: '0px 30px 0px 30px',
                          height: '20px',
                          textAlign: 'center',

                          lineHeight: '1.5714285714285714',
                        }}
                      >
                        <Text
                          style={{
                            color: selectedIAmI === el?.value ? 'white' : '#548AD3',
                            fontSize: '12px',
                            margin: '0px',
                            padding: '0px',
                          }}
                        >
                          {el?.label}
                        </Text>
                      </Radio.Button>
                    </Tag>
                  );
                })}
              </Radio.Group>
            </Form.Item>
            <Divider />
            <Form.Item className="brokerrow-form">
              <Space className="brokerrow-form__space">
                <Text className="brokerrow-form__space--subTitle">Open to broker ?</Text>
                <Form.Item name="broker_open_to_broker" className="brokerrow-form__space--form">
                  <OpenToBrokerForm />
                </Form.Item>
              </Space>
            </Form.Item>
          </Col>
        ) : (
          <Col className="profile-description">
            <Text className="profile-description-text">
              Let us know you better, Give us more information about your profile.
            </Text>
            <Form.Item
              name="profileDescription"
              rules={[
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject(new Error('Please enter your Profile Description!'));
                    }
                    if (value.trim() === '') {
                      return Promise.reject(new Error('Profile Description cannot contain only spaces!'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <TextArea
                name="profileDescription"
                placeholder="Type here"
                autoSize={{ minRows: 6, maxRows: 6 }}
              />
            </Form.Item>

          </Col>
        )}
      </Row>
    </div>
  );
};

export default Offerings;
