import React, { useEffect } from 'react';
import './searchPage.scss';
import Property from './components/property/Property';
import Organization from './components/organization/Organization';
import Wishlist from './components/wishlist/Wishlist';
import Units from './components/units/Units';
import ResidentialProperty from './components/residentialProperty/ResidentialProperty';
import { useDispatch, useSelector } from 'react-redux';
import Search_assets from '../../../assets/images/search-assets.svg';
import { useWindowSize } from '../../../../src/utils/useWindowSize/useWindowSize';

import {
  clearBreadcrumbs,
  deleteResources,
  getWishlistContentWithPoints,
  saveResources,
  showSearchBar,
} from '../../features/searchSlice';
import Contact from './components/contacts/Connect';

const SearchPage = () => {
  // hooks
  const dispatch = useDispatch();
  const { isAbove1320, isAbove1020 } = useWindowSize();

  // states
  const {
    propertyData,
    residentialPropertyData,
    organizationsSearchData,
    connectsData,
    loading,
    wishlist,
    unitsData,
    searchContext,
  } = useSelector((store) => store.search);

  useEffect(() => {
    dispatch(showSearchBar(true));
    dispatch(getWishlistContentWithPoints());
    dispatch(clearBreadcrumbs());
    return () => {
      dispatch(showSearchBar(false));
    };
  }, []);

  const onDeleteCard = (resourceId, resourceName, cardType, unlockedFields, resourceType) => {
    dispatch(
      deleteResources({
        body: {
          payload: [
            {
              name: resourceName,
              resourceId: resourceId,
              resourceType,
              resourceSubType: cardType,
              unlockedFields,
            },
          ],
        },
      }),
    );
  };

  const onSaveCard = (resourceId, resourceName, cardType, unlockedFields, resourceType) => {
    dispatch(
      saveResources({
        body: {
          payload: [
            {
              name: resourceName,
              resourceId: resourceId,
              resourceType,
              resourceSubType: cardType,
              unlockedFields,
            },
          ],
        },
      }),
    );
  };

  const SEARCH_TYPE_VIEW = Object.freeze({
    commercialProperty: <Property propertyData={propertyData} onSaveCard={onSaveCard} onDeleteCard={onDeleteCard} />,
    residentialProperty: (
      <ResidentialProperty propertyData={residentialPropertyData} onSaveCard={onSaveCard} onDeleteCard={onDeleteCard} />
    ),
    organization: (
      <Organization organizationsData={organizationsSearchData} onSaveCard={onSaveCard} onDeleteCard={onDeleteCard} />
    ),
    connect: <Contact connectsData={connectsData} onSaveCard={onSaveCard} onDeleteCard={onDeleteCard} />,
    units: <Units unitsData={unitsData} onSaveCard={onSaveCard} onDeleteCard={onDeleteCard} />,
  });

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        // height: '88vh',
        gap: '12px',
        overflow: 'hidden',
      }}
    >
      <div className="cardsContainer" style={{ width: '85%', height: '100%', overflowX: 'scroll', height: '92vh' }}>
        <div className="cardsContainerInternal">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: isAbove1320 ? '-1px' : '10px',
              gap: '14px',
            }}
          ></div>

          <div style={{ width: '100%', borderRadius: '8px' }}>
            <div style={{ display: 'grid', flexDirection: 'row', width: '100%', gap: '10px' }}>
              <div
                style={{
                  fontWeight: '600',
                  color: '#707070',
                  position: 'absolute',
                  marginLeft: '8px',
                  fontSize: isAbove1020 && '12px',
                }}
              >
                Search Results 
              </div>
              <div style={{ zIndex: '2' }}>{SEARCH_TYPE_VIEW[searchContext]}</div>
              <div style={{ width: '100%', display: 'contents' }}>
                <img
                  src={Search_assets}
                  alt="Search"
                  style={{
                    width: '74%',
                    position: 'absolute',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    top: '61%',
                    opacity: 0.8,
                    zIndex: 0,
                    marginTop: '50px',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: '18%', borderRadius: '8px', zIndex: '1', height: isAbove1020 ? '83vh' : '91vh' }}>
        <Wishlist wishlist={wishlist} />
      </div>
    </div>
  );
};

export default SearchPage;
