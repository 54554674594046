import React from 'react';
import { Skeleton, Row, Col } from 'antd';
import './BuildingSkeletonLoader.scss'; // You can define custom styles here

const BuildingSkeletonLoader = ({floor}) => {
  // Simulating 3 floors with 5 rooms each

  return (
    <div className="grid-container">
      {[...Array(floor)].map((_, roomIndex) => (
        <div className='grid-item'>
          <Skeleton.Input active />
        </div>

      ))}

    </div>
  );
};

export default BuildingSkeletonLoader;
