import { Avatar, Rate, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import bookmark from '../../../../../assets/images/bookmark.svg';
import bookmark_selected from '../../../../../assets/images/bookmark_selected.svg';
import './companyDetails.scss';
import KebabMenu from '../../../../components/kebabMenu/KebabMenu';
import { populateAddressInfo } from '../../../../../utils/formatSearchData/Address';
import RaiseConcern from '../../../../../layout/universal/components/raiseConcern/RaiseConcern';
import { truncate } from 'lodash';
import { ShareModal } from '../../../../../utils/ShareModal/ShareModal';
import { useSubscriptionHandlers } from '../../../../../leadgen/services/subscribtionsHandler';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import animationData from '../../../../../assets/images/SelectedLottie.json';
import LottieAnimation from '../../../../../universal/components/lottieAnimation/LottieAnimation';
import animationDataEmp from '../../../../../assets/images/PropertyAndComapnyLottieImage.json';
import { useWindowSize } from '../../../../../utils/useWindowSize/useWindowSize';

const SelectedCompanyDetails = ({ loader, companyData, onDeleteCard, onSaveCard }) => {
  const { isAbove1020, isAbove1280, isAbove1440 } = useWindowSize();
  // hooks
  const navigateTo = useNavigate();
  const [showShareModal, setShowShareModal] = useState(null);
  const [showRaiseConcern, setShowRaiseConcern] = useState(false);
  // const companyData = {};
  const { handleSubscribedClick, handleUnSubscribedClick } = useSubscriptionHandlers();

  const { raiseModal } = useSelector((store) => store.myAccount);
  const [zIndexer, setZindexer] = useState(false);
  useEffect(() => {
    setZindexer(raiseModal);
  }, [raiseModal]);

  // Function to show RaiseConcern
  const handleRaiseConcernClick = () => {
    setShowRaiseConcern(true);
  };
  // Function to close RaiseConcern
  const handleCloseRaiseConcern = () => {
    setShowRaiseConcern(false);
  };

  return (
    <>
      {' '}
      <div key={companyData?._id} className="position-relative" style={{ zIndex: '0' }}>
        <div className="companyCard">
          {isEmpty(companyData) && !loader ? (
            <div className="company-imgdiv">
              <LottieAnimation height={120} width={120} animationData={animationData} loop={true} />
              <span className="bold company-imgdiv__text">
                We are finding right<br></br> <span className="bold text-middlecard">COMPANY</span>
                <br></br>
                For you, Be with us !
              </span>
            </div>
          ) : (
            <div>
              <div className=" g-5 mt-10 flex-1" style={{ marginBottom: '10px' }}>
                <div className="d-flex jc-between a-center mt-5 ">
                  <div className="d-flex a-center g-5">
                    {companyData?.otherCompanyInfo?.assetsInfo?.[0]?.featuredImage ? (
                      <Avatar
                        className="companyAvatar"
                        src={companyData?.otherCompanyInfo?.assetsInfo?.[0]?.featuredImage}
                      />
                    ) : (
                      <div
                        style={{
                          marginRight: '5px',
                          border: '1px solid black',
                          width: isAbove1020 ? '35px' : isAbove1280 ? '40px' : isAbove1440 ? '45px' : '60px',
                          height: isAbove1020 ? '35px' : isAbove1280 ? '40px' : isAbove1440 ? '45px' : '60px',
                          borderRadius: '50%',
                        }}
                      >
                        <LottieAnimation height={'100%'} width={'100%'} animationData={animationDataEmp} loop={true} />
                      </div>
                    )}
                    <div>
                      <h5
                        style={{ color: '#0081FC' }}
                        className="text-underline companyContainer__div__card__row__col__row__col1__h5 cursor-pointer"
                      >
                        {companyData?.companyName}
                      </h5>
                      <Rate
                        allowHalf
                        disabled
                        defaultValue={
                          !isNaN(Number(companyData?.averageRating)) ? Number(companyData?.averageRating) : 4
                        }
                      />
                    </div>
                  </div>
                  <div className="d-flex" style={{ marginTop: '-25px', justifyContent : 'center', alignItems : 'center' }}>
                    {!companyData?.isSaved && (
                      <img
                        src={bookmark}
                        alt="bookmark"
                        style={{
                          visibility: !companyData?.isResourceLocked ? 'hidden' : 'visible',
                          cursor: 'pointer',
                          height: '25px',
                          marginRight : '9px'
                        }}
                        onClick={() => {
                          onSaveCard(
                            companyData?._id,
                            companyData?.companyName,
                            'organization',
                            ['directorInfo', 'propertyEngagement'],
                            'commercial',
                          );
                        }}
                      />
                    )}
                    {companyData?.isSaved && (
                      <img
                        src={bookmark_selected}
                        alt="bookmark_selected"
                        style={{
                          visibility: !companyData?.isResourceLocked ? 'hidden' : 'visible',
                          cursor: 'pointer',
                          height: '25px',
                          marginRight : '9px'

                        }}
                        onClick={() => {
                          onDeleteCard(
                            companyData?._id,
                            companyData?.companyName,
                            'organization',
                            ['directorInfo', 'propertyEngagement'],
                            'commercial',
                          );
                        }}
                      />
                    )}
                    <span>
                      <KebabMenu
                        onShare={() => {
                          const companyId = companyData?._id;
                          const url = new URL(`/leadGen/organizationDetails/${companyId}`, window.location.origin);
                          url.searchParams.set('shareable', 'true');
                          url.searchParams.set('list', 'occupants');
                          setShowShareModal(url.toString());
                        }}
                        onRaiseConcern={handleRaiseConcernClick}
                        onSubscribed={() => handleSubscribedClick(companyData, 'CompanyDetails')}
                        onUnSubscribed={() => handleUnSubscribedClick(companyData, 'CompanyDetails')}
                        resourceId={companyData}
                      />

                      {showShareModal && (
                        <ShareModal
                          shareUrl={showShareModal}
                          showShareModal={showShareModal}
                          setShowShareModal={setShowShareModal}
                        />
                      )}
                    </span>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="d-flex w-50 d-column font10 " style={{ marginLeft: '5px' }}>
                    <div className="bold fontLight-2 mt-15">Registered Address :</div>
                    <div className="mt-15">{populateAddressInfo(companyData?.companyAddresses?.[0])}</div>
                  </div>
                  <div
                    className="mt-20"
                    style={{
                      backgroundColor: '#707070',
                      width: '1px' /* Adjust the width as needed */,
                      height: '75px',
                      marginRight: '15px',
                      marginBottom: '8px',
                    }}
                  ></div>
                  <div className="d-flex w-50 d-column font10 fontLight-2 mt-10 ">
                    <div className="fontLight-2  p-5">
                      <span className="bold"> Floor No : </span>{' '}
                      {companyData?.propertyEngagement?.floorNo || ' Awaited'}
                    </div>
                    <div className=" fontLight-2  p-5">
                      <span className="bold"> Unit No : </span>
                      <Tooltip title={companyData?.propertyEngagement?.unitNo}>
                        {truncate(companyData?.propertyEngagement?.unitNo, {
                          length: 30,
                        }) || ' Awaited'}
                      </Tooltip>
                    </div>
                    <div className=" fontLight-2  p-5">
                      <span className="bold">Industry : </span>
                      {companyData?.industryType || ' Awaited'}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottomRow  d-flex jc-center">
                <span
                  className="property-hover font10 cursor-pointer bold"
                  onClick={() => navigateTo(`/leadGen/organizationDetails/${companyData?._id}`)}
                >
                  View More Details
                </span>
              </div>
            </div>
          )}
        </div>
        <span className="connectorLine" style={{ width: zIndexer ? '25%' : '130%' }}>
          {[...new Array(200)]?.map(() => (
            <>&#x2022;</>
          ))}
        </span>
      </div>
      {showRaiseConcern && (
        <RaiseConcern
          resourceDetails={{
            resourceType: 'commercial',
            resourceSubType: 'Organization',
            resourceName: companyData?.companyName,
            resourceId: companyData?._id,
          }}
          onClose={handleCloseRaiseConcern}
        />
      )}
      {showShareModal && (
        <ShareModal shareUrl={showShareModal} showShareModal={showShareModal} setShowShareModal={setShowShareModal} />
      )}
    </>
  );
};

export default SelectedCompanyDetails;
