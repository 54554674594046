import { Radio } from 'antd';
import { useState } from 'react';
import RequirementForm from './RequirementForm';
import ListingForm from './ListingForm';
import MyLeads from "./listing/";

const Stepper = ({ user }) => {
    const [tabs, setTabs] = useState(0);
    const [createMode, setCreateMode] = useState(false);
    const handleTabChange = (e) => {
        setTabs(e.target.value);
    };

    return (
        <div style={{ marginTop: 20, marginLeft: 20, marginRight: '20px' }}>
            {createMode && <Radio.Group className='boxBackgroundListing'
                defaultValue={0}
                value={tabs}
                buttonStyle="solid"
                optionType="button"
                options={[
                    { label: "Requirement", value: 0 },
                    { label: "Listing", value: 1 }
                ]}
                onChange={handleTabChange}
            />}
            {createMode && tabs === 0 && <RequirementForm currentState={0} setMode={setCreateMode} />}
            {createMode && tabs === 1 && <ListingForm currentState={0} setMode={setCreateMode} />}
            {!createMode && <MyLeads tabs={tabs} setTabs={setTabs} setCreateMode={setCreateMode} />}
        </div>
    );
}


export default Stepper;