import React from 'react';
import CustomSkeleton from '../../../../../../universal/components/skeleton/CustomSkeleton';
import '../employeeDetails.scss';
import { nanoid } from '@reduxjs/toolkit';
const SimilarEmployeesSkeleton = () => {
  return (
    <div className="d-flex a-center jc-between mt-10 mb-10 border-light-2 p-5 border-radius-8 ">
      <span className="d-flex g-10 jc-between a-center">
        <CustomSkeleton width={30} height={30} />
        <span className="d-flex d-column jc-between">
          <CustomSkeleton width={'100%'} height={20} />
          <div className="d-flex g-5 mt-10">
            {[...new Array(5)]?.map(() => (
              <CustomSkeleton key={nanoid()} height={20} width={20} />
            ))}
          </div>
        </span>
      </span>
      <CustomSkeleton width={30} height={30} />
    </div>
  );
};

export default SimilarEmployeesSkeleton;
