import { LeftOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Flex, Form, Input, Row, Spin, Typography, Upload, message } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { socialAppCreatePost, uploadPostImage } from '../../../../../features/socialAppSlice';
import CommonButton from '../../../common/CommonButton';
import UploadedImage from '../../UploadedImage';
import { bytesToMB } from '../addPostForm.utils';
import ReactQuillComponent from '../common/ReactQuillComponent';
import SelectUploadedImages from '../SelectUploadedImages';
import './newsPostForm.scss';
import { uploadTypes, uploadTypesKeyMap } from './newsposts.utils';

const NewsPostForm = ({
  setAllposts = () => {},
  postRel = 'user_post',
  setShowForm = () => {},
  setActiveTab = () => {},
  isCompany,
  isPostFromMyAccount = false,
  groupId,
}) => {
  // hooks
  const dispatch = useDispatch();
  const { Text } = Typography;

  // states
  const [selectedUploadType, setSelectedUploadType] = useState('IMAGE');
  const companyId = useSelector((state) => state.user?.userV2?.companyDetails?.companyId);
  const userv2 = useSelector((state) => state.user?.userV2);
  const userId = useSelector((state) => state.user?.user?.id);
  const personalDetails = useSelector((store) => store?.user?.personalDetails);
  const companyName = useSelector((state) => state.user?.user?.professionalDetails?.companyName);

  // form derails
  const [formDetails] = Form.useForm();

  // states
  const [loader, setLoader] = useState(false);
  const [imgLoader, setImageloader] = useState(false);
  const [imgUrls, setImgUrls] = useState([]);
  const [imgNames, setImgNames] = useState([]);
  const [videoUrls, setVideoUrls] = useState([]);
  const [videoNames, setVideoNames] = useState([]);
  const [audioUrls, setAudioUrls] = useState([]);
  const [audioNames, setAudioNames] = useState([]);
  const [audioCoverUrls, setAudioCoverUrls] = useState([]);
  const [audioCoverNames, setAudioCoverNames] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);

  const typeMap = {
    image: {
      names: setImgNames,
      urls: setImgUrls,
      nameState: imgNames,
      urlState: imgUrls,
    },
    video: {
      names: setVideoNames,
      urls: setVideoUrls,
      nameState: videoNames,
      urlState: videoUrls,
    },
    audio: {
      names: setAudioNames,
      urls: setAudioUrls,
      nameState: audioNames,
      urlState: audioUrls,
    },
    audiocover: {
      names: setAudioCoverNames,
      urls: setAudioCoverUrls,
      nameState: audioCoverNames,
      urlState: audioCoverUrls,
    },
  };
  // handle image upload
  const handleUpload = async (file, type = 'image') => {
    if (bytesToMB(file?.size) <= 5) {
      const formData = new FormData();
      formData.append('files', file);
      setImageloader(type);
      dispatch(uploadPostImage(formData))?.then((res) => {
        setImageloader(false);
        message.success(`Image uploaded successfuly...`);
        if (res?.payload?.data?.response?.data) {
          const setterFuncNames = typeMap?.[type?.toLowerCase()]?.names;
          const setterFuncUrls = typeMap?.[type?.toLowerCase()]?.urls;
          setterFuncNames((prev) => [...(prev || []), file?.name]);
          setterFuncUrls((prev) => [...(prev || []), ...(res?.payload?.data?.response?.data || [])]);
        }
      });
    } else {
      message.error('File size should not exceed 5 MB');
    }
  };

  const createImageUrls = (e) => {
    let imageUrls = [];
    const urls = typeMap?.[selectedUploadType?.toLowerCase()]?.urlState;
    if (selectedUploadType == 'IMAGE') {
      imageUrls = urls?.map((elem) => ({
        url: elem,
        type: 'image',
      }));
    }
    if (selectedUploadType == 'VIDEO') {
      imageUrls = [
        ...urls?.map((elem) => ({
          url: elem,
          type: 'image',
        })),
        {
          url: e?.video_link,
          type: 'video',
        },
      ];
    }
    if (selectedUploadType == 'AUDIO') {
      imageUrls = [
        ...urls?.map((elem) => ({
          url: elem,
          type: 'image',
        })),
        {
          url: e?.audio_link,
          type: 'audio',
        },
      ];
    }
    return imageUrls;
  };

  // handle submit
  const handleFormSubmit = (e) => {
    const createPostPayload = {
      title: e?.post_headline || '',
      type: 'news_card',
      ownerType: postRel,
      ownerId: postRel === 'company_post' ? companyId : userId,
      imageUrls: createImageUrls(e),
      postDetails: {
        body: e?.post_description,
        // link: e?.link || undefined,
        date: e?.post_date,
        place: e?.post_places,
        publisherName: e?.post_news_publisher,
        category: e?.post_news_category,
        subType: selectedUploadType?.toLowerCase(),
      },
      isGroupPost: groupId || undefined,
    };
    setLoader(true);
    dispatch(socialAppCreatePost(createPostPayload))?.then((res) => {
      setLoader(false);
      if (res?.payload?.data?.response) {
        if (!isPostFromMyAccount) {
          const addedPost = {
            _id: res?.payload?.data?.response?.data?._id,
            type: 'news_card',
            createdAt: new Date(),
            title: e?.post_headline || '',
            body: e?.post_description || '',
            ownerId: userId,
            ownerType: postRel,
            postDetails: {
              body: e?.post_description || '',
              date: e?.post_date,
              place: e?.post_places,
              publisherName: e?.post_news_publisher,
              category: e?.post_news_category,
              subType: selectedUploadType?.toLowerCase(),
            },
            likes: 0,
            comments: 0,
            imageUrls: createImageUrls(e),
            isOwner: true,
            ownerDetails: {
              _id: userId,
              name: postRel === 'company_post' ? companyName : userv2?.name,
              email: userv2?.email,
              professionalDetails: userv2?.professionalDetails,
              personalDetails,
            },
          };
          setAllposts((prev) => ({
            ...prev,
            posts: [addedPost, ...(prev?.posts || [])],
            totalRecords: prev?.totalRecords + 1,
          }));

          // Clear any existing timeout to avoid removing the wrong item
          if (timeoutId) {
            clearTimeout(timeoutId);
          }

          //clear post after 3 seconds from all posts
          // const id = setTimeout(() => {
          //   setAllposts((prevItems) => ({
          //     ...prevItems,
          //     posts: prevItems?.posts?.slice(1) || [],
          //     totalRecords: prevItems?.totalRecords - 1,
          //   }));
          // }, 5000);

          // setTimeoutId(id);
        }
        message.success('Post created successfuly...');
        setShowForm(false);
        setActiveTab(isCompany ? 'COMPANY_POST' : 'MY_POST');
      } else {
        message.error('Some error occured...');
      }
    });
  };

  //reset from
  const resetForm = () => {
    formDetails.resetFields();
    setImgUrls([]);
    setImgNames([]);
    setVideoUrls([]);
    setVideoNames([]);
    setAudioUrls([]);
    setAudioNames([]);
    setAudioCoverUrls([]);
    setAudioCoverNames([]);
  };

  // Cleanup timeout if the component unmounts before timeout completes
  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return (
    <Form
      className="pt-20 newsPostFormContainer"
      style={{ flexGrow: 1 }}
      layout="vertical"
      form={formDetails}
      onFinish={handleFormSubmit}
    >
      {true && (
        <div className="d-flex a-center jc-between g-10">
          <div className="d-flex a-center">
            <LeftOutlined
              style={{ fontSize: 20, marginRight: 10, cursor: 'pointer', color: '#0081FC' }}
              onClick={() => setShowForm(false)}
            />
            <Text className="font18 fontDark font600">News</Text>
          </div>

          {!isPostFromMyAccount && (
            <div>
              <CommonButton ghost size="large" onClick={resetForm} style={{ marginRight: 10 }}>
                Reset
              </CommonButton>
              <CommonButton loader={loader} type="primary" htmlType="submit" onClick={() => formDetails.submit()}>
                Create Post
              </CommonButton>
            </div>
          )}
        </div>
      )}
      <div className={`mainContainerNewsPost g-5 mb-20 ${isPostFromMyAccount && 'd-column'}`}>
        <div className={`imageContainer ${isPostFromMyAccount && 'w-100'}`}>
          <Form.Item
            name={selectedUploadType}
            className="dropdown-form-item"
            rules={[
              {
                required: true,
                message: 'Please upload a file!',
              },
            ]}
          >
            <div className="d-flex d-column g-20">
              <Upload
                listType="picture-card"
                className="uploadImage"
                multiple
                showUploadList={false}
                accept={'.png,.jpg,.jpeg,.avif'}
                customRequest={({ file }) => handleUpload(file, uploadTypesKeyMap?.[selectedUploadType]?.type)}
              >
                {imgLoader == uploadTypesKeyMap?.[selectedUploadType]?.type ? (
                  <Spin indicator={<LoadingOutlined />} />
                ) : (
                  <>
                    <UploadOutlined style={{ fontSize: 28, paddingBottom: '1rem' }} />
                    <Text>{uploadTypesKeyMap?.[selectedUploadType]?.label}</Text>
                  </>
                )}
              </Upload>

              {typeMap?.[selectedUploadType?.toLowerCase()]?.nameState?.length > 0 && (
                <Form.Item name={selectedUploadType} className="m-0">
                  <UploadedImage
                    imgNames={typeMap?.[selectedUploadType?.toLowerCase()]?.nameState}
                    setImgNames={typeMap?.[selectedUploadType?.toLowerCase()]?.names}
                    imageUrls={typeMap?.[selectedUploadType?.toLowerCase()]?.urlState}
                    setImageUrls={typeMap?.[selectedUploadType?.toLowerCase()]?.urls}
                  />
                </Form.Item>
              )}
            </div>
          </Form.Item>
          <div className="d-flex g-10">
            {uploadTypes?.map((elem) => {
              const Icon = elem?.icon;
              return (
                <span
                  onClick={() => {
                    setSelectedUploadType(elem?.key);
                    const setterFuncNames = typeMap?.[selectedUploadType?.toLowerCase()]?.names;
                    const setterFuncUrls = typeMap?.[selectedUploadType?.toLowerCase()]?.urls;
                    setterFuncNames([]);
                    setterFuncUrls([]);
                  }}
                  className={`uploadIcon ${selectedUploadType == elem?.key && 'uploadIconSelected'}`}
                >
                  <Icon color={selectedUploadType == elem?.key ? '#0081fc' : '#b4b4b4'} />
                </span>
              );
            })}
          </div>
          <div className="w-100">
            <Form.Item required label="" className="dropdown-form-item">
              <Form.Item
                name={selectedUploadType}
                rules={[
                  {
                    required: true,
                    message: 'Select Image',
                  },
                ]}
              >
                <SelectUploadedImages
                  imageUrl={typeMap?.[selectedUploadType?.toLowerCase()]?.urlState}
                  setImgUrl={typeMap?.[selectedUploadType?.toLowerCase()]?.urls}
                  setImageNames={typeMap?.[selectedUploadType?.toLowerCase()]?.names}
                />
              </Form.Item>
            </Form.Item>
            {selectedUploadType != 'IMAGE' && (
              <Form.Item
                label={selectedUploadType == 'VIDEO' ? 'Youtube Link' : 'Audio Link'}
                className="dropdown-form-item"
              >
                <Flex gap="middle" horizontal>
                  <Form.Item
                    name={selectedUploadType == 'VIDEO' ? 'video_link' : 'audio_link'}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter valid url.',
                        pattern:
                          '^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$',
                      },
                    ]}
                  >
                    <Input placeholder="http://" style={{ width: '100%' }} min={0} />
                  </Form.Item>
                </Flex>
              </Form.Item>
            )}
          </div>
        </div>
        <div className={`formContainer ${isPostFromMyAccount && 'w-100'}`}>
          <Form.Item required label="Headline" className="dropdown-form-item">
            <Flex gap="middle" horizontal>
              <Form.Item
                name="post_headline"
                rules={[
                  {
                    required: true,
                    message: 'Add Headline!',
                  },
                ]}
              >
                <Input placeholder="Add Headline" style={{ width: '100%' }} min={0} />
              </Form.Item>
            </Flex>
          </Form.Item>

          <Form.Item required label="Description" className="dropdown-form-item">
            <Flex gap="middle" horizontal>
              <Form.Item
                name="post_description"
                rules={[
                  {
                    required: true,
                    message: 'Add Description!',
                  },
                ]}
              >
                <ReactQuillComponent placeholder="Write description here" />
              </Form.Item>
            </Flex>
          </Form.Item>

          <Form.Item label="Link" className="dropdown-form-item">
            <Flex gap="middle" horizontal>
              <Form.Item
                name="link"
                rules={[
                  {
                    message: 'Please enter valid url.',
                    pattern:
                      '^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$',
                  },
                ]}
              >
                <Input placeholder="http://" style={{ width: '100%' }} min={0} />
              </Form.Item>
            </Flex>
          </Form.Item>

          <div className={`d-flex ${isPostFromMyAccount && 'd-column'}`}>
            <Form.Item required label="Places" className="dropdown-form-item">
              <Flex gap="middle" horizontal>
                <Form.Item
                  name="post_places"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Location!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const regex = /\d/;
                        if (value && regex.test(value)) {
                          return Promise.reject('Places should be text only');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Enter Location" style={{ width: '100%' }} />
                </Form.Item>
              </Flex>
            </Form.Item>

            <Form.Item required label="Date" className="dropdown-form-item">
              <Flex gap="middle" horizontal>
                <Form.Item
                  name="post_date"
                  rules={[
                    {
                      required: true,
                      message: 'Select Date!',
                    },
                  ]}
                >
                  <DatePicker minDate={dayjs()} placeholder="DD/MM/YYYY" style={{ width: '100%' }} />
                </Form.Item>
              </Flex>
            </Form.Item>
          </div>

          <div className={`d-flex ${isPostFromMyAccount && 'd-column'}`}>
            <Form.Item label="Name of the news publisher" className="dropdown-form-item">
              <Flex gap="middle" horizontal>
                <Form.Item name="post_news_publisher">
                  <Input placeholder="Name of publisher" style={{ width: '100%' }} />
                </Form.Item>
              </Flex>
            </Form.Item>

            <Form.Item required label="News Category" className="dropdown-form-item">
              <Flex gap="middle" horizontal>
                <Form.Item
                  name="post_news_category"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Category!',
                    },
                  ]}
                >
                  <Input placeholder="Enter Category" style={{ width: '100%' }} min={0} />
                </Form.Item>
              </Flex>
            </Form.Item>
          </div>

          {isPostFromMyAccount && (
            <Row className="d-flex jc-end ">
              <div className="d-flex jc-end g-20 ">
                <Button type="primary" ghost onClick={resetForm}>
                  Reset
                </Button>

                <Button htmlType="submit" loader={loader} type="primary">
                  Create Post
                </Button>
              </div>
            </Row>
          )}
        </div>
      </div>
    </Form>
  );
};

export default NewsPostForm;
