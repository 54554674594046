import { Card, Space, Row, Col } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { useNavigate, useParams } from 'react-router';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  addBreadcrumb,
  getOccupantDetailsFullInfo,
  getVacantUnitFullInfo,
  addBrokerHelp,
  getBrokerHelp,
  truncateBreadcrumbs,
  clearBreadcrumbs,
  unlockVacantUnitFields,
} from '../../features/searchSlice';
import { set_toast } from '../../../universal/features/toastSlice';

import './UnitDetailsCard.scss';
import AdPlaceHolder from '../../../layout/home/images/AdPlaceHolder';
import LottieAnimation from '../../../universal/components/lottieAnimation/LottieAnimation';
import animationData from '../../../assets/images/UnitBrokerImage.json';
import ImageGalleryAnimationData from '../../../assets/images/SelectedLottie.json';
import LockedIcon from '../../../layout/home/images/LockedIcon';
import UnlockedIcon from '../../../layout/home/images/UnlockedIcon';
import PlaceHolderImage1 from '../../../assets/images/PlaceHolderImage1.png';
import PlaceHolderImage2 from '../../../assets/images/PlaceHolderImage2.png';
import PlaceHolderImage3 from '../../../assets/images/PlaceHolderImage3.png';
import { isArray } from 'lodash';
import { useSearchParams } from 'react-router-dom';

function UnitDetailsCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    detailedPropertyData,
    vacantUnitDetails,
    navigateFrom,
    searchClasifier,
    detailedResidentialPropertyData,
    breadcrumbs,
  } = useSelector((store) => store.search);
  const { selectedNav } = useSelector((store) => store.leadGenSideNav);
  const { user } = useSelector((store) => store.user);

  const [propertyDetails, setPropertyDetails] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [randomIndex, setRandomIndex] = useState(0);
  const [isBrokerHelpRequired, setIsBrokerHelpRequired] = useState(null);
  const [params, setSearchParams] = useSearchParams();

  const placeholderImages = [PlaceHolderImage1, PlaceHolderImage2, PlaceHolderImage3];
  const imageToRender = randomIndex !== null ? placeholderImages[randomIndex] : null;
  const searchQueries = params?.get('shareable');
  const [isOnResourceLocked, setOnIsResourceLocked] = useState(false);
  useEffect(() => {
    if (searchQueries) {
      dispatch(clearBreadcrumbs());
    }
  }, [searchQueries]);

  const onLoadHandleUnlock = async (id) => {
    await dispatch(getVacantUnitFullInfo(id)).then((data) => {
      if (data?.payload[0]?.isResourceLocked) {
        const transactPayload = {
          body: {
            payload: [
              {
                itemUnlocked: vacantUnitDetails?.propertyInfo?.buildingName || "Awaited",
                resourceType: 'commercial',
                resourceSubType: 'vacantUnit',
                resourceId: id,
                unlockedFields: ['address', 'poc'],
              },
            ],
          },
        };
        dispatch(unlockVacantUnitFields(transactPayload))
          .then((res) => {
            setOnIsResourceLocked(true);
          })
          .catch((err) => {
            console.log(err, 'err');
          });
      }
    });

  };

  useEffect(() => {
    onLoadHandleUnlock(id);
  }, []);

  useEffect(() => {
    dispatch(getOccupantDetailsFullInfo(id));
    if (vacantUnitDetails?.address?.unitNo && !searchQueries) {
      dispatch(addBreadcrumb({ name: `${vacantUnitDetails?.address?.unitNo}`, path: `/leadGen/unitDetails/${id}` }));
    }
    dispatch(getVacantUnitFullInfo(id));
    setSelectedImage(vacantUnitDetails?.assetsInfo?.featuredImage);
    handleGetBrokerHelp();
    if (placeholderImages && placeholderImages.length > 0) {
      const index = Math.floor(Math.random() * placeholderImages.length);
      setRandomIndex(index);
      return;
    }
    setRandomIndex(null);
  }, [isOnResourceLocked]);

  useEffect(() => {
    if (searchClasifier === 'commercialProperty') {
      setPropertyDetails(detailedPropertyData);
      return;
    }
    setPropertyDetails(detailedResidentialPropertyData);
  }, [detailedPropertyData, detailedResidentialPropertyData]);

  const handleGetBrokerHelp = () => {
    const getBrokerHelpPayload = {
      resourceId: id,
      resourceType: 'Property',
      isCommercial: searchClasifier === 'commercialProperty' ? true : false,
    };
    dispatch(getBrokerHelp(getBrokerHelpPayload)).then((res) => {
      setIsBrokerHelpRequired(res?.payload?.isBrokerHelpRequired);
    });
  };

  const handleAddBrokerHelp = (isBrokerHelpRequired) => {
    const brokerHelpPayload = {
      isCommercial: searchClasifier === 'commercialProperty' ? true : false,
      resourceType: 'Property',
      resourceSubType: 'VacantUnits',
      resourceId: id,
      userEmail: user?.email,
      userName: user?.name,
      isBrokerHelpRequired,
    };
    dispatch(addBrokerHelp(brokerHelpPayload)).then((res) => {
      dispatch(set_toast({ show: true, type: 'success', content: 'Request received successfully !' }));
      handleGetBrokerHelp();
    });
  };

  return (
    <Fragment>
      <section
        className="unitDtlsSection"
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: '#F8F8F8',
          gap: '10px',
        }}
      >
        <Card style={{ flex: '1.5' }}>
          <Space direction="vertical">
            <div style={{ display: 'inline-flex', gap: '5px' }}>
              <Title level={4} strong style={{ color: '#0081FC', fontSize: '24px', marginBottom: '0' }}>
                <span
                  onClick={() => {
                    if (selectedNav !== 'ContactBook') {
                      dispatch(truncateBreadcrumbs(breadcrumbs?.[breadcrumbs.length - 2]?.name));
                      navigate(breadcrumbs?.[breadcrumbs.length - 2]?.path);
                      return;
                    }
                    navigate('/leadgen/contactbook');
                  }}
                  style={{ cursor: 'pointer', verticalAlign: 'middle' }}
                >
                  {'< '}
                </span>
              </Title>
              <Title level={4} strong style={{ color: '#0081FC', fontSize: '24px', marginBottom: '0', marginTop: '0' }}>
                <span className="font16 font600" style={{ verticalAlign: 'middle' }}>
                  {`${propertyDetails?.buildingName ?? 'Awaited'} - ${vacantUnitDetails?.address?.unitNo ?? ''}`}
                </span>
              </Title>
            </div>
            <Text className="font16 font900 p-10">Unit Details</Text>
            <Text className="font12 font600 p-10">
              <div style={{ display: 'inline-flex', gap: '5px' }}>
                <span>Building Name :</span>
                <span>{vacantUnitDetails?.propertyInfo?.buildingName ?? 'Awaited'}</span>
              </div>
            </Text>
            <div className="icon-container">
              <Space
                direction="vertical"
                className="highlightedDiv"
                style={{ border: '1.5px solid #ffa31d', borderRadius: '10px', padding: '5px 10px' }}
              >
                <Text className="font14 font900">Floor No : {vacantUnitDetails?.address?.floorNo ?? 'Awaited'}</Text>
                <Text className="font14 font900">Unit No : {vacantUnitDetails?.address?.unitNo ?? 'Awaited'}</Text>
              </Space>
              <div className="lock-icon-container ">
                {vacantUnitDetails?.isResourceLocked ? <LockedIcon /> : <UnlockedIcon />}
              </div>
            </div>
            <Text className="font12 font600 p-10">Unit Status : {vacantUnitDetails?.unitStatus ?? 'Awaited'}</Text>
            <Text className="font12 font600 p-10">
              Unit Condition : {vacantUnitDetails?.unitCondition ?? 'Awaited'}
            </Text>
            <Text className="font12 font600 p-10">
              Locality : {vacantUnitDetails?.propertyInfo?.addressDetail?.[0]?.locality ?? 'Awaited'}
            </Text>
            <Text className="font12 font600 p-10">
              City : {vacantUnitDetails?.propertyInfo?.addressDetail?.[0]?.city ?? 'Awaited'}
            </Text>
            <Text className="font12 font600 p-10">
              Chargeable Area :{' '}
              {vacantUnitDetails?.moreTransactionInfo?.[0]?.chargableArea
                ? `${Number(vacantUnitDetails?.moreTransactionInfo?.[0]?.chargableArea)?.toFixed(2)} sq.ft`
                : 'Awaited'}
            </Text>
          </Space>
        </Card>
        <Card style={{ flex: '2.2', border: '0px' }}>
          <Space direction="vertical" className="w-100">
            <Text className="font18 font900">Parties Involved</Text>
            <Card className="container-card" style={{ border: 'none !important' }}>
              <Text className="font16 font900" style={{ padding: '10px' }}>
                POC
              </Text>
              <div className="icon-container">
                <Space
                  direction="vertical"
                  className="w-100 mt-10"
                  style={{ border: '1.5px solid #ffa31d', borderRadius: '10px', padding: '5px 10px' }}
                >
                  <Row>
                    <Col className="font12 font900" span={7}>
                      Name:
                    </Col>
                    <Col style={{ textAlign: 'left' }} className="font12 font900" span={11}>
                      E-mail
                    </Col>
                    <Col className="font12 font900" span={2}>
                      Contact
                    </Col>
                  </Row>
                  <Row>
                    <Col className="font12 font600 fontLight-3" span={7}>
                      {vacantUnitDetails?.poc?.personName ?? 'Awaited'}
                    </Col>
                    <Col style={{ textAlign: 'left' }} className="font12 font600 fontLight-3" span={11}>
                      {vacantUnitDetails?.poc?.emailId ?? 'Awaited'}
                    </Col>
                    <Col className="font12 font600 fontLight-3" span={2}>
                      {vacantUnitDetails?.poc?.contactNumber ?? 'Awaited'}
                    </Col>
                  </Row>
                </Space>
                <div className="lock-icon-container ">
                  {vacantUnitDetails?.isResourceLocked ? <LockedIcon /> : <UnlockedIcon />}
                </div>
              </div>
            </Card>
            <div className="placeholder">
              {imageToRender ? (
                <div className="placeholder-image-div">
                  <img src={imageToRender} alt="Placeholder" className="placeholder-image" />
                </div>
              ) : (
                <>
                  <AdPlaceHolder />
                  <Text className="placeholder-text">AD Place holder</Text>
                </>
              )}
            </div>
          </Space>
        </Card>
        <Card style={{ flex: '2.2' }}>
          <Text className="font18 font600">Image Gallery</Text>
          {!vacantUnitDetails?.assetsInfo ? (
            <div className="lottie-div mb-20">
              <LottieAnimation height={120} width={120} animationData={ImageGalleryAnimationData} loop={true} />
              <span className="bold lottie-div__text">
                We are finding right<br></br> <span className="bold text-middle">IMAGES</span>
                <br></br>
                For you, Be with us !
              </span>
            </div>
          ) : (
            <div className="imageDiv">
              <div className="left-image">
                <img src={selectedImage} alt="Selected" className="preview-image" />
              </div>
              <div className="right-image">
                {[
                  vacantUnitDetails?.assetsInfo?.featuredImage,
                  Array.isArray(vacantUnitDetails?.assetsInfo?.galleryFiles)
                    ? vacantUnitDetails?.assetsInfo?.galleryFiles
                    : [],
                ]
                  ?.flat()
                  ?.map((image, index) => (
                    <div
                      key={index}
                      className={`image-item ${selectedImage === image ? 'selected' : ''}`}
                      onClick={() => setSelectedImage(image)}
                    >
                      <img src={image} alt={`Image ${index + 1}`} />
                    </div>
                  ))}
              </div>
            </div>
          )}

          <div className="content-container">
            <div className="left-section">
              <div className="text-line">Looking for a property?</div>
              <div className="text-line">Need help from a broker?</div>
              <div className="buttons">
                <button
                  className={`${isBrokerHelpRequired !== null && isBrokerHelpRequired !== undefined ? 'cursor-not-allowed' : ''
                    } ${isBrokerHelpRequired === true ? 'btn-yes-highlight ' : 'btn-yes'} YesOrNoAction`}
                  onClick={() => {
                    if (isBrokerHelpRequired !== null && isBrokerHelpRequired !== undefined) return;
                    handleAddBrokerHelp(true);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Yes
                </button>
                <button
                  className={`${isBrokerHelpRequired !== null && isBrokerHelpRequired !== undefined ? 'cursor-not-allowed' : ''
                    } ${isBrokerHelpRequired === false ? 'btn-no-highlight' : 'btn-no'} YesOrNoAction`}
                  onClick={() => {
                    if (isBrokerHelpRequired !== null && isBrokerHelpRequired !== undefined) return;
                    handleAddBrokerHelp(false);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  No
                </button>
              </div>
            </div>
            <div className="right-section">
              <LottieAnimation height={200} width={180} animationData={animationData} loop={true} />
            </div>
          </div>
        </Card>
      </section>
    </Fragment>
  );
}

export default UnitDetailsCard;
