import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import './barGraph.scss';
ChartJS.register(...registerables);

const BarGraph = ({ data }) => {
  const sortRanges = (array) => {
    const items = Object.entries(array?.[0]);
    const transformed = items.map(([key, value]) => {
      let sortKey = 0;
      if (key.includes('-')) {
        sortKey = parseInt(key.split('-')[0]);
      } else if (key.startsWith('>')) {
        sortKey = Infinity;
      }
      return { key, value, sortKey };
    });

    transformed.sort((a, b) => a.sortKey - b.sortKey);

    const sortedData = {};
    transformed.forEach((item) => {
      sortedData[item.key] = item.value;
    });
    return [sortedData];
  };

  const chartData = {
    labels: Object.keys(sortRanges(data)?.[0]),
    datasets: [
      {
        label: '',
        backgroundColor: 'rgb(0, 128, 252)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 0,
        barThickness: 24,
        data: Object.values(sortRanges(data)?.[0]),
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        type: 'category',
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: ' Occupied Chargeable Area (Sq. ft) / Unit',
          color: 'rgba(0, 0, 0, 0.88)',
          font: {
            family: 'Poppins', // Use Poppins font
            size: 12,
            weight: '600',
          },
        },
        labels: data.labels,
      },
      y: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: 'No. of Units',
          color: 'rgba(0, 0, 0, 0.88)',
          font: {
            family: 'Poppins', // Use Poppins font
            size: 12,
            weight: '600',
          },
        },
        beginAtZero: true,
        ticks: {
          // Ensuring y-axis ticks are integers
          stepSize: 5,
          callback: function (value) {
            return Number.isInteger(value) ? value : null;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: '#000',
        titleColor: '#ffffff',
        padding: 10,
        font: {
          family: 'Poppins', // Use Poppins font
          size: 12,
        },
      }
    },
  };

  return (
    <div className="barGraphContainer">
      <div className="barGraphContainer-div">
        <h4 className="barGraphContainer-div__text">Building Analytics</h4>
      </div>
      <Bar className="barGraphContainer-graph" data={chartData} options={chartOptions} />
    </div>
  );
};

export default BarGraph;
