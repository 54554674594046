import './personalDetails.scss';
import { useEffect, useState } from 'react';
import { Button, Form, Input, message, Modal, Space } from 'antd';
import UserImage from '../../../components/userImage/UserImage';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import verifiedIcon from '../../../../assets/images/verified.png';
import Dragger from 'antd/es/upload/Dragger';
import { CheckCircleOutlined, InboxOutlined } from '@ant-design/icons';
import LinkedinIcon from '../../../../assets/images/linkedIn.png';
// import TwitterIcon from '../../../../assets/images/twitter.png';
import TwitterIcon from '../../../../assets/images/TwitterNew.png';
import { setIsFormEditable } from '../../../features/myAccountSlice';
import { LeftOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

const PersonalDetails = ({
  isFormEditable,
  loading,
  user,
  profileCompletion,
  handleOpenVerifyOtpModal,
  userImgUrl,
  uploadUserRERACertificate,
  personalDetails,
  setReraIdpayload,
  isSaveButtonEnabled,
  isUploaded,
  setIsUploaded,
  fileUrl,
  setFileUrl,
  setIsSaveButtonEnabled,
}) => {
  const [personalEmail, setPersonalEmail] = useState(user?.personalDetails?.personalEmail ?? '');
  const [, setIsValidEmail] = useState(false);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isreraIdValue, setReraIdValue] = useState();
  const [isValidReraId, setIsValidReraId] = useState(false);
  const [isImageModalVisibleUpload, setIsImageModalVisibleUpload] = useState(false);

  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const dispatch = useDispatch();

  const [latestUrl, setLatestUrl] = useState(null);

  useEffect(() => {
    if (user?.personalDetails?.personalRERARefistration?.registrationFileUrl) {
      setLatestUrl(user?.personalDetails?.personalRERARefistration?.registrationFileUrl);
    }
  }, [user?.personalDetails?.personalRERARefistration?.registrationFileUrl]);

  // Effect for updating latestUrl based on fileUrl
  useEffect(() => {
    if (fileUrl) {
      setLatestUrl(fileUrl);
    }
  }, [fileUrl]);
  const handleChange = (e) => {
    setPersonalEmail(e?.target?.value);
    setIsValidEmail(/^[a-z0-9._]+@[a-z0-9.]+\.[a-z]{2,4}$/.test(personalEmail));
  };
  const handleUploadClick = (e) => {
    setIsModalVisible(true);
  };

  const handleUploadedClick = (e) => {
    setIsImageModalVisible(true);
  };

  const handleChangeReraId = (e) => {
    setReraIdValue(e);
    const formattedValue = e.toUpperCase();
    personalDetails.setFieldValue('rera', formattedValue);
    setReraIdpayload(personalDetails.getFieldValue('rera'));
  };

  const handleModalCancel = () => {
    setFileList([]);
    setIsModalVisible(false);
  };
  const handleModalVisibleCancel = () => {
    setIsImageModalVisible(false);
  };
  const handleModalVisibleCancelUpload = () => {
    setIsImageModalVisibleUpload(false);
  };
  const handleDeleteUploadedFile = () => {
    // Clear the uploaded file URL from the form
    // personalDetails.setFieldValue('reraFileURL', null);
    // personalDetails.setFieldValue('rera', '');
    setFileUrl(null);
    setIsUploaded(false);
    setIsImageModalVisibleUpload(false);
    // Provide feedback to the user
    message.success('The uploaded file has been deleted.');
  };

  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const handleSuccessModalClose = () => {
    setFileList([]);
    setIsSuccessModalVisible(false);
  };
  const beforeUpload = async (file) => {
    const isAllowedType = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'].includes(file.type);
    const isAllowedSize = file.size / 1024 / 1024 < 5; // Size in MB

    if (!isAllowedType) {
      message.error('You can only upload JPEG, JPG, PNG, or PDF files!');
      return false;
      // return Upload.LIST_IGNORE; // Prevent file from uploading
    }
    if (!isAllowedSize) {
      message.error('File must be smaller than 5MB!');
      return false;
      // return Upload.LIST_IGNORE; // Prevent file from uploading
    }

    try {
      await uploadUserRERACertificate(file);
      // await uploadUserRERACertificate(file, isreraIdValue);

      setIsModalVisible(false);
      setIsSuccessModalVisible(true);

      setReraIdpayload(personalDetails.getFieldValue('rera'));
    } catch (error) {
      console.error('Upload failed', error);
    }
    return false;
  };
  useEffect(() => {
    if (isSuccessModalVisible) {
      const timer = setTimeout(() => {
        handleSuccessModalClose();
      }, 1000); // Close the modal after 3 seconds

      return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
    }
  }, [isSuccessModalVisible, handleSuccessModalClose]);

  return (
    <>
      <div className={isModalVisible || isSuccessModalVisible ? 'blurred' : ''}>
        <div className="personalDtlsSection__personalDetails">
          {isFormEditable ? (
            <Title
              onClick={() => {
                dispatch(setIsFormEditable(false));
              }}
              className="personalDetails-title"
            >
              <LeftOutlined style={{ marginRight: '5px' }} />
              Personal Details
            </Title>
          ) : (
            <Title className="personalDetails-title">Personal Details</Title>
          )}

          {/* <Title className="personalDetails-title">Personal Details</Title> */}

          <Form.Item className="editDetails-div">
            {isFormEditable ? (
              <Button
                className="editSaveBtn"
                htmlType="submit"
                ghost
                type="primary"
                loading={loading}
                disabled={!isSaveButtonEnabled} // Disable the Save button if no changes
              >
                Save
              </Button>
            ) : null}
          </Form.Item>
          {!isFormEditable ? (
            <Button
              className="editSaveBtn"
              onClick={() => {
                dispatch(setIsFormEditable(true)); // Dispatch your redux action
                // setIsUploaded(false); // Reset the uploaded state
              }}
              ghost
              type="primary"
              loading={loading}
            >
              Edit Profile
            </Button>
          ) : null}
        </div>
        <Space direction="vertical" className="personalDetails-space">
          <Text className="personalDetails-message">Complete your entire profile details and gain 500 points</Text>
          <div className="item-center">
            <UserImage
              userImage={userImgUrl}
              isFormEditable={isFormEditable}
              isVerified={true}
              completionPercentage={profileCompletion?.overallCompletion}
              user={user}
              setIsSaveButtonEnabled={setIsSaveButtonEnabled}
            />
          </div>{' '}
        </Space>

        <Form.Item
          label="Name"
          name="name"
          className="formItemLabel "
          rules={[{ required: true, message: 'Please input your name!' }]}
        >
          {isFormEditable ? (
            <Input
              suffix={<img src={verifiedIcon} alt="verified" className="form-suffix" />}
              disabled={true}
              className="input-box"
            />
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <Text strong className="userPersonalDtls__formValues">
                  {getFieldValue('name') || '-'}
                  <img src={verifiedIcon} alt="verified" width="17px" height="17px" style={{ marginLeft: '8px' }} />
                </Text>
              )}
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item
          label="Mobile no"
          name="phone"
          className="formItemLabel"
          rules={[{ required: true, message: 'Please input your mobile!' }]}
        >
          {isFormEditable ? (
            <Input
              suffix={
                user?.verificationStatus?.phone ? (
                  <img src={verifiedIcon} alt="verified" width="17px" height="17px" className="form-suffix" />
                ) : (
                  <Button clasName="anchortobutton" onClick={() => handleOpenVerifyOtpModal(user?.phone, 'phone')}>
                    Verify
                  </Button>
                )
              }
              disabled={true}
              className="input-box"
            />
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <Text strong className="userPersonalDtls__formValues">
                  {getFieldValue('phone') || '-'}
                  {user?.verificationStatus?.phone && (
                    <img src={verifiedIcon} alt="verified" width="17px" height="17px" style={{ marginLeft: '8px' }} />
                  )}
                </Text>
              )}
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item
          label="Personal Email"
          name="personalEmail"
          className="formItemLabel companyDetails-label"
          rules={[
            {
              required: false,
              message: 'Please input a valid Email.',
              pattern: /^[a-z0-9._]+@[a-z0-9.]+\.[a-z]{2,4}$/,
            },
          ]}
        >
          {isFormEditable ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.personalEmail !== currentValues.personalEmail}
                className="formItemLabel"
              >
                {({ getFieldValue }) => {
                  const value = getFieldValue('personalEmail') || ''; // Fetch the value of the field
                  const isVerified = user?.verificationStatus?.personalEmail;
                  const isSameAsVerifiedEmail = value === user?.personalDetails?.personalEmail;

                  return (
                    <Input
                      name="personalEmail"
                      placeholder="Please enter Personal Email Id"
                      className="input-details aadhar-input input-box"
                      value={value} // Set the value of the input field
                      onChange={handleChange}
                      suffix={
                        isVerified && isSameAsVerifiedEmail ? (
                          <img src={verifiedIcon} alt="verified" width="17px" height="17px" className="form-suffix" />
                        ) : null
                      }
                    />
                  );
                }}
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.personalEmail !== currentValues.personalEmail}
              >
                {({ getFieldError, getFieldValue }) => {
                  const value = getFieldValue('personalEmail') || '';
                  const hasError = getFieldError('personalEmail').length > 0;
                  const isEmpty = value.trim() === '';

                  const isVerified = user?.verificationStatus?.personalEmail;
                  const isSameAsVerifiedEmail = value === user?.personalDetails?.personalEmail;

                  let isDisabled;
                  if (isVerified && isSameAsVerifiedEmail) {
                    // If email is verified and matches the current input, disable the Verify button
                    isDisabled = true;
                  } else {
                    // Enable or disable based on validation
                    isDisabled = hasError || isEmpty;
                  }

                  return (
                    <Button
                      className="anchor-button-myaccount"
                      onClick={() => {
                        handleOpenVerifyOtpModal(getFieldValue('personalEmail'), 'personalEmail');
                      }}
                      style={{ color: isDisabled ? '#bfbfbf' : '#0080FC' }}
                      disabled={isDisabled}
                    >
                      {isVerified && isSameAsVerifiedEmail ? 'Verified' : 'Verify'}
                    </Button>
                  );
                }}
              </Form.Item>
            </div>
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <div className="userPersonalDtls__formValues" style={{ display: 'flex', alignItems: 'center' }}>
                  <Text strong>
                    {getFieldValue('personalEmail') || '-'}
                    {user?.verificationStatus?.personalEmail ? (
                      <img src={verifiedIcon} alt="verified" width="17px" height="17px" style={{ marginLeft: '8px' }} />
                    ) : null}
                  </Text>
                </div>
              )}
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item
          label="Aadhar No"
          name="aadharNumber"
          className="formItemLabel"
          rules={[
            {
              message: 'Please input valid Aadhar no.',
              pattern: /^[0-9]{12}$/,
            },
          ]}
        >
          {isFormEditable ? (
            <Input
              initialValue={user?.personalDetails?.aadharNumber}
              placeholder="Please enter Aadhar Number"
              type="text"
              className="input-details aadhar-input input-box"
              maxLength={12}
              onChange={(e) => {
                // Remove any non-digit characters
                let value = e.target.value.replace(/\D/g, '');
                // Limit to 12 digits
                if (value.length > 12) {
                  value = value.slice(0, 12);
                }
                e.target.value = value;
              }}
            />
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <Text strong className="userPersonalDtls__formValues">
                  {getFieldValue('aadharNumber') || '-'}
                </Text>
              )}
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item
          label="Personal RERA registration id"
          name="rera"
          className="formItemLabel"
          rules={[
            {
              required: false,
              message: 'Please input valid RERA Id.',
            },
          ]}
        >
          {isFormEditable ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Input
                placeholder="Please fill in, if you have one"
                className="input-details aadhar-input input-box"
                onInput={(e) => (e.target.value = e?.target?.value?.toUpperCase())}
                onChange={(e) => handleChangeReraId(e?.target?.value)}
                defaultValue={user?.personalDetails?.personalRERARefistration?.registrationId}
              />
              {
                <Button
                  className="anchor-button-myaccount"
                  onClick={() => {
                    if (isUploaded) {
                      setIsImageModalVisibleUpload(true);
                    } else {
                      handleUploadClick();
                      setIsImageModalVisibleUpload(false);
                    }
                  }}
                  style={{ padding: '10px', color: '#1677ff' }}
                >
                  {isUploaded ? 'Uploaded' : 'Upload'}
                </Button>
              }
            </div>
          ) : (
            <Form.Item
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.rera !== currentValues.rera || prevValues.reraFileURL !== currentValues.reraFileURL
              }
              className="userPersonalDtls__formValues"
            >
              {({ getFieldValue }) => (
                <>
                  <div className="rera-upload-container">
                    <Text strong className="userPersonalDtls__formValues">
                      {getFieldValue('rera') || '-'}
                    </Text>
                    {getFieldValue('reraFileURL') ||
                    user?.personalDetails?.personalRERARefistration?.registrationFileUrl ? (
                      <>
                        <Button type="link" className="anchor-button" onClick={handleUploadedClick}>
                          <img
                            src={verifiedIcon}
                            alt="verified"
                            width="17px"
                            height="17px"
                            className="verified-icon"
                            style={{ marginLeft: '80px' }}
                          />
                          Uploaded
                        </Button>
                      </>
                    ) : null}
                  </div>

                  {/* Modal for showing the uploaded image */}
                  <Modal
                    title="Personal RERA Registration Certificate"
                    visible={isImageModalVisible}
                    onCancel={handleModalVisibleCancel}
                    footer={null}
                    centered
                  >
                    <div style={{ textAlign: 'center' }}>
                      {getFieldValue('reraFileURL') ||
                      user?.personalDetails?.personalRERARefistration?.registrationFileUrl ? (
                        <>
                          {/* Check if the uploaded file is a PDF */}
                          {getFieldValue('reraFileURL')?.endsWith('.pdf') ||
                          user?.personalDetails?.personalRERARefistration?.registrationFileUrl?.endsWith('.pdf') ? (
                            <div style={{ marginTop: '20px' }}>
                              <Button
                                type="primary"
                                alt="PDF File"
                                onClick={() =>
                                  window.open(
                                    getFieldValue('reraFileURL') ||
                                      user?.personalDetails?.personalRERARefistration?.registrationFileUrl,
                                  )
                                }
                              >
                                Download File
                              </Button>
                            </div>
                          ) : (
                            <img
                              src={
                                getFieldValue('reraFileURL') ||
                                user?.personalDetails?.personalRERARefistration?.registrationFileUrl
                              }
                              alt="Uploaded"
                              style={{ maxWidth: '100%', maxHeight: '100%' }}
                            />
                          )}
                        </>
                      ) : (
                        <p>No image available</p>
                      )}
                    </div>
                  </Modal>
                </>
              )}
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item
          label="PAN"
          name="panNumber"
          className="formItemLabel"
          rules={[
            {
              required: false,
              message: 'Please input valid PAN no.',
              pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
            },
          ]}
        >
          {isFormEditable ? (
            <Input
              placeholder="Please enter Pan Number"
              className="input-details input-box"
              initialValue={user?.personalDetails?.aadharNumber}
              onInput={(e) => (e.target.value = e?.target?.value?.toUpperCase())}
              maxLength={10} // Limits input to 10 characters
              onChange={(e) => {
                let value = e.target.value.toUpperCase();
                // Allow only alphanumeric characters
                value = value.replace(/[^A-Z0-9]/g, '');
                e.target.value = value;
              }}
            />
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <Text strong className="userPersonalDtls__formValues">
                  {getFieldValue('panNumber') || '-'}
                </Text>
              )}
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item label="Social Profiles" className="formItemLabel">
          {isFormEditable ? (
            <>
              {/* LinkedIn URL Input */}
              <Form.Item
                name="linkedInUrl"
                placeholder="Please enter LinkedIn URL"
                label="LinkedIn URL"
                className="formItemLabel "
                rules={[
                  {
                    message: 'Please input a valid LinkedIn URL!',
                    pattern: /^https:\/\/(www\.)?linkedin\.com\/.*$/,
                  },
                ]}
              >
                <Input placeholder="Please enter LinkedIn URL" className="input-details input-box" />
              </Form.Item>

              {/* Twitter URL Input */}
              <Form.Item
                name="twitterUrl"
                placeholder="Please enter Twitter URL"
                className="formItemLabel"
                label="Twitter URL"
                rules={[
                  {
                    message: 'Please input a valid Twitter URL!',
                    // pattern: /^https:\/\/(www\.)?twitter\.com\/.*$/,
                    pattern: /^https:\/\/(www\.)?(twitter\.com|x\.com)\/.*$/,
                  },
                ]}
              >
                <Input placeholder="Please enter Twitter URL" className="input-details input-box" />
              </Form.Item>
            </>
          ) : (
            <Form.Item shouldUpdate className="userPersonalDtls__formValues">
              {({ getFieldValue }) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  {/* LinkedIn Icon */}
                  {getFieldValue('linkedInUrl') ? (
                    <a href={getFieldValue('linkedInUrl')} target="_blank" rel="noopener noreferrer">
                      <img src={LinkedinIcon} alt="LinkedIn Profile" width="30px" height="30px" />
                    </a>
                  ) : (
                    '-'
                  )}

                  {/* Twitter Icon */}
                  {getFieldValue('twitterUrl') ? (
                    <a href={getFieldValue('twitterUrl')} target="_blank" rel="noopener noreferrer">
                      <img
                        src={TwitterIcon}
                        alt="Twitter Profile"
                        width="30px"
                        height="30px"
                        style={{ border: '1px solid #DBDBDB', borderRadius: '5px', padding: '6px' }}
                      />
                    </a>
                  ) : (
                    '-'
                  )}
                </div>
              )}
            </Form.Item>
          )}
        </Form.Item>

        {/* Modal for showing the uploaded image preview on selction */}
        <Modal
          title="Personal RERA Registration Certificate Selected"
          visible={isImageModalVisibleUpload}
          onCancel={handleModalVisibleCancelUpload}
          closable={false}
          centered
          footer={[
            <Button key="delete" type="primary" danger onClick={handleDeleteUploadedFile}>
              Delete
            </Button>,
            <Button key="cancel" onClick={handleModalVisibleCancelUpload}>
              Cancel
            </Button>,
          ]}
        >
          <div style={{ textAlign: 'center' }}>
            {latestUrl ? (
              <>
                {/* Check if the uploaded file is a PDF */}
                {latestUrl?.endsWith('.pdf') ? (
                  <div style={{ marginTop: '20px' }}>
                    <Button type="primary" alt="PDF File" onClick={() => window.open(latestUrl)}>
                      Download File
                    </Button>
                  </div>
                ) : (
                  <img src={latestUrl} alt="Uploaded" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                )}
              </>
            ) : (
              <p>No image available</p>
            )}
          </div>
        </Modal>
        {/* Modal for Drag and Drop Upload */}
        <Modal visible={isModalVisible} onCancel={handleModalCancel} footer={null} centered className="custom-modal">
          <Dragger
            name="file"
            multiple={false}
            beforeUpload={beforeUpload}
            fileList={fileList}
            onRemove={() => setFileList([])}
            onChange={({ fileList: newFileList }) => setFileList(newFileList)}
            showUploadList={false}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Drag and Drop</p>
            <p className="ant-upload-hint">your file here or upload from your gallery</p>
            <p className="ant-upload-hint" style={{ color: '#0080FC' }}>
              Only JPEG, JPG, PNG, and PDF files with a max size of 5MB
            </p>
          </Dragger>
        </Modal>

        {/* Success Modal for showing the upload success message */}
        <Modal visible={isSuccessModalVisible} onCancel={handleSuccessModalClose} footer={null} centered>
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <CheckCircleOutlined style={{ fontSize: '48px', color: '#52c41a' }} />
            <h2 style={{ marginTop: '20px' }}>Uploaded Successfully!</h2>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default PersonalDetails;
