import { asyncThunkCreator, buildCreateSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { ExternalError } from '../../utils/error/error';
import {
  deleteComment as _deleteComment,
  getUserAllPosts as _getAllPosts,
  getComments as _getComments,
  getCompanyAllPosts as _getCompanyAllPosts,
  getUserSavedPosts as _getUserSavedPosts,
  updateActivity as _updateActivity,
  updateComment as _updateComment,
  uploadPostImage as _uploadPostImage,
  createCommentAPI,
  createPost,
  createSocialGroups,
  deletePost,
  followUser,
  getDashboardAllPosts,
  getGroupAllPostsAPI,
  getPost,
  getPostDetailsByIdService,
  getSharedPost,
  getSocialAllEvents,
  getSocialAllGroupsAPI,
  getSocialGroupDetailAPI,
  getSocialGroupMembers,
  getSocialLeadsAPI,
  joinSocialGroupAPI,
  leaveSocialGroupAPI,
  removeFromSocialGroupAPI,
  unfollowUser,
  unlockLeadAPI,
  updateSocialEventsAPI,
  voteToPollCard,
  repostCard,
  undoRepostCard,
  getProfileDetailsByIDAPI,
  getProfilePosts,
  reportSocialAccount,
} from '../services/socialAppServiceAPI';
const createSliceWithThunks = buildCreateSlice({
  creators: {
    asyncThunk: asyncThunkCreator,
  },
});

const initialState = {
  socialApploader: false,
  socialAppPosts: [],
  socialAppSavedPosts: [],
  socialAppComments: [],
  socialAppOffset: 0,
  socialAppLimit: 10,
  isUserFollowed: false,
  isModalShow: false,
  searchVisibleSocialApp: false,
  isCompanyToggleOnPostTrue: false,
  socialAppCurrentTab: null,
};

const socialAppSlice = createSliceWithThunks({
  name: 'socialAppSlice',
  initialState,
  reducers: (create) => ({
    setSocialAppLimit: (state, action) => {
      state.socialAppLimit = action?.payload;
    },
    setSocialAppOffet: (state, action) => {
      state.socialAppOffset = action?.payload;
    },
    setIsUserFollowed: (state, action) => {
      state.isUserFollowed = false;
    },
    isModalShow: (state, action) => {
      state.isModalShow = false;
    },
    setSocialAppCurrentTab: (state, action) => {
      state.socialAppCurrentTab = action.payload;
    },

    setSearchVisibleSocialApp: (state, action) => {
      state.searchVisibleSocialApp = action?.payload;
    },
    setIsCompanyToggleOnPostTrue: (state, action) => {
      state.isCompanyToggleOnPostTrue = !state.isCompanyToggleOnPostTrue;
    },
    setIsCompanyToggleFalse: (state, action) => {
      state.isCompanyToggleOnPostTrue = false;
    },
    getSocialDashbordPosts: create.asyncThunk(
      async (payload, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getDashboardAllPosts(userId, payload);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.message, {
              statusCode: data.status,
            });
          }
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state, action) => {
          state.socialApploader = true;
        },
      },
      {
        rejected: (state, action) => {
          state.error = action.error;
          state.socialApploader = false;
        },
      },
      {
        fulfilled: (state, action) => {
          state.socialApploader = false;
          const prevPosts = state.socialAppPosts;
          state.socialAppPosts = [...prevPosts, action?.payload?.data?.response?.data];
        },
      },
    ),
    getSocialGroupPosts: create.asyncThunk(
      async (payload, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getGroupAllPostsAPI(userId, payload);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.message, {
              statusCode: data.status,
            });
          }
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state, action) => {
          state.socialApploader = true;
        },
      },
      {
        rejected: (state, action) => {
          state.error = action.error;
          state.socialApploader = false;
        },
      },
      {
        fulfilled: (state, action) => {
          state.socialApploader = false;
          const prevPosts = state.socialAppPosts;
          state.socialAppPosts = [...prevPosts, action?.payload?.data?.response?.data];
        },
      },
    ),
    getSocialEvents: create.asyncThunk(
      async (payload, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getSocialAllEvents(userId, payload);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.message, {
              statusCode: data.status,
            });
          }
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state, action) => {
          state.socialApploader = true;
        },
      },
      {
        rejected: (state, action) => {
          state.error = action.error;
          state.socialApploader = false;
        },
      },
      {
        fulfilled: (state, action) => {
          state.socialApploader = false;
          const prevPosts = state.socialAppPosts;
          state.socialAppPosts = [...prevPosts, action?.payload?.data?.response?.data];
        },
      },
    ),
    updateSocialEvent: create.asyncThunk(
      async ({ eventId, deleteData }, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await updateSocialEventsAPI(userId, eventId, deleteData);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.message, {
              statusCode: data.status,
            });
          }
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state, action) => {
          state.socialApploader = true;
        },
      },
      {
        rejected: (state, action) => {
          state.error = action.error;
          state.socialApploader = false;
        },
      },
      {
        fulfilled: (state, action) => {
          state.socialApploader = false;
          const prevPosts = state.socialAppPosts;
          state.socialAppPosts = [...prevPosts, action?.payload?.data?.response?.data];
        },
      },
    ),
    getUserAllPosts: create.asyncThunk(
      async (payload, thunkAPI) => {
        try {
          let userId;
          if (payload?.userId) {
            userId = payload?.userId;
          } else {
            userId = thunkAPI.getState().user?.user?.id;
          }
          let data = await _getAllPosts(userId, payload);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.message, {
              statusCode: data.status,
            });
          }
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state, action) => {
          state.socialApploader = true;
        },
      },
      {
        rejected: (state, action) => {
          state.error = action.error;
          state.socialApploader = false;
        },
      },
      {
        fulfilled: (state, action) => {
          state.socialApploader = false;
          const prevPosts = state.socialAppPosts;
          state.socialAppPosts = [...prevPosts, action?.payload?.data?.response?.data];
        },
      },
    ),
    getProfileAllPosts: create.asyncThunk(
      async (payload, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await getProfilePosts(userId, payload);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.message, {
              statusCode: data.status,
            });
          }
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state, action) => {
          state.socialApploader = true;
        },
      },
      {
        rejected: (state, action) => {
          state.error = action.error;
          state.socialApploader = false;
        },
      },
      {
        fulfilled: (state, action) => {
          state.socialApploader = false;
          const prevPosts = state.socialAppPosts;
          state.socialAppPosts = [...prevPosts, action?.payload?.data?.response?.data];
        },
      },
    ),
    getUserSavedPosts: create.asyncThunk(
      async (payload, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await _getUserSavedPosts(userId, payload);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.message, {
              statusCode: data.status,
            });
          }
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state, action) => {
          state.socialApploader = true;
        },
      },
      {
        rejected: (state, action) => {
          state.error = action.error;
          state.socialApploader = false;
        },
      },
      {
        fulfilled: (state, action) => {
          state.socialApploader = false;
          const prevPosts = state.socialAppSavedPosts;
          state.socialAppSavedPosts = [...prevPosts, action?.payload?.data?.response?.data];
        },
      },
    ),
    getCompanyAllPosts: create.asyncThunk(
      async (payload, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          const companyId = thunkAPI.getState().user?.userV2?.companyDetails?.companyId;
          let data = await _getCompanyAllPosts(userId, companyId, payload);
          if (data?.status !== 200) {
            throw new ExternalError(data?.response?.data?.errors?.message, {
              statusCode: data.status,
            });
          }
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state, action) => {
          state.socialApploader = true;
        },
      },
      {
        rejected: (state, action) => {
          state.error = action.error;
          state.socialApploader = false;
        },
      },
      {
        fulfilled: (state, action) => {
          state.socialApploader = false;
          const prevPosts = state.socialAppPosts;
          state.socialAppPosts = [...prevPosts, action?.payload?.data?.response?.data];
        },
      },
    ),
    // ----------Get All  Post---------
    socialAppCreatePost: create.asyncThunk(async (payload, thunkAPI) => {
      try {
        const userId = thunkAPI.getState().user?.user?.id;
        let data = await createPost(userId, payload);
        if (data?.status !== 201) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data?.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),

    uploadPostImage: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await _uploadPostImage(_, userId);
          if (data?.status !== 201) {
            throw new ExternalError(data?.response?.data?.errors?.message, {
              statusCode: data.status,
            });
          }
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.error = action.error;
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
        },
      },
    ),
    // ------Follow user reducer-------
    socialAppFollowUser: create.asyncThunk(
      async (payload, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await followUser(payload, userId);
          if (data?.status !== 201) {
            throw new ExternalError(data?.response?.data?.errors?.message, {
              statusCode: data.status,
            });
          }
          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.error = action.error;
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
        },
      },
    ),
    socialAppUnFollowUser: create.asyncThunk(
      async (payload, thunkAPI) => {
        try {
          const userId = thunkAPI.getState().user?.user?.id;
          let data = await unfollowUser(payload, userId);
          if (data?.status !== 201) {
            throw new ExternalError(data?.response?.data?.errors?.message, {
              statusCode: data.status,
            });
          }

          return data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.error = action.error;
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
        },
      },
    ),
    updateActivity: create.asyncThunk(
      async (payload, thunkAPI) => {
        try {
          const { userId, postId, action, text } = payload;
          const response = await _updateActivity(userId, postId, action, text);
          if (response.status !== 201) {
            throw new ExternalError(response.data.errors.message, {
              statusCode: response.status,
            });
          }
          return response.data.response.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.socialApploader = true;
        },
        rejected: (state, action) => {
          state.error = action.error;
          state.socialApploader = false;
        },
        fulfilled: (state, action) => {
          state.socialApploader = false;
          const updatedPost = action.payload;
          const index = state.socialAppPosts.findIndex((post) => post._id === updatedPost._id);
          if (index !== -1) {
            state.socialAppPosts[index] = updatedPost;
          }
        },
      },
    ),
    createComments: create.asyncThunk(
      async ({ userId, payload }, thunkAPI) => {
        try {
          const response = await createCommentAPI(userId, payload);
          if (response.status !== 200) {
            throw new ExternalError(response.data.errors.message, {
              statusCode: response.status,
            });
          }
          return response.data.response.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.socialApploader = true;
        },
        rejected: (state, action) => {
          state.error = action.error;
          state.socialApploader = false;
        },
        fulfilled: (state, action) => {
          state.socialApploader = false;
          state.socialAppComments = action.payload;
        },
      },
    ),
    getComments: create.asyncThunk(
      async ({ userId, postId }, thunkAPI) => {
        try {
          const response = await _getComments(userId, postId);
          if (response.status !== 200) {
            throw new ExternalError(response.data.errors.message, {
              statusCode: response.status,
            });
          }
          return response.data.response.data?.posts;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.socialApploader = true;
        },
        rejected: (state, action) => {
          state.error = action.error;
          state.socialApploader = false;
        },
        fulfilled: (state, action) => {
          state.socialApploader = false;
          state.socialAppComments = action.payload;
        },
      },
    ),
    getPostDetails: create.asyncThunk(
      async ({ userId, postId }, thunkAPI) => {
        try {
          const response = await getPost(userId, postId);
          return response.data.response.data.activityDetails;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.socialApploader = true;
        },
        rejected: (state, action) => {
          state.error = action.error;
          state.socialApploader = false;
        },
        fulfilled: (state, action) => {
          state.socialApploader = false;
          const updatedPost = action.payload;
          state.socialAppPosts = state.socialAppPosts.map((post) =>
            post._id === updatedPost._id ? updatedPost : post,
          );
        },
      },
    ),
    getSharedPostDetails: create.asyncThunk(
      async ({ postId }, thunkAPI) => {
        try {
          const response = await getSharedPost(postId);

          return response.data.response.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.socialApploader = true;
        },
        rejected: (state, action) => {
          state.error = action.error;
          state.socialApploader = false;
        },
        fulfilled: (state, action) => {
          state.socialApploader = false;
        },
      },
    ),
    deleteUserPost: create.asyncThunk(
      async (payload, thunkAPI) => {
        try {
          const { userId, postId } = payload;
          const response = await deletePost(userId, postId);
          if (response.status !== 200) {
            throw new Error('Failed to delete post');
          }
          return { postId };
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.socialApploader = true;
        },
        rejected: (state, action) => {
          state.error = action.error;
          state.socialApploader = false;
        },
        fulfilled: (state, action) => {
          state.socialApploader = false;
          const { postId } = action.payload;
          state.socialAppPosts = state.socialAppPosts.filter((post) => post._id !== postId);
        },
      },
    ),
    updateComment: create.asyncThunk(
      async (payload, thunkAPI) => {
        try {
          const { userId, commentId, text } = payload;
          const response = await _updateComment(userId, commentId, text);
          if (response.status !== 200) {
            throw new Error('Failed to update comment');
          }
          return response.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.socialApploader = true;
        },
        rejected: (state, action) => {
          state.error = action.error;
          state.socialApploader = false;
        },
        fulfilled: (state, action) => {
          state.socialApploader = false;
        },
      },
    ),
    deleteComment: create.asyncThunk(
      async (payload, thunkAPI) => {
        try {
          const { userId, commentId, postId } = payload;
          const response = await _deleteComment(userId, commentId, postId);
          if (response.status !== 200) {
            throw new Error('Failed to delete comment');
          }
          return response.data;
        } catch (err) {
          const message = get(err, 'message', 'Something Went Wrong!');
          const name = get(err, 'name', 'Error!');
          const statusCode = get(err, 'metadata.statusCode', '');
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.socialApploader = true;
        },
        rejected: (state, action) => {
          state.error = action.error;
          state.socialApploader = false;
        },
        fulfilled: (state, action) => {
          state.socialApploader = false;
        },
      },
    ),
    votePollCard: create.asyncThunk(async (payload, thunkAPI) => {
      try {
        const userId = thunkAPI.getState().user?.user?.id;
        let data = await voteToPollCard(userId, payload);
        if (data?.status !== 201) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data?.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    repostCardPost: create.asyncThunk(async (payload, thunkAPI) => {
      try {
        const userId = thunkAPI.getState().user?.user?.id;
        let data = await repostCard(userId, payload);
        if (data?.status !== 201) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data?.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    undoRepostedCardPost: create.asyncThunk(async (payload, thunkAPI) => {
      try {
        const userId = thunkAPI.getState().user?.user?.id;
        let data = await undoRepostCard(userId, payload);
        if (data?.status !== 201) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data?.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    getSocialLeads: create.asyncThunk(async ({ leadType, engagementType, offset }, thunkAPI) => {
      try {
        let data = await getSocialLeadsAPI(leadType, engagementType, offset);
        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data?.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),

    getPostDetailsById: create.asyncThunk(async ({ type, postId, ownerId }, thunkAPI) => {
      try {
        const userId = thunkAPI.getState().user?.user?.id;
        let data = await getPostDetailsByIdService(userId, type, postId, ownerId);
        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data?.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    unlockLead: create.asyncThunk(async (payload, thunkAPI) => {
      try {
        let data = await unlockLeadAPI({ ...payload });

        if (data?.status !== 201) {
          throw new ExternalError(data?.response?.data?.errors?.message, {
            statusCode: data?.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    // -------Social groups------------
    createSocialGroup: create.asyncThunk(async (payload, thunkAPI) => {
      try {
        let data = await createSocialGroups(payload);
        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data?.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    getSocialMembers: create.asyncThunk(async ({ searchBy }, thunkAPI) => {
      try {
        const userId = thunkAPI.getState().user?.user?.id;
        let data = await getSocialGroupMembers(userId, searchBy);
        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data?.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    getSocialAllGroups: create.asyncThunk(async (_, thunkAPI) => {
      try {
        const userId = thunkAPI.getState().user?.user?.id;
        let data = await getSocialAllGroupsAPI();
        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data?.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    getSocialGroupDetail: create.asyncThunk(async (groupId, thunkAPI) => {
      try {
        const userId = thunkAPI.getState().user?.user?.id;
        let data = await getSocialGroupDetailAPI(groupId);
        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data?.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    joinSocialGroup: create.asyncThunk(async (groupId, thunkAPI) => {
      try {
        const userId = thunkAPI.getState().user?.user?.id;
        let data = await joinSocialGroupAPI(groupId);
        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data?.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    leaveSocialGroup: create.asyncThunk(async (groupId, thunkAPI) => {
      try {
        let data = await leaveSocialGroupAPI(groupId);
        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data?.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    removeFromSocialGroup: create.asyncThunk(async ({ groupId, userId }, thunkAPI) => {
      try {
        let data = await removeFromSocialGroupAPI(groupId, userId);
        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data?.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    getProfileDetailsByID: create.asyncThunk(async ({ type, id }, thunkAPI) => {
      try {
        const userId = thunkAPI.getState().user?.user?.id;
        let data = await getProfileDetailsByIDAPI(userId, type, id);
        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data?.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    removeFromSocialGroup: create.asyncThunk(async ({ groupId, userId }, thunkAPI) => {
      try {
        let data = await removeFromSocialGroupAPI(groupId, userId);
        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data?.status,
          });
        }
        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
    reportSocialPost: create.asyncThunk(async ({ postId }, thunkAPI) => {
      try {
        const userId = thunkAPI.getState().user?.user?.id;
        let data = await reportSocialAccount(postId, userId);
        if (data?.status !== 200) {
          throw new ExternalError(data?.response?.data?.errors?.[0]?.message, {
            statusCode: data?.status,
          });
        }

        return data;
      } catch (err) {
        const message = get(err, 'message', 'Something Went Wrong!');
        const name = get(err, 'name', 'Error!');
        const statusCode = get(err, 'metadata.statusCode', '');
        return thunkAPI.rejectWithValue({ message, name, statusCode });
      }
    }),
  }),
});

export const {
  getUserAllPosts,
  getUserSavedPosts,
  getCompanyAllPosts,
  uploadPostImage,
  setSocialAppLimit,
  setSocialAppOffet,
  socialAppCreatePost,
  getSocialDashbordPosts,
  setIsUserFollowed,
  socialAppFollowUser,
  socialAppUnFollowUser,
  isModalShow,
  setSearchVisibleSocialApp,
  updateActivity,
  updateActivityLinkUnLink,
  getComments,
  getPostDetails,
  deleteUserPost,
  updateComment,
  deleteComment,
  setIsCompanyToggleOnPostTrue,
  votePollCard,
  setSocialAppCurrentTab,
  createComments,
  getPostDetailsById,
  getSocialEvents,
  updateSocialEvent,
  getSocialLeads,
  unlockLead,
  getSharedPostDetails,
  getSocialMembers,
  createSocialGroup,
  getSocialAllGroups,
  getSocialGroupPosts,
  getSocialGroupDetail,
  joinSocialGroup,
  leaveSocialGroup,
  removeFromSocialGroup,
  repostCardPost,
  undoRepostedCardPost,
  setIsCompanyToggleFalse,
  getProfileDetailsByID,
  getProfileAllPosts,
  reportSocialPost,
} = socialAppSlice.actions;
export default socialAppSlice.reducer;
