import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Form, Modal } from 'antd';
import { isEqual, isEmpty } from 'lodash';
import PersonalDetails from './PersonalDetails';
import CompanyDetails from './CompanyDetails';
import './index.scss';

import {
  fetchUserProfileData,
  fetchUserProfileDataV2,
  sendOtp,
  setIsFormEditable,
  setVerifyOtpModalState,
  updateUserProfileData,
  verifyOtp,
  UploadUserRERACertificate,
} from '../../../features/myAccountSlice';
import VerifyOtp from '../../../components/myAccount/verifyOtp/VerifyOtp';
import PostDetails from '../PostDetails/PostDetails';
import { LeftOutlined } from '@ant-design/icons';

const PersonalDetailsTab = ({
  activeAccountTab,
  user,
  userPoints,
  loading,
  isFormEditable,
  profileCompletion,
  userImgUrl,
  userV2,
}) => {
  const dispatch = useDispatch();
  const [personalDetails] = Form.useForm();
  const { verifyOtpModalState } = useSelector((store) => store.myAccount);

  const [otpBody, setOtpBody] = useState({});
  const [fileUrl, setFileUrl] = useState(null);
  const [reraIdpayload, setReraIdpayload] = useState();
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false); // Track Save button state
  const [initialValues, setInitialValues] = useState({}); // Track initial form values
  const [isUploaded, setIsUploaded] = useState(false);

  // Load the initial data and set the form initial values only once
  useEffect(() => {
    dispatch(fetchUserProfileData());
    dispatch(fetchUserProfileDataV2());
    dispatch(setIsFormEditable(false));
    dispatch(setVerifyOtpModalState(false));
  }, [dispatch]);
  useEffect(() => {
    setIsUploaded(false);
  }, []);
  // Set initial form values and ensure it only sets once when `userV2` is available
  useEffect(() => {
    if (isEmpty(userV2)) return;

    const initialFormValues = {
      name: userV2?.name,
      phone: userV2?.phone,
      personalEmail: userV2?.personalDetails?.personalEmail,
      aadharNumber: userV2?.personalDetails?.aadharNumber,
      panNumber: userV2?.personalDetails?.panNumber,
      rera: userV2?.personalDetails?.personalRERARefistration?.registrationId,
      reraFileURL: userV2?.personalDetails?.personalRERARefistration?.registrationFileUrl,
      linkedInUrl: userV2?.personalDetails?.linkedInProfileUrl,
      twitterUrl: userV2?.personalDetails?.twitterProfileUrl,
      companyName: userV2?.professionalDetails?.companyName,
      industry: userV2?.companyDetails?.industryId,
      companyEmailId: userV2?.email,
      designation: userV2?.professionalDetails?.designation,
      yearsOfExperience: userV2?.professionalDetails?.experience,
      keySkills: userV2?.professionalDetails?.keySkills,
      lastCompanyIndustry: userV2?.professionalDetails?.lastCompanyIndustry,
      lastCompanyName: userV2?.professionalDetails?.lastCompanyName || '',
      lastCompanyDesignation: userV2?.professionalDetails?.lastCompanyDesignation || '',
      companyLabelText: userV2?.professionalDetails?.companyName,
    };

    if (userV2?.personalDetails?.personalRERARefistration?.registrationFileUrl) {
      setIsUploaded(true);
    }
    setInitialValues(initialFormValues); // Set initial values only once
    personalDetails.setFieldsValue(initialFormValues); // Set form values based on initial state
  }, [userV2, personalDetails]);

  const getPayloadTemplate = (e) => {
    return [
      {
        pageName: 'personalDetails',
        body: {
          name: e?.name || userV2?.name,
          phone: e?.phone || userV2?.phone,
          personalEmail: e?.personalEmail,
          aadharNumber: e?.aadharNumber,
          panNumber: e?.panNumber,
          imageUrl: userImgUrl,
          personalRERARefistration: {
            registrationId: reraIdpayload || userV2?.personalDetails?.personalRERARefistration?.registrationId,
            registrationFileUrl: fileUrl || userV2?.personalDetails?.personalRERARefistration?.registrationFileUrl,
          },
          linkedInProfileUrl: e?.linkedInUrl,
          twitterProfileUrl: e?.twitterUrl,
        },
      },
      {
        pageName: 'professionalDetails',
        body: {
          companyName: e?.companyName,
          industry: e?.industry,
          companyEmailId: e?.companyEmailId || userV2?.email,
          designation: e?.designation,
          experience: e?.yearsOfExperience,
          keySkills: e?.keySkills,
          lastCompanyIndustry: e?.lastCompanyIndustry,
          lastCompanyDesignation: e?.lastCompanyDesignation,
          lastCompanyName: e?.lastCompanyName,
        },
      },
    ];
  };

  const onFinish = (e) => {
    setIsSaveButtonEnabled(false);
    if (isFormEditable) {
      dispatch(updateUserProfileData(getPayloadTemplate(e)));
      return;
    }
    dispatch(setIsFormEditable(true));
    setIsUploaded(false);
  };

  const handleFormChange = (changedValues, allValues) => {
    // Ensure rera, reraFileURL, and companyLabelText are being captured
    const relevantCurrentValues = {
      ...allValues,
      rera: allValues.rera || initialValues.rera || '',
      reraFileURL: allValues.reraFileURL || initialValues.reraFileURL || '',
      companyLabelText: allValues.companyLabelText || initialValues.companyLabelText || '',
    };

    const relevantInitialValues = {
      ...initialValues,
      rera: initialValues.rera || '',
      reraFileURL: initialValues.reraFileURL || '',
      companyLabelText: initialValues.companyLabelText || '',
    };

    // Perform deep comparison to detect if the form is modified
    const isFormModified = !isEqual(relevantCurrentValues, relevantInitialValues);

    // Enable or disable Save button based on whether form is modified
    setIsSaveButtonEnabled(isFormModified);
  };

  const handleOpenVerifyOtpModal = (value, type) => {
    const body = {
      type: type,
      data: value,
    };
    setOtpBody(body);
    dispatch(sendOtp(body));
  };

  const handleVerifyOtp = (otp) => {
    const body = {
      ...otpBody,
      otp: otp.join(''),
    };
    dispatch(verifyOtp(body));
  };

  const handleUploadUserRERACertificate = (file) => {
    let data = new FormData();

    data.append('file', file);

    dispatch(UploadUserRERACertificate(data))
      .then((res) => {
        if (res?.payload?.data?.response?.code == '201') {
          const fileUrl = res?.payload?.data?.response?.data?.fileUrl;
          setIsUploaded(true);
          if (fileUrl) {
            personalDetails.setFieldsValue({ reraFileURL: fileUrl });
            setFileUrl(fileUrl);
            setIsSaveButtonEnabled(true); // Enable Save button when file is uploaded
          }
        }
      })
      .catch((error) => {
        console.error('Error occurred while uploading:', error);
      });
  };

  const handleCloseEmailVerification = () => {};

  return (
    <>
      <section className="personalDtlsSection">
        <Form
          className="personalDtlsSection-form"
          name="personalDetails"
          layout="vertical"
          onFinish={onFinish}
          form={personalDetails}
          onValuesChange={handleFormChange} // Trigger when form values change
        >
          <Card className="personalDtlsSection-form__card">
            <PersonalDetails
              isFormEditable={isFormEditable}
              loading={loading}
              user={userV2}
              profileCompletion={profileCompletion}
              handleOpenVerifyOtpModal={handleOpenVerifyOtpModal}
              uploadUserRERACertificate={handleUploadUserRERACertificate}
              personalDetails={personalDetails}
              setReraIdpayload={setReraIdpayload}
              isSaveButtonEnabled={isSaveButtonEnabled}
              isUploaded={isUploaded}
              setIsUploaded={setIsUploaded}
              fileUrl={fileUrl}
              setFileUrl={setFileUrl}
              setIsSaveButtonEnabled={setIsSaveButtonEnabled}
            />
          </Card>

          <Card className="personalDtlsSection-form__card">
            <CompanyDetails
              isFormEditable={isFormEditable}
              user={userV2}
              handleOpenVerifyOtpModal={handleOpenVerifyOtpModal}
            />
          </Card>
        </Form>
        <Card id="scrollableDiv" className="personalDtlsSection-form__cardtwo">
          <PostDetails activeAccountTab={activeAccountTab} isPersonalPosts={true} />
        </Card>
        <Modal
          className="verifyEmailBody"
          open={verifyOtpModalState}
          footer=""
          onCancel={() => dispatch(setVerifyOtpModalState(false))}
        >
          <VerifyOtp
            handleCloseEmailVerification={handleCloseEmailVerification}
            handleVerifyOtp={handleVerifyOtp}
            loading={loading}
            mediumType={otpBody?.type}
            user={userV2}
            otpBody={otpBody}
          />
        </Modal>
      </section>
    </>
  );
};

export default PersonalDetailsTab;
