import { Badge, Button, Card, Checkbox, Col, Divider, Flex, Row, Tag, Typography } from "antd";
import { useNavigate } from 'react-router';
import { LeftOutlined, DeleteOutlined } from "@ant-design/icons";
import "./RequirementDetails.scss";
const RequirementDetail = () => {
    const { Title, Text } = Typography;
    const navigate = useNavigate();
    const userDetails = {
        Name: "Sayali",
        Email: "sayali@altrr.com",
        Phone: 8988555221
    }

    const propertyDetails = {
        "Preferred Locality": "VB Capital",
        "Required Carpet Area": "Pune",
        "Budget Range": "Maharashtra",
        "Want Possession": "410445",
        "Open To Broker": "Yes",
        "Required Amenities": "Broker",
        "Expected ROI": "Lorem Ipsum",
        "Any Addition Listing": "Lorem Ipsum Lorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem Ipsum"
    }

    return (
        <div className="boxBackgroundListing">
            <Flex align justify="space-between" className="mb-20">
                <Flex gap={"middle"} justify="center">
                    <Button className="back-button" icon={<LeftOutlined onClick={() => navigate(-2)} />} />
                    <Title level={4} className="m-0">Requirement Details</Title>
                </Flex>
                <Button className="delete-button" icon={<DeleteOutlined />} danger />
            </Flex>
            <Row>
                <Col span={24}>
                    <Badge.Ribbon
                        placement="start"
                        text="Want To Buy"
                        color="orange"
                        className="fontWhite font16"
                    >
                        <Card className="boxShadow bgBlue">
                            <Flex align="center">
                                <Flex align="center">
                                    <Text className="fontWhite mr-10 font16 font900">Property Type</Text>
                                    <Tag color="green" className="font16 p-8">Commercial</Tag>
                                    <Checkbox className="fontWhite font14">Office Space</Checkbox>
                                    <Checkbox className="fontWhite font14">Showroom</Checkbox>
                                </Flex>
                            </Flex>
                        </Card>
                    </Badge.Ribbon>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className="boxBackgroundListing mt-10">
                        <Title level={5}>Contact Details</Title>
                        {Object.entries(userDetails).map(([key, value]) => (
                            <Row gutter={10}>
                                <Col span={2}>
                                    <Text><b>{key}:</b></Text>
                                </Col>
                                <Col span={10}>
                                    <Text>{value}</Text>
                                </Col>
                            </Row>
                        ))}
                        <Divider />
                        <Title level={5}>Requirement Details</Title>

                        {Object.entries(propertyDetails).reduce((rows, [key, value], index, array) => {
                            if (index % 2 === 0) {
                                rows.push(
                                    <Row key={index}>
                                        <Col span={6}>
                                            <Text><b>{key}:</b></Text>
                                        </Col>
                                        <Col span={6}>
                                            <Text>{value}</Text>
                                        </Col>
                                        {index + 1 < array.length && (
                                            <>
                                                <Col span={6}>
                                                    <Text><b>{array[index + 1][0]}:</b></Text>
                                                </Col>
                                                <Col span={6}>
                                                    <Text>{array[index + 1][1]}</Text>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                );
                            }
                            return rows;
                        }, [])}
                        <Divider />
                    </div>
                </Col>
            </Row >
        </ div >
    )

}

export default RequirementDetail