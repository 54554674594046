import { Card, Col, Row, Rate, List, Divider, Spin, Empty } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { nanoid } from '@reduxjs/toolkit';
import ProspectImage from '../../../../components/prospectImage/ProspectImage';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getSearchResults, doFollowUnfollowe } from '../../../../features/searchSlice';
import bookmark from '../../../../../assets/images/bookmark.svg';
import bookmark_selected from '../../../../../assets/images/bookmark_selected.svg';
import LottieAnimation from '../../../../../universal/components/lottieAnimation/LottieAnimation';
import animationData from '../../../../../assets/images/PropertyAndComapnyLottieImage.json';
import { useNavigate } from 'react-router';
import KebabMenu from '../../../../components/kebabMenu/KebabMenu';
import RaiseConcern from '../../../../../layout/universal/components/raiseConcern/RaiseConcern';
import { ShareModal } from '../../../../../utils/ShareModal/ShareModal';
import { setSelectedNav } from '../../../../features/universalSidenavSlice';
import { socialAppFollowUser, socialAppUnFollowUser } from '../../../../../universal/features/socialAppSlice';

import SelectedPropertyDetailsSkeleton from '../../../propertyDetailsPage/components/ProperyDetails/SelectedPropertyDetailsSkeleton';
import { useWindowSize } from '../../../../../utils/useWindowSize/useWindowSize';
import noData from '../../../../../assets/images/noData.svg';
const Contact = ({ connectsData, onDeleteCard, onSaveCard }) => {
  const { searchQuery, pagination, hasMoreData, searchResultLoader } = useSelector((store) => store.search);
  const { isAbove1020, isAbove1280, isAbove1320, isAbove1440 } = useWindowSize();
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const [showShareModal, setShowShareModal] = useState(null);
  const [showRaiseConcern, setShowRaiseConcern] = useState(false);
  const [selectedConnect, setSelectedConnect] = useState(null);

  // Function to show RaiseConcern
  const handleRaiseConcernClick = (item) => {
    setSelectedConnect(item);
    setShowRaiseConcern(true);
  };

  // Function to close RaiseConcern
  const handleCloseRaiseConcern = () => {
    setShowRaiseConcern(false);
    setSelectedConnect(null);
  };

  //function to subscribe and unsubscribe an organization

  const loadMoreData = () => {
    dispatch(
      getSearchResults({
        body: {
          query: searchQuery?.body?.query,
          offset: pagination?.offset,
          limit: pagination?.limit,
        },
      }),
    );
  };

  const handleFollow = (userId) => {
    return dispatch(
      socialAppFollowUser({
        userToFollow: userId,
      }),
    )?.then((res) => {
      if (res?.payload?.data?.response?.code == 201) {
        dispatch(
          doFollowUnfollowe({
            userId: userId,
            isFollow: true,
          }),
        );
      }
    });
  };
  const handleUnFollow = (userId) => {
    return dispatch(
      socialAppUnFollowUser({
        userToUnfollow: userId,
      }),
    )?.then((res) => {
      if (res?.payload?.data?.response?.code == 201) {
        dispatch(
          doFollowUnfollowe({
            userId: userId,
            isFollow: false,
          }),
        );
      }
    });
  };
  return (
    <div>
      {!isEmpty(connectsData) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            height: '30px',
            gap: '30px',
          }}
        >
          <div style={{ color: '#9f9f9f', marginLeft: '126px' }}>
            Your search result for connect as{' '}
            <i style={{ color: '#48a4fc', fontWeight: '600' }}>{searchQuery.body.query}</i> and similar contacts
          </div>
        </div>
      )}

      {/* ----------search card skeleton------- */}

      {isEmpty(connectsData) && searchResultLoader && (
        <div className="d-flex  flex-wrap g-10 jc-between">
          {[...new Array(20)]?.map(() => (
            <SelectedPropertyDetailsSkeleton className="searchCardSkeleton" key={nanoid()} />
          ))}
        </div>
      )}
      {/* ----------search card skeleton------- */}
      {!isEmpty(connectsData) ? (
        <div
          id="columnOneDiv"
          style={{ height: '80vh', overflow: 'auto', paddingBottom: '100px', marginTop: isAbove1440 ? '34px' : '14px' }}
          className={connectsData?.length < 3 ? 'columnOneDivNoWrap' : 'columnOneDiv'}
        >
          <InfiniteScroll
            dataLength={connectsData?.length}
            next={() => {
              loadMoreData();
            }}
            loader={
              <div className="d-flex jc-center">
                <Spin size={64} />
              </div>
            }
            // inverse
            // hasMore={hasMoreData}
            // endMessage={<Divider plain>It is all, nothing more</Divider>}
            scrollableTarget="columnOneDiv"
            hasMore={connectsData?.length >= 10 && hasMoreData}
            endMessage={
              connectsData?.length < 10 ? (
                <Divider plain>It is all, nothing more</Divider>
              ) : (
                <Divider plain>It is all, nothing more</Divider>
              )
            }
          >
            <List
              dataSource={connectsData}
              renderItem={(con) => (
                <List.Item
                  key={nanoid()}
                  style={{
                    borderBlockEnd: 'none',
                    width: '100%',
                    padding: '0px 0px',
                  }}
                >
                  <div key={con?._id} className="contactListContainer__div ">
                    <Card
                      className="contactListContainer__div__card cursor-pointer"
                      key={nanoid()}
                      onClick={() => {
                        navigateTo(`/leadGen/reverse/connectedDetails/${con?._id}`);
                        dispatch(setSelectedNav('LeadGen'));
                      }}
                    >
                      <Row className="contactListContainer__div__card__row">
                        <Col span={24} className="contactListContainer__div__card__row__col">
                          <Row className="contactListContainer__div__card__row__col__row">
                            <Col span={6} className="contactListContainer__div__card__row__col__row__col">
                              {con?.additionalConnectInfo?.assetsInfo?.[0]?.featuredImage ? (
                                <ProspectImage
                                  imageUrl={con?.additionalConnectInfo?.assetsInfo?.[0]?.featuredImage}
                                  isVerificationIcon
                                  size={60}
                                  imgClassName="contactListContainer__div__card__row__col__row__col__img"
                                />
                              ) : (
                                <div
                                  className="contactListContainer__div__card__row__col__row__col__img_lottie"
                                  style={{
                                    border: '1px solid black',
                                    borderRadius: '50%',
                                    marginBottom: '5px',
                                  }}
                                >
                                  <LottieAnimation
                                    height={'100%'}
                                    width={'100%'}
                                    animationData={animationData}
                                    loop={true}
                                  />
                                </div>
                              )}
                            </Col>
                            <Col
                              md={{ span: 11 }}
                              lg={{ span: 14 }}
                              xl={{ span: 15 }}
                              className="contactListContainer__div__card__row__col__row__col1"
                            >
                              <h5
                                className="contactListContainer__div__card__row__col__row__col1__h5"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigateTo(`/leadGen/connectDetails/${con?._id}`);
                                  dispatch(setSelectedNav('LeadGen'));
                                }}
                              >
                                <u style={{ color: '#0081FC' }}>{con?.personalInfo?.personName}</u>
                              </h5>
                              <p className="contactListContainer__div__card__row__col__row__col1__para">
                                {con?.additionalConnectInfo?.designation || ''}
                              </p>

                              <Rate allowHalf disabled defaultValue={con?.averageRating || 4} />
                            </Col>

                            <Col span={2} className="contactListContainer__div__card__row__col__row__col2">
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                {!con?.isSaved ? (
                                  <img
                                    src={bookmark}
                                    alt="bookmark"
                                    style={{
                                      visibility: !con?.isResourceLocked ? 'hidden' : 'visible',
                                      cursor: 'pointer',
                                      marginRight: '9px'
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onSaveCard(
                                        con?._id,
                                        con?.personalInfo?.personName,
                                        'connect',
                                        ['personalInfo'],
                                        'commercial',
                                      );
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={bookmark_selected}
                                    alt="bookmark_selected"
                                    style={{
                                      visibility: !con?.isResourceLocked ? 'hidden' : 'visible',
                                      cursor: 'pointer',
                                      marginRight: '9px'

                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onDeleteCard(con?._id, con?.personalInfo?.personName, 'connect', [], 'commercial');
                                    }}
                                  />
                                )}

                                <KebabMenu
                                  isFollowed={con?.isFollowed}
                                  isSystemUser={con?.isSystemUser}
                                  onShare={() => {
                                    const connectId = con?._id;
                                    const url = new URL(`/leadGen/connectDetails/${connectId}`, window.location.origin);
                                    url.searchParams.set('shareable', 'true');
                                    setShowShareModal(url.toString());
                                  }}
                                  onRaiseConcern={() => handleRaiseConcernClick(con)}
                                  resourceId={con}
                                  isConnectCard
                                  onFollow={() => {
                                    handleFollow(con?._id);
                                  }}
                                  onUnFollow={() => {
                                    handleUnFollow(con?._id);
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="contactListContainer__div__card__row__col__row1__col__row">
                            <Col span={14} className="contactListContainer__div__card__row__col__row1__col__row__col">
                              <Row className="contactListContainer__div__card__row__col__row1__col1__row">
                                <h5 className="contactListContainer__div__card__row__col__row1__col1__row__h5">
                                  Company:
                                </h5>
                                <p className="contactListContainer__div__card__row__col__row1__col1__row__para">
                                  {con?.additionalConnectInfo?.companyName ?? 'Awaited'}
                                </p>
                              </Row>
                              <Row className="contactListContainer__div__card__row__col__row1__col1__row">
                                <h5 className="contactListContainer__div__card__row__col__row1__col1__row__h5">
                                  Yrs Of Exp:
                                </h5>
                                <p className="contactListContainer__div__card__row__col__row1__col1__row__para">
                                  {con?.additionalConnectInfo?.experience ?? 'Awaited'}
                                </p>
                              </Row>
                            </Col>

                            <Col span={10} className="contactListContainer__div__card__row__col__row1__col1">
                              <Row className="contactListContainer__div__card__row__col__row1__col1__row">
                                <h5 className="contactListContainer__div__card__row__col__row1__col1__row__h5">
                                  Industry:
                                </h5>
                                <p className="contactListContainer__div__card__row__col__row1__col1__row__para">
                                  {con?.additionalConnectInfo?.currentIndustry ?? 'Awaited'}
                                </p>
                              </Row>
                              <Row className="contactListContainer__div__card__row__col__row1__col1__row">
                                <h5 className="contactListContainer__div__card__row__col__row1__col1__row__h5">
                                  Key Skill:
                                </h5>
                                <p className="contactListContainer__div__card__row__col__row1__col1__row__para">
                                  {con?.additionalConnectInfo?.keySkills ?? 'Awaited'}
                                </p>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Divider style={{ marginLeft: '0px' }} />
                        <div style={{ margin: 'auto' }}>
                          <h3
                            onClick={(e) => {
                              e.stopPropagation();
                              navigateTo(`/leadGen/connectDetails/${con?._id}`);
                              dispatch(setSelectedNav('LeadGen'));
                            }}
                            className="organisation-hover"
                          >
                            View Details
                          </h3>
                        </div>
                      </Row>
                    </Card>
                  </div>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div>
      ) : (
        <div
          className='d-flex jc-center ai-center h-90vh'
        >
            <Empty
            image={noData}
            className='empty-image'
            description={
              <span className="custom-description font18 font600">
                No Data
              </span>
            } />
        </div>
      )}

      {showRaiseConcern && (
        <RaiseConcern
          resourceDetails={{
            resourceType: 'commercial',
            resourceSubType: 'connect',
            resourceId: selectedConnect?._id,
            resourceName: selectedConnect?.personalInfo?.personName || '',
          }}
          onClose={handleCloseRaiseConcern}
        />
      )}
      {showShareModal && (
        <ShareModal shareUrl={showShareModal} showShareModal={showShareModal} setShowShareModal={setShowShareModal} />
      )}
    </div>
  );

};

export default Contact;
