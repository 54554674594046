import { Avatar, Rate } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import bookmark from '../../../../../assets/images/bookmark.svg';
import bookmark_selected from '../../../../../assets/images/bookmark_selected.svg';
import './employeeDetails.scss';
import KebabMenu from '../../../../components/kebabMenu/KebabMenu';
import RaiseConcern from '../../../../../layout/universal/components/raiseConcern/RaiseConcern';
import { ShareModal } from '../../../../../utils/ShareModal/ShareModal';
import { useSubscriptionHandlers } from '../../../../../leadgen/services/subscribtionsHandler';
import { useDispatch, useSelector } from 'react-redux';
import LottieAnimation from '../../../../../universal/components/lottieAnimation/LottieAnimation';
import animationDataEmp from '../../../../../assets/images/PropertyAndComapnyLottieImage.json';
import { useWindowSize } from '../../../../../utils/useWindowSize/useWindowSize';
import { socialAppFollowUser, socialAppUnFollowUser } from '../../../../../universal/features/socialAppSlice';
import { doFollowUnfollowe } from '../../../../features/searchSlice';

const SelectedConnectDetails = ({ connectData, onDeleteCard, onSaveCard }) => {
  // hooks
  const navigateTo = useNavigate();
  const [showShareModal, setShowShareModal] = useState(null);
  const [showRaiseConcern, setShowRaiseConcern] = useState(false);
  const { isAbove1020, isAbove1280, isAbove1440 } = useWindowSize();
  const dispatch = useDispatch();
  const { raiseModal } = useSelector((store) => store.myAccount);
  const [zIndexer, setZindexer] = useState(false);
  useEffect(() => {
    setZindexer(raiseModal);
  }, [raiseModal]);

  // Function to show RaiseConcern
  const handleRaiseConcernClick = () => {
    setShowRaiseConcern(true);
  };
  // Function to close RaiseConcern
  const handleCloseRaiseConcern = () => {
    setShowRaiseConcern(false);
  };
  const handleFollow = (userId) => {
    return dispatch(
      socialAppFollowUser({
        userToFollow: userId,
      }),
    )?.then((res) => {
      if (res?.payload?.data?.response?.code == 201) {
        dispatch(
          doFollowUnfollowe({
            userId: userId,
            isFollow: true,
          }),
        );
      }
    });
  };
  const handleUnFollow = (userId) => {
    return dispatch(
      socialAppUnFollowUser({
        userToUnfollow: userId,
      }),
    )?.then((res) => {
      if (res?.payload?.data?.response?.code == 201) {
        dispatch(
          doFollowUnfollowe({
            userId: userId,
            isFollow: false,
          }),
        );
      }
    });
  };
  return (
    <>
      <div className="employeeDetailsCard">
        <div className=" g-5 mt-10 flex-1">
          <div className="d-flex jc-between a-center mt-5 ">
            <div className="d-flex a-center g-5">
              {connectData?.otherCompanyInfo?.assetsInfo?.[0]?.featuredImage ? (
                <Avatar className="companyAvatar" src={connectData?.otherCompanyInfo?.assetsInfo?.[0]?.featuredImage} />
              ) : (
                <div
                  style={{
                    marginRight: '2px',
                    border: '1px solid black',
                    width: isAbove1020 ? '35px' : isAbove1280 ? '40px' : isAbove1440 ? '50px' : '60px',
                    height: isAbove1020 ? '35px' : isAbove1280 ? '40px' : isAbove1440 ? '50px' : '60px',
                    borderRadius: '50%',
                  }}
                >
                  <LottieAnimation height={'100%'} width={'100%'} animationData={animationDataEmp} loop={true} />
                </div>
              )}
              <div>
                <h5
                  style={{ color: '#0081FC' }}
                  className="text-underline companyContainer__div__card__row__col__row__col1__h5 cursor-pointer"
                >
                  {connectData?.personalInfo?.personName}
                </h5>
                <p className="contactListContainer__div__card__row__col__row__col1__para">
                  {connectData?.additionalConnectInfo?.designation || ''}
                </p>
                <Rate
                  allowHalf
                  disabled
                  defaultValue={
                    !isNaN(Number(connectData?.averageRating)) ? Number(connectData?.averageRating || 4) : 4
                  }
                />
              </div>
            </div>
            <div className="d-flex" style={{ marginTop: '-25px' }}>
              {!connectData?.isSaved ? (
                <img
                  src={bookmark}
                  alt="bookmark"
                  style={{
                    visibility: !connectData?.isResourceLocked ? 'hidden' : 'visible',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onSaveCard(
                      connectData?._id,
                      connectData?.personalInfo?.personName,
                      'connect',
                      ['personalInfo'],
                      'commercial',
                    );
                  }}
                />
              ) : (
                <img
                  src={bookmark_selected}
                  alt="bookmark_selected"
                  style={{
                    visibility: !connectData?.isResourceLocked ? 'hidden' : 'visible',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteCard(connectData?._id, connectData?.personalInfo?.personName, 'connect', [], 'commercial');
                  }}
                />
              )}
              <span>
                <KebabMenu
                  isFollowed={connectData?.isFollowed}
                  isSystemUser={connectData?.isSystemUser}
                  onShare={() => {
                    const connectId = connectData?._id;
                    const url = new URL(`/leadGen/connectDetails/${connectId}`, window.location.origin);
                    url.searchParams.set('shareable', 'true');
                    setShowShareModal(url.toString());
                  }}
                  onRaiseConcern={handleRaiseConcernClick}
                  resourceId={connectData}
                  isConnectCard
                  onFollow={() => {
                    handleFollow(connectData?._id);
                  }}
                  onUnFollow={() => {
                    handleUnFollow(connectData?._id);
                  }}
                />
                {showShareModal && (
                  <ShareModal
                    shareUrl={showShareModal}
                    showShareModal={showShareModal}
                    setShowShareModal={setShowShareModal}
                  />
                )}
              </span>
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex w-50 d-column font10 fontLight-2 mt-10 ">
              <div className=" fontLight-2  p-5">
                <span className="bold">Company : </span> {connectData?.additionalConnectInfo?.CompanyName || ' Awaited'}
              </div>
              <div className="fontLight-2  p-5">
                <span className="bold">Yrs Of Exp : </span>{' '}
                {connectData?.additionalConnectInfo?.experience || ' Awaited'}
              </div>
            </div>
            <div className="d-flex w-50 d-column font10 fontLight-2 mt-10 ">
              <div className="fontLight-2  p-5">
                <span className="bold">Industry : </span>{' '}
                {connectData?.additionalConnectInfo?.currentIndustry || ' Awaited'}
              </div>
              <div className=" fontLight-2  p-5">
                <span className="bold">Key Skills : </span>{' '}
                {connectData?.additionalConnectInfo?.keySkills || ' Awaited'}
              </div>
            </div>
          </div>
        </div>
        <div className="bottomRow  d-flex jc-center">
          <span
            className="property-hover font10 cursor-pointer bold"
            onClick={() => navigateTo(`/leadGen/connectDetails/${connectData?._id}`)}
          >
            View More Details
          </span>
        </div>
      </div>
      {showRaiseConcern && (
        <RaiseConcern
          resourceDetails={{
            resourceType: 'commercial',
            resourceSubType: 'connect',
            resourceId: connectData?._id,
            resourceName: connectData?.personalInfo?.personName || '',
          }}
          onClose={handleCloseRaiseConcern}
        />
      )}
      {showShareModal && (
        <ShareModal shareUrl={showShareModal} showShareModal={showShareModal} setShowShareModal={setShowShareModal} />
      )}
    </>
  );
};

export default SelectedConnectDetails;
